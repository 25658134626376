import React from 'react';
import './Footer.scss'

function Footer(props) {
  return (
    <>
      <footer className={`footer-admin tx-small neutral200 d-flex justify-content-center align-items-center ${props.className}`}>
        <div>© {new Date().getFullYear()} PT Atlaz Belajar Bahasa. All Right Reserved</div>
      </footer>
    </>
  )
}

export default Footer;