import React, { useState, useEffect } from "react";
import "./SubTopicEmbedHtml5.scss";
import * as Yup from "yup";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import Toast from "../../../../atoms/Toast";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import { useParams } from 'react-router';
import { ModalLink, TitleSection } from "../../../../molecules";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import { 
  NotInterested
 } from '@mui/icons-material';

const SubTopicEmbedHtml5 = () => {
  const { id } = useParams()
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)
  const idSubTopic = localStorage.getItem('ID_SUBTOPIC');
  const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SUBTOPIC'))
  const [initialValues, setInitialValues] = useState({
    title: "",
    fileWidth: '',
    fileHeight: '',
    documentPath: "",
    subTopicId: "",
    contentTypeId: "",
  })

  const contentType = JSON.parse(localStorage.getItem('CONTENT_TYPE'))
  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(!idSubTopic) {
      navigate("/subtopic")
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config).then(res => {
        dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res })
      }).catch((err) => {
        console.log(err.response)
        navigate("/notfound")
      });
      if(id) {
          RestAPI.find(`content/embed/${contentType.id}`, config).then(res => {
          setInitialValues({
            title: res.title,
            fileWidth: res.fileWidth,
            fileHeight: res.fileHeight,
            documentPath: res.documentPath,
            subTopicId: "",
            contentTypeId: res._id,
          })
        })
      }
      dispatch({
        type: 'OPEN_MODAL', newValue: {
          status: false,
          target: '',
          crud: null,
          modal: '',
        }
      })
    }
  }, [])

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Embed HTML5", path: "" },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("This field is required. Don't leave it empty!"),
    fileWidth: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .required("This field is required. Don't leave it empty!"),
    fileHeight: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .required("This field is required. Don't leave it empty!"),
    documentPath: Yup.string().required("This field is required. Don't leave it empty!"),
  });

  const onSubmit = (values, {setSubmitting}) => {
    if(!id) {
      values = {
        ...values,
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
      }
      RestAPI.post("content/embed", values, config)
        .then((r) => {
          processSuccess('Created', 'create')
        })
        .catch((err) => {
          processFailed('create')
          setSubmitting(false);
        });
    } else {
      values = {
        ...values,
        contentTypeId: contentType.id,
      }
      RestAPI.put(`content/embed/${contentType.id}`, values, config)
        .then((r) => {
          processSuccess('Updated', 'update')
        })
        .catch((err) => {
          processFailed('update')
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
      ...getWhereFrom,
      toast: true,
      message: { title: message1, desc: message2}
    }));
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  }

  const processFailed = (message) => {
    toast.error(contentToast(`Failed to ${message}!`, "Your connection not stable. Try again."), {
      icon: (<NotInterested style={{ color: "var(--danger500)", fontSize: "30px" }}
      />
      ),
    });
  }

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel input',
    description: '',
    message: 'Continue to cancel input? Once canceled, any changes will be lost.',
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: 'Yes',
    footerNo: 'No',
  }

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="embed-html5">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Embed HTML5" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form className="formFormik">
                  <div className="col-12">
                    <div className="row mb-32">
                      <div className="col-md-5">
                        <div className="tx-small-po">Title</div>
                      </div>
                      <div className="col-md-7">
                        <FormikControl addClass="isTaSmall isMoExSmall" control="input" type="text" name="title" placeholder="e.g Atlazone" />
                      </div>
                    </div>

                    <div className="row mb-32">
                      <div className="col-md-5">
                        <div className="tx-small-po mb-1">File Width</div>
                        <div className="desc tx-ex-small neutral400">
                          Default width 1280px
                        </div>
                      </div>
                      <div className="col-md-7">
                        <FormikControl addClass="isTaSmall isMoExSmall" control="input" type="number" name="fileWidth" placeholder="e.g 1280" />
                      </div>
                    </div>

                    <div className="row mb-32">
                      <div className="col-md-5">
                        <div className="tx-small-po mb-1">File Height</div>
                        <div className="desc tx-ex-small neutral400">
                          Default width 720px
                        </div>
                      </div>
                      <div className="col-md-7">
                        <FormikControl addClass="isTaSmall isMoExSmall" control="input" type="number" name="fileHeight" placeholder="e.g 720" />
                      </div>
                    </div>

                    <div className="row mb-24">
                      <div className="col-md-5">
                        <div className="tx-small-po mb-1">Embed Link</div>
                        <div className="desc tx-ex-small neutral400">
                          Path to document or file want to embed.
                        </div>
                      </div>
                      <div className="col-md-7">
                        <FormikControl addClass="isTaSmall isMoExSmall" control="input" type="text" name="documentPath" placeholder="e.g. https://hiatlaz.com" />
                      </div>
                    </div>

                  </div>

                  <div className="text-end btn-form">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline btnRegular btnMoExSmall
                      data={modalBack} path="MODAL_LINK">Cancel</Buttons>
                    <Buttons
                      control='button'
                      type="submit"
                      name={id ? 'Update' : 'Save'}
                      addClass='py-8 px-60'
                      btnPrimary btnRegular btnMoExSmall
                      btnDisable={formik.isSubmitting} disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};
export default connect()(SubTopicEmbedHtml5);