import React from 'react'
import { ModalCreateInstitution, ModalDeleteInstitution, TableInstitution, TitleSection } from '../../../molecules'
import { useInstitutionList } from '../../../services'

function IntituionList() {
    const {
      dataCrumbs,
    } = useInstitutionList()

  return (
    <section className="school-list">
      <div className="container-fluid">
        <div className="row">
          <TitleSection data={dataCrumbs} titleheader="Fondations Information" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <TableInstitution />
            <ModalCreateInstitution />
            <ModalDeleteInstitution />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntituionList