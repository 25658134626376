import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

function Checkbox (props) {
  const { label, name, options, onClick, addClass, inputClass, labelClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, ...rest } = props

  const className = ["formFormik"];
  const inputClassName = ["check-control"];
  const labelClassName = ["label-checkbox"];
  className.push(addClass);
  inputClassName.push(inputClass);
  labelClassName.push(labelClass);

  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")
  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {({ field }) => {
          return options.map(option => {
            return (
              <React.Fragment key={option.value}>
                <input
                  type='checkbox'
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  onClick={onClick}
                  // checked={Boolean(option.value)}
                  className={inputClassName.join(" ")}
                />
                <label className={labelClassName.join(" ")} htmlFor={option.value}>{option.key}</label>
              </React.Fragment>
            )
          })
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Checkbox