import React from "react";
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import { connect } from 'react-redux'
import { ModalLink, ModalRedirect, MemberInfoEdit } from '../../../../molecules';
import { useMemberEdit } from "../../../../services";
import {
  ArrowBack,
} from '@mui/icons-material';
import "./MemberEdit.scss";

function MemberEdit() {
  const {
    backStatus,
    dataCrumbs,
    modalBack,
  } = useMemberEdit()

  return (
    <>
      <ModalLink />
      <ModalRedirect />
      <section className="member-info">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} />
            <div className="col-md-12 nav-back">
              <Buttons
                control='modalLink'
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : 'MODAL_REDIRECT'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <MemberInfoEdit />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default connect()(MemberEdit)
