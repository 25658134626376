import React, { useState, useEffect } from "react";
import "../Modal.scss";
import * as Yup from "yup";
import RestAPI from "../../../../config/RestAPI";
import Buttons from "../../../atoms/Buttons";
import Modal from "react-modal";
import FormikControl from "../../../atoms/Formik/FormikControl";
import { useParams } from 'react-router';
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalUpdateLesson() {
  const { id } = useParams()
  const statusMODAL = "MODAL_UPDATE_LESSON";
  const isStatusMODAL = useSelector((state) => state.openModal);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    name: "",
    subjectId: "",
    imageCover: "",
    fakeImage: "",
  });

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      if (isStatusMODAL.modal === "EDIT") {
        setInitialValues({
          _id: isStatusMODAL.crud._id,
          name: isStatusMODAL.crud.name,
          subjectId: isStatusMODAL.crud.subject
            ? isStatusMODAL.crud.subject._id
            : "",
          imageCover: isStatusMODAL.crud.imageCover,
        });
      } 
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    setInitialValues({
      name: "",
      subjectId: "",
      imageCover: "",
      fakeImage: "",
    });
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };
  const onSubmit = (values) => {
    if (isStatusMODAL.modal === "CREATE") {
      values = {
        ...values,
        subjectId: id,
      };
      RestAPI.post("lesson", values, config)
        .then(() => {
          reloadTable("Created", "create");
        })
        .catch(failedProcess);
    } else {
      values = {
        ...values,
        subjectId: id,
      };
      RestAPI.put(`lesson/${values._id}`, values, config).then(() => {
        setInitialValues({
          name: "",
          subjectId: "",
          imageCover: "",
          fakeImage: "",
        });
        reloadTable('Updated', 'update')
      })
          .catch(failedProcess);
    }
  };
  const reloadTable = (mess1, mess2) => {
    RestAPI.find(`content/frame?subjectId=${id}`, config).then(res => {
      dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: res[0].subject.lessons })
    })
    toast.success(
      contentToast(`Lesson ${mess1}!`, `Success to ${mess2} the lesson.`),
      {
        icon: (
          <CheckCircleOutline
            style={{ color: "var(--info500)", fontSize: "30px" }}
          />
        ),
      }
    );
    closeModal();
  };
  const failedProcess = () => {
    toast.error(
      contentToast(
        "Failed to process",
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
    closeModal();
  };

  return (
    <>
      <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
        <div className="modal-content isForm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="formFormik">
                <div className="modal-header">
                  <h5 className="tx-header5">
                    {isStatusMODAL.modal === "CREATE" ? "Create New" : "Edit"}{" "}
                    Lesson
                  </h5>
                  <p className="tx-small neutral300">
                    {isStatusMODAL.modal === "CREATE"
                      ? "Fill all input to create new lesson"
                      : "Fill all input to change info of selected lesson."}
                  </p>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="input"
                        type="text"
                        name="name"
                        placeholder="e.g. Unit 1 - How's Life?"
                        label="Lesson name"
                        isLabel
                      />
                    </div>
                    <div style={{ height: "24px" }}></div>
                    <div className="col-12">
                      <label
                        htmlFor="imageCover"
                        className="form-label tx-small-po"
                      >
                        Lesson cover{" "}
                        <span className="tx-small-qu">(optional)</span>
                      </label>

                        <FormikControl
                          addClass="isTaSmall isMoExSmall"
                          control="inputbase64"
                          type="file"
                          name={`fakeImage`}
                          base64Name="imageCover"
                        />
                        <div className="tx-small mt-1 neutral400">
                          For best quality, please upload with size 1000x500 pixel.
                        </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Buttons
                    control="button"
                    type="reset"
                    btnExSmall
                    btnOutline
                    name="Cancel"
                    onClick={() => closeModal()}
                  />
                  <Buttons
                    control="button"
                    type="submit"
                    name={
                      isStatusMODAL.modal === "CREATE" ? "Create" : "Update"
                    }
                    addClass="btn-confirm"
                    btnExSmall
                    btnOutline
                    btnDisable={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default ModalUpdateLesson
