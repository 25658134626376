import React, { useEffect, useRef, useState } from "react";
import RestAPI from "../../../config/RestAPI";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../atoms/Toast/ContentToast";
import { NotInterested, CheckCircleOutline } from "@mui/icons-material";
import * as Yup from "yup";

const useResourcesWorksheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editorRef = useRef(null);
  const inpFile = useRef();

  const { idType, id } = useParams();
  const [actionType, setActionType] = useState("");

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = { headers: { token: "Bearer " + tokenAccess.accessToken } };

  const [levelOptions, setLevelOptions] = useState([
    { key: "None", value: "" },
  ]);
  const initDataLevel = () => {
    RestAPI.find("level", config).then((res2) => {
      let data = [{ key: "Select level", value: "" }];
      res2.map((lvl) => {
        data.push({
          key: lvl.level,
          value: lvl.level,
        });
      });
      setLevelOptions(data);
    });
  };

  const cefrOption = ["Pre A1", "A1", "A2", "B1", "B2", "C1", "C2"];

  const [initialValues, setInitialValues] = useState({
    name: "",
    type: idType,
    attachment: "",
    fakeAttachment: "",
    level: "",
    skill: "",
    contentType: "",
    author: "",
    cefr: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    skill: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    contentType: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    author: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    cefr: Yup.string().required("Select One. Don't leave it empty"),
  });

  const initUpdateData = () => {
    RestAPI.find(`resources/teacher_resources/${id}`, config)
      .then((res) => {
        setInitialValues({
          ...initialValues,
          name: res.name ? res.name : "",
          attachment: res.attachment ? res.attachment : "",
          level: res.level ? res.level : "",
          skill: res.skill ? res.skill : "",
          contentType: res.contentType ? res.contentType : "",
          author: res.author ? res.author : "",
          cefr: res.cefr ? res.cefr : "",
        });
      })
      .catch((err) => {
        console.log("error", err.response);
      });
  };

  const _validFilePdf = [".pdf"];
  const checkFormatPdf = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFilePdf.length; j++) {
        let sCurExtension = _validFilePdf[j];
        if (
          value
            .substr(value.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true;
      }
    }
  };

  const [sizePdf1, setSizePdf1] = useState(0);
  const validateFilePdf1 = (value, init) => {
    let error;
    if (init === "") {
      error = "This field is required. Don't leave it empty!";
    }
    if (sizePdf1 > 5000000) {
      error = "File size is too large";
    }
    if (value && checkFormatPdf(value)) {
      error = "The file format is not suitable!";
    }
    return error;
  };

  const changeBackStatus = (formik, initial, value) => {
    const newInitial = {
      ...formik.values,
      [initial]: value,
    };
    const {
      name,
      fakeAttachment,
      attachment,
      level,
      skill,
      contentType,
      author,
      cefr,
    } = newInitial;

    if (
      actionType === "Create" &&
      (name || attachment || level || skill || contentType || author || cefr)
    ) {
      dispatch({ type: "BACK_STATUS", newValue: true });
    } else if (
      actionType === "Update" &&
      (name !== initialValues.name ||
        fakeAttachment !== initialValues.fakeAttachment ||
        attachment !== initialValues.attachment ||
        level !== initialValues.level ||
        skill !== initialValues.skill ||
        contentType !== initialValues.contentType ||
        author !== initialValues.author ||
        cefr !== initialValues.cefr)
    ) {
      dispatch({ type: "BACK_STATUS", newValue: true });
    } else {
      dispatch({ type: "BACK_STATUS", newValue: false });
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (actionType === "Create") {
      RestAPI.post("resources/teacher_resources/create", values, config)
        .then((res) => {
          dispatch({ type: "BACK_STATUS", newValue: false });
          localStorage.setItem(
            "CRUD_RESOURCES",
            JSON.stringify({
              goal: "CREATE",
              toast: true,
            })
          );
          setSubmitting(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
          setInterval(1000);
          navigate(`/teacher-resources`);
        })
        .catch((err) => {
          console.log("error", err.response);
          setSubmitting(false);
          toast.error(
            contentToast(
              "Failed to create",
              "Your connection not stable. Try again."
            ),
            {
              icon: (
                <NotInterested
                  style={{ color: "var(--danger500)", fontSize: "30px" }}
                />
              ),
            }
          );
        });
    } else if (actionType === "Update") {
      RestAPI.put(`resources/teacher_resources/update/${id}`, values, config)
        .then((res) => {
          dispatch({ type: "BACK_STATUS", newValue: false });
          setSubmitting(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
          toast.success(
            contentToast(
              "Teacher Resources Updated!",
              "Success to update the teacher resources."
            ),
            {
              icon: (
                <CheckCircleOutline
                  style={{ color: "var(--info500)", fontSize: "30px" }}
                />
              ),
            }
          );
        })
        .catch((err) => {
          console.log("error", err.response);
          setSubmitting(false);
          window.scrollTo({ top: 0, behavior: "smooth" });
          toast.error(
            contentToast(
              "Failed to update",
              "Your connection not stable. Try again."
            ),
            {
              icon: (
                <NotInterested
                  style={{ color: "var(--danger500)", fontSize: "30px" }}
                />
              ),
            }
          );
        });
    }
  };

  useEffect(() => {
    if (!idType && !id) {
      navigate("/not-found");
    }
    if (idType && !id) {
      setActionType("Create");
      initDataLevel();
    } else if (idType && id) {
      setActionType("Update");
      initDataLevel();
      initUpdateData();
    }
  }, []);

  return {
    id,
    idType,
    editorRef,
    inpFile,
    tokenAccess,
    levelOptions,
    setLevelOptions,
    initDataLevel,
    initialValues,
    validationSchema,
    actionType,
    cefrOption,
    changeBackStatus,
    onSubmit,
    setSizePdf1,
    validateFilePdf1,
  };
};

export default useResourcesWorksheet;
