import React from "react";
import Buttons from "../../../atoms/Buttons";
import Toast from "../../../atoms/Toast";
import FormikControl from "../../../atoms/Formik/FormikControl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useResourcesWorksheet } from "../../../services";
import "./ResourcesWorksheet.scss";
import TextError from "../../../atoms/Formik/TextError";

function ResourcesWorksheet() {
  const {
    levelOptions,
    initialValues,
    validationSchema,
    actionType,
    cefrOption,
    onSubmit,
    changeBackStatus,
    setSizePdf1,
    validateFilePdf1,
  } = useResourcesWorksheet();

  return (
    <>
      <Toast />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="formSubjectInfo formFormik">
            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Worksheet Name</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="name"
                  placeholder="e.g. English Worksheet"
                  onInput={(e) =>
                    changeBackStatus(formik, "name", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">Upload file</div>
                <div className="desc tx-ex-small neutral400">
                  Format file .pdf
                </div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="inputfile"
                  name="fakeAttachment"
                  base64Name="attachment"
                  accept=".pdf"
                  validate={(e) =>
                    validateFilePdf1(e, formik.values.attachment)
                  }
                  defFile={initialValues}
                  onFileChange={(e) => {
                    setSizePdf1(e.size);
                    changeBackStatus(
                      formik,
                      "attachment",
                      e.name !== undefined ? e.name : ""
                    );
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row visually-hidden">
              {" "}
              {/*this level are hidden*/}
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">Level</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="select"
                  name="level"
                  options={levelOptions}
                  onInput={(e) => {
                    formik.setFieldValue("level", e.target.value);
                    changeBackStatus(formik, "level", e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">CEFR Level</div>
              </div>
              <div className="col-md-3 d-flex justify-content-between">
                <div className="d-flex flex-column gap-3">
                  {cefrOption.map((val, ind) => {
                    if (ind <= 3) {
                      return (
                        <label>
                          <Field
                            className="me-2"
                            type="radio"
                            name="cefr"
                            value={val}
                            onInput={(e) => {
                              formik.setFieldValue("cefr", e.target.value);
                              changeBackStatus(formik, "cefr", e.target.value);
                            }}
                          />
                          {val}
                        </label>
                      );
                    }
                  })}
                  <ErrorMessage component={TextError} name="cefr" />
                </div>
                <div className="d-flex flex-column gap-3">
                  {cefrOption.map((val, ind) => {
                    if (ind >= 4) {
                      return (
                        <label>
                          <Field
                            className="me-2"
                            type="radio"
                            name="cefr"
                            value={val}
                            onInput={(e) => {
                              formik.setFieldValue("cefr", e.target.value);
                              changeBackStatus(formik, "cefr", e.target.value);
                            }}
                          />
                          {val}
                        </label>
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Skill</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="skill"
                  placeholder="e.g. Reading"
                  onInput={(e) =>
                    changeBackStatus(formik, "author", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Content Type</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="contentType"
                  placeholder="e.g. Fill in the blank"
                  onInput={(e) =>
                    changeBackStatus(formik, "author", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Author Name</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="author"
                  placeholder="e.g. Atlaz"
                  onInput={(e) =>
                    changeBackStatus(formik, "author", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="height54"></div>

            <div className="text-end">
              <Buttons
                control="button"
                type="submit"
                name={actionType}
                addClass="btnMoBlock btnCreateForm text-capitalize"
                btnRegular
                btnMoExSmall
                btnPrimary
                btnDisable={formik.isSubmitting}
                disabled={formik.isSubmitting}
                onClick={() =>
                  window.scrollTo({ top: 200, behavior: "smooth" })
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ResourcesWorksheet;
