import React, { useEffect, useState } from "react";
import "./SubTopicDetailShow.scss";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import TextEditorShow from "./TextEditorShow";
import EmbedHtml5Show from "./EmbedHtml5Show";
import MultipleChoiceShow from "./MultipleChoiceShow";
import SingleChoiceShow from "./SingleChoiceShow";
import EssayShow from "./EssayShow";
import ShortAnswerShow from "./ShortAnswerShow";
import CompleteParahraphShow from "./CompleteParahraphShow";
import DialogAndNumberingShow from "./DialogAndNumberingShow";
import { useParams } from "react-router";
import { ModalCreateContent, ModalDeleteContent } from "../../../../molecules";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import {
  CheckCircleOutline,
  NotInterested,
  FiberManualRecord,
  Add,
  Visibility,
} from "@mui/icons-material";
import EmptySpaceShow from "./EmptySpaceShow";
import CodeEditorShow from "./CodeEditorShow";
import MatchPairsShow from "./MatchPairsShow";
import Skeleton from "react-loading-skeleton";
const SubTopicDetailShow = (props) => {
  const { dataSubTopic } = props;
  const { id } = useParams();
  // const inputContent = JSON.parse(localStorage.getItem("INPUT_CONTENT"));
  const getWhereFrom = JSON.parse(localStorage.getItem("EDIT_SUBTOPIC"));
  const [dataContent, setDataContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initData = () => {
    setIsLoading(true);
    localStorage.setItem("ID_SUBTOPIC", id);
    RestAPI.find(`content/preview?subTopicId=${id}`, config).then((res) => {
      setDataContent(res);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false)
    })
  };

  useEffect(() => {
    setIsLoading(false)
    if (dataSubTopic) {
      initData();
    }
  }, [dataSubTopic]);

  const showContent = (data, index) => {
    switch (data.contentType.name.toLowerCase()) {
      case "text editor":
        return (
          <TextEditorShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "embed html5":
        return (
          <EmbedHtml5Show
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "single choice":
        return (
          <SingleChoiceShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "multiple choice":
        return (
          <MultipleChoiceShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "complete a paragraph":
        return (
          <CompleteParahraphShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "fill in the blank":
        return (
          <DialogAndNumberingShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "short answer":
        return (
          <ShortAnswerShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "essay":
        return (
          <EssayShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "empty space":
        return (
          <EmptySpaceShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "code editor":
        return (
          <CodeEditorShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      case "match pairs":
        return (
          <MatchPairsShow
            data={data}
            order={dataContent}
            submitAction={changeOrder}
            index={index}
          />
        );
      default:
        return;
    }
  };

  const callToast = (status, title, message) => {
    if (status === "Success") {
      toast.success(contentToast(title, message), {
        icon: (
          <CheckCircleOutline
            style={{ color: "var(--info500)", fontSize: "30px" }}
          />
        ),
      });
    } else {
      toast.error(contentToast(title, message), {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      });
    }
  };

  const handleDelete = (id, contentType) => {
    const deleteReq = {
      id,
      contentType,
    };
    RestAPI.post("content/delete", deleteReq, config)
      .then((r) => {
        initData();
        callToast(
          "Success",
          "Content Deleted!",
          "Success to delete the content."
        );
      })
      .catch((err) => {
        console.log('err', err);
        callToast(
          "Error",
          "Failed to delete",
          "Your connection not stable. Try again."
        );
      });
  };

  const changeOrder = (order, contentBlockId) => {
    const values = {
      order: order,
    };
    RestAPI.put(`content/reorder/${contentBlockId}`, values, config)
      .then(() => {
        initData();
        callToast(
          "Success",
          "Content Updated!",
          "Success to update the content."
        );
      })
      .catch((err) => {
        callToast(
          "Error",
          "Failed to process",
          "Your connection not stable. Try again."
        );
      });
  };

  const handleInlineContent = (name, contentTypeId) => {
    if (name.toLowerCase() === "empty space") {
      let request = {
        subTopicId: id,
        contentTypeId,
      };
      RestAPI.post("content/space", request, config)
        .then((r) => {
          initData();
          callToast(
            "Success",
            "Content Created!",
            "Success to create content."
          );
        })
        .catch((err) => {
          callToast(
            "Error",
            "Failed to delete",
            "Something went wrong. Try again."
          );
        });
    }
  };

  return (
    <>
      <ModalDeleteContent submitAction={handleDelete} />
      <ModalCreateContent onInlineContent={handleInlineContent} />
      <section className="sub-topic-detatil-show">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <div className="header-subject">
                <div className="tx-header5 mb-1">{dataSubTopic?.name}</div>
                <div className="status">
                  {dataSubTopic?.status ? (
                    <div className="status-active">
                      <FiberManualRecord style={{ fontSize: "6px" }} /> Active
                    </div>
                  ) : (
                    <div className="status-inactive">
                      <FiberManualRecord style={{ fontSize: "6px" }} /> Inactive
                    </div>
                  )}
                </div>
              </div>

              {/* <Title title={props.name} /> */}
              <Buttons
                control="link"
                path={`/subtopic/editsubtopic/detail-preview/${id}`}
                addClass="btnAdd text-center"
                btnSmall
                btnMoExSmall
                btnOutline
                isShadow
              >
                <Visibility className="icon" /> Preview
              </Buttons>
            </div>
            <div className="height24"></div>

            {
              !isLoading ?
                <>
                  <div className="list-content">
                    <div className="row justify-content-center mb-16">
                      <div className="content">
                        {dataContent.map((data, index) => {
                          return <div key={index}>{showContent(data, index)}</div>;
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <Buttons
                      type="button"
                      addClass="col-12 more-option"
                      control="modal"
                      target="MODAL_CREATE_CONTENT"
                    >
                      <div className="tx-body text-center neutral200">
                        <span className="new-question">
                          <Add /> <br />
                          Create new block
                        </span>
                      </div>
                    </Buttons>
                  </div>

                </>
                : <Skeleton height={120} />
            }

          </div>
        </div>
      </section>
    </>
  );
};

export default connect()(SubTopicDetailShow);
