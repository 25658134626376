import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Tooltips from '../../../atoms/Tooltips';
import { useParams } from 'react-router';
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import {
  CheckCircleOutline,
  NotInterested,
  InfoOutlined,
} from '@mui/icons-material';

function ModalUpdateSubtopic() {
    const { id } = useParams()
    const statusMODAL = 'MODAL_UPDATE_SUBTOPIC'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [isAssessment, setIsAssessment] = useState(false)
    const [initialValues, setInitialValues] = useState({
        name: '',
        topicId: '',
        isAssessment: false,
        assessmentType: '',
        preparationText: '',
    })
    const [typeOptions, setTypeOptions] = useState([
        { key: 'Select assessment type', value: '' },
    ])

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            setIsAssessment(isStatusMODAL.crud.isAssessment)
            RestAPI.find('assessmenttype/category', config).then((res) => {
                const typeList = [ { key: 'Select assessment type', value: '' } ]
                res.map(item => {
                    typeList.push({ key: item.title, value: item.title })
                })
                setTypeOptions(typeList)
            }).catch(err => console.log(err.response))

            if (isStatusMODAL.modal === 'EDIT') {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    isAssessment: isStatusMODAL.crud.isAssessment,
                    assessmentType: isStatusMODAL.crud.assessmentTitle,
                })
            } else {
                setInitialValues({
                    name: '',
                    topicId: isStatusMODAL.crud._id,
                    isAssessment: isStatusMODAL.crud.isAssessment,
                    preparationText: '',
                    assessmentType: '',
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = isAssessment && isStatusMODAL.modal !== 'EDIT' ?
    Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        assessmentType: Yup.string().required("This field is required. Don't leave it empty!"),
    })
    : Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const onSubmit = values => {
        if (isStatusMODAL.modal === 'CREATE') {
            if(!isAssessment) {
                values = {
                    name: values.name,
                    topicId: values.topicId,
                    isAssessment: values.isAssessment,
                    preparationText: values.preparationText,
                }
            }
            RestAPI.post('subtopic', values, config).then(() => {
                callTopic(values.topicId)
                reloadTable('Created', 'create')
            })
                .catch(err => console.log(err.response));
        } else {
            RestAPI.put(`subtopic/${values._id}`, values, config).then(() => {
                reloadTable('Updated', 'update')
            })
                .catch(failedProcess);
        }
    }
    const reloadTable = (mess1, mess2) => {
        RestAPI.find(`content/frame?subjectId=${id}`, config).then(res => {
          dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: res[0].subject.lessons })
        })
        toast.success(contentToast(
            `Sub Topic ${mess1}!`, `Success to ${mess2} the sub topic.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = () => {
        toast.error(contentToast(
            "Failed to process", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const callTopic = (topicId) => {
        RestAPI.find(`subtopic/find?topicId=${topicId}`, config).then(res => {
            dispatch({ type: 'API_SUBTOPIC_IN_SUBJECT', newValue: [...res] })
        })
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form className="formFormik">
                                <div className="modal-header">
                                    <h5 className="tx-header5">{(isStatusMODAL.modal === 'CREATE') ? 'Create New' : 'Edit'} Sub Topic</h5>
                                    <p className="tx-small neutral300">{(isStatusMODAL.modal === 'CREATE') ? 'Fill all input to create new sub topic' : 'Fill all input to change info of selected sub topic.'}</p>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Reading' label="Sub Topic name" isLabel />
                                        </div>
                                        { isAssessment && isStatusMODAL.modal !== 'EDIT' ?
                                            <>
                                                <div style={{ height: '24px' }}></div>
                                                <div className="col-12">
                                                    <label htmlFor="assessmentType" className="form-label tx-small-po">
                                                        Assessment Type <Tooltips
                                                        id="tooltips-tes"
                                                        place="right"
                                                        icon={<InfoOutlined style={{ color: 'var(--neutral500)', fontSize: '16px' }} />}
                                                        data={{
                                                            title : 'Perk of Assessment',
                                                            list: [
                                                                { text: 'Auto Grading is assessment for single choice.' },
                                                                { text: 'Manual Grading is assessment for file upload, and voice record.' },
                                                            ]
                                                        }}
                                                    />
                                                    </label>
                                                    <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='assessmentType' options={typeOptions} />
                                                </div>
                                            </>
                                            : ''
                                        }
                                        { isAssessment && isStatusMODAL.modal === 'EDIT' ?
                                            <>
                                                <div style={{ height: '24px' }}></div>
                                                <div className="col-12">
                                                    <label htmlFor="assessmentType" className="form-label tx-small-po">
                                                        Assessment Type <Tooltips
                                                        id="tooltips-tes"
                                                        place="right"
                                                        icon={<InfoOutlined style={{ color: 'var(--neutral500)', fontSize: '16px' }} />}
                                                        data={{
                                                            title : 'Perk of Assessment',
                                                            list: [
                                                                { text: 'Auto Grading is assessment for single choice.' },
                                                                { text: 'Manual Grading is assessment for file upload, and voice record.' },
                                                            ]
                                                        }}
                                                    />
                                                    </label>
                                                    <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='assessmentType' disabled />
                                                </div>
                                            </>
                                            : ''
                                        }

                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name={(isStatusMODAL.modal === 'CREATE') ? 'Create' : 'Update'} addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default connect()(ModalUpdateSubtopic)