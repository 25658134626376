import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import Buttons from "../../../atoms/Buttons";
import Modal from "react-modal";
const ModalDeleteAssessment = (props) => {
  const statusMODAL = "MODAL_DELETE_ASSESSMENT";
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: "",
    contentType: "",
  });
  const dispatch = useDispatch();

  const isStatusMODAL = useSelector((state) => state.openModal);
  const crud = useSelector((state) => state.openModal.crud);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };
  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      setInitialValues(crud);
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  return (
    <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
      <div className="modal-header bg-danger400">
        <h5 className="tx-header5 text-white">Delete Content </h5>
      </div>
      <div className="modal-body">
        <div className="message">
          <span className="tx-body-po">{initialValues.contentType} &nbsp;</span>
          will be permanently lost
        </div>
      </div>
      <div className="modal-footer">
        <Buttons
          control="button"
          btnExSmall
          btnOutline
          name="Cancel"
          onClick={() => closeModal()}
        />
        <Buttons
          control="button"
          type="submit"
          name="Delete"
          addClass=""
          btnExSmall
          btnDanger
          onClick={() => {
            props.onDeleteAssessment(initialValues.id);
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
};

export default connect()(ModalDeleteAssessment);
