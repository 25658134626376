import React from 'react';
import './Title.scss'

function Title(props) {
    return (
        <>
            <div className="header-title">
                <div className="tx-header5">{props.title}</div>
            </div>
        </>
    );
}

export default Title;
