import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'
import '../Formik.scss'

function Input(props) {
  const { label, name, placeholder, disabled, type, onClick, onChange, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, maxlength, onInput, width, hideArrow, ...rest } = props

  const className = ["formFormik"];
  const inputClassName = ["form-control"];
  className.push(addClass);
  inputClassName.push(inputClass);

  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")
  if (hideArrow) className.push("hideArrow")

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {
          ({
            field, meta: { touched, error }
          }) => <input style={{width: width}} className={ touched && error ? inputClassName.join(" error") : inputClassName.join(" ") } type={type} placeholder={placeholder} onClick={onClick} onChange={onChange} disabled={disabled} maxLength={maxlength} onInput={onInput} { ...field } />
        }
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Input