import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import TitleSection from '../../../molecules/TitleSection';
import RestAPI from "../../../../config/RestAPI";
import '../Style.scss'

function ShortAnswers() {
  const dataCrumbs = [
    { name: 'Test', path: '/test' },
    { name: 'Base64', path: '/base64' },
    { name: 'Short Answers', path: '' },
  ]

  const { id } = useParams();
  const [data, setData] = useState();

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initData = () => {
    RestAPI.find(`base64/shortanswers/${id}`, config).then((res) => {
      setData(res)
    }).catch(err => {
      console.log(err)
    })
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <section className="base64-list">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Base64 Short Answers" />
          </div>
          <div className="row">
            <div className="col-md-12">
              {data?.questions?.map((r, i) => {
                return (
                  <React.Fragment key={i}>
                    {r.image ? (
                      <div className="text-center mb-16">
                        <img src={r.image} alt="" width="100%" />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={`${i > 0 ? "mt-32" : ""} `}>
                      <span>
                        {i + 1}. {r.question}
                      </span>
                      <div className="ml-16 mt-8">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Enter the answer here"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect()(ShortAnswers)
