import React from 'react'
import './SelectOption.scss'

function SelectOption(props) {
    const { data, accessor, label, onChange, value, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, noAppreance, isLabel, ...rest } = props

    const className = ["selectOption"];
    const inputClassName = ["select-option"];
    className.push(addClass);
    inputClassName.push(inputClass);
    if (isShadow) className.push("isShadow")
    if (isSmall) className.push("isSmall")
    if (isExSmall) className.push("isExSmall")
    if (isTaSmall) className.push("isTaSmall")
    if (isTaExSmall) className.push("isTaExSmall")
    if (isMoSmall) className.push("isMoSmall")
    if (isMoExSmall) className.push("isMoExSmall")
    if (noAppreance) className.push("noAppreance")

    return (
        <>
            <div className={className.join(" ")}>
                {
                    isLabel && <label htmlFor={accessor} className="form-label tx-small-po">{label}</label>
                }
                <select className={inputClassName.join(" ")} id={accessor} onChange={onChange} >
                    {
                        data.map((item, index) => {
                            return <option key={index} value={item.value} disabled={item.disabled} >{item.name}</option>
                        })
                    }
                </select>
            </div>
        </>
    )
}

export default SelectOption