import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';
import { format } from 'date-fns'

export const COLUMNS = [
    // {
    //     Header: 'ID',
    //     accessor: '_id',
    //     Placehoder: 'e.g. 123456',
    //     Filter: ColumnFilter,
    //     // disableFilters: true
    // },
    {
        Header: 'Lesson Name',
        accessor: 'name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Subject',
        accessor: 'subject.name',
        Placehoder: 'e.g. English Play 01',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Subject',
        accessor: 'subject._id',
        Placehoder: 'e.g. 12345',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Course',
        accessor: 'course.name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Course',
        accessor: 'course._id',
        Placehoder: 'e.g. 12345',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Owner',
        accessor: 'createdBy.name',
        Placehoder: 'e.g. Atlaz',
        Filter: ColumnFilter
    },
    {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        // Cell: ({value}) => { return format(new Date(value), 'dd MMM yyyy, hh:mm a')},
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                {/* <Buttons control="modal" target="MODAL_DELETE_LESSON" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}
                
                <Buttons control="modal" target="MODAL_EDIT_LESSON" modal="EDIT" addClass="neutral200" crud={props.row.original} btnExSmall btnGhost btnIcon><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]