export const dropdownAction = [
    { 
        name: "Active", 
        control: 'modal',
        path: "MODAL_ACTIVE_USER", 
        modal: 'ACTIVE',
        addClass: "" ,
    },
    { 
        name: "Inactive", 
        control: 'modal',
        path: "MODAL_ACTIVE_USER", 
        modal: 'INACTIVE',
        addClass: "" ,
    }
]