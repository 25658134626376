import React, { useState, useEffect } from "react";
import "../Modal.scss";
import "./ModalSubTopic.scss";
import RestAPI from "../../../../config/RestAPI";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ModalCreateContent = (props) => {
  const statusMODAL = "MODAL_CREATE_CONTENT";
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const isStatusMODAL = useSelector((state) => state.openModal);
  const [arrContentType, setArrContentType] = useState([]);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      RestAPI.find("contenttype", config).then((res) => {
        const data = [];
        res.map((item) => {
          data.push({
            categoryId: item.categoryId,
            name: item.name,
            contentType: item.contentType,
          });
        });
        setArrContentType(data);
      });
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  function isLast(index, data) {
    return index === data.length - 1;
  }

  return (
    <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
      <div className="modal-sub-topic modal-content">
        <div
          className=" isNoForm p-8  scrollbar-modal"
          style={{ height: "400px" }}
        >
          {arrContentType.map((category, index) => {
            return (
              <div key={index}>
                <h6 className="neutral300 mb-12 pl-4">{category.name}</h6>
                {category.contentType.map((item, key) => {
                  return (
                    <ContentCardGoTo
                      key={key}
                      className="mb-16"
                      id={item._id}
                      title={item.name}
                      text={item.description}
                      icon={item.icon}
                      to={`/subtopic/editsubtopic/${item.path}`}
                      onInlineContent={(name, id) => {
                        props.onInlineContent(name, id);
                        closeModal();
                      }}
                    />
                  );
                })}
                {!isLast(index, arrContentType) && <hr />}
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

const ContentCardGoTo = (props) => {
  const setIdContentType = (id, name) => {
    localStorage.setItem(
      "CONTENT_TYPE",
      JSON.stringify({
        id: id,
        name: name,
        goal: "CREATE",
      })
    );
  };

  const picker = (
    <div
      className={`d-flex align-items-center content-card-go-to p-1  ${props.className}`}
    >
      <div className="mr-8">
        <img
          src={props.icon}
          alt=""
          width="54"
          style={{
            padding: "10px",
            border: "1px solid #D4D7DB",
            borderRadius: "4px",
          }}
        />
      </div>
      <div>
        <h6 className="neutral500">{props.title}</h6>
        <p className="">
          <span className="tx-small">{props.text}</span>
        </p>
      </div>
    </div>
  );

  if (props.title.toLowerCase() === "empty space") {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => props.onInlineContent(props.title, props.id)}
      >
        {picker}
      </div>
    );
  }

  return (
    <Link
      to={props.to}
      className="text-decoration-none"
      onClick={() => setIdContentType(props.id, props.title)}
    >
      {picker}
    </Link>
  );
};
export default connect()(ModalCreateContent);
