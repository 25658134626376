import React from 'react'
import './LoginAdmin.scss'
import * as Yup from 'yup'
import Logo from '../../../assets/images/logo/regular/default/Double.png'
import FormikControl from '../../atoms/Formik/FormikControl'
import Buttons from '../../atoms/Buttons'
import { Formik, Form } from 'formik'

function ForgotPass() {
    const initialValues = {
        email: '',
    }
    const validationSchema = Yup.object({
        email: Yup.string().email('Please input the correct format email address!').required("This field is required, Don't leave it empty!"),
    })
    const onSubmit = values => {
        console.log('Saved data', JSON.parse(JSON.stringify(values)))
    }

    return (
        <div className="page-login d-flex flex-column justify-content-between align-items-center">
            <div className="col-lg-12">
                <div className="header text-center">
                    <Buttons control="exlink" path="/" isBlank btnLink name={<img src={Logo} alt="logo" className='logo' />} />
                    
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="top text-center">
                    <div className="tx-header4">Forgot Password</div>
                    <div className="height20"></div>
                    <div className="desc tx-body">Submit your email address and we will send <br />recovery password to your email </div>
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {
                        formik => <Form>
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='email' label="Email address" placeholder="Enter your email address" isLabel />
                            <div className="height18"></div>

                            <Buttons control="button" type="submit" btnRegular btnBlock btnSecondary name="Reset Password" />
                            <div className="height20"></div>

                            <div className="text-center">
                                <Buttons control="link" path="/" addClass="tx-small" btnLink name="Try to Sign In" />
                            </div>
                            <div className="height100"></div>
                        </Form>
                    }
                </Formik>
            </div>
            <div className="col-lg-12">
                <div className="footer tx-small neutral200 text-center">
                    © 2022 PT Atlaz Belajar Bahasa. All Right Reserved
                </div>
            </div>
        </div>
    )
}

export default ForgotPass