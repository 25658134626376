import React from 'react'
import { TableGenerateCoupons, TitleSection } from '../../../molecules'
import { useGenerateCoupons } from '../../../services'

function GenerateList() {
  const {
    dataCrumbs,
  } = useGenerateCoupons()

  return (
    <section className="school-list">
      <div className="container-fluid">
        <div className="row">
          <TitleSection data={dataCrumbs} titleheader="Generate Coupons" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <TableGenerateCoupons />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GenerateList