import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Tooltips from '../../../atoms/Tooltips';
import { useParams } from 'react-router';
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
    CheckCircleOutline,
    NotInterested,
    InfoOutlined,
} from '@mui/icons-material';

function ModalUpdateTopic() {
    const { id } = useParams()
    const statusMODAL = 'MODAL_UPDATE_TOPIC'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [isAssessment, setIsAssessment] = useState("false")
    const [arrWeight, setArrWeight] = useState([])
    const [totalWeight, setTotalWeight] = useState(0)
    const [initialValues, setInitialValues] = useState({
        name: '',
        isAssessment: false,
        subtopics: [],
    })

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if (isStatusMODAL.modal === 'EDIT') {
                let weight = 0;
                const weightArray = []
                isStatusMODAL.crud.subtopics.map( item => {
                    weight = weight + item.weight
                    weightArray.push(item.weight)
                })
                setTotalWeight(weight)
                setArrWeight(weightArray)
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    isAssessment: isStatusMODAL.crud.isAssessment,
                    subtopics: isStatusMODAL.crud.subtopics,
                })
                setIsAssessment(`${isStatusMODAL.crud.isAssessment}`)
            } else {
                setInitialValues({
                    name: '',
                    lessonId: (isStatusMODAL.crud) ? isStatusMODAL.crud._id : '',
                    isAssessment: false,
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        setIsAssessment(false)
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const changeWeight = (e, index) => {
        arrWeight[index] = parseInt(e.target.value)
        let weight = 0;
        arrWeight.map( item => {
            weight = weight + item
        })
        setTotalWeight(weight)
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }
    const onSubmit = values => {
        if (isStatusMODAL.modal === 'CREATE') {
            RestAPI.post('topic', values, config).then(() => {
                reloadTable('Created', 'create')
            })
            .catch((err) => failedProcess(err));
        } else {
            RestAPI.put(`subtopic/weight?topicId=${values._id}`, values, config).then(() =>{
                reloadTable('Updated', 'update')
            })
                .catch((err) => failedProcess(err));
        }
    }
    const reloadTable = (mess1, mess2) => {
        RestAPI.find(`content/frame?subjectId=${id}`, config).then(res => {
            dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: res[0].subject.lessons })
        })
        toast.success(contentToast(
            `Topic ${mess1}!`, `Success to ${mess2} the topic.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = (err) => {
        console.log(err.response)
        toast.error(contentToast(
            "Failed to process", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form className="formFormik">
                                <div className="modal-header">
                                    <h5 className="tx-header5">{(isStatusMODAL.modal === 'CREATE') ? 'Create New' : 'Edit'} Topic</h5>
                                    <p className="tx-small neutral300">{(isStatusMODAL.modal === 'CREATE') ? 'Fill all input to create new topic' : 'Fill all input to change info of selected topic.'}</p>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Reading 1' label="Topic name" isLabel />
                                        </div>
                                        {
                                            isStatusMODAL.modal !== 'EDIT' &&
                                            <div className="col-12 mt-24">
                                                <div className="d-flex align-items-end">
                                                    <Field type="checkbox" name="isAssessment" id="isAssessment" className="check-control" style={{marginBottom: '3.5px'}}
                                                    onInput={(e) => setIsAssessment(e.target.value)} />
                                                    <label htmlFor="isAssessment" className="label-checkbox tx-small mr-6">Assessment mode</label>
                                                    <Tooltips
                                                        id="tooltips-tes"
                                                        place="right"
                                                        icon={<InfoOutlined style={{ color: 'var(--neutral500)', fontSize: '16px' }} />}
                                                        data={{
                                                            title : 'Perk of Assessment',
                                                            list: [
                                                                { text: 'Results will be saved' },
                                                                { text: 'Student will be got a score for the assessment process' },
                                                                { text: 'Have a certain schedule and limited time' },
                                                            ]
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isAssessment === 'true' &&   
                                            <div className="col-12 mt-24">
                                                <div className='tx-small-po mb-8'>Score weight</div>
                                                <table className="table-assessment">
                                                    <thead>
                                                        <tr>
                                                            <th width="5%">#</th>
                                                            <th width="70%">Assessment</th>
                                                            <th width="25%">Weight</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            isStatusMODAL.modal === 'EDIT' && isStatusMODAL.crud.subtopics.length > 0 ?
                                                            initialValues.subtopics?.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="neutral200">{index+1}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                        <FormikControl addClass="isExSmall" 
                                                                        control='input' type='number' name={`subtopics[${index}].weight`}  inputClass="hideArrow text-center"
                                                                        onInput={(e) => changeWeight(e, index) }
                                                                        />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td colSpan="3" className="neutral200 text-center">No assessment created</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th colSpan="2">Total Weight</th>
                                                            <th>{ totalWeight > 0 ? totalWeight : '-'}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                <div className="notes tx-ex-small neutral300">Weight Percentage = (Weight / Total Weight) * 100%</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name={(isStatusMODAL.modal === 'CREATE') ? 'Create' : 'Update'} addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default connect()(ModalUpdateTopic)