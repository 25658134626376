import React, { useEffect } from 'react';
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import RestAPI from '../../../../../config/RestAPI'
import Buttons from "../../../../atoms/Buttons";
import Toast from '../../../../atoms/Toast';
import { ArrowBack } from '@mui/icons-material';
import { connect, useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { ModalLink, ModalRedirect, TableMemberUpload } from '../../../../molecules';
import './MemberUpload.scss'

function MemberUpload() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const dataSchool = useSelector((state) => state.APIEditSchool)
  const backStatus = useSelector((state) => state.BackStatus)

  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    RestAPI.find(`school/${id}`, config).then(res => {
        dispatch({ type: 'API_EDIT_SCHOOL', newValue: res })
      }).catch((err) => {
        console.log(err.response)
        navigate("/notfound")
    });
    dispatch({ type: 'BACK_STATUS', newValue: false })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }, [])

  const dataCrumbs = [
      { name: "Dashboard", path: "/dashboard" },
      { name: "School List", path: "/school" },
      { name: dataSchool?.name , path: `/school/editschool/${id}` },
      { name: "Upload Confirmation", path: "" },
  ];

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel upload member',
    description: '',
    message: 'Once canceled, your inputed data will be lost.',
    path: `/school/editschool/${id}`,
    footerYes: 'Yes',
    footerNo: 'No',
  }

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalRedirect />
      <section className="memberupload-info">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} />
            <div className="col-md-12 nav-back">
              <Buttons
                control='modalLink'
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : 'MODAL_REDIRECT'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TableMemberUpload />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect()(MemberUpload)
