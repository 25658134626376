import React, { useEffect, useState } from "react";
import RestAPI from "../../../../config/RestAPI";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

function useTableMember() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingTable);
  const data = useSelector((state) => state.APIMember);
  const dataClass = useSelector((state) => state.APIClassMini);
  const dataSchool = useSelector((state) => state.APIEditSchool);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const [idSchool, setIdSchool] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const [dataSubject, setDataSubject] = useState([]);
  const [expiredSubject, setExpiredSubject] = useState([]);
  const DATA_CSV = [
    {
      NISN: "123456",
      name: "Nick from Atlaz",
      roleName: "Student",
      role: "6",
      schoolName: dataSchool?.name,
      school: id,
      className: "Class 01 - 2022/2023",
      class: "625669e4caac7f2ce57758ef",
      subjectName: "English Escalate 01",
      subject: "622eeb1da163f47749094128",
      expiredSubject: "2023-12-12",
    },
  ];
  const arrCSV = [
    DATA_CSV,
    idSchool,
    dataRole,
    dataClass,
    dataSubject,
    expiredSubject,
  ];
  const arrNameCSV = [
    "data",
    "school",
    "role",
    "class",
    "subject",
    "expiredSubject",
  ];

  const initDataMember = () => {
    dispatch({ type: "LOADING_TABLE", newValue: false });
    RestAPI.find(`school/members?school=${id}`, config).then((res) => {
      const newdata = [];
      res?.map((item, index) => {
        newdata.push({
          ...item,
          subject: item.subject ? item.subject.name : "",
          expiredSubject: format(new Date(item.expiredSubject), "yyyy-MM-dd"),
          updatedAt: format(new Date(item.updatedAt), "yyyy-MM-dd, HH:mm"),
        });
      });
      dispatch({ type: "API_MEMBER", newValue: [...newdata] });
      dispatch({ type: "LOADING_TABLE", newValue: true });
    });
  };

  useEffect(() => {
    setIdSchool([
      {
        id: id,
        name: dataSchool?.name,
      },
    ]);
    initDataMember();
    RestAPI.find("users/roles", config).then((res) => {
      let data = [];
      res.map((rl) => {
        data.push({
          id: rl.level,
          role: rl.title,
        });
      });
      setDataRole(data);
    });
    RestAPI.find("subject", config).then((res) => {
      let data = [];
      res.map((sbj) => {
        data.push({
          id: sbj._id,
          subject: sbj.name,
        });
      });
      setDataSubject(data);
    });
  }, [dataSchool]);

  return {
    id,
    loading,
    data,
    dataClass,
    dataSchool,
    idSchool,
    dataRole,
    dataSubject,
    DATA_CSV,
    arrCSV,
    arrNameCSV,
    initDataMember,
  };
}

export default useTableMember;
