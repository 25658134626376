import React from 'react';
import { CourseList, Footer, Navbar } from '../../../components/organisms';

function Courses() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <CourseList />
        <Footer/>
      </>
  )
}

export default Courses;
