import React from "react";
import {
  Footer,
  Navbar,
  SubTopicShortAnswer,
} from "../../../components/organisms";
const ShortAnswer = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SubTopicShortAnswer />
      <Footer />
    </>
  );
};

export default ShortAnswer;
