import axios from "axios"
import jwt_decode from "jwt-decode";
import { BaseURL } from "./BaseURL";
import { useNavigate } from "react-router-dom";

const API = axios.create({
    baseURL: BaseURL
});

API.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        const originalRequest = error.config
        const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
        const refreshToken = tokenAccess.refreshToken
        if (refreshToken) {
            let decode = jwt_decode(refreshToken);
            if (Math.floor(new Date().getTime() / 1000) > decode.exp) {
                localStorage.removeItem("ATLAZ_Authentication");
                const navigate = useNavigate()
                navigate('/login')
            }
        }
        if (error.response.data === "Please Refresh Token!") {
            return axios
                .get('/users/token', originalRequest)
                .then(res => {
                    localStorage.setItem("ATLAZ_Authentication", JSON.stringify({
                        ...tokenAccess,
                        accessToken: res.data.accessToken.token,
                        refreshToken: res.data.refreshToken,
                    }));
                    originalRequest.headers.token = "Bearer " + res.data.accessToken.token
                    return axios(originalRequest)
                })
        }
        return Promise.reject(error)
    }
)

export default API;