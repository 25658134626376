import React, { useState, useEffect } from "react";
import "./SubTopicEssay.scss";
import * as Yup from "yup";
import Toast from "../../../../atoms/Toast";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { Form, Formik } from "formik";
import { useSelector, useDispatch, connect } from "react-redux";
import { ModalLink, TitleSection } from "../../../../molecules";
import { useNavigate } from "react-router";
import { NotInterested } from "@mui/icons-material";

const SubTopicEssay = () => {
  const { id } = useParams();
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic);
  const idSubTopic = localStorage.getItem("ID_SUBTOPIC");
  const getWhereFrom = JSON.parse(localStorage.getItem("EDIT_SUBTOPIC"));
  const [initialValues, setInitialValues] = useState({
    subTopicId: "",
    contentTypeId: "",
    instruction: "",
    fakeImage: "",
    image: "",
    annotation: "",
    correctionText: "",
  });

  const contentType = JSON.parse(localStorage.getItem("CONTENT_TYPE"));
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!idSubTopic) {
      navigate("/subtopic");
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config)
        .then((res) => {
          dispatch({ type: "API_EDIT_SUBTOPIC", newValue: res });
        })
        .catch((err) => {
          console.log(err.response);
          navigate("/notfound");
        });
      if (id) {
        RestAPI.find(`content/essay/${contentType.id}`, config).then((res) => {
          setInitialValues({
            subTopicId: "",
            contentTypeId: res._id,
            instruction: res.instruction,
            fakeImage: "",
            image: res.image,
            annotation: res.annotation,
            correctionText: res.correctionText,
          });
        });
      }
      dispatch({
        type: "OPEN_MODAL",
        newValue: {
          status: false,
          target: "",
          crud: null,
          modal: "",
        },
      });
    }
  }, []);

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Essay", path: "" },
  ];

  const validationSchema = Yup.object().shape({
    instruction: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    correctionText: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!id) {
      values = {
        ...values,
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
      };
      RestAPI.post("content/essay", values, config)
        .then((r) => {
          processSuccess("Created", "create");
        })
        .catch((err) => {
          processFailed("create");
          setSubmitting(false);
        });
    } else {
      values = {
        ...values,
        contentTypeId: contentType.id,
      };
      RestAPI.put(`content/essay/${contentType.id}`, values, config)
        .then((r) => {
          processSuccess("Updated", "update");
        })
        .catch((err) => {
          processFailed("update");
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem(
      "EDIT_SUBTOPIC",
      JSON.stringify({
        ...getWhereFrom,
        toast: true,
        message: { title: message1, desc: message2 },
      })
    );
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  };

  const processFailed = (message) => {
    toast.error(
      contentToast(
        `Failed to ${message}!`,
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
  };

  const handleChange = () => {
    console.log("OK");
  };

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="essay">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Essay" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <div className="col-md-12  mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <div className="mb-16 tx-header5">
                      Instruction{" "}
                      <span className="tx-header6">
                        <i>(Optional)</i>
                      </span>
                    </div>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="instruction"
                      placeholder="e.g. A. Answer the question!"
                    />
                  </div>

                  <div className="col-12 p-40 bg-secondary200 radius-14 mb-24">
                    <div className="col mb-32">
                      <label htmlFor="" className="tx-small">
                        <span className="text-strong ">Annotation</span>{" "}
                        <i>(optional)</i>
                      </label>
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="textarea"
                        type="text"
                        name={`annotation`}
                        placeholder="Enter the question here"
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="" className="tx-small">
                        <span className="text-strong">Upload image</span>
                        <i>(Optional)</i>
                      </label>
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="inputbase64"
                        type="text"
                        name={`fakeImage`}
                        base64Name={`image`}
                      />
                    </div>
                  </div>

                  <div className="col-md-12  mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <label htmlFor="" className="tx-small">
                      <span className="text-strong">Explanation Text</span>
                      <i>(Optional)</i>
                    </label>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="correctionText"
                      placeholder="Explain the answer here"
                    />
                  </div>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={id ? "Update" : "Save"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect()(SubTopicEssay);
