import React from 'react'
import { Footer, Navbar, PublicList } from '../../../components/organisms';

function Public() {
  return (
    <>
        <Navbar/>
        <div className="height-82"></div>
        <PublicList />
        <Footer/>
      </>
  )
}

export default Public