import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import TitleSection from '../../../molecules/TitleSection';
import RestAPI from "../../../../config/RestAPI";
import '../Style.scss'

function MultipleChoices() {
  const dataCrumbs = [
    { name: 'Test', path: '/test' },
    { name: 'Base64', path: '/base64' },
    { name: 'Multiple Choices', path: '' },
  ]

  const { id } = useParams();
  const [data, setData] = useState();
  const abjads = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initData = () => {
    RestAPI.find(`base64/multiplechoices/${id}`, config).then((res) => {
      setData(res)
    }).catch(err => {
      console.log(err)
    })
  };


  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <section className="base64-list">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Base64 Multiple Choices" />
          </div>
          <div className="row">
            <div className="col-md-12">
            <div>
              {data?.questions?.map((d, i) => {
                return (
                  <React.Fragment key={i}>
                    {d.image ? (
                      <div className="text-center ">
                        <img src={d.image} alt="" width="100%" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={`${i > 0 ? "mt-16" : ""}`}>
                      <span>
                        {i + 1}. {d.question}
                      </span>

                      {d.options.map((r, i) => {
                        return (
                          <div className="my-8 ml-12" key={i}>
                            <label className=" rounded mr-8">
                              <input
                                type="checkbox"
                                name="value"
                                hidden
                                className="radio-custom"
                              />
                              <div className="custom align-items-center text-center rounded ps-2">
                                <div className="mr-3 text-center">
                                  {abjads[i]}.
                                </div>
                              </div>
                            </label>
                            {r.text}
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect()(MultipleChoices)
