import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'NISN/NIK',
        accessor: 'user.username',
        Placehoder: 'e.g. 1234556',
        Filter: ColumnFilter
    },
    {
        Header: 'Name',
        accessor: 'user.name',
        Placehoder: 'e.g. User 01',
        Filter: ColumnFilter
    },
    {
        Header: 'Role',
        accessor: 'role.title',
        Placehoder: 'e.g. Public',
        Filter: ColumnFilter
    },
    {
        Header: 'Class',
        accessor: 'classlist.name',
        Placehoder: 'e.g. Class 01',
        Filter: ColumnFilter
    },
    {
        Header: 'Academic Year',
        accessor: 'classlist.academicYear',
        Placehoder: 'e.g. 2022/2023',
        Filter: ColumnFilter
    },
    {
        Header: 'Subject',
        accessor: 'subject',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter,
    },
    {
        Header: 'Subject Exp Date',
        accessor: 'expiredSubject',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Last update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                {/* <Buttons control="modal" target="MODAL_DELETE_MEMBER" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}
                
                <Buttons control="link" path={`/school/editschool/${props.row.original.school._id}/editmember/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]