import React, { useEffect, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";

const SingleChoiceShow = (props) => {
  const [data, setData] = useState(props.data);
  return (
    <>
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          {data.instruction ? (
            <p className="tx-header5 mb-16">{data.instruction}</p>
          ) : (
            ""
          )}
          <table className="table  table-borderless">
            <thead className=" ">
              <tr>
                <th
                  scope="col "
                  className="tx-small-po bg-secondary200 radius-tl-8"
                >
                  Question
                </th>
                <th
                  scope="col "
                  className="tx-small-po bg-secondary200 radius-tr-8"
                >
                  Answer
                </th>
              </tr>
            </thead>
            <tbody>
              {data.questions.map((r, i) => {
                return (
                  <tr className="table-content align-items-center " key={i}>
                    <td className="tx-small pt-3">{r.question}</td>
                    <td className="form-formik">
                      <select id="" className="form-select">
                        <option value="">Choose</option>
                        {r.options.map((res, ind) => {
                          return (
                            <option value={res.text} key={ind}>
                              {res.text}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};
export default SingleChoiceShow;
