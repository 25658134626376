import React, { useState } from "react";
import "./SubTopicFillInTheBlank.scss";
import * as Yup from "yup";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import ReactTooltip from "react-tooltip";
import TextError from "../../../../atoms/Formik/TextError";
import { TitleSection } from "../../../../molecules";
import { FieldArray, Form, Formik } from "formik";
import { 
  PostAdd,
  InfoOutlined,
  DeleteOutline
 } from '@mui/icons-material';

const SubTopicFillInTheBlank = () => {
  const [templateChoosen, setTemplateChoosen] = useState("");
  const [templateChoosenValidation, setTemplateChoosenValidation] =
    useState("");
  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "My Books", path: "/mybooks" },
    { name: "Subject", path: "/subject" },
    { name: "Sub topic details", path: "/sub-topic-detail" },
    { name: "Fill in The Blank", path: "" },
  ];
  const templateOptions = [
    {
      key: "pilih",
      value: "",
    },
    {
      key: "1 input",
      value: "1",
    },
    {
      key: "2 input",
      value: "2",
    },
  ];
  const initialValues = {
    instruction: "",
    template: {},
    image: "",
    questionAnswerT1: "",
    questionAnswerT2: [
      {
        col1: "",
        col2: "",
      },
    ],
    corection: "",
  };

  const q1Validation = () => {
    if (templateChoosen === 1) {
      return Yup.string().required(
        "This field is required. Don't leave it empty!"
      );
    }
    return;
  };

  const q2Validation = () => {
    if (templateChoosen === 2) {
      return Yup.array().of(
        Yup.object().shape({
          col1: Yup.string().required(
            "This field is required. Don't leave it empty!!"
          ),
          col2: Yup.string().required(
            "This field is required. Don't leave it empty!"
          ),
        })
      );
    }
    return;
  };

  const validationSchema = Yup.object().shape({
    // template: Yup.string().required(
    //   "This field is required. Don't leave it empty!"
    // ),
    // image: Yup.string().required(
    //   "This field is required. Don't leave it empty!"
    // ),
    questionAnswerT1: q1Validation(),
    questionAnswerT2: q2Validation(),
    // corection: Yup.string().required(
    //   "This field is required. Don't leave it empty!"
    // ),
  });
  const onSubmit = (values) => {
    if (!templateChoosen) {
      setTemplateChoosenValidation(true);
      return;
    }

    values = { ...values, template: templateChoosen };
    console.log(values);
  };

  return (
    <>
      <section className="fill-in-the-blank">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Fill in The Blank" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              <Form className="formFormik">
                <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                  <h5 className="mb-16 ">
                    <span className="text-strong"> Instruction</span>
                    <i>(Optional)</i>
                  </h5>
                  <FormikControl
                    control="textarea"
                    type="text"
                    name="instruction"
                    placeholder="e.g. A. Answer the question!"
                  />
                </div>
                <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                  <div className="row">
                    <div className="col-md-6 mb-16">
                      <label htmlFor="" className="text-strong tx-small mr-4">
                        Template
                      </label>
                      <span data-tip data-for="template">
                        <InfoOutlined
                          style={{
                            fontSize: "16px",
                            color: "var(--neutral300)",
                          }}
                          className="cursor-pointer"
                        />
                      </span>
                      <ReactTooltip
                        id="template"
                        place="top"
                        type="dark"
                        effect="solid"
                      >
                        <div className="my-16">
                          <h6 className="mb-8 text-strong tx-small text-white">
                            Template Style
                          </h6>
                          <ol>
                            <li className="ml-16">
                              Input used for paragraph text, long <br />
                              sentence, etc.
                            </li>
                            <li className="ml-16">
                              Input used for numbered question, <br />
                              dialogue, etc.
                            </li>
                          </ol>
                        </div>
                      </ReactTooltip>
                      {/* <FormikControl
                        control="select"
                        name="template"
                        options={templateOptions}
                        onChange={(e) => {
                          // initialValues.template = e.target.value;

                          console.log(e.target.value);
                        }}
                      /> */}
                      <select
                        name=""
                        id=""
                        className={`form-select  ${
                          templateChoosenValidation ? "error" : ""
                        }`}
                        onChange={(e) => {
                          setTemplateChoosen(e.target.value);
                          setTemplateChoosenValidation(false);
                        }}
                      >
                        <option value="">pilih</option>
                        <option value="1">1 input</option>
                        <option value="2">2 input</option>
                      </select>
                      {templateChoosenValidation ? (
                        <TextError>
                          This field is required. Don't leave it empty!
                        </TextError>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6 ">
                      <label htmlFor="" className="tx-small ">
                        <span className="text-strong"> Upload image </span>
                        <i>(Optional)</i>
                      </label>
                      <FormikControl
                        control="inputfile"
                        type="file"
                        name={`image`}
                      />
                    </div>
                    {templateChoosen === 1 ? (
                      <Template1 />
                    ) : templateChoosen === 2 ? (
                      <Template2 />
                    ) : (
                      ""
                    )}
                    <div className="col-12 ">
                      <label htmlFor="" className="tx-small ">
                        <span className="text-strong ">Correction Text </span>
                        <i>(Optional)</i>
                      </label>
                      <FormikControl
                        control="textarea"
                        type="text"
                        name={`corection`}
                        placeholder="Explain the answer here"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center text-md-end">
                    <Buttons
                      type="button"
                      className="btnOutline py-8 mr-8 mt-16 px-60"
                      control="button"
                      name="Cancel"
                    />
                    <Buttons
                      type="submit"
                      className="btnPrimary py-8 px-60"
                      control="button"
                      name="Save"
                    />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

const Template1 = (props) => {
  return (
    <div className="col-12 mt-32 mb-32">
      <label htmlFor="" className="tx-small mr-4">
        <span className="text-strong ">Question & Answer</span>
      </label>
      <span data-tip data-for="qa">
        <InfoOutlined
          style={{
            fontSize: "16px",
            color: "var(--neutral300)",
          }}
          className="cursor-pointer"
        />
      </span>
      <ReactTooltip id="qa" place="top" type="dark" effect="solid">
        <div className="my-16">
          <h6 className="mb-8 text-strong tx-small text-white">
            How to input question & answer
          </h6>
          <ul>
            <li className="ml-16">Use enter to create new paragraph.</li>
            <li className="ml-16">
              Use “*” before and after words to <br /> make answers. For
              example: *Atlaz*.
            </li>
          </ul>
        </div>
      </ReactTooltip>
      <FormikControl
        control="textarea"
        type="text"
        name={`questionAnswerT1`}
        placeholder="Enter the input"
      />
    </div>
  );
};

const Template2 = () => {
  return (
    <div className="mt-32">
      <label htmlFor="" className="tx-small ">
        <span className="text-strong mr-4">Question & Answer</span>
        <span data-tip data-for="qa2">
          <InfoOutlined
            style={{
              fontSize: "16px",
              color: "var(--neutral300)",
            }}
            className="cursor-pointer"
          />
        </span>
        <ReactTooltip id="qa2" place="top" type="dark" effect="solid">
          <div className="my-16">
            <h6 className="mb-8 text-strong text-white tx-small">
              How to input question & answer
            </h6>
            <ul>
              <li className="ml-16">
                Use “*” before and after words to <br /> make answers. For
                example: *Atlaz*.
              </li>
              <li className="ml-16">
                1st input will automatic bold based <br /> on purpose of being
                speaker name on <br /> the dialog.
              </li>
            </ul>
          </div>
        </ReactTooltip>
      </label>
      <FieldArray name={`questionAnswerT2`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;

          const { questionAnswerT2 } = values;
          // console.log("fieldArrayProps", fieldArrayProps);
          // console.log("Form errors", spliter[1].substring(0, 1));

          return (
            <>
              {questionAnswerT2.map((r, i) => {
                return (
                  <div className="mb-12 row gx-0 " key={i}>
                    <div className="col">
                      <div className="row">
                        <div className=" radius-4 mb-6 col-12 col-md-4 align-items-center neutral200 ">
                          <FormikControl
                            name={`questionAnswerT2[${i}].col1`}
                            type="text"
                            control="input"
                            placeholder="1st input"
                          />
                        </div>
                        <div className="pg-input  col">
                          <FormikControl
                            name={`questionAnswerT2[${i}].col2`}
                            type="text"
                            control="input"
                            placeholder="2nd input"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className=" radius-4 cursor-pointer ml-8 d-flex justify-content-center align-items-center neutral200 remove-pg-button"
                      onClick={() => {
                        remove(i);
                      }}
                    >
                      <DeleteOutline style={{ color: "var(--neutral300)" }} />
                    </div>
                  </div>
                );
              })}
              <div className="mb-32 col-12 text-center  neutral300 more-option radius-4 p-8 ">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    push({
                      col1: "",
                      col2: "",
                    })
                  }
                >
                  More option <PostAdd />
                </span>
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default SubTopicFillInTheBlank;
