import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function useSubjectDetails() {
    const dispatch = useDispatch()
    const backStatus = useSelector((state) => state.BackStatus)

    useEffect(() => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
    }, [])

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "Subject", path: "/subject" },
        { name: "Create Subject", path: "" },
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel creating subject',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: '/subject',
        footerYes: 'Yes',
        footerNo: 'No',
    }

    return {
        backStatus,
        dataCrumbs,
        modalBack,
    }
}

export default useSubjectDetails