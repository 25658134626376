import React from "react";
import "./Assessments-preview.scss";
const AssessmentDividerPreview = (props) => {
  return (
    <>
      <div className="d-flex justify-content-center  py-16">
        <div
          className=" "
          style={{
            width: "240px",
            backgroundColor: "var(--secondary500)",
            height: "2px",
          }}
        ></div>
      </div>
    </>
  );
};

export default AssessmentDividerPreview;
