import React from "react";
import { Footer, SubTopicPreview } from "../../../components/organisms";

const DetailPreview = () => {
  return (
    <>
      <SubTopicPreview />
      <Footer className="bg-secondary200" />
    </>
  );
};

export default DetailPreview;
