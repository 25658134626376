import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import '../Modal.scss'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router';

function ModalGenerateCoupons() {
    const navigate = useNavigate()
    const statusMODAL = 'MODAL_GENERATE_COUPONS'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [nameSubject, setNameSubject] = useState('')
    const [initialValues, setInitialValues] = useState({
        subject: '',
        count: 1,
    })
    const [optionSubject, setOptionSubject] = useState([{ key: 'Choose subject', value: '' }])

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            RestAPI.find('subject/find?courseId=622ee2cd7ef86a9e5bc3ceb7&author=AtlazAdmin', config).then(res => {
                function compare(a, b) {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  }
                res.sort(compare);

                let data = [{ key: 'Choose subject', value: '' }]
                res.map(subject => {
                    data.push({
                        key: subject.name,
                        value: subject._id
                    })
                })
                setOptionSubject(data)
            })
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = Yup.object({
        subject: Yup.string().required("This field is required. Don't leave it empty!"),
        count: Yup.number()
          .typeError("This field must specify a number")
          .min(1, "Min value 1.")
          .max(1000, "Min value 1000.")
          .required("This field is required. Don't leave it empty!"),
    })
    
    const onSubmit = values => {
        RestAPI.post('coupon', values, config).then(res => {
            localStorage.setItem('ATLAZ_Coupons', JSON.stringify({
                subject: nameSubject,
                data: res.data,
              }))
            navigate('/generate-coupons')
            toast.success(contentToast(
                `Generate Coupons!`, `Success to generate coupons.`), {
                icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })
            closeModal()
        })
        .catch((err) => {
            toast.error(contentToast(
                "Failed to update", "Your connection not stable. Try again."), {
                icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
            closeModal()
        });
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                <div className="modal-header">
                                    <h5 className="tx-header5">Generate Coupons</h5>
                                    <p className="tx-small neutral300">Fill all input to generate coupons</p>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="subject" className="form-label tx-small-po mb-1">Subject</label>
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='subject' options={optionSubject}
                                                onInput={(e) => {
                                                    formik.setFieldValue("subject", e.target.value)
                                                   let name =  e.target.options[e.target.selectedIndex].text
                                                   setNameSubject(name)
                                                }}
                                            />
                                        </div>
                                        <div style={{ height: '12px' }}></div>
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='number' name='count' placeholder='Input count' label="Count" isLabel />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name='Create' addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default ModalGenerateCoupons