import React from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";
const EmptySpaceShow = (props) => {
  console.log(props);

  return (
    <div className="col-12 card-container mb-40">
      <div className="card-content">
        <div style={{ height: "32px" }}></div>
      </div>
      <SubTopicDetailShowFooter
        title={props.data.contentType.name}
        data={props.data}
        order={props.order}
        index={props.index}
        submitAction={props.submitAction}
      />
    </div>
  );
};

export default EmptySpaceShow;
