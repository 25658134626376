import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Buttons from "../../../atoms/Buttons";
import { connect, useDispatch, useSelector } from "react-redux";

const ModalFillInTheBlank = () => {
  const statusMODAL = "MODAL_FILL_IN_THE_BLANK";
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const isStatusMODAL = useSelector((state) => state.openModal);
  const [initialValues, setInitialValues] = useState({
    name: "",
    data: "",
    formik: null,
  });

  const modalClass = ["modal-content"];

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      setInitialValues({
        name: isStatusMODAL.crud.name,
        formik: isStatusMODAL.crud.formik,
        data: isStatusMODAL.crud.data,
      });
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  const onSubmit = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
    initialValues.formik.setFieldValue(initialValues.name, initialValues.data);
  };
  return (
    <>
      <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
        <div className=" px-24 pb-16 py-24 ">
          <h5 className="tx-header5">Input 2 Answer</h5>
          <p className="neutral300">
            Fill the answer according the order of question.
          </p>
        </div>
        <div className="p-24 bg-secondary200 ">
          <p className="tx-small-po mb-6">Answer</p>

          <textarea
            name=""
            id=""
            cols="30"
            rows="3"
            className="form-control"
            onChange={(e) => {
              setInitialValues({ ...initialValues, data: e.target.value });
            }}
          >
            {initialValues.data}
          </textarea>

          <ul className="ml-20 neutral400 mt-6">
            <li className="tx-small">
              The answer will be in the order of __ in the question column.
            </li>
            <li className="tx-small">
              For answers that are more than 1 in the same question, separate
              the answers with a semicolon ( ; ). Example: Elephant; Giraffe.
            </li>
          </ul>
        </div>
        <div className="p-24 text-end">
          <Buttons
            type="button"
            addClass="btnOutline mr-16"
            control="button"
            name="Discard"
            btnExSmall
            onClick={() => closeModal()}
          />
          <Buttons
            type="submit"
            addClass="btnPrimary "
            control="button"
            name="Save Answer"
            btnExSmall
            onClick={() => onSubmit()}
          />
        </div>
      </Modal>
    </>
  );
};

export default connect()(ModalFillInTheBlank);