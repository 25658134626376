import React, { useEffect, useRef, useState } from "react";
import { ModalLink, ModalRedirect, TitleSection } from "../../../../molecules";
import "./TextEditorAssesment.scss";
import { Formik, Form, ErrorMessage } from "formik";
import TextError from "../../../../atoms/Formik/TextError";
import { Editor } from "@tinymce/tinymce-react";
import Buttons from "../../../../atoms/Buttons";
import Toast from "../../../../atoms/Toast";
import { connect } from "react-redux";
import { useAssessmentTextEditor } from "../../../../services";
const TextEditorAssessment = () => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    dataCrumbs,
    modalBack,
    action,
    handleCancel,
  } = useAssessmentTextEditor();
  const editorRef = useRef(null);

  const inpFile = useRef();

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalRedirect />
      <section className="assessment-text-editor mx-48">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mt-16">
              <TitleSection data={dataCrumbs} titleheader="Text Editor" />
            </div>
            <div className="col-12 ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form>
                    {
                      <div className="row">
                        <div className="col-12 mb-24 ">
                          <input type="file" ref={inpFile} hidden />
                          <Editor
                            name="contents"
                            initialValue={initialValues.contents}
                            apiKey="5bvlkdhnupz7sh6ea9qwoeyec1uh4uaa4yida68iaak00gfu"
                            onInit={(evt, editor) => {
                              return (editorRef.current = editor);
                            }}
                            onEditorChange={(value) => {
                              formik.setFieldValue("contents", value);
                            }}
                            onBlur={formik.handleBlur("contents")}
                            init={{
                              height: 500,
                              menubar: false,
                              placeholder: "Create your content here",
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "styleselect | bold italic link align | bullist numlist outdent indent | image media code table | insertfile undo redo",
                              image_advtab: true,

                              file_picker_callback: function (
                                callback,
                                value,
                                meta
                              ) {
                                inpFile.current.click();
                                if (meta.filetype === "image") {
                                  inpFile.current.addEventListener(
                                    "change",
                                    (ev) => {
                                      var file = ev.target.files[0];
                                      var reader = new FileReader();
                                      reader.onload = function (e) {
                                        callback(reader.result, {
                                          alt: "",
                                        });
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  );
                                }
                              },
                            }}
                          />
                          <ErrorMessage component={TextError} name="contents" />
                        </div>
                      </div>
                    }

                    <div className="text-end btn-form mb-32">
                      <Buttons
                        control="modalLink"
                        addClass="py-8 mr-16 px-48"
                        btnOutline
                        btnRegular
                        btnMoExSmall
                        data={modalBack}
                        path={handleCancel(formik)}
                        // path="MODAL_LINK"
                      >
                        Cancel
                      </Buttons>
                      <Buttons
                        control="button"
                        type="submit"
                        name={action !== "update" ? "Save" : "Update"}
                        addClass="py-8 px-60"
                        btnPrimary
                        btnRegular
                        btnMoExSmall
                        btnDisable={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect()(TextEditorAssessment);
