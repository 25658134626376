import React from "react";
import { useTeacherResources } from "../../../services";
import {
  ModalActiveResources,
  ModalDeleteResources,
  ModalTypeResources,
  TableTeacherResources,
  TitleSection,
} from "../../../molecules";

const TeacherResourcesList = () => {
  const {
    dataCrumbs,
    initDataType,
  } = useTeacherResources()

  initDataType()

  return (
    <section className="school-list">
      <div className="container-fluid">
        <div className="row">
          <TitleSection data={dataCrumbs} titleheader="Teacher Resources" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <TableTeacherResources />
            <ModalTypeResources />
            <ModalDeleteResources />
            <ModalActiveResources />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeacherResourcesList;
