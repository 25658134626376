import React from 'react';
import { Base64Essays, Footer, Navbar } from '../../components/organisms';

function Essays() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64Essays />
        <Footer/>
      </>
  )
}

export default Essays;
