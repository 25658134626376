import React from 'react';
import propTypes from "prop-types"
import './Modal.scss'
import Buttons from '../../atoms/Buttons';

function Modals(props) {
    const className = [""];
    const modalClass = ["modal-content"];
    className.push(props.addClass);

    if (props.btnPrimary) className.push("btnPrimary")
    if (props.btnSuccess) className.push("btnSuccess")
    if (props.btnDanger) className.push("btnDanger")
    if (props.btnSecondary) className.push("btnSecondary")
    if (props.btnWarning) className.push("btnWarning")
    if (props.btnInfo) className.push("btnInfo")
    if (props.btnGhost) className.push("btnGhost")
    if (props.btnOutline) className.push("btnOutline")
    if (props.btnWrapper) className.push("btnWrapper")
    if (props.btnDisable) className.push("disabled")
    if (props.btnExSmall) className.push("btnExSmall")
    if (props.btnSmall) className.push("btnSmall")
    if (props.btnRegular) className.push("btnRegular")
    if (props.btnTaSmall) className.push("btnTaSmall")
    if (props.btnMoSmall) className.push("btnMoSmall")
    if (props.btnTaExSmall) className.push("btnTaExSmall")
    if (props.btnMoExSmall) className.push("btnMoExSmall")
    if (props.btnIcon) className.push("btnIcon")
    if (props.btnShadow) className.push("isShadow")

    if (props.isForm) modalClass.push("isForm")
    if (props.isNoForm) modalClass.push("isNoForm")

    return (
        <>
            <Buttons control="modal" className={className.join(" ")} target={props.dataTarget} name={props.children} />
            <div className="modal fade modalcrud" id={props.dataTarget} tabIndex="-1" aria-labelledby="crudModalLabel" aria-hidden="true">
                <div className='modal-dialog modal-dialog-centered'>
                    <div className={modalClass.join(" ")} >
                        <div className="modal-header d-block">
                            <h5 className="tx-header5" id="crudModalLabel">{props.data.title}</h5>
                            <p className="tx-small neutral300">{props.data.description}</p>
                        </div>
                        <div className="modal-body">
                            {
                                (props.isNoForm) && <div className="message">
                                    <span className='tx-body-po'>{props.howMuch}</span> {props.data.message}
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <Buttons control="modal" btnExSmall btnOutline name="Cancel" />
                            <Buttons control="button" addClass="btn-confirm" btnExSmall btnOutline name={props.data.footer} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Modals.propTypes = {
    className: propTypes.string,
    howMuch: propTypes.string,
    isForm: propTypes.bool,
    isNoForm: propTypes.bool,
};

export default Modals;
