import React from 'react';
import TitleSection from '../../molecules/TitleSection';
import { connect } from 'react-redux'
import { useDashboard } from '../../services'
import './DashboardList.scss'

function DashboardList() {
  const { name } = useDashboard()

  return (
      <>
        <section className="dashboard-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection titleheader={name} />
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(DashboardList)