import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import rootReducer from './redux/reducer/globalReducer'

// Store
const storeRedux = createStore(rootReducer)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={storeRedux}><App /></Provider>
  </BrowserRouter>,
  document.getElementById('root')
);