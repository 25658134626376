import React from 'react';
import { Footer, Navbar, SubtopicList } from '../../../components/organisms';

function Subtopics() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <SubtopicList />
        <Footer/>
      </>
  )
}

export default Subtopics;
