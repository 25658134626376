import React from "react";
import { Navigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const ReAuthenticate = (props) => {
    const Authentication = JSON.parse(localStorage.getItem("ATLAZ_Authentication"))

    if(Authentication) {
        // let decode = jwt_decode(Authentication.accessToken);
        // if (Math.floor(new Date().getTime()/1000) > decode.exp) {
        //     localStorage.removeItem("ATLAZ_Authentication");
        //     return <Navigate to="/Logout" />
        // }
        return <Navigate to="/dashboard" />
    } 
    return props.children
};
export default ReAuthenticate