import React from 'react';
import { DashboardList, Footer, Navbar } from '../components/organisms';

function Dashboard() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <DashboardList />
      <Footer />
    </>
  )
}

export default Dashboard
