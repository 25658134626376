import React from "react";

import { Footer, Navbar, AssessmentShow } from "../../../components/organisms";
const AssesmentShowPage = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <AssessmentShow />
      <Footer />
    </>
  );
};

export default AssesmentShowPage;
