import { NotInterested, PostAdd } from "@mui/icons-material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ImageIcon from "@mui/icons-material/Image";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import RestAPI from "../../../../../config/RestAPI";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Toast from "../../../../atoms/Toast";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { ModalLink, TitleSection } from "../../../../molecules";
import { useAssessmentMatchPairs } from "../../../../services";
// import "./MatchPairs.scss";

const MatchPairs = () => {
  const {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
    colors,
    abj,
  } = useAssessmentMatchPairs();
  return (
    <>
      <Toast />
      <ModalLink />
      <section className="match-pairs">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Match Pairs" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <div className="col-12">
                    <OptionsSection formik={formik} abj={abj} />
                  </div>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={action !== "update" ? "Save" : "Update"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

const OptionsSection = (props) => {
  const { abj, formik } = props;
  const handlePush = () => {
    return { abjad: "A", question: "", answer: "" };
  };
  return (
    <div className="mt-32">
      <FieldArray name={`options`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          const { options } = values;
          return (
            <>
              <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 ">
                <div className="mb-16">
                  <div className="mb-16 tx-header6">Question</div>
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="textarea"
                    type="text"
                    name="question"
                    placeholder="e.g. A. Answer the question!"
                  />
                </div>
                {options.map((r, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="mb-12 row  " key={i}>
                        <div className="col-6">
                          <div className="d-flex ">
                            <div className="abjad d-flex align-items-center justify-content-center ">
                              {abj[i]}
                            </div>
                            <FormikControl
                              addClass={`isTaSmall isMoExSmall  w-full options-${i}-question  ${
                                r.question.includes("data:image/") && "d-none"
                              }`}
                              control="input"
                              type="text"
                              hidden={true}
                              name={`options.[${i}].question`}
                              placeholder="Enter the option"
                            />

                            <input
                              type="text"
                              className="form-control"
                              disabled={true}
                              hidden={
                                r.question.includes("data:image/")
                                  ? false
                                  : true
                              }
                              defaultValue={"fake filename...."}
                              style={{ height: "40px" }}
                              id={`fake-name-options-${i}-question`}
                            />
                            {/* input file toggle with name */}
                            <input
                              type="file"
                              hidden
                              accept={"image/*"}
                              id={`file-hidden-question-options-${i}-question`}
                              onInput={(e) => {
                                let fakeName = document.querySelector(
                                  `#fake-name-options-${i}-question`
                                );
                                fakeName.value = e.target.files[0].name;
                                fakeName.hidden = false;

                                let imagePreview = document.querySelector(
                                  `#image-options-${i}-question`
                                );
                                imagePreview.hidden = false;

                                let question = document.querySelector(
                                  `.options-${i}-question`
                                );
                                question.hidden = true;

                                let file = e.target.files[0];
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  var result = reader.result;

                                  document.querySelector(
                                    `#image-preview-${i}-question`
                                  ).src = result;

                                  formik.setFieldValue(
                                    `options.[${i}].question`,
                                    result
                                  );
                                };
                                reader.readAsDataURL(file);
                              }}
                            />

                            <button
                              type="button"
                              className=" img-button bg-neutral100 px-12 py-6 radius-tr-4 radius-br-4 radius-tl-0 radius-bl-0 "
                              style={{ border: "none" }}
                              control="button"
                              onClick={() => {
                                document
                                  .querySelector(
                                    `#file-hidden-question-options-${i}-question`
                                  )
                                  .click();
                              }}
                            >
                              <ImageIcon />
                            </button>
                          </div>

                          {/* preview image */}
                          <div
                            className="img-preview bg-white position-relative"
                            id={`image-options-${i}-question`}
                            hidden={
                              r.question.includes("data:image/") ? false : true
                            }
                            style={{
                              width: "120px",
                              height: "120px",
                              marginTop: "24px",
                            }}
                          >
                            <span
                              className={`close-icon cursor-pointer  `}
                              onClick={(e) => {
                                formik.setFieldValue(
                                  `options.[${i}].question`,
                                  ""
                                );

                                let fakeName = document.querySelector(
                                  `#fake-name-options-${i}-question`
                                );

                                fakeName.value = "";
                                fakeName.hidden = true;

                                let imagePreview = document.querySelector(
                                  `#image-options-${i}-question`
                                );
                                imagePreview.hidden = true;

                                let question = document.querySelector(
                                  `.options-${i}-question`
                                );
                                question.hidden = false;

                                //input file hidden
                                document.querySelector(
                                  `#file-hidden-question-options-${i}-question`
                                ).value = "";
                              }}
                            >
                              <CloseOutlinedIcon className="text-white" />
                            </span>

                            <img
                              id={`image-preview-${i}-question`}
                              src={r.question}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "14px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex ">
                            <FormikControl
                              addClass={`isTaSmall isMoExSmall w-full options-${i}-answer ${
                                r.answer.includes("data:image/") && "d-none"
                              }`}
                              control="input"
                              type="text"
                              name={`options.[${i}].answer`}
                              placeholder="Enter the option"
                            />

                            <input
                              type="text"
                              className="form-control"
                              disabled={true}
                              hidden={
                                r.answer.includes("data:image/") ? false : true
                              }
                              defaultValue="fake file name..."
                              style={{ height: "40px" }}
                              accept={"image/*"}
                              id={`fake-name-options-${i}-answer`}
                            />
                            {/* input file toggle with name */}
                            <input
                              type="file"
                              hidden
                              accept={"image/*"}
                              id={`file-hidden-question-options-${i}-answer`}
                              onInput={(e) => {
                                let fakeName = document.querySelector(
                                  `#fake-name-options-${i}-answer`
                                );
                                fakeName.value = e.target.files[0].name;
                                fakeName.hidden = false;

                                let imagePreview = document.querySelector(
                                  `#image-options-${i}-answer`
                                );
                                imagePreview.hidden = false;

                                let answer = document.querySelector(
                                  `.options-${i}-answer`
                                );
                                answer.hidden = true;

                                let file = e.target.files[0];
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  var result = reader.result;

                                  document.querySelector(
                                    `#image-preview-${i}-answer`
                                  ).src = result;

                                  formik.setFieldValue(
                                    `options.[${i}].answer`,
                                    result
                                  );
                                };
                                reader.readAsDataURL(file);
                              }}
                            />

                            <button
                              type="button"
                              className=" img-button bg-neutral100 px-12 py-6 radius-tr-4 radius-br-4 radius-tl-0 radius-bl-0 "
                              style={{ border: "none" }}
                              control="button"
                              onClick={() => {
                                document
                                  .querySelector(
                                    `#file-hidden-question-options-${i}-answer`
                                  )
                                  .click();
                              }}
                            >
                              <ImageIcon />
                            </button>

                            <div
                              className={`delete d-flex align-items-center cursor-pointer ${
                                i < 2 ? "bg-neutral100" : "bg-white"
                              } justify-content-center `}
                              onClick={() => {
                                if (i >= 2) {
                                  remove(i);
                                }
                              }}
                            >
                              <DeleteOutlinedIcon className="neutral400" />
                            </div>
                          </div>

                          {/* preview image */}
                          <div
                            className="img-preview bg-white position-relative"
                            id={`image-options-${i}-answer`}
                            hidden={
                              r.answer.includes("data:image/") ? false : true
                            }
                            style={{
                              width: "120px",
                              height: "120px",
                              marginTop: "24px",
                            }}
                          >
                            <span
                              className={`close-icon cursor-pointer  `}
                              onClick={(e) => {
                                formik.setFieldValue(
                                  `options.[${i}].answer`,
                                  ""
                                );

                                let fakeName = document.querySelector(
                                  `#fake-name-options-${i}-answer`
                                );

                                fakeName.value = "";
                                fakeName.hidden = true;

                                let imagePreview = document.querySelector(
                                  `#image-options-${i}-answer`
                                );
                                imagePreview.hidden = true;

                                let answer = document.querySelector(
                                  `.options-${i}-answer`
                                );
                                answer.hidden = false;

                                //input file hidden
                                document.querySelector(
                                  `#file-hidden-question-options-${i}-answer`
                                ).value = "";
                              }}
                            >
                              <CloseOutlinedIcon className="text-white" />
                            </span>

                            <img
                              id={`image-preview-${i}-answer`}
                              src={r.answer}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "14px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
                {options.length < 10 && (
                  <div
                    className=" col-12 text-center cursor-pointer neutral300 more-option radius-4 p-8 "
                    onClick={() => {
                      let abjLength = options.length;
                      push({
                        abjad: "",
                        question: "",
                        answer: "",
                        color: "",
                      });
                    }}
                  >
                    <span className="cursor-pointer">
                      More option <PostAdd />
                    </span>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default connect()(MatchPairs);
