import React from "react";
import Role from "../config/Role"
import { Navigate } from 'react-router-dom';

const CheckRole = (props) => {
    const { allowAccess } = props
    const Authentication = JSON.parse(localStorage.getItem("ATLAZ_Authentication"))
    const canAccess = allowAccess.split(", ");

    let userRoleName = Role.find(r => r.level === Authentication.role).name
    let isUserHaveAccess = canAccess.find(r => r.toLowerCase() === userRoleName.toLowerCase())

    if (!isUserHaveAccess) {
        return <Navigate to="/dashboard" />
    }
    
    return props.children
};
export default CheckRole