import React from "react";
import { Footer, Navbar, Worksheet } from "../../../../components/organisms";
const WorkSheet = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <Worksheet />
      <Footer />
    </>
  );
};

export default WorkSheet;
