import React from 'react'
import {LoginAdmin} from '../components/organisms'

function Login() {
  return (
    <>
      <LoginAdmin />
    </>
  )
}

export default Login