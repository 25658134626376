import React, { useState } from "react";
import { ReactAudioRecorder } from "@sarafhbk/react-audio-recorder";
import AssessmentShowFooter from "./AssessmentShowFooter";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import StopIcon from "@mui/icons-material/Stop";
import Audio from "../../../atoms/Audio/Audio";
import CachedIcon from "@mui/icons-material/Cached";
const AssessmentVoiceRecorderShow = (props) => {
  const [base64Result, setBase64Result] = useState("");
  const [base64UrlStill, setBase64UrlStill] = useState("");
  const [recordStatus, setRecordStatus] = useState("Idle");

  const blobToBase64 = (blobUrl) => {
    if (blobUrl !== null) {
      fetch(blobUrl).then((r) => {
        r.blob().then((re) => {
          let convertBlob = new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(re);
          });
          convertBlob.then((res) => {
            setBase64Result(res);
          });
        });
      });
    } else {
      return "";
    }
  };
  return (
    <>
      <div className="assessments-content-show">
        <div className="card-container mb-40 ">
          <div className="card-content ">
            <div className="card-content text-center">
              <div className="col-12">
                <ReactAudioRecorder
                  render={({
                    timer,
                    stopRecording,
                    startRecording,
                    resumeRecording,
                    pauseRecording,
                    audioResult,
                    status,
                    errorMessage,
                  }) => {
                    //
                    if (audioResult) {
                      if (audioResult !== base64UrlStill) {
                        setBase64UrlStill(audioResult); //ini berisi url blob
                        blobToBase64(audioResult); //ini meng konvert ke blob dan base 64
                        setRecordStatus("Recorded");
                      }
                    }

                    return (
                      <>
                        <div className="row gx-0">
                          <div className="col-12 text-center pt-32 pb-32 border-bottom">
                            {recordStatus === "Recorded" &&
                            status != "recording" ? (
                              <Audio src={base64Result} />
                            ) : (
                              <h1>
                                {new Date(timer * 1000)
                                  .toISOString()
                                  .substr(14, 5)}
                              </h1>
                            )}

                            <p className="mt-24 neutral300">
                              {status === "recording"
                                ? "Recording"
                                : recordStatus}
                            </p>
                          </div>

                          <div className="col-12 text-center pt-32 pb-24">
                            <div className="d-flex justify-content-center">
                              {status === "recording" ? (
                                <div
                                  className="radius-p-100 d-flex align-items-center   cursor-pointer justify-content-center "
                                  style={{ height: "64px", width: "64px" }}
                                >
                                  <div className="recording-animation d-flex justify-content-center align-items-center bg-secondary500 radius-p-100">
                                    <div
                                      className="bg-danger400 d-flex align-items-center cursor-pointer justify-content-center radius-p-100 text-white "
                                      style={{ height: "48px", width: "48px" }}
                                      onClick={() => {
                                        stopRecording();
                                      }}
                                    >
                                      <StopIcon />
                                    </div>
                                  </div>
                                </div>
                              ) : recordStatus === "Recorded" ? (
                                <div
                                  style={{ height: "64px", width: "64px" }}
                                  className="radius-p-100 bg-secondary300 d-flex align-items-center cursor-pointer justify-content-center"
                                >
                                  <div
                                    className="bg-white info500  d-flex align-items-center cursor-pointer justify-content-center radius-p-100 text-white"
                                    style={{
                                      height: "48px",
                                      width: "48px",
                                      border: "1px solid var(--info500)",
                                    }}
                                    onClick={() => {
                                      // startRecording();
                                      setRecordStatus("Idle");
                                      blobToBase64(null);
                                    }}
                                  >
                                    <CachedIcon />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div
                                    style={{ height: "64px", width: "64px" }}
                                    className="radius-p-100 bg-white d-flex align-items-center cursor-pointer justify-content-center"
                                  >
                                    <div
                                      className="bg-danger400 d-flex align-items-center cursor-pointer justify-content-center radius-p-100 text-white"
                                      style={{ height: "48px", width: "48px" }}
                                      onClick={() => {
                                        startRecording();
                                      }}
                                    >
                                      <KeyboardVoiceIcon />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <p className="text-center neutral300 mb-32">
                            {status === "recording"
                              ? "Click to stop record"
                              : recordStatus === "Recorded"
                              ? "Click to retry recording "
                              : "Click to record"}
                          </p>
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <AssessmentShowFooter
            data={props.data}
            status="inline"
            submitAction={props.submitAction}
          />
        </div>
      </div>
    </>
  );
};

export default AssessmentVoiceRecorderShow;
