import React from 'react'
import './LoginAdmin.scss'
import * as Yup from 'yup'
import RestAPI from '../../../config/RestAPI'
import FormikControl from '../../atoms/Formik/FormikControl'
import Buttons from '../../atoms/Buttons'
import Toast from '../../atoms/Toast'
import Logo from '../../../assets/images/logo/regular/default/Double.png'
import { Formik, Form } from 'formik'
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { contentToast } from '../../atoms/Toast/ContentToast'
import { NotInterested } from '@mui/icons-material';

function LoginAdmin() {
    const navigate = useNavigate();

    const initialValues = {
        email: '',
        password: '',
        remember: []
    }
    const validationSchema = Yup.object({
        email: Yup.string().required("This field is required, Don't leave it empty!"),
        password: Yup.string().required("This field is required, Don't leave it empty!"),
    })
    const onSubmit = values => {
        RestAPI.login('auth/login', values).then(res => {
            if (res.message === "Login success") {
                localStorage.setItem("ATLAZ_Authentication", JSON.stringify({
                    isAuthenticated: true,
                    userEmail: res.data.email,
                    userID: res.data._id,
                    userName: res.data.name,
                    accessToken: res.accessToken.token,
                    refreshToken: res.refreshToken,
                    role: res.data.role
                }));
                navigate("/dashboard");
            }
        })
        .catch((err) => {
            toast.error(contentToast(
                "Login Failed!","Incorrect email/password."), {
                icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
        });
    }

    return (
        <>
            <div className="page-login d-flex flex-column justify-content-between align-items-center">
                <div className="col-lg-12">
                    <div className="header text-center">
                        <Buttons control="exlink" path="/" isBlank btnLink name={<img src={Logo} alt="logo" className='logo' />} />

                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-10">
                    <div className="top text-center">
                        <div className="tx-header4">Login Admin</div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {
                            formik => <Form>
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='email' label="Email address" placeholder="Enter your email address" isLabel />
                                <div className="height18"></div>

                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='password' label='Password' isLabel name='password' placeholder="Enter your password" />
                                <div className="height18"></div>

                                {/* <div className="d-flex justify-content-between">
                                    <FormikControl addClass="isTaSmall isMoExSmall" control='checkbox' name='remember' labelClass="tx-small" options={[
                                        { key: 'Remember me', value: 'true' }
                                    ]} />
                                    <Buttons control="link" path="/forgotpassword" addClass="tx-small" btnLink name="Forgot password?" />
                                </div> */}
                                <div className="height20"></div>

                                <Buttons control="button" type="submit" btnRegular btnBlock btnSecondary name="Sign In" />
                                <div className="height20"></div>

                                <div className="text-center">
                                    <Buttons control="exlink" path="https://lms.hiatlaz.com" isBlank addClass="tx-small" btnLink name="Back to LMS" />
                                </div>
                                <div className="height100"></div>
                            </Form>
                        }
                    </Formik>
                </div>
                <div className="col-lg-12">
                    <div className="footer tx-small neutral200 text-center">
                        © 2022 PT Atlaz Belajar Bahasa. All Right Reserved
                    </div>
                </div>
            </div>
            <Toast />
        </>
    )
}

export default LoginAdmin