import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Class Name',
        accessor: 'name',
        Placehoder: 'e.g. Class 01',
        Filter: ColumnFilter
    },
    {
        Header: 'Academic Year',
        accessor: 'academicYear',
        Placehoder: 'e.g. 2022/2023',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Last update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                {/* <Buttons control="modal" target="MODAL_DELETE_CLASS" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}
                
                <Buttons control="modal" modal="EDIT" target="MODAL_UPDATE_CLASS" addClass="neutral200" crud={props.row.original} btnExSmall btnGhost btnIcon><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]