import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import RestAPI from "../../../../config/RestAPI";
import {
  AssessmentDividerPreview,
  AssessmentFileUploadPreview,
  AssessmentSingleChoicePreview,
  AssessmentTextEditorPreview,
  AssessmentVoiceRecorderPreview,
  AssessmentEssayPreview,
  AssessmentMatchPairsPreview,
  AssessmentMultipleChoicePreview,
  AssessmentShortAnswerPreview,
} from "../../../molecules";
const useAssessmentPreview = () => {
  const [initialValues, setInitialValues] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const { idsubtopic } = params;

  const [subTopicData, setSubTopicData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [questionsName, setQuestionsName] = useState([]);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const getSubTopicData = () => {
    setIsLoading(true);
    RestAPI.find("assessment/view/" + idsubtopic, config)
      .then((r) => {
        setSubTopicData(r.subTopic);
        let newQuestionName = [];
        let formValue = r.assessment.map((r, i) => {
          if (r.assessmentType.name.toLowerCase() === "single choice") {
            let buildScInit = r.questions.map((res, ind) => {
              newQuestionName.push({
                name: `${i}.questions[${ind}].userAnswer`,
                isFilled: false,
              });
              res = {
                ...res,
                userAnswer: undefined,
              };
              return res;
            });
            return { ...r, questions: buildScInit };
          } else if (
            r.assessmentType.name.toLowerCase() === "multiple choice"
          ) {
            let buildMcInit = r.questions.map((res, ind) => {
              newQuestionName.push({
                name: `${i}.questions[${ind}].userAnswer`,
                isFilled: false,
              });
              res = {
                ...res,
                userAnswer: [],
              };
              return res;
            });
            return { ...r, questions: buildMcInit };
          } else if (r.assessmentType.name.toLowerCase() === "short answer") {
            let buildSaInit = r.questions.map((res, ind) => {
              newQuestionName.push({
                name: `${i}.questions[${ind}].userAnswer`,
                isFilled: false,
              });
              res = {
                ...res,
                userAnswer: "",
              };
              return res;
            });

            return { ...r, questions: buildSaInit };
          } else if (r.assessmentType.name.toLowerCase() === "file uploader") {
            newQuestionName.push({
              name: `[${i}].userAnswer.base64File`,
              isFilled: false,
            });
            r = { ...r, userAnswer: { fakeFile: "", base64File: "" } };
            return r;
          } else if (r.assessmentType.name.toLowerCase() === "audio recorder") {
            newQuestionName.push({
              name: `[${i}].userAnswer`,
              isFilled: false,
            });
            r = { ...r, userAnswer: "" };
            return r;
          } else if (r.assessmentType.name.toLowerCase() === "essay") {
            newQuestionName.push({
              name: `${i}.userAnswer`,
              isFilled: false,
            });
            r = { ...r, userAnswer: "" };
            return r;
          } else if (r.assessmentType.name.toLowerCase() === "match pairs") {
            newQuestionName.push({
              name: `${i}.userAnswer`,
              isFilled: false,
            });
            let buildMpInit = r.options.map((res, ind) => {
              res = {
                ...res,
                userAnswer: "",
              };
              return res;
            });

            return { ...r, options: buildMpInit };
          } else {
            return r;
          }
        });

        setQuestionsName(newQuestionName);
        setInitialValues(formValue);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // navigate("/page-not-found");
      });
  };

  const handleProggress = (name, isFilled) => {
    let result = questionsName.map((r) => {
      if (r.name === name) {
        r = { ...r, isFilled: isFilled };
      }
      return r;
    });

    setQuestionsName(result);
  };

  useEffect(() => {
    getSubTopicData();
  }, []);

  const callBlock = (assessment, index, formik, nomor) => {
    assessment = { ...assessment, idsubtopic };
    switch (assessment.assessmentType.name.toLowerCase()) {
      case "text editor":
        return <AssessmentTextEditorPreview data={assessment} key={index} />;
      case "single choice":
        return (
          <AssessmentSingleChoicePreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
            nomor={nomor}
          />
        );
      case "multiple choice":
        return (
          <AssessmentMultipleChoicePreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
            nomor={nomor}
          />
        );
      case "match pairs":
        return (
          <AssessmentMatchPairsPreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
            nomor={nomor}
          />
        );
      case "short answer":
        return (
          <AssessmentShortAnswerPreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
            nomor={nomor}
          />
        );
      case "essay":
        return (
          <AssessmentEssayPreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
            nomor={nomor}
          />
        );

      case "divider":
        return <AssessmentDividerPreview data={assessment} key={index} />;
      case "audio recorder":
        return (
          <AssessmentVoiceRecorderPreview
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
          />
        );
      case "file uploader":
        return (
          <AssessmentFileUploadPreview
            data={assessment}
            key={index}
            index={index}
            formik={formik}
            setFieldValue={formik.setFieldValue}
            onSendProgress={handleProggress}
          />
        );
      default:
        return;
    }
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    console.log(values);
    // window.scrollTo({ top: 0, behavior: "smooth" });
    // navigate(`/subtopic/editsubtopic/${idsubtopic}`);
  };
  return {
    callBlock,
    params,
    isLoading,
    initialValues,
    onSubmit,
    questionsName,
    subTopicData,
  };
};

export default useAssessmentPreview;
