import React from 'react';
import './DropdownSelect.scss'
import Buttons from '../Buttons';

function DropdownSelect(props) {
    const { title, list, dropId, isDivider, divider, dividerSec, isLabel, dataSelected, howMuch, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, addClass, bgToogle, dropdownMenu, width, btnMoBlock, ...rest } = props

    const className = ["dropdown DropdownSelect"];
    const toggleClass = ["dropdown-toggle text-start"];
    const menuClass = ["dropdown-menu"];
    className.push(addClass);
    toggleClass.push(bgToogle)
    menuClass.push(dropdownMenu)
    if (isSmall) className.push("isSmall")
    if (isExSmall) className.push("isExSmall")
    if (isTaSmall) className.push("isTaSmall")
    if (isTaExSmall) className.push("isTaExSmall")
    if (isMoSmall) className.push("isMoSmall")
    if (isMoExSmall) className.push("isMoExSmall")
    
    if (btnMoBlock) toggleClass.push("btnMoBlock")

    const selectOption = (tes) => {
        console.log('tes');
    }

    return (
        <>
            <div className={className.join(" ")}>
                {
                    isLabel && <label htmlFor="" className="form-label tx-small-po d-block">Label</label>
                }
                <button className={toggleClass.join(" ")} type="button" id={dropId} data-bs-toggle="dropdown" aria-expanded="false" style={{ width: width}}>
                    {title}
                </button>
                <ul className={menuClass.join(" ")} aria-labelledby={dropId} {...rest}>
                    {
                        list.map((item, index) => {
                            switch (item.control) {
                                case 'button':
                                    return (
                                        <div key={item.name}>
                                            {
                                                isDivider && index === divider && <li><div className="dropdown-divider"></div></li>
                                            }
                                            {
                                                isDivider && index === dividerSec && <li><div className="dropdown-divider"></div></li>
                                            }
                                            <li {...rest}>
                                                <Buttons control="button" addClass={`dropdown-item ${item.addClass}`} onClick={item.onClick} name={item.name} />
                                            </li>
                                        </div>
                                    )
                                case 'link':
                                    return (
                                        <div key={item.name}>
                                            {
                                                isDivider && index === divider && <li><div className="dropdown-divider"></div></li>
                                            }
                                            {
                                                isDivider && index === dividerSec && <li><div className="dropdown-divider"></div></li>
                                            }
                                            <li {...rest}>
                                                <Buttons control="link" path={item.path} addClass={`dropdown-item ${item.addClass}`} onClick={(e) => selectOption(e)} name={item.name} />
                                            </li>
                                        </div>
                                    )
                                case 'modal':
                                    return (
                                        <div key={item.name}>
                                            {
                                                isDivider && index === divider && <li><div className="dropdown-divider"></div></li>
                                            }
                                            {
                                                isDivider && index === dividerSec && <li><div className="dropdown-divider"></div></li>
                                            }
                                            <li {...rest} >
                                                <Buttons control="modal" modal={item.modal} target={item.path} addClass={`dropdown-item ${item.addClass}`} crud={dataSelected} >{item.name}</Buttons>
                                            </li>
                                        </div>
                                    )
                                default: return null
                            }
                        })
                    }
                </ul>
            </div>

        </>
    )
}

export default DropdownSelect;
