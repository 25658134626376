import React, { useEffect, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";

const ShortAnswerShow = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <>
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          {data.instruction ? (
            <h5 className="mb-16">{data.instruction}</h5>
          ) : (
            ""
          )}
          <div className="mb-16 formFormik">
            <div className="">
              {data.questions.map((r, i) => {
                return (
                  <React.Fragment key={i}>
                    {r.image ? (
                      <div className="text-center mb-16">
                        <img src={r.image} alt="" width="100%" />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={`${i > 0 ? "mt-32" : ""} `}>
                      <span>
                        {i + 1}. {r.question}
                      </span>
                      <div className="ml-16 mt-8">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Enter the answer here"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};

export default ShortAnswerShow;
