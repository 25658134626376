import React from 'react'
import { Footer, MemberList, Navbar } from '../../../components/organisms';

function Member() {
  return (
    <>
        <Navbar/>
        <div className="height-82"></div>
        <MemberList />
        <Footer/>
      </>
  )
}

export default Member