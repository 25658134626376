export const dropdownAction = [
    { 
        name: "Active", 
        control: 'modal',
        path: "MODAL_ACTIVE_RESOURCES", 
        modal: 'ACTIVE',
        addClass: "" ,
    },
    { 
        name: "Inactive", 
        control: 'modal',
        path: "MODAL_ACTIVE_RESOURCES", 
        modal: 'INACTIVE',
        addClass: "" ,
    },
    { 
        name: "Delete", 
        control: "modal",
        path: "MODAL_DELETE_RESOURCES", 
        modal: 'DELETE',
        addClass: "danger400 btnGhost",
    },
]