import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';
import { CheckCircleOutline } from '@mui/icons-material';

function useSchoolEdit() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SCHOOL'))
  const getWhereFromMember = JSON.parse(localStorage.getItem('EDIT_MEMBER'))
  const dataSchool = useSelector((state) => state.APIEditSchool)
  const backStatus = useSelector((state) => state.BackStatus)
  const [tabs1, setTabs1] = useState('active')
  const [tabs2, setTabs2] = useState('')
  const [tabs3, setTabs3] = useState('')
  const [tabsBody1, setTabsBody1] = useState('show active')
  const [tabsBody2, setTabsBody2] = useState('')
  const [tabsBody3, setTabsBody3] = useState('')

  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dataCrumbs = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "School Member", path: "/school" },
    { name: dataSchool?.name , path: "" },
  ];

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel updating school',
    description: '',
    message: 'Once canceled, your inputed data will be lost.',
    path: '/school',
    footerYes: 'Yes',
    footerNo: 'No',
  }

  const iniData = () => {
    RestAPI.find(`school/${id}`, config).then(res => {
      dispatch({ type: 'API_EDIT_SCHOOL', newValue: res })
    }).catch((err) => {
      console.log(err.response)
      navigate("/notfound")
    });
  }
  
  useEffect(() => {
    iniData()
    if(getWhereFromMember) {
      if (getWhereFromMember.toast) {    
        if(getWhereFromMember.goal === 'CREATE') {
          toast.success(contentToast(
            "Member Created!", "Success to create the member."), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
          })
        } else if(getWhereFromMember.goal === 'EDIT') {
          toast.success(contentToast(
            "Member Updated!", "Success to update the member."), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
          })
        } else if(getWhereFromMember.goal === 'UPLOAD') {
          toast.success(contentToast(
            "Member Uploaded!", "Success to upload data member."), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
          })
        }
      }
      localStorage.removeItem("EDIT_MEMBER")
    }
    if(getWhereFrom) {
      if(getWhereFrom.goal === 'CREATE_CLASS') {
        setTabs1('')
        setTabsBody1('')
        setTabs2('active')
        setTabsBody2('show active')
        setTabs3('')
        setTabsBody3('')
      } else if(getWhereFrom.goal === 'CREATE_MEMBER') {
        setTabs1('')
        setTabsBody1('')
        setTabs2('')
        setTabsBody2('')
        setTabs3('active')
        setTabsBody3('show active')
      } else {
        setTabs1('active')
        setTabsBody1('show active')
        setTabs2('')
        setTabsBody2('')
        setTabs3('')
        setTabsBody3('')
        if (getWhereFrom.toast) {    
          toast.success(contentToast(
            "School Created!", "Success to create the school."), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
          })
        }
        localStorage.setItem("EDIT_SCHOOL", JSON.stringify({
          ...getWhereFrom,
          toast: false,
        }));
      }
    } else {
      setTabs1('active')
      setTabsBody1('show active')
      setTabs2('')
      setTabsBody2('')
      setTabs3('')
      setTabsBody3('')
    }
    dispatch({ type: 'BACK_STATUS', newValue: false })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }, [])

  return {
    id,
    getWhereFrom,
    getWhereFromMember,
    dataSchool,
    backStatus,
    tabs1,
    tabs2,
    tabs3,
    tabsBody1,
    tabsBody2,
    tabsBody3,
    dataCrumbs,
    modalBack,
    iniData,
  }
}

export default useSchoolEdit