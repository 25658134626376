import React, { useEffect, useState } from 'react'
import TextError from '../TextError'
import DefaultImage from "../../../../../src/assets/images/default.png";
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, ErrorMessage } from 'formik'
import '../Formik.scss'

function InputImageBox(props) {
  const { label, name, base64Name, defImg, accept, onInput, width, height, addClass, inputClass, boxClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, btnDelete, onClick, ...rest } = props

  const [initialImage, setInitialImage] = useState(DefaultImage)
  const [deleteImage, setDeleteImage] = useState(false)
  
  const className = ["formFormik"];
  const inputClassName = ["imagebox"];
  const boxClassName = ["frame-imagebox"];
  className.push(addClass);
  inputClassName.push(inputClass);
  boxClassName.push(boxClass);

  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")

  const handleFile = async (file) => {
    const base64 = await convertBase64(file)
    setInitialImage(base64)    
    setDeleteImage(true)
    return base64
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(() => {
    setInitialImage(DefaultImage)
    setDeleteImage(false)
    if(defImg[base64Name] !== '') {
      setInitialImage(defImg[base64Name])
      setDeleteImage(true)
    }
  }, [defImg])

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {({ form, field, meta: { touched, error } }) => {
          let { setFieldValue } = form;
          
          return (
            <>
              <div className={touched && error ? boxClassName.join(" error") : boxClassName.join(" ")} style={{ maxWidth: (width ? width : "432px"), height: (height ? height : "219px") }} >
                <img src={initialImage} alt="" className="image-preview" />
                <input
                  className={touched && error ? inputClassName.join(" error") : inputClassName.join(" ")}
                  style={{ maxWidth: (width ? width : "432px"), height: (height ? height : "219px") }} {...field}
                  type="file"
                  accept={accept}
                  onInput={(e) => {
                    const file = e.target.files[0]
                    setFieldValue(name, e.target.value) 
                    if (file !== undefined) {
                      const base64 = handleFile(file)
                      base64.then(r => {
                        setFieldValue(base64Name, r);    
                      })             
                      props.onFileChange(file)
                    } 
                  }} />
                <div
                  className={`btn-delete-image ${deleteImage ? '' : 'd-none'}`}
                  onClick={() => {
                    let file = { size: 0 }
                    setDeleteImage(false)
                    props.onFileChange(file)
                    setInitialImage(DefaultImage)
                    setFieldValue(name, "")
                    setFieldValue(base64Name, "")   
                  }}
                ><CancelIcon /></div>
              </div>
            </>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default InputImageBox