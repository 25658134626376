import React, { useState, useEffect } from 'react';
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useParams } from 'react-router';
import { useTableClass } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';
import '../Modal.scss'

function ModalUpdateClass() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const statusMODAL = 'MODAL_UPDATE_CLASS'
    const { initData } = useTableClass()
    const isStatusMODAL = useSelector((state) => state.openModal)
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        name: '',
        academicYear: '',
        school: id,
    })
    const currentYear = new Date().getFullYear()

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if (isStatusMODAL.modal === 'EDIT') {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    academicYear: isStatusMODAL.crud.academicYear,
                    school: id,
                })
            } else {
                setInitialValues({
                    name: '',
                    academicYear: '',
                    school: id,
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        academicYear: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }
    const onSubmit = values => {
        if (isStatusMODAL.modal === 'CREATE') {
            RestAPI.post('school/classlist', values, config).then(res => {
                reloadTableClass('Created', 'create')
            })
            .catch((err) => {
                failedProcess()
            });
        } else {
            RestAPI.put(`school/classlist/${values._id}`, values, config).then(res => {
                reloadTableClass('Updated', 'update')
            })
            .catch((err) => {
                failedProcess()
            });
        }
    }
    const reloadTableClass = (mess1, mess2) => {
        initData()      
        RestAPI.find(`school/members?school=${id}`, config).then(res => {
            const newdata = []
            res?.map((item, index) => {
                newdata.push({
                    ...item,
                    subject: item.subject ? item.subject.name : '',
                    expiredSubject: format(new Date(item.expiredSubject), 'yyyy-MM-dd'),
                    updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
                })
            })
            dispatch({ type: 'API_MEMBER', newValue: [...newdata] })
        })
        toast.success(contentToast(
            `Class ${mess1}!`, `Success to ${mess2} the class.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: true })
        toast.error(contentToast(
            "Failed to update", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                {
                                    (isStatusMODAL.modal === 'CREATE') ?
                                        <>
                                            <div className="modal-header">
                                                <h5 className="tx-header5">Create New Class</h5>
                                                <p className="tx-small neutral300">Fill all input to create new class</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Class 01' label="Class name" isLabel />
                                                    </div>
                                                    <div style={{ height: '24px' }}></div>
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='academicYear' placeholder={`e.g. ${currentYear}/${currentYear+1}`} label="Academic year" isLabel />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="modal-header">
                                                <h5 className="tx-header5">Edit Class</h5>
                                                <p className="tx-small neutral300">Change and update the class info.</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Class 01' label="Class name" isLabel />
                                                    </div>
                                                    <div style={{ height: '24px' }}></div>
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='academicYear' placeholder={`e.g. ${currentYear}/${currentYear+1}`} label="Academic year" isLabel />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }

                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name={(isStatusMODAL.modal === 'CREATE') ? 'Create' : 'Update'} addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default ModalUpdateClass