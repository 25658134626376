import React, { useEffect, useRef, useState } from "react";
import RestAPI from "../../../../config/RestAPI";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { NotInterested } from "@mui/icons-material";
import * as Yup from "yup";

function useCreateSubject() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editorRef = useRef(null);
  const inpFile = useRef();

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = { headers: { token: "Bearer " + tokenAccess.accessToken } };

  const [iconDiscount, setIconDiscount] = useState("Rp");
  const [maxDiscount, setMaxDiscount] = useState(99999999999);
  const [finalPrice, setFinalPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const discountOptions = [
    { key: "Price", value: "price" },
    { key: "Percentage", value: "percent" },
  ];

  const [assignCourseOptions, setAssignCourseOptions] = useState([
    { key: "None", value: "" },
  ]);
  const [levelOptions, setLevelOptions] = useState([
    { key: "None", value: "" },
  ]);

  const iniDataCourse = () => {
    RestAPI.find("course", config).then((res) => {
      let data = [{ key: "None", value: "" }];
      res.map((crs) => {
        data.push({
          key: crs.name,
          value: crs._id,
        });
      });
      setAssignCourseOptions(data);
    });
  };

  const initDataLevel = () => {
    RestAPI.find("level", config).then((res2) => {
      let data = [{ key: "Select level", value: "" }];
      res2.map((lvl) => {
        data.push({
          key: lvl.level,
          value: lvl.level,
        });
      });
      setLevelOptions(data);
    });
  };

  useEffect(() => {
    iniDataCourse();
    initDataLevel();
  }, []);

  const initialValues = {
    name: "",
    courseId: "",
    author: "",
    originalPrice: 0,
    discountType: "price",
    discountAmount: 0,
    overview: "",
    imageCover: "",
    imageBook1: "",
    imageBook2: "",
    imageBook3: "",
    fakeImageCover: "",
    fakeImage1: "",
    fakeImage2: "",
    fakeImage3: "",
    level: "",
    cefrLevel: "",
    subjectFocus: "",
    tokopedia: "",
    shopee: "",
    blibli: "",
    fakePdf1: "",
    fakePdf2: "",
    lessonPlanSchool: "",
    lessonPlanPublic: "",
  };

  const cefrOption = ["Pre A1", "A1", "A2", "B1", "B2", "C1", "C2"];

  const objValidationSchema = {
    name: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    author: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    originalPrice: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .required("This field is required. Don't leave it empty!"),
    discountAmount: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .max(maxDiscount, `Max value ${maxDiscount}`),
    overview: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    cefrLevel: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    subjectFocus: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  };

  let validationSchema = Yup.object(objValidationSchema);
  if (tokenAccess.role === 5) {
    validationSchema = Yup.object({
      ...objValidationSchema,
      originalPrice: Yup.number()
        .typeError("This field must specify a number")
        .min(0, "Min value 0."),
      tokopedia: Yup.string(),
      shopee: Yup.string(),
      blibli: Yup.string(),
    });
  }

  const _validFileImage = [".jpg", ".jpeg"];
  const checkFormatImage = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFileImage.length; j++) {
        let sCurExtension = _validFileImage[j];
        if (
          value
            .substr(value.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true;
      }
    }
  };

  const [sizeImage1, setSizeImage1] = useState(0);
  const validateFileImage = (value) => {
    let error;
    if (!value && sizeImage1 === 0) {
      error = "This field is required. Don't leave it empty!";
    }
    if (sizeImage1 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };

  const [sizeImage2, setSizeImage2] = useState(0);
  const validateFileImage2 = (value) => {
    let error;
    if (sizeImage2 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };
  const [sizeImage3, setSizeImage3] = useState(0);
  const validateFileImage3 = (value) => {
    let error;
    if (sizeImage3 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };
  const [sizeImage4, setSizeImage4] = useState(0);
  const validateFileImage4 = (value) => {
    let error;
    if (sizeImage4 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };

  const _validFilePdf = [".pdf"];
  const checkFormatPdf = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFilePdf.length; j++) {
        let sCurExtension = _validFilePdf[j];
        if (
          value
            .substr(value.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true;
      }
    }
  };

  const [sizePdf1, setSizePdf1] = useState(0);
  const validateFilePdf1 = (value) => {
    let error;
    if (sizePdf1 > 70000000) {
      error = "File size is too large";
    }
    if (value && checkFormatPdf(value)) {
      error = "The file format is not suitable!";
    }
    return error;
  };

  const [sizePdf2, setSizePdf2] = useState(0);
  const validateFilePdf2 = (value) => {
    let error;
    if (sizePdf2 > 70000000) {
      error = "File size is too large";
    }
    if (value && checkFormatPdf(value)) {
      error = "The file format is not suitable!";
    }
    return error;
  };

  const changeBackStatus = (formik, initial, value) => {
    const newInitial = {
      ...formik.values,
      [initial]: value,
    };
    const {
      name,
      courseId,
      author,
      overview,
      originalPrice,
      discountAmount,
      fakeImageCover,
      fakeImage1,
      fakeImage2,
      fakeImage3,
      level,
      cefrLevel,
      subjectFocus,
      tokopedia,
      shopee,
      blibli,
      lessonPlanSchool,
      lessonPlanPublic,
    } = newInitial;

    if (
      name ||
      courseId ||
      author ||
      overview ||
      parseInt(originalPrice) ||
      parseInt(discountAmount) ||
      fakeImageCover ||
      fakeImage1 ||
      fakeImage2 ||
      fakeImage3 ||
      level ||
      cefrLevel ||
      subjectFocus ||
      tokopedia ||
      shopee ||
      blibli ||
      lessonPlanSchool ||
      lessonPlanPublic
    ) {
      dispatch({ type: "BACK_STATUS", newValue: true });
    } else {
      dispatch({ type: "BACK_STATUS", newValue: false });
    }
  };

  const updateFinalPrice = (value) => {
    let total = 0;
    if (iconDiscount === "Rp") {
      total = value - discount;
    } else if (iconDiscount === "%") {
      total = value - (value * discount) / 100;
    }
    setOriginalPrice(value);
    setFinalPrice(total);
  };

  const onSubmit = (values, { setSubmitting }) => {
    // setSubmitting(false);
    // console.log(values)
    RestAPI.post("subject/create/new", values, config)
      .then((res) => {
        dispatch({ type: "BACK_STATUS", newValue: false });
        localStorage.setItem(
          "EDIT_SUBJECT",
          JSON.stringify({
            goal: "CREATE",
            toast: true,
          })
        );
        localStorage.setItem(
          "QUICK_ADD_CONTENT",
          JSON.stringify({
            goal: "CREATE",
            subjectId: res.data._id,
            subjectName: res.data.name,
            toast: true,
          })
        );
        setSubmitting(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setInterval(1000);
        navigate(`/subject/editsubject/${res.data._id}`);
      })
      .catch((err) => {
        console.log("error", err.response);
        setSubmitting(false);
        toast.error(
          contentToast(
            "Failed to create",
            "Your connection not stable. Try again."
          ),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
      });
  };

  return {
    config,
    editorRef,
    inpFile,
    tokenAccess,
    iconDiscount,
    setIconDiscount,
    maxDiscount,
    setMaxDiscount,
    finalPrice,
    setFinalPrice,
    updateFinalPrice,
    originalPrice,
    setOriginalPrice,
    discount,
    setDiscount,
    discountOptions,
    assignCourseOptions,
    levelOptions,
    cefrOption,
    iniDataCourse,
    initDataLevel,
    initialValues,
    validationSchema,
    setSizeImage1,
    setSizeImage2,
    setSizeImage3,
    setSizeImage4,
    validateFileImage,
    validateFileImage2,
    validateFileImage3,
    validateFileImage4,
    changeBackStatus,
    onSubmit,
    setSizePdf1,
    setSizePdf2,
    validateFilePdf1,
    validateFilePdf2,
  };
}

export default useCreateSubject;
