import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CheckCircleOutline } from '@mui/icons-material';
import { toast } from 'react-toastify'
import { contentToast } from '../../atoms/Toast/ContentToast';

function useTeacherResources() {
    const dispatch = useDispatch()
    const getWhereFrom = JSON.parse(localStorage.getItem('CRUD_RESOURCES'))

    const dataCrumbs = [
      { name: "Dashboard", path: "/dashboard" },
      { name: "Teacher Resources", path: "" },
    ];

    const initDataType = () => {
        if (getWhereFrom) {
            if (getWhereFrom.goal === 'CREATE') {
                if (getWhereFrom.toast) {
                    toast.success(contentToast(
                        "Teacher Resources Created!", "Success to create the teacher resources."), {
                        icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                    })
                }
                localStorage.setItem("CRUD_RESOURCES", JSON.stringify({
                    ...getWhereFrom,
                    toast: false,
                }));
            }
        }
        dispatch({ type: 'BACK_STATUS', newValue: false })
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    useEffect(() => {
        initDataType()
    }, [])


    return {
        dataCrumbs,
        initDataType,
    }
}

export default useTeacherResources