import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';
import {
    CheckCircleOutline,
    NotInterested,
} from '@mui/icons-material';
import * as Yup from 'yup'

function useEditInstitutionList() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [namePage, setNamePage] = useState('')
  const backStatus = useSelector((state) => state.BackStatus)

  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dataCrumbs = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Fondations Information", path: "/fondations" },
    { name: namePage, path: "" }
  ];

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel updating fondation',
    description: '',
    message: 'Once canceled, your inputed data will be lost.',
    path: '/fondations',
    footerYes: 'Yes',
    footerNo: 'No',
  }

  const exSchool = [
    { _id: '62becb9b77e6248e8bc3a0ce', value:"62becb9b77e6248e8bc3a0ce", label:"Sekolah Atlas" }
  ]

  const [initialValues, setInitialValues] = useState({
    _id: '',
    name: '',
    schoolList: []
  })
  const [optionSchools, setOptionSchools] = useState([])
  const [optionSchoolsAll, setOptionSchoolsAll] = useState([])

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required. Don't leave it empty!"),
  })

  const changeBackStatus = (initial, value) => {
    const allInitial = {
      ...initialValues,
      status: initialValues.status.toString()
    }
    const newInitial = {
      ...initialValues,
      [initial]: value
    }

    if (allInitial.status !== newInitial.status) {
      dispatch({ type: 'BACK_STATUS', newValue: true })
    } else {
      dispatch({ type: 'BACK_STATUS', newValue: false })
    }
  }

  const onSubmit = (values, { setSubmitting }) => {
    RestAPI.put(`institution/update/${id}`, values, config).
      then(res => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
        toast.success(contentToast(
          "Fondations Updated!", "Success to update the fondations."), {
          icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response)
        toast.error(contentToast(
          "Failed to update", "Your connection not stable. Try again."), {
          icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        setSubmitting(false);
      });
  }

  const initData = () => {
    RestAPI.find(`institution/${id}`, config).then(res => {
      setNamePage(res.institution.name)

      const currentSchool = []
      const schoolAll = []
      res.institution.schoolList.map((item, index) => {
        currentSchool.push({
          _id: item._id,
          value: item._id,
          label: item.name,
        })
        schoolAll.push({
          _id: item._id,
          value: item._id,
          label: item.name,
        })
      })
      res.schools.map((item, index) => {
        schoolAll.push({
          _id: item._id,
          value: item._id,
          label: item.name,
        })
      })
      setOptionSchools(currentSchool)
      setOptionSchoolsAll(schoolAll)
      setInitialValues({
        _id: res.institution._id,
        name: res.institution.name,
        schoolList: currentSchool,
      })

    }).catch((err) => {
      console.log(err.response)
      navigate("/notfound")
    });
    dispatch({ type: 'BACK_STATUS', newValue: false })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    initData()
  }, [])

  return {
    id,
    backStatus,
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    changeBackStatus,
    onSubmit,
    optionSchools,
    exSchool,
    optionSchoolsAll,
    setOptionSchools,
  }
}

export default useEditInstitutionList