import React, { useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";

const DialogAndNumberingShow = (props) => {
  const [data, setData] = useState(props.data);

  const flatMap = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
      var mapping = fn(array[i], i);
      result = result.concat(mapping);
    }
    return result;
  };
  const qaReplace = (str, name) => {
    var result = str;
    result = flatMap(result.split("__"), function (part, i) {
      return [part, <AutoGrowInput name={name + `[${i}]`} key={i} />];
    });
    result.pop();
    return result;
  };
  return (
    <>
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          {data.instruction ? (
            <h5 className="mb-16">{data.instruction}</h5>
          ) : (
            ""
          )}
          {data.image ? (
            <div className="text-center mb-2">
              <img src={data.image} alt="" width="100%" />
            </div>
          ) : (
            ""
          )}
          <div className="">
            <>
              <table>
                <tbody>
                  {data.questions.map((r, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr className="mb-4 w-full">
                          <td
                            className="pr-20 fw-bold"
                            style={{ verticalAlign: "top" }}
                          >
                            {qaReplace(
                              r.col1.question,
                              `questions[${i}].col1.answer`
                            )}
                          </td>
                          <td className="text-start">
                            {qaReplace(
                              r.col2.question,
                              `questions[${i}].col2.answer`
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </>
          </div>
        </div>
        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};
const AutoGrowInput = () => {
  return (
    <>
      <>
        <label className={`input-sizer  `}>
          <input
            type="text"
            size="1"
            onInput={(e) => {
              e.target.parentNode.dataset.value = e.target.value;
            }}
          />
        </label>
      </>
    </>
  );
};
export default DialogAndNumberingShow;
