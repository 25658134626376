import React from 'react'
import {connect} from 'react-redux'
import { useDispatch  } from 'react-redux'

function BtnModal(props) { 
  const { 
    children, name, target, crud, modal, addClass, btnPrimary, btnSuccess, btnDanger, btnSecondary, btnWarning, btnInfo, btnGhost, btnOutline, btnWrapper, btnRegular, btnSmall, btnExSmall, btnTaSmall, btnTaExSmall, btnMoSmall, btnMoExSmall, btnIcon, btnDisable, isShadow, ...rest 
  } = props

  const className = [""];
  className.push(addClass);
  if(btnPrimary) className.push("btnPrimary")
  if(btnSuccess) className.push("btnSuccess")
  if(btnDanger) className.push("btnDanger")
  if(btnSecondary) className.push("btnSecondary")
  if(btnWarning) className.push("btnWarning")
  if(btnInfo) className.push("btnInfo")
  if(btnGhost) className.push("btnGhost")
  if(btnOutline) className.push("btnOutline")
  if(btnWrapper) className.push("btnWrapper")
  if(btnRegular) className.push("btnRegular")
  if(btnSmall) className.push("btnSmall")
  if(btnExSmall) className.push("btnExSmall")
  if(btnTaSmall) className.push("btnTaSmall")
  if(btnTaExSmall) className.push("btnTaExSmall")
  if(btnMoSmall) className.push("btnMoSmall")
  if(btnMoExSmall) className.push("btnMoExSmall")
  if(btnIcon) className.push("btnIcon")
  if(btnDisable) className.push("disabled")
  if(isShadow) className.push("isShadow")

  const dispatch = useDispatch()
  const openModal = () => {
    dispatch({ type: "OPEN_MODAL", newValue: {
      status: true,
      target: target || "",
      crud: crud || "",
      modal: modal || "",
    } })
  }

  return (
    <>
      <button type="button" className={className.join(" ")} onClick={openModal} {...rest} >
        {children}
      </button>
    </>
  )
}

export default BtnModal