import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';
import { NotInterested } from '@mui/icons-material';

function useMemberInfo() {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const dataSchool = useSelector((state) => state.APIEditSchool)
    const backStatus = useSelector((state) => state.BackStatus)

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const [roleOptions, setRoleOptions] = useState([
        { key: 'Select role', value: '' },
    ])

    const [classOptions, setClassOptions] = useState([
        { key: 'Select classroom', value: '' },
    ])

    const [subjectOptions, setSubjectOptions] = useState([
        { key: 'Select subject', value: '' },
    ])

    const initialValues = {
        username: '',
        name: '',
        role: 0,
        classlist: '',
        subject: '',
        expiredSubject: '',
    }

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "School Member", path: "/school" },
        { name: dataSchool?.name , path: `/school/editschool/${id}` },
        { name: "Member Details", path: "" },
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel creating member',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: `/school/editschool/${id}`,
        footerYes: 'Yes',
        footerNo: 'No',
    }

    const validationSchema = Yup.object({
        username: Yup.string().required("This field is required. Don't leave it empty!"),
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        role: Yup.number().required("This field is required. Don't leave it empty!"),
        classlist: Yup.string().required("This field is required. Don't leave it empty!"),
        subject: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeBackStatus = (formik, initial, value) => {
        const newInitial = {
          ...formik.values,
          [initial]: value
        }
        const { username, name, role, classlist, subject } = newInitial
        
        if (username || name || role || classlist || subject) {
          dispatch({ type: 'BACK_STATUS', newValue: true })
        } else {
          dispatch({ type: 'BACK_STATUS', newValue: false })
        }
    }

    const onSubmit = (values, { setSubmitting }) => {
        values = {
            ...values,
            role: parseInt(values.role)
        }
        RestAPI.post('users/create', values, config).then(res => {
            dispatch({ type: 'BACK_STATUS', newValue: false })
            localStorage.setItem("EDIT_MEMBER", JSON.stringify({
                goal: 'CREATE',
                toast: true,
            }));
            setSubmitting(false);
            navigate(`/school/editschool/${id}`)
        })
            .catch((err) => {
                console.log('error', err.response)
                setSubmitting(false);
                toast.error(contentToast(
                    "Failed to create", "Your connection not stable. Try again."), {
                    icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                })
            });
    }

    const initData = () => { 
        RestAPI.find(`school/classlist?school=${id}`, config).then(res => {
            let data = [{ key: 'Select classroom', value: '' }]
            res.map(cls => {
                data.push({
                    key: `${cls.name} - ${cls.academicYear}`,
                    value: cls._id
                })
            })
            setClassOptions(data)
        })
        RestAPI.find('users/roles', config).then(res => {
            let data = [{ key: 'Select role', value: '' }]
            res.map(rl => {
                data.push({
                    key: rl.title,
                    value: rl.level
                })
            })
            setRoleOptions(data)
        })
        RestAPI.find('subject', config).then(res => {
            let data = [{ key: 'Select subject', value: '' }]
            res.map(sbj => {
                data.push({
                    key: sbj.name,
                    value: sbj._id
                })
            })
            setSubjectOptions(data)
        })
        dispatch({ type: 'BACK_STATUS', newValue: false })
        dispatch({
          type: 'OPEN_MODAL', newValue: {
            status: false,
            target: '',
            crud: null,
            modal: '',
          }
        })
    }

    useEffect(() => {
        initData()    
    }, [])

    return {
        id,
        dataCrumbs,
        modalBack,
        dataSchool,
        backStatus,
        roleOptions,
        classOptions,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
        initData
    }
}

export default useMemberInfo