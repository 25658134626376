import React from 'react'
import InputText from '../../atoms/InputText'
import SelectOption from '../../atoms/SelectOption'

export const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column
    const dataSelect = [
        { name: 'e.g. Active', value: '', disabled: false, selected: false },
        { name: 'Active', value: true, disabled: false, selected: false },
        { name: 'Inactive', value: false, disabled: false, selected: false }
    ]
    return (
        <span>
            {
                (column.Header != 'Status') ?
                <InputText inputClass="bRadius4" isExSmall value={filterValue || ''} placeholder={column.Placehoder} onChange={e => setFilter(e.target.value)} />
                :
                <SelectOption data={dataSelect} noAppreance inputClass="bRadius4 w-100" accessor={column.Placehoder} placeholder={column.Placehoder} onChange={e => setFilter(e.target.value)} />
            }
           
        </span>
    )
} 
