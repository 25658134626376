import RestAPI from '../../../../config/RestAPI'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { format } from 'date-fns'

function useTableClass() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APIClass)
    const dataSchool = useSelector((state) => state.APIEditSchool)

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const initData = () => {     
        dispatch({ type: 'LOADING_TABLE', newValue: false })   
        RestAPI.find(`school/classlist?school=${id}`, config).then(res => {
            const newdata = []
            const newClassMini = []
            res.map(item => {
                newdata.push({
                    ...item,
                    updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
                })
                newClassMini.push({
                    id: item._id,
                    name: item.name,
                    academicYear: item.academicYear
                })
            })
            dispatch({ type: 'API_CLASS', newValue: [...newdata] })
            dispatch({ type: 'API_CLASS_MINI', newValue: [...newClassMini] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
        initData()
    }, [])

  return {
    id,
    loading,
    data,
    dataSchool,
    initData,
  }
}

export default useTableClass