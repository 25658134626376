import React, { useEffect, useState } from 'react'
import TextError from '../TextError'
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, ErrorMessage } from 'formik'
import '../Formik.scss'

function InputFile(props) {
  const { label, name, base64Name, defFile, accept, onClick, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, onInput, ...rest } = props

  const [deleteImage, setDeleteImage] = useState(false)
  const [fileName, setFilename] = useState('No file chosen')

  const className = ["formFormik"];
  const inputClassName = ["form-control form-inputfile"];
  className.push(addClass);
  inputClassName.push(inputClass);
  
  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")

  const handleFile = async (file) => {
    const base64 = await convertBase64(file)
    setDeleteImage(true)
    return base64
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(() => {
    setFilename('No file chosen')
    setDeleteImage(false)
    if (defFile[base64Name] !== '') {
      setFilename(defFile[base64Name])
      setDeleteImage(true)
    }
  }, [defFile])

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {({ form, field, meta: { touched, error } }) => {
          let { setFieldValue } = form;

          return (
            <div className="pdf-box">
              <input
                className={touched && error ? inputClassName.join(" error") : inputClassName.join(" ")} {...field}
                type="file"
                accept={accept}
                onInput={(e) => {
                  const file = e.target.files[0]
                  setFieldValue(name, e.target.value)
                  if (file !== undefined) {
                    const base64 = handleFile(file)
                    base64.then(r => {
                      setFieldValue(base64Name, r);
                    })
                    setFilename(e.target.files[0].name)
                    props.onFileChange(file)
                  }
                }} />
              <div className="input-group inputfilebox-shadow">
                <span className="input-group-text">Choose File</span>
                <div className="form-control">{fileName}</div>
              </div>
              <div
                className={`btn-delete-pdf ${deleteImage ? '' : 'd-none'}`}
                onClick={() => {
                  let file = { size: 0 }
                  setDeleteImage(false)
                  props.onFileChange(file)
                  setFieldValue(name, "")
                  setFieldValue(base64Name, "")
                  setFilename('No file chosen')
                }}
              ><CancelIcon style={{ fontSize: '20px' }} /></div>
            </div>
          );
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default InputFile