import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Sub Topic Name',
        accessor: 'name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Topic',
        accessor: 'topic.name',
        Placehoder: 'e.g. Reading',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Topic',
        accessor: 'topic._id',
        Placehoder: 'e.g. 123456',
        Filter: ColumnFilter
    },
    {
        Header: 'Lesson',
        accessor: 'lesson.name',
        Placehoder: 'e.g. Unit 1',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Lesson',
        accessor: 'lesson._id',
        Placehoder: 'e.g. 123456',
        Filter: ColumnFilter
    },
    {
        Header: 'Subject',
        accessor: 'subject.name',
        Placehoder: 'e.g. English Play 01',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Subject',
        accessor: 'subject._id',
        Placehoder: 'e.g. 123456',
        Filter: ColumnFilter
    },
    {
        Header: 'Course',
        accessor: 'course.name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'ID Course',
        accessor: 'course._id',
        Placehoder: 'e.g. 123456',
        Filter: ColumnFilter
    },
    {
        Header: 'Owner',
        accessor: 'createdBy.name',
        Placehoder: 'e.g. Atlaz',
        Filter: ColumnFilter
    },
    {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                {/* <Buttons control="modal" target="MODAL_DELETE_SUBTOPIC" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}

                <Buttons control="link" path={`/subtopic/editsubtopic/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon
                onClick={() => {
                    localStorage.setItem('BackLink', '/subtopic')
                }}
                ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]