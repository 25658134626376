import { CheckCircleOutline, NotInterested } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import RestAPI from "../../../../config/RestAPI";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import {
  AssessmentDividerShow,
  AssessmentFileUploadShow,
  AssessmentSingleChoiceShow,
  AssessmentTextEditorShow,
  AssessmentVoiceRecorderShow,
  AssessmentEssayShow,
  AssessmentMatchPairsShow,
  AssessmentMultipleChoiceShow,
  AssessmentShortAnswerShow,
} from "../../../molecules";
const useAssessmentShow = () => {
  const [responseSubtopic, setResponseSubtopic] = useState({});
  const [assessmentData, setAssessmentData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  // const { idsubtopic } = params;
  const idsubtopic = params.id;

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initToast = () => {
    let assessmentToast = localStorage.getItem("atlazAssessmentToast");
    if (assessmentToast) {
      assessmentToast = JSON.parse(assessmentToast);
      if (
        assessmentToast.show === true &&
        assessmentToast.status === "success"
      ) {
        callToast("success", assessmentToast.title, assessmentToast.message);
        localStorage.setItem("atlazAssessmentToast", JSON.stringify({}));
      }
    }
  };

  const callToast = (status, title, message) => {
    if (status === "success") {
      return toast.success(contentToast(title, message), {
        icon: (
          <CheckCircleOutline
            style={{ color: "var(--info500)", fontSize: "30px" }}
          />
        ),
      });
    } else {
      return toast.error(contentToast(title, message), {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      });
    }
  };

  useEffect(() => {
    getSubTopicData();
    initToast();
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  }, []);

  const getSubTopicData = () => {
    setIsLoading(true);
    RestAPI.find("assessment/view/" + idsubtopic, config)
      .then((r) => {
        console.log('r.subTopic', r.subTopic)
        setAssessmentData(r.assessment);
        setResponseSubtopic(r.subTopic);
        setIsLoading(false);
        let checkEdiorContent = r.assessment.filter(
          (filterRes) =>
            filterRes.assessmentType.name === "File Uploader" ||
            filterRes.assessmentType.name === "Audio Recorder"
        );

        let type;
        if(r?.subTopic?.isAssessment){
          if(r?.subTopic.assessmentType === 'Reading Listening Assessment'){
            type = "Automatic Grading"
          } else if(r?.subTopic.assessmentType === 'Speaking Writing Assessment'){
            type = "Manual Grading"
          } else {
            type = r?.subTopic.assessmentType
          }
        }
        console.log(type)
        RestAPI.find(
          `assessmenttype?category=${type}`,
          config
        )
          .then((res) => {
            if (checkEdiorContent.length > 0) {
              let contentEditor = res.find(
                (fc) => fc.name === "Content Editor"
              );
              let inline = res.find((fc) => fc.name === "Inline");
              let showedContent = contentEditor.assessmentType.filter(
                (f) => f.name !== "File Uploader" && f.name !== "Audio Recorder"
              );

              res = res.map((final) => {
                if (final.name === "Content Editor") {
                  final = { ...final, assessmentType: showedContent };
                }
                return final;
              });
            }
            setModalData(res);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        navigate("/page-not-found");
      });
  };

  const callBlock = (assessment, nomor) => {
    assessment = { ...assessment, idsubtopic };
    switch (assessment.assessmentType.name.toLowerCase()) {
      case "text editor":
        return (
          <AssessmentTextEditorShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
          />
        );
      case "single choice":
        return (
          <AssessmentSingleChoiceShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
            nomor={nomor}
          />
        );
      case "multiple choice":
        return (
          <AssessmentMultipleChoiceShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
            nomor={nomor}
          />
        );
      case "match pairs":
        return (
          <AssessmentMatchPairsShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
            nomor={nomor}
          />
        );
      case "short answer":
        return (
          <AssessmentShortAnswerShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
            nomor={nomor}
          />
        );
      case "essay":
        return (
          <AssessmentEssayShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
            nomor={nomor}
          />
        );

      case "divider":
        return (
          <AssessmentDividerShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
          />
        );
      case "audio recorder":
        return (
          <AssessmentVoiceRecorderShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
          />
        );
      case "file uploader":
        return (
          <AssessmentFileUploadShow
            data={assessment}
            submitAction={changeOrder}
            key={Math.random()}
          />
        );
      default:
        return;
    }
  };

  const handleCreateInline = (data) => {
    setIsLoading(true);
    let req = {
      subTopicId: data[0],
      assessmentCategory: data[1],
      assessmentTypeId: data[2],
    };
    RestAPI.post("assessment", req, config)
      .then((r) => {
        callToast("success", "Content Created!", "Success to create content.");
        getSubTopicData();
      })
      .catch((err) => {
        callToast(
          "error",
          "Failed to create content!",
          err.response.data.message
        );
      });
  };

  const handleDeleteAssessment = (id) => {
    RestAPI.delete("assessment/" + id, config)
      .then((r) => {
        callToast("success", "Content Deleted!", "Success to delete content.");
        getSubTopicData();
      })
      .catch((err) => {
        callToast(
          "error",
          "Failed to delete content!",
          err.response.data.message
        );
      });
  };

  const changeOrder = (order, id) => {
    // console.log(id);
    const values = {
      order: order,
    };
    RestAPI.put(`assessment/reorder/${id}`, values, config)
      .then(() => {
        callToast(
          "success",
          "Content Updated!",
          "Success to update the content."
        );
        getSubTopicData();
      })
      .catch((err) => {
        callToast(
          "Error",
          "Failed to process",
          "Your connection not stable. Try again."
        );
      });
  };

  return {
    responseSubtopic,
    assessmentData,
    modalData,
    isLoading,
    idsubtopic,
    initToast,
    getSubTopicData,
    callBlock,
    handleCreateInline,
    handleDeleteAssessment,
    changeOrder,
  };
};

export default useAssessmentShow;
