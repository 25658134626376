import React, { useState, useEffect } from 'react'
import './SubtopicInfo.scss'
import * as Yup from 'yup'
import FormikControl from '../../../atoms/Formik/FormikControl'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons'
import { useParams } from 'react-router';
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { 
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function SubtopicInfo(props) {
    const { dataSubTopic } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    // const inputContent = JSON.parse(localStorage.getItem('INPUT_CONTENT'))
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const statusOptions = [
        { key: 'Active', value: true },
        { key: 'Inactive', value: false },
    ]
    const [optionCourse, setOptionCourse] = useState([{ key: 'None', value: '' }])
    const [optionSubject, setOptionSubject] = useState([])
    const [optionLesson, setOptionLesson] = useState([])
    const [optionTopic, setOptionTopic] = useState([])

    const [initialCourse, setInitialCourse] = useState()
    const [initialSubject, setInitialSubject] = useState()
    const [initialLesson, setInitialLesson] = useState()

    const [initialValues, setInitialValues] = useState({
        _id: '',
        name: '',
        status: false,
        courseId: '',
        subjectId: '',
        lessonId: '',
        topicId: '',
    })

    useEffect(() => {
        RestAPI.find('course', config).then(res => {
            let data = [{ key: 'None', value: '' }]
            res.map(crs => {
                data.push({
                    key: crs.name,
                    value: crs._id
                })
            })
            setOptionCourse(data)
        })
        if (dataSubTopic) {
                setInitialValues({
                    _id: dataSubTopic._id,
                    name: dataSubTopic.name,
                    status: dataSubTopic.status,
                    courseId: dataSubTopic.course._id,
                    subjectId: dataSubTopic.subject._id,
                    lessonId: dataSubTopic.lesson._id,
                    topicId: dataSubTopic.topic._id,
                })
                setInitialCourse(dataSubTopic.course._id)
                setInitialSubject(dataSubTopic.subject._id)
                setInitialLesson(dataSubTopic.lesson._id)
                RestAPI.find(`subject/find?courseId=${dataSubTopic.course._id}`, config).then(res2 => {
                    let data2 = [{ key: 'Select subject', value: '' }]
                    res2.map(subject => {
                        data2.push({
                            key: subject.name,
                            value: subject._id
                        })
                    })
                    setOptionSubject(data2)
                })
                RestAPI.find(`lesson/find?subjectId=${dataSubTopic.subject._id}`, config).then(res3 => {
                    let data3 = [{ key: 'Select lesson', value: '' }]
                    res3.map(lesson => {
                        data3.push({
                            key: lesson.name,
                            value: lesson._id
                        })
                    })
                    setOptionLesson(data3)
                })
                RestAPI.find(`topic/find?lessonId=${dataSubTopic.lesson._id}`, config).then(res4 => {
                    let data4 = [{ key: 'Select topic', value: '' }]
                    res4.map(topic => {
                        if(!topic.isAssessment) {
                            data4.push({
                                key: topic.name,
                                value: topic._id
                            })
                        }
                    })
                    setOptionTopic(data4)
                })
        }
    }, [dataSubTopic])

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        subjectId: Yup.string().required("This field is required. Don't leave it empty!"),
        lessonId: Yup.string().required("This field is required. Don't leave it empty!"),
        topicId: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeBackStatus = () => {
      dispatch({ type: 'BACK_STATUS', newValue: true })
    }

    const onSubmit = values => {
        RestAPI.put(`subtopic/${id}`, values, config).then(() => {
            RestAPI.find(`subtopic/${id}`, config).then(res2 => {
              dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res2 })
            })
            dispatch({ type: 'BACK_STATUS', newValue: false })
            toast.success(contentToast(
              "Sub topic Updated!", "Success to update the sub topic."), {
              icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })  
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((err) => {
            console.log(err.response)
            toast.error(contentToast(
              "Failed to update", "Your connection not stable. Try again."), {
              icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
            window.scrollTo({ top: 0, behavior: 'smooth' })
        });
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formSubtopicInfo formFormik">
                        <div className="tx-header5">General Information</div>
                        <div className="height48"></div>
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">ID</div>
                            </div>
                            <div className="col-md-7">
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues._id} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Sub Topic Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Reading 01' onInput={() => changeBackStatus()} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Sub Topic Status</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' options={statusOptions} onInput={() => changeBackStatus()} />
                            </div>
                        </div>

                        <hr className="divider" />

                        <div className="tx-header5">Assign Section</div>
                        <div className="height48"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Assign Course</div>
                                <div className="desc tx-ex-small neutral400">
                                    Subject will assigned to selected course. If you want to unassigned course, select the “None” option.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='courseId' options={optionCourse}
                                    onInput={(e) => {
                                        formik.setFieldValue("courseId", e.target.value)
                                        formik.setFieldValue("subjectId", '')
                                        formik.setFieldValue("lessonId", '')
                                        formik.setFieldValue("topicId", '')
                                        changeBackStatus()
                                        RestAPI.find(`subject/find?courseId=${e.target.value}`,
                                            config).then(res => {
                                                let data = [{ key: 'Select subject', value: '' }]
                                                res.map(subject => {
                                                    data.push({
                                                        key: subject.name,
                                                        value: subject._id
                                                    })
                                                    let data2 = [{ key: 'Select lesson', value: '' }]
                                                    RestAPI.find(`lesson/find?subjectId=${subject._id}`,
                                                        config).then(res2 => {
                                                            res2.map(lesson => {
                                                                data2.push({
                                                                    key: lesson.name,
                                                                    value: lesson._id
                                                                })
                                                                let data3 = [{ key: 'Select topic', value: '' }]
                                                                RestAPI.find(`topic/find?lessonId=${lesson._id}`,
                                                                    config).then(res3 => {
                                                                        res3.map(topic => {
                                                                            if(!topic.isAssessment) {
                                                                                data3.push({
                                                                                    key: topic.name,
                                                                                    value: topic._id
                                                                                })
                                                                            }
                                                                        })
                                                                        setOptionTopic(data3)
                                                                    })
                                                            })
                                                            setOptionLesson(data2)
                                                        })
                                                })
                                                setOptionSubject(data)
                                            })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Subject</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='subjectId' options={optionSubject}
                                    onInput={(e) => {
                                        formik.setFieldValue("subjectId", e.target.value)
                                        formik.setFieldValue("lessonId", '')
                                        formik.setFieldValue("topicId", '')
                                        changeBackStatus()
                                        RestAPI.find(`lesson/find?subjectId=${e.target.value}`,
                                            config).then(res => {
                                                let data = [{ key: 'Select lesson', value: '' }]
                                                res.map(lesson => {
                                                    data.push({
                                                        key: lesson.name,
                                                        value: lesson._id
                                                    })
                                                    RestAPI.find(`topic/find?lessonId=${e.target.value}`,
                                                        config).then(res2 => {
                                                            let data2 = [{ key: 'Select topic', value: '' }]
                                                            res2.map(topic => {
                                                                if(!topic.isAssessment) {
                                                                    data2.push({
                                                                        key: topic.name,
                                                                        value: topic._id
                                                                    })
                                                                }
                                                            })
                                                            setOptionTopic(data2)
                                                        })
                                                })
                                                setOptionLesson(data)
                                            })
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Lesson</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='lessonId' options={optionLesson}
                                    onInput={(e) => {
                                        formik.setFieldValue("lessonId", e.target.value)
                                        formik.setFieldValue("topicId", '')
                                        changeBackStatus()
                                        RestAPI.find(`topic/find?lessonId=${e.target.value}`,
                                            config).then(res => {
                                                let data = [{ key: 'Select topic', value: '' }]
                                                res.map(topic => {
                                                    if(!topic.isAssessment) {
                                                        data.push({
                                                            key: topic.name,
                                                            value: topic._id
                                                        })
                                                    }
                                                })
                                                setOptionTopic(data)
                                            })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Topic</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='topicId' options={optionTopic} onInput={() => changeBackStatus()} />
                            </div>
                        </div>
                        <div className="height40"></div>
                        <div className="height48"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default connect()(SubtopicInfo)