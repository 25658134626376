import RestAPI from '../../../config/RestAPI';
import { useState } from 'react';
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

function useGenerateCoupons() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable)
    const [data, setData] = useState([])
    const [arrCSV, setArrCSV] = useState([data])
    let arrNameCSV = ["data"]

    const dataCrumbs = [
      { name: "Dashboard", path: "/dashboard" },
      { name: "Coupon List", path: "/coupons" },
      { name: "Generate Coupons", path: "" },
    ];

    const initData = () => {
        const result = JSON.parse(localStorage.getItem("ATLAZ_Coupons"))
        const newData = []
        result?.data?.map((item, key) => {
            newData.push({
                no: key+1,
                subject: result.subject ? result.subject : '',
                subjectId: item.subject ? item.subject : '',
                codeCoupon: item.codeCoupon ? item.codeCoupon : '',
            })
        })
        setData(newData)
        setArrCSV([newData])
    }

    useEffect(() => {
        initData()
    }, [])


    return {
        loading,
        initData,
        dataCrumbs,
        data,
        arrCSV,
        arrNameCSV,
    }
}

export default useGenerateCoupons