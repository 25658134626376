import React from "react";
import { Field, Form, Formik } from "formik";
import AssessmentShowFooter from "./AssessmentShowFooter";
import FormikControl from "../../../atoms/Formik/FormikControl";
const AssessmentFileUploadShow = (props) => {
  const initialValues = {
    file: "",
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      <div className="assessments-content-show">
        <div className="card-container mb-40 ">
          <div className="card-content ">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form>
                <Field name="file">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <input type="file" className="form-control" {...field} />
                  )}
                </Field>

                <div className="tx-small neutral200">
                  Format file : pdf, doc, docx
                  <br />
                  File size : &lt; 5mb
                </div>
              </Form>
            </Formik>
          </div>
          <AssessmentShowFooter
            data={props.data}
            status="inline"
            submitAction={props.submitAction}
          />
        </div>
      </div>
    </>
  );
};

export default AssessmentFileUploadShow;
