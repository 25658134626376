import { DeleteOutline, PostAdd } from "@mui/icons-material";
import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Toast from "../../../../atoms/Toast";
import { ModalLink, TitleSection } from "../../../../molecules";
import { useAssessmentMultipleChoice } from "../../../../services";
import "./MultipleChoiceAssessment.scss";
const MultipleChoiceAssessment = () => {
  const {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
  } = useAssessmentMultipleChoice();

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="multiple-choice ">
        <div className="container-fluid ">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Multiple Choice" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <FieldArray name={"questions"}>
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { questions } = values;
                      return (
                        <>
                          {questions.map((q, i) => {
                            return (
                              <React.Fragment key={i}>
                                <QuestionSection
                                  name={`questions[${i}]`}
                                  onClose={() => {
                                    remove(i);
                                  }}
                                  formik={formik}
                                />
                              </React.Fragment>
                            );
                          })}
                          {/* <div className="col-12 mb-24">
                            <div
                              className=" text-center neutral300 more-option radius-4  cursor-pointer py-24 radius-8"
                              onClick={() => push(initQuestion)}
                            >
                              <span className="new-question">
                                <Add /> <br />
                                New question
                              </span>
                            </div>
                          </div> */}
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={action !== "update" ? "Save" : "Update"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};
const QuestionSection = (props) => {
  return (
    <>
      <div className="col-12 p-40 bg-secondary200 radius-14 mb-24">
        <div className="mb-16">
          <label htmlFor="">
            <h6 className="text-strong">Question </h6>
          </label>
          <FormikControl
            addClass="isTaSmall isMoExSmall"
            control="input"
            type="text"
            name={`${props.name}.question`}
            placeholder="Enter the question"
          />
        </div>
        <div className="mb-32">
          <label htmlFor="">
            <p className="tx-small">
              <span className="text-strong">Upload image </span>
              <i>(optional)</i>
            </p>
          </label>

          <FormikControl
            addClass="isTaSmall isMoExSmall"
            control="inputbase64"
            name={`${props.name}.fakeImage`}
            base64Name={`${props.name}.image`}
          />
        </div>
        <div>
          <p className="text-strong mb-16">Option</p>
          <PG name={props.name} />
        </div>
      </div>
    </>
  );
};
const PG = (props) => {
  return (
    <>
      <FieldArray name={`${props.name}.options`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          let spliter = props.name.split("[");
          const { options } = values[spliter[0]][spliter[1].substring(0, 1)];
          // console.log("fieldArrayProps", fieldArrayProps);
          // console.log("Form ", options);
          return (
            <div className="mb-24">
              {options.map((options, index) => (
                <div key={index} className="mb-12 d-flex">
                  <div className="pg-input mr-16">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      name={`${props.name}.options[${index}].text`}
                      type="text"
                      control="input"
                      placeholder="Enter the option"
                    />
                  </div>
                  <div className="bg-white radius-4 pg-card d-flex justify-content-center align-items-center neutral200 ">
                    <Field
                      type="checkbox"
                      name={`${props.name}.options[${index}].isAnswer`}
                    />
                  </div>
                  <div
                    className={` radius-4 cursor-pointer pg-card ml-16 d-flex justify-content-center align-items-center neutral200 remove-pg-button ${
                      index < 2 ? "bg-neutral100" : ""
                    }`}
                    onClick={() => {
                      if (index > 1) {
                        return remove(index);
                      }
                    }}
                  >
                    <DeleteOutline className="btn-delete" />
                  </div>
                </div>
              ))}
              <div
                className={`mb-32 text-center cursor-pointer neutral300 more-option  radius-4 p-8 ${
                  options.length >= 12 ? "bg-neutral100" : ""
                }`}
                onClick={() => {
                  if (options.length < 12) {
                    return push({
                      text: "",
                      isAnswer: false,
                    });
                  }
                }}
              >
                <span className="cursor-pointer">
                  More option <PostAdd />
                </span>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </>
  );
};

export default connect()(MultipleChoiceAssessment);
