export default ([
  {
    name: "SUPERUSER",
    title: "Super User",
    level: 1,
  },
  {
    name: "ADMINISTRATOR",
    title: "Adminsitrator",
    level: 2,
  },
  {
    name: "HEADMASTER",
    title: "Headmaster",
    level: 3,
  },
  {
    name: "SCHOOLADMIN",
    title: "School Admin",
    level: 4,
  },
  {
    name: "TEACHER",
    title: "Teacher",
    level: 5,
  },
  {
    name: "STUDENT",
    title: "Student",
    level: 6,
  },
  {
    name: "PUBLIC",
    title: "Public",
    level: 7,
  },
])