import React from 'react'
import {
  Footer,
  InstitutionList,
  Navbar,
} from "../../../components/organisms";

function Institution() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <InstitutionList />
      <Footer />
    </>
  )
}

export default Institution