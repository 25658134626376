import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  Courses,
  Subjects,
  Lessons,
  Topics,
  Subtopics,
  CreateSubject,
  Login,
  Logout,
  MultipleChoice,
  FillInTheBlank,
  SingleChoice,
  ShortAnswer,
  Essay,
  EmbedHtml5,
  CKEditorContent,
  EditSubject,
  SubtopicEdit,
  CompleteParagraph,
  DialogAndNumbering,
  DetailPreview,
  Notfound,
  AssesmentShowPage,
  TextEditorAssessmentPage,
  SingleChoiceAssessmentPage,
  AssessmentPreviewPage,
  CodeEditor,
  GlobalStyle,
  Schools,
  CreateSchool,
  EditSchool,
  CreateMember,
  EditMember,
  UploadMember,
  Member,
  EditUserMember,
  Public,
  EditPublic,
  MatchPairs,
  MultipleChoiceAssessmentPage,
  MatchPairsAssessmentPage,
  ShortAnswerAssessmentPage,
  EssayAssessmentPage,
  TeacherResources,
  WorkSheet,
  TrainingVideo,
  CouponList,
  GenerateCoupons,
  Institution,
  EditInstitution,
  CompleteDialogs,
  Essays,
  CompleteParagraphs,
  ContentMatchPairs,
  ContentTextEditors,
  ContentCodeEditors,
  ContentMultipleChoices,
  ContentShortAnswers,
} from "./pages";
import { ForgotPass } from "./components/organisms";
import Authenticate from "./midlewares/Authenticate";
import ReAuthenticate from "./midlewares/ReAuthenticate";
import CheckRole from "./midlewares/CheckRole";
import Modal from "react-modal";
import { ScrollToTop } from "./components/organisms";
Modal.setAppElement("#root");

function App() {
  return (
    <div className="App tx-body bg-neutral50">
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<ReAuthenticate><Login /></ReAuthenticate>} />
        <Route path="/forgotpassword" element={<ReAuthenticate><ForgotPass /></ReAuthenticate>} />

        <Route
          path="/test/base64/shortanswers/:id"
          element={
            <Authenticate>
              <ContentShortAnswers />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/multiplechoices/:id"
          element={
            <Authenticate>
              <ContentMultipleChoices />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/codeeditors/:id"
          element={
            <Authenticate>
              <ContentCodeEditors />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/texteditors/:id"
          element={
            <Authenticate>
              <ContentTextEditors />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/matchpairs/:id"
          element={
            <Authenticate>
              <ContentMatchPairs />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/completeparagraphs/:id"
          element={
            <Authenticate>
              <CompleteParagraphs />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/essays/:id"
          element={
            <Authenticate>
              <Essays />
            </Authenticate>
          }
        />
        <Route
          path="/test/base64/completedialogs/:id"
          element={
            <Authenticate>
              <CompleteDialogs />
            </Authenticate>
          }
        />

        <Route
          path="/dashboard"
          element={
            <Authenticate>
              <Dashboard />
            </Authenticate>
          }
        />

        <Route
          path="/course"
          element={
            <Authenticate>
              <Courses />
            </Authenticate>
          }
        />

        <Route
          path="/subject"
          element={
            <Authenticate>
              <Subjects />
            </Authenticate>
          }
        />

        <Route
          path="/subject/createsubject"
          element={
            <Authenticate>
              <CreateSubject />
            </Authenticate>
          }
        />

        <Route
          path="/subject/editsubject/:id"
          element={
            <Authenticate>
              <EditSubject />
            </Authenticate>
          }
        />

        <Route
          path="/lesson"
          element={
            <Authenticate>
              <Lessons />
            </Authenticate>
          }
        />

        <Route
          path="/topic"
          element={
            <Authenticate>
              <Topics />
            </Authenticate>
          }
        />

        <Route
          path="/subtopic"
          element={
            <Authenticate>
              <Subtopics />
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/:id"
          element={
            <Authenticate>
              <SubtopicEdit />
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/text-editor"
          element={
            <Authenticate>
              <ScrollToTop>
                <CKEditorContent />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/text-editor/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <CKEditorContent />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/match-pairs"
          element={
            <Authenticate>
              <ScrollToTop>
                <MatchPairs />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/match-pairs/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <MatchPairs />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/multiple-choice"
          element={
            <Authenticate>
              <ScrollToTop>
                <MultipleChoice />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/multiple-choice/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <MultipleChoice />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/fill-in-the-blank"
          element={
            <Authenticate>
              <ScrollToTop>
                <FillInTheBlank />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/fill-in-the-blank/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <FillInTheBlank />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/single-choice"
          element={
            <Authenticate>
              <ScrollToTop>
                <SingleChoice />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/single-choice/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <SingleChoice />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/short-answer"
          element={
            <Authenticate>
              <ScrollToTop>
                <ShortAnswer />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/short-answer/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <ShortAnswer />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/essay"
          element={
            <Authenticate>
              <ScrollToTop>
                <Essay />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/essay/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <Essay />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/embed-html5"
          element={
            <Authenticate>
              <ScrollToTop>
                <EmbedHtml5 />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/embed-html5/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <EmbedHtml5 />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/code-editor"
          element={
            <Authenticate>
              <ScrollToTop>
                <CodeEditor />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/code-editor/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <CodeEditor />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/complete-paragraph"
          element={
            <Authenticate>
              <ScrollToTop>
                <CompleteParagraph />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/complete-paragraph/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <CompleteParagraph />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/dialog-and-numbering"
          element={
            <Authenticate>
              <ScrollToTop>
                <DialogAndNumbering />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/dialog-and-numbering/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <DialogAndNumbering />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/detail-preview"
          element={
            <Authenticate>
              <ScrollToTop>
                <DetailPreview />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/editsubtopic/detail-preview/:id"
          element={
            <Authenticate>
              <ScrollToTop>
                <DetailPreview />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment-show/:idsubtopic"
          element={
            <Authenticate>
              <ScrollToTop>
                <AssesmentShowPage />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment-preview/:idsubtopic"
          element={
            <Authenticate>
              <ScrollToTop>
                <AssessmentPreviewPage />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment/texteditor/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <ScrollToTop>
                <TextEditorAssessmentPage />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment/single-choice/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <ScrollToTop>
                <SingleChoiceAssessmentPage />
              </ScrollToTop>
            </Authenticate>
          }
        />

        <Route
          path="/global-style"
          element={
            <Authenticate>
              <GlobalStyle />
            </Authenticate>
          }
        />

        <Route
          path="/school"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <Schools />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/school/createschool"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <CreateSchool />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/school/editschool/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <EditSchool />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/school/editschool/:id/createmember"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <CreateMember />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/school/editschool/:id/editmember/:idmember"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <EditMember />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/school/editschool/:id/uploadmember"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <UploadMember />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/member"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <Member />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/member/editmember/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <EditUserMember />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/public"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <Public />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/public/editpublic/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <EditPublic />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment/multiple-choice/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <MultipleChoiceAssessmentPage />
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment/match-pairs/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <MatchPairsAssessmentPage />
            </Authenticate>
          }
        />

        <Route
          path="/subtopic/assessment/short-answer/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <ShortAnswerAssessmentPage />
            </Authenticate>
          }
        />
        <Route
          path="/subtopic/assessment/essay/:idsubtopic/:idassessmenttype"
          element={
            <Authenticate>
              <EssayAssessmentPage />
            </Authenticate>
          }
        />

        <Route
          path="/teacher-resources"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <TeacherResources />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/teacher-resources/worksheet/:idType"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <WorkSheet />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/teacher-resources/worksheet/:idType/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <WorkSheet />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/teacher-resources/teacher-training-video/:idType"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <TrainingVideo />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/teacher-resources/teacher-training-video/:idType/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <TrainingVideo />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/coupons"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <CouponList />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/generate-coupons"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <GenerateCoupons />
              </CheckRole>
            </Authenticate>
          }
        />

        <Route
          path="/fondations"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <Institution />
              </CheckRole>
            </Authenticate>
          }
        />
        <Route
          path="/fondations/editfondations/:id"
          element={
            <Authenticate>
              <CheckRole allowAccess="SUPERUSER, ADMINISTRATOR">
                <EditInstitution />
              </CheckRole>
            </Authenticate>
          }
        />



        <Route path="*" element={<Notfound />} />

      </Routes>
    </div>
  );
}

export default App;
