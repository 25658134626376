import ActionType from "./globalActionType";

const globalState = {
  loadingTable: false,
  inputContent: {
    status: false,
    goal: "",
    subtopicId: "",
    subtopicName: "",
    toast: false,
  },
  IsReload: false,
  toast: null,
  BackStatus: false,
  openModal: {
    status: false,
    target: "",
    crud: null,
    modal: "",
  },
  APICourse: [],
  APISubject: [],
  APIEditSubject: null,
  APILesson: [],
  APITopic: [],
  APISubTopic: [],
  APIEditSubTopic: null,
  APIQuickAddContent: [],
  APISchool: [],
  APIEditSchool: null,
  APIClass: [],
  APIClassMini: [],
  APIMember: [],
  APIEditMember: null,
  APIUser: [],
  APIPublic: [],
  APITeacherResources: [],
  APIGenerateCoupons: [],
  APIInstitution: [],
  APICoupon: [],
};
// Reducer
const rootReducer = (state = globalState, action) => {
  switch (action.type) {
    case ActionType.LOADING_TABLE:
      return {
        ...state,
        loadingTable: action.newValue,
      };
    case ActionType.INPUT_CONTENT:
      return {
        ...state,
        inputContent: { ...action.newValue },
      };
    case ActionType.IS_RELOAD:
      return {
        ...state,
        IsReload: action.newValue,
      };
    case ActionType.TOAST:
      return {
        ...state,
        toast: { ...action.newValue },
      };
    case ActionType.BACK_STATUS:
      return {
        ...state,
        BackStatus: action.newValue,
      };
    case ActionType.OPEN_MODAL:
      return {
        ...state,
        openModal: { ...action.newValue },
      };
    case ActionType.API_COURSE:
      return {
        ...state,
        APICourse: [...action.newValue],
      };
    case ActionType.API_SUBJECT:
      return {
        ...state,
        APISubject: [...action.newValue],
      };
    case ActionType.API_EDIT_SUBJECT:
      return {
        ...state,
        APIEditSubject: {...action.newValue},
      };
    case ActionType.API_LESSON:
      return {
        ...state,
        APILesson: [...action.newValue],
      };
    case ActionType.API_TOPIC:
      return {
        ...state,
        APITopic: [...action.newValue],
      };
    case ActionType.API_SUBTOPIC:
      return {
        ...state,
        APISubTopic: [...action.newValue],
      };
    case ActionType.API_EDIT_SUBTOPIC:
      return {
        ...state,
        APIEditSubTopic: {...action.newValue},
      };
    case ActionType.API_QUICK_ADD_CONTENT:
      return {
        ...state,
        APIQuickAddContent: [...action.newValue],
      };
    case ActionType.API_SCHOOL:
      return {
        ...state,
        APISchool: [...action.newValue],
      };
    case ActionType.API_EDIT_SCHOOL:
      return {
        ...state,
        APIEditSchool: {...action.newValue},
      };
    case ActionType.API_CLASS:
      return {
        ...state,
        APIClass: [...action.newValue],
      };
    case ActionType.API_CLASS_MINI:
      return {
        ...state,
        APIClassMini: [...action.newValue],
      };
    case ActionType.API_MEMBER:
      return {
        ...state,
        APIMember: [...action.newValue],
      };
    case ActionType.API_EDIT_MEMBER:
      return {
        ...state,
        APIEditMember: {...action.newValue},
      };
    case ActionType.API_USER:
      return {
        ...state,
        APIUser: [...action.newValue],
      };
    case ActionType.API_PUBLIC:
      return {
        ...state,
        APIPublic: [...action.newValue],
      };
    case ActionType.API_TEACHER_RESOURCES:
      return {
        ...state,
        APITeacherResources: [...action.newValue],
      };
    case ActionType.API_GENERATE_COUPONS:
      return {
        ...state,
        APIGenerateCoupons: [...action.newValue],
      };
    case ActionType.API_INSTITUTION:
      return {
        ...state,
        APIInstitution: [...action.newValue],
      };
    case ActionType.API_COUPON:
      return {
        ...state,
        APICoupon: [...action.newValue],
      };
    default:
      return state;
  }
};

export default rootReducer;
