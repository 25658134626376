import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch  } from 'react-redux'
import { useLesson } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';
import '../Modal.scss'

function ModalDeleteLesson() {
    const { config, initData } = useLesson()  
    const statusMODAL = 'MODAL_DELETE_LESSON'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
            _id: 0,
            name: ''
    })
    let saveValue = []

    useEffect(() => {
        if(statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if(isStatusMODAL.crud.length) {
                isStatusMODAL.crud.map(item => {
                    saveValue.push({
                        id: item.original._id,
                    })
                })
                setInitialValues([...saveValue])
            } else {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])
    const closeModal = () => {
        dispatch({ type: 'OPEN_MODAL', newValue: {
            status: false,
            target: '',
            crud: null,
            modal: '',
        } })
    }
    
    const onSubmit = values => {
        if(values.length) {    
            RestAPI.deleteMany(`lesson/delete/`, values, config).then(reloadTable)
            .catch((err) => failedProcess(err));
        } else {
            RestAPI.delete(`lesson/${values._id}`, config).then(reloadTable)
            .catch((err) => failedProcess(err));
        }
    }
    const reloadTable = () => {
        initData()
        toast.success(contentToast(
            "Lesson Deleted!", "Success to delete the lesson."), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = (err) => {
        toast.error(contentToast(
            "Failed to delete", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isNoForm">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                <div className="modal-header">
                                    <h5 className="tx-header5">Delete Lesson</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="message">
                                        <span className='tx-body-po'>
                                        {
                                            (initialValues.length) ? "All Selected Lesson" :
                                            `${initialValues.name}`
                                        }    
                                        </span> will be permanently lost, but the assigned content will remain and be unassigned.
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Buttons control="button" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name="Delete" addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default ModalDeleteLesson