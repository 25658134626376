import React, { useEffect, useState } from "react";
import AssessmentShowFooter from "./AssessmentShowFooter";
const AssessmentShortAnswerShow = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <div className="assessments-content-show">
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          <div className="mb-16 formFormik">
            <div className="">
              {data.questions.map((r, i) => {
                return (
                  <React.Fragment key={i}>
                    {r.image ? (
                      <div className="text-center mb-16">
                        <img src={r.image} alt="" width="100%" />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={`${i > 0 ? "mt-32" : ""} `}>
                      <span>
                        {props.nomor}. {r.question}
                      </span>
                      <div className="ml-16 mt-8">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Enter the answer here"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <AssessmentShowFooter data={data} submitAction={props.submitAction} />
      </div>
    </div>
  );
};

export default AssessmentShortAnswerShow;
