import React from 'react'
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import { useResourcesTrainingVideo } from "../../../services"

function ResourcesTrainingVideo() {
    const {
        initialValues,
        validationSchema,
        actionType,
        onSubmit,
        changeBackStatus,
    } = useResourcesTrainingVideo()

    return (
        <>
            <Toast />
            
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formSubjectInfo formFormik">
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Video Title</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. English Training Video'
                                    onInput={(e) => changeBackStatus(formik, 'name', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Embed Video</div>
                                <div className="desc tx-ex-small neutral400">
                                    Please make sure to input embed video from youtube.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='attachment' placeholder='e.g. https://youtube.com/atlaz'
                                    onInput={(e) => changeBackStatus(formik, 'attachment', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Author Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='author' placeholder='e.g. Atlaz'
                                    onInput={(e) => changeBackStatus(formik, 'author', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="height54"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name={actionType} addClass='btnMoBlock btnCreateForm text-capitalize' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 200, behavior: 'smooth' })} />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ResourcesTrainingVideo