const ActionType = {
  LOADING_TABLE: "LOADING_TABLE",
  INPUT_CONTENT: "INPUT_CONTENT",
  TOAST: "TOAST",
  IS_RELOAD: "IS_RELOAD",
  BACK_STATUS: "BACK_STATUS",
  OPEN_MODAL: "OPEN_MODAL",
  API_COURSE: "API_COURSE",
  API_SUBJECT: "API_SUBJECT",
  API_EDIT_SUBJECT: "API_EDIT_SUBJECT",
  API_LESSON: "API_LESSON",
  API_TOPIC: "API_TOPIC",
  API_SUBTOPIC: "API_SUBTOPIC",
  API_EDIT_SUBTOPIC: "API_EDIT_SUBTOPIC",
  API_QUICK_ADD_CONTENT: "API_QUICK_ADD_CONTENT",
  API_SCHOOL: "API_SCHOOL",
  API_EDIT_SCHOOL: "API_EDIT_SCHOOL",
  API_CLASS: "API_CLASS",
  API_CLASS_MINI: "API_CLASS_MINI",
  API_MEMBER: "API_MEMBER",
  API_EDIT_MEMBER: "API_EDIT_MEMBER",
  API_USER: "API_USER",
  API_PUBLIC: "API_PUBLIC",
  API_TEACHER_RESOURCES: "API_TEACHER_RESOURCES",
  API_GENERATE_COUPONS: "API_GENERATE_COUPONS",
  API_INSTITUTION: "API_INSTITUTION",  
  API_COUPON: "API_COUPON",  
  
};

export default ActionType;
