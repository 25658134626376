import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import '../Modal.scss'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import { useTableTeacherResources } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalActiveResources() {
    const { config, initData } = useTableTeacherResources()    
    const statusMODAL = 'MODAL_ACTIVE_RESOURCES'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState([{
        id: 0,
        status: true,
    }])
    let saveValue = []

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            isStatusMODAL.crud.map(item => {
                saveValue.push({
                    id: item.original._id,
                    status: (isStatusMODAL.modal === 'ACTIVE') ? true : false,
                })
            })
            setInitialValues([...saveValue])
            submitting(saveValue)
        } else {
            setModalOpen(false)
        }
    }, [isStatusMODAL])
    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const submitting = (values) => {
        RestAPI.updateMany(`resources/teacher_resources/update`, values, config).then(res => {
            initData()
            toast.success(contentToast(
                (isStatusMODAL.modal === 'ACTIVE') ? "Teacher Resources Activated!" : "Teacher Resources Inactivated!", 
                (isStatusMODAL.modal === 'ACTIVE') ? "Success change status to activated." : "Success change status to inactivated."), {
                icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })
            closeModal()
        })
        .catch((err) => {
            toast.error(contentToast(
                "Failed to change status", "Your connection not stable. Try again."), {
                icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
            closeModal()
        });
    }

    return null
}

export default ModalActiveResources