import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useParams } from 'react-router';
import { useTableClass } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';
import '../Modal.scss'

function ModalActiveClass() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const statusMODAL = 'MODAL_ACTIVE_CLASS'
    const { initData } = useTableClass()
    const isStatusMODAL = useSelector((state) => state.openModal)
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState([{
        _id: 0,
        status: true,
    }])
    let saveValue = []

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            isStatusMODAL.crud.map(item => {
                saveValue.push({
                    _id: item.original._id,
                    status: (isStatusMODAL.modal === 'ACTIVE') ? true : false,
                })
            })
            setInitialValues([...saveValue])
            submitting(saveValue)
        } else {
            setModalOpen(false)
        }
    }, [isStatusMODAL])
    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const submitting = (values) => {
        RestAPI.updateMany(`school/classlist/update`, values, config).then(res => {
            initData()
            toast.success(contentToast(
                (isStatusMODAL.modal === 'ACTIVE') ? "Class Activated!" : "Class Inactivated!", 
                (isStatusMODAL.modal === 'ACTIVE') ? "Success change status to activated." : "Success change status to inactivated."), {
                icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })
            closeModal()
        })
        .catch((err) => {
            dispatch({ type: 'LOADING_TABLE', newValue: true })
            toast.error(contentToast(
                "Failed to change status", "Your connection not stable. Try again."), {
                icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
            closeModal()
        });
    }

    return null
}

export default ModalActiveClass