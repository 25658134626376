import React from 'react';
import './Breadcrumbs.scss'
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function Breadcrumbs(props) {

  function isLast(index) {
    return index === props.data.length - 1;
  }

  return (
    <>
      <div className="breadcrumb-admin d-none d-lg-block">
        <div className="d-flex align-items-center">
          {
            props.data.map((crumb, ci) => {
              {
                if(isLast(ci)) {
                  return <div key={ci} className="bread-item active">{crumb.name}</div>
                } else {
                  return  <div key={ci} className="bread-item">
                    <Link to={crumb.path} className="bread-link" onClick={crumb.onClick} >{crumb.name}</Link>
                    <ArrowRightIcon fontSize='small' style={{color: 'var(--neutral200)'}} />
                    </div>
                }
              }
            })
          }
        </div>
      </div>
    </>
  )
}

export default Breadcrumbs;
