import React from 'react'
import { Footer, Navbar, SchoolDetails } from '../../../components/organisms';

function CreateSchool() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SchoolDetails />
      <Footer />
    </>
  )
}

export default CreateSchool