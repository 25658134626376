import React from 'react'
import { Footer, MemberUpload, Navbar } from '../../../components/organisms';

function UploadMember() {
  return (
    <>
        <Navbar/>
        <div className="height-82"></div>
        <MemberUpload />
        <Footer/>
      </>
  )
}

export default UploadMember