import React from 'react'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import Select, { Option, ReactSelectProps } from 'react-select'
import { Formik, Form } from 'formik'
import { useEditInstitutionList, useMemberEdit } from "../../../services";
import './InstitutionEdit.scss'

function InstitutionEdit() {
    const {
        classOptions,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
        optionSchools,
        optionSchoolsAll,
        exSchool,
        setOptionSchools,
    } = useEditInstitutionList()

    

    return (
        <>
            <Toast />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formInstitutionEdit formFormik">
                    <div className="tx-header5">Edit Fondations</div>
                    <div className="height48"></div>
                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">ID</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='_id' disabled />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Fondations Name</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' placeholder="Input fondations name" name='name' />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Schools</div>
                        </div>
                        <div className="col-md-7">
                            <Select
                                // defaultValue={exSchool}
                                value={optionSchools}
                                isMulti
                                name="schoolList"
                                options={optionSchoolsAll}
                                placeholder="Select school"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(value) => {
                                    setOptionSchools(value)
                                    formik.setFieldValue('schoolList', value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="text-end">ß
                        <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                    </div>
                </Form>
                )}
            </Formik>
        </>
    )
}

export default InstitutionEdit