import React from 'react';
import TitleSection from '../../../molecules/TitleSection';
import { connect } from 'react-redux'
import { 
  ModalActiveTopic, ModalDeleteTopic, ModalEditTopic, TableTopic 
} from '../../../molecules';
import './TopicList.scss'

function TopicList() {
  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Topic', path: '' }
  ]

  return (
      <>
        <section className="topic-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Topic List" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableTopic />
                <ModalEditTopic />
                <ModalDeleteTopic />
                <ModalActiveTopic />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(TopicList)
