import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import TitleSection from '../../../molecules/TitleSection';
import RestAPI from "../../../../config/RestAPI";
import '../Style.scss'

function CompleteDialogs() {
  const dataCrumbs = [
    { name: 'Test', path: '/test' },
    { name: 'Base64', path: '/base64' },
    { name: 'Fill In The Blank', path: '' },
  ]

  const { id } = useParams();
  const [data, setData] = useState();

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initData = () => {
    RestAPI.find(`base64/completedialogs/${id}`, config).then((res) => {
      setData(res)
    }).catch(err => {
      console.log(err)
    })
  };


  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <section className="base64-list">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Base64 Fill In The Blank" />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="text-center mb-2">
                <img src={data?.image} alt="" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect()(CompleteDialogs)
