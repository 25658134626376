import React from 'react';
import { Base64CompleteParagraphs, Footer, Navbar } from '../../components/organisms';

function CompleteParagraphs() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64CompleteParagraphs />
        <Footer/>
      </>
  )
}

export default CompleteParagraphs;
