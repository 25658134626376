import API from ".";

export default ({
    login: (elem, data) => API.post(`/${elem}`, data).then(res => res.data),

    post: (elem, data, config) => API.post(`/${elem}`, data, config).then(res => res.data),

    find: (elem, config) => API.get(`/${elem}`, config).then(res => res.data.data),

    findOrder: (elem, config) => API.get(`/${elem}`, config).then(res => res.data),

    detail: (elem, id) => API.get(`/${elem}/${id}`).then(res => res.data.data),

    put: (elem, data, config) => API.put(`/${elem}`, data, config).then(res => res.data),
    
    delete: (elem, config) => API.delete(`/${elem}`, config).then(res => res.data),

    updateMany: (elem, data, config) => API.post(`/${elem}`, data, config).then(res => res.data),
    
    deleteMany: (elem, data, config) => API.post(`/${elem}`, data, config).then(res => res.data),
})