import React, { useEffect } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'
import '../Formik.scss'

function Textarea (props) {
  const { label, name, placeholder, onClick,onChange, rows, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, onInput, ...rest } = props

  const className = ["formFormik"];
  const inputClassName = ["form-control"];
  className.push(addClass);
  inputClassName.push(inputClass);

  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field as='textarea' className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {
          ({
            field, meta: { touched, error }
          }) => <textarea onChange={onChange} className={ touched && error ? inputClassName.join(" error") : inputClassName.join(" ") } placeholder={placeholder}  onClick={onClick} rows={rows ? rows : "1"} onInput={onInput} { ...field }></textarea>
        }
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Textarea