import React from "react";
import FormikControl from "../../../atoms/Formik/FormikControl";
import TextError from "../../../atoms/Formik/TextError";
import Buttons from "../../../atoms/Buttons";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import { useEditSubject } from "../../../services";
import "./SubjectInfoEdit.scss";

function SubjectInfoEdit() {
  const {
    tokenAccess,
    inpFile,
    editorRef,
    iconDiscount,
    setIconDiscount,
    setMaxDiscount,
    finalPrice,
    setFinalPrice,
    originalPrice,
    setOriginalPrice,
    discount,
    setDiscount,
    statusOptions,
    discountOptions,
    assignCourseOptions,
    levelOptions,
    cefrOption,
    initialValues1,
    initialValues2,
    initialValues3,
    initialValues4,
    initialValues5,
    validationSchema1,
    validationSchema3,
    validationSchema4,
    setFormik1,
    setFormik2,
    setFormik3,
    setFormik4,
    setFormik5,
    sizeImage1,
    setSizeImage1,
    setSizeImage2,
    setSizeImage3,
    setSizeImage4,
    setSizePdf1,
    setSizePdf2,
    validateFileImage,
    validateFileImage2,
    validateFileImage3,
    validateFileImage4,
    validateFilePdf1,
    validateFilePdf2,
    changeBackStatus,
    updateFinalPrice,
    onSubmit1,
    onSubmit2,
    onSubmit3,
    onSubmit4,
    onSubmit5,
  } = useEditSubject();

  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={onSubmit1}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="formSubjectInfo formFormik">
            <div className="tx-header5">General Information</div>
            <div className="height48"></div>
            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">ID</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="_id"
                  disabled
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Subject Name</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="name"
                  placeholder="e.g. English Play 01"
                  onInput={(e) => {
                    setFormik1(formik.values);
                    changeBackStatus("name", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Subject Status</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="select"
                  name="status"
                  options={statusOptions}
                  onInput={(e) => {
                    setFormik1(formik.values);
                    changeBackStatus("status", e.target.value);
                    formik.setFieldValue("status", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">
                  Assign Course <span className="tx-small-qu">(optional)</span>
                </div>
                <div className="desc tx-ex-small neutral400">
                  If this section remains blank, then this subject{" "}
                  <span className="tx-ex-small-po">
                    will not assigned to any course
                  </span>
                </div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="select"
                  name="courseId"
                  options={assignCourseOptions}
                  onInput={(e) => {
                    setFormik1(formik.values);
                    changeBackStatus("courseId", e.target.value);
                    formik.setFieldValue("courseId", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Subject Author Name</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="input"
                  type="text"
                  name="author"
                  placeholder="e.g. Atlaz"
                  onInput={(e) => {
                    setFormik1(formik.values);
                    changeBackStatus("author", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Overview</div>
              </div>
              <div className="col-md-7">
                <input type="file" ref={inpFile} hidden />
                <Editor
                  name="overview"
                  initialValue={initialValues1 ? initialValues1.overview : ""}
                  apiKey="5bvlkdhnupz7sh6ea9qwoeyec1uh4uaa4yida68iaak00gfu"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(value) => {
                    formik.setFieldValue("overview", value);
                    if (value !== initialValues1.overview) {
                      setFormik1(formik.values);
                      changeBackStatus("overview", value);
                    }
                  }}
                  onBlur={formik.handleBlur("overview")}
                  init={{
                    height: 200,
                    menubar: false,
                    placeholder: "Book overview",
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "styleselect | bold italic link align | bullist numlist outdent indent | image media | insertfile undo redo",
                    image_advtab: true,

                    file_picker_callback: function (callback, value, meta) {
                      inpFile.current.click();
                      if (meta.filetype === "image") {
                        inpFile.current.addEventListener("change", (ev) => {
                          var file = ev.target.files[0];
                          var reader = new FileReader();
                          reader.onload = function (e) {
                            callback(reader.result, {
                              alt: "",
                            });
                          };
                          reader.readAsDataURL(file);
                          console.log(ev.target.files[0]);
                        });
                      }
                    },
                  }}
                />
                <ErrorMessage component={TextError} name="overview" />
              </div>
            </div>
            <div className="height54"></div>

            <div className="text-end">
              <Buttons
                control="button"
                type="submit"
                name="Update"
                addClass="btnMoBlock btnCreateForm"
                btnRegular
                btnMoExSmall
                btnPrimary
                btnDisable={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={initialValues2}
        onSubmit={onSubmit2}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="formSubjectInfo formFormik">
            <div className="divider"></div>

            <div className="tx-header5">Image Section</div>
            <div className="height48"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Book Cover Image</div>
                <ul className="desc tx-ex-small neutral400 ms-3">
                  <li>image ratio 1:1</li>
                  <li>{`file size < 1 mb`}</li>
                  <li>format file .jpg / .jpeg</li>
                </ul>
                <div className="height40"></div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-6 col-lg-6 col-xl-3 col-md-6 mb-4">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      width="204px"
                      height="204px"
                      control="inputImageBox"
                      type="file"
                      name="fakeImageCover"
                      base64Name="imageCover"
                      accept=".jpg, .jpeg"
                      onFileChange={(e) => {
                        setSizeImage1(e.size);
                        setFormik2(formik.values);
                        changeBackStatus(
                          "aufakeImageCoverthor",
                          e.name !== undefined ? e.name : ""
                        );
                      }}
                      validate={(e) =>
                        validateFileImage(e, formik.values.imageCover)
                      }
                      defImg={initialValues2}
                    />
                  </div>
                  <div className="col-6 col-lg-6 col-xl-3 col-md-6 mb-4">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      width="204px"
                      height="204px"
                      control="inputImageBox"
                      type="file"
                      name="fakeImage1"
                      base64Name="imageBook1"
                      accept=".jpg, .jpeg"
                      onFileChange={(e) => {
                        setSizeImage2(e.size);
                        setFormik2(formik.values);
                        changeBackStatus(
                          "fakeImage1",
                          e.name !== undefined ? e.name : ""
                        );
                      }}
                      validate={validateFileImage2}
                      defImg={initialValues2}
                    />
                  </div>
                  <div className="col-6 col-lg-6 col-xl-3 col-md-6">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      width="204px"
                      height="204px"
                      control="inputImageBox"
                      type="file"
                      name="fakeImage2"
                      base64Name="imageBook2"
                      accept=".jpg, .jpeg"
                      onFileChange={(e) => {
                        setSizeImage3(e.size);
                        setFormik2(formik.values);
                        changeBackStatus(
                          "fakeImage2",
                          e.name !== undefined ? e.name : ""
                        );
                      }}
                      validate={validateFileImage3}
                      defImg={initialValues2}
                    />
                  </div>
                  <div className="col-6 col-lg-6 col-xl-3 col-md-6">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      width="204px"
                      height="204px"
                      control="inputImageBox"
                      type="file"
                      name="fakeImage3"
                      base64Name="imageBook3"
                      accept=".jpg, .jpeg"
                      onFileChange={(e) => {
                        setSizeImage4(e.size);
                        setFormik2(formik.values);
                        changeBackStatus(
                          "fakeImage3",
                          e.name !== undefined ? e.name : ""
                        );
                      }}
                      validate={validateFileImage4}
                      defImg={initialValues2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="height54"></div>

            <div className="text-end">
              <Buttons
                control="button"
                type="submit"
                name="Update"
                addClass="btnMoBlock btnCreateForm"
                btnRegular
                btnMoExSmall
                btnPrimary
                btnDisable={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={initialValues3}
        validationSchema={validationSchema3}
        onSubmit={onSubmit3}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="formSubjectInfo formFormik">
            <div className="divider"></div>

            <div className="tx-header5">Detail Information</div>
            <div className="height48"></div>

            <div className="row visually-hidden">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">Level</div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="select"
                  name="level"
                  options={levelOptions}
                  onInput={(e) => {
                    setFormik3(formik.values);
                    changeBackStatus("level", e.target.value);
                    formik.setFieldValue("level", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">CEFR Level</div>
              </div>
              <div className="col-md-3 d-flex justify-content-between">
                <div className="d-flex flex-column gap-3">
                  {cefrOption.map((val, ind) => {
                    if (ind <= 3) {
                      return (
                        <label>
                          <Field
                            className="me-2"
                            type="radio"
                            name="cefrLevel"
                            checked={val === formik.values.cefrLevel}
                            value={val}
                            onInput={(e) => {
                              setFormik3(formik.values);
                              formik.setFieldValue("cefrLevel", e.target.value);
                              changeBackStatus(
                                formik,
                                "cefrLevel",
                                e.target.value
                              );
                            }}
                          />
                          {val}
                        </label>
                      );
                    }
                  })}
                  <ErrorMessage component={TextError} name="cefrLevel" />
                </div>
                <div className="d-flex flex-column gap-3">
                  {cefrOption.map((val, ind) => {
                    if (ind >= 4) {
                      return (
                        <label>
                          <Field
                            className="me-2"
                            type="radio"
                            name="cefrLevel"
                            value={val}
                            checked={val === formik.values.cefrLevel}
                            onInput={(e) => {
                              setFormik3(formik.values);
                              formik.setFieldValue("cefrLevel", e.target.value);
                              changeBackStatus(
                                formik,
                                "cefrLevel",
                                e.target.value
                              );
                            }}
                          />
                          {val}
                        </label>
                      );
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-5 label-left">
                <div className="tx-small-po">Subject Focus</div>
                <div className="desc tx-ex-small neutral400">
                  Press semicolon ( ; ) to separate tags!
                </div>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="textarea"
                  type="text"
                  name="subjectFocus"
                  rows="3"
                  placeholder="e.g.: Simple past tense;Simple present tense;Adverb"
                  onInput={(e) => {
                    setFormik3(formik.values);
                    changeBackStatus("subjectFocus", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="height54"></div>

            <div className="text-end">
              <Buttons
                control="button"
                type="submit"
                name="Update"
                addClass="btnMoBlock btnCreateForm"
                btnRegular
                btnMoExSmall
                btnPrimary
                btnDisable={formik.isSubmitting}
                disabled={formik.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>

      {tokenAccess.role !== 5 && (
        <Formik
          initialValues={initialValues4}
          validationSchema={validationSchema4}
          onSubmit={onSubmit4}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="formSubjectInfo formFormik">
              <div className="divider"></div>

              <div className="tx-header5">Pricing Plan</div>
              <div className="height48"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po mb-1">Original Price</div>
                  <div className="desc tx-ex-small neutral400">
                    Will be considered{" "}
                    <span className="tx-ex-small-po">Free</span> if filled with
                    0
                  </div>
                </div>
                <div className="col-md-7">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="inputgroup"
                    type="number"
                    name="originalPrice"
                    placeholder="e.g. 500000"
                    icongroup="Rp"
                    onInput={(e) => {
                      updateFinalPrice(e.target.value);
                      setFormik4(formik.values);
                      changeBackStatus("originalPrice", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="height40"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po mb-1">
                    Discount <span className="tx-small-qu">(optional)</span>
                  </div>
                  <div className="desc tx-ex-small neutral400">
                    You can input <span className="tx-ex-small-po">price</span>{" "}
                    or{" "}
                    <span className="tx-ex-small-po">discount percentage</span>.
                    If this section remains blank, it is not considered a
                    discount
                  </div>
                </div>
                <div className="col-md-3 col-lg-2 mb4">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="select"
                    name="discountType"
                    options={discountOptions}
                    onInput={(e) => {
                      formik.setFieldValue("discountType", e.target.value);
                      if (e.target.value === "percent") {
                        setIconDiscount("%");
                        setMaxDiscount(100);
                        const total =
                          originalPrice - (originalPrice * discount) / 100;
                        setFinalPrice(total);
                      } else if (e.target.value === "price") {
                        setIconDiscount("Rp");
                        setMaxDiscount(99999999999);
                        const total = originalPrice - discount;
                        setFinalPrice(total);
                      }
                    }}
                  />
                </div>
                <div className="col-md-4 col-lg-5">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="inputgroup"
                    type="number"
                    name="discountAmount"
                    placeholder={
                      iconDiscount === "Rp" ? "e.g. 500000" : "e.g. 50"
                    }
                    icongroup={iconDiscount}
                    onInput={(e) => {
                      if (iconDiscount === "Rp") {
                        const total = originalPrice - e.target.value;
                        setDiscount(e.target.value);
                        setFinalPrice(total);
                      } else if (iconDiscount === "%") {
                        const total =
                          originalPrice -
                          (originalPrice * e.target.value) / 100;
                        setDiscount(e.target.value);
                        setFinalPrice(total);
                      }
                      setFormik4(formik.values);
                      changeBackStatus("discountAmount", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="height40"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po mb-1">Final Price</div>
                  <div className="desc tx-ex-small neutral400">
                    Calculate price of original price and discount
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="input-group">
                    <span className="input-group-text">Rp</span>
                    <div className="form-control">{finalPrice}</div>
                  </div>
                </div>
              </div>
              <div className="height40"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po">
                    Tokopedia Link{" "}
                    <span className="tx-small-qu">(optional)</span>
                  </div>
                  <div className="desc tx-ex-small neutral400">
                    Your product link from Tokopedia.
                  </div>
                </div>
                <div className="col-md-7">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="input"
                    type="text"
                    name="tokopedia"
                    placeholder="e.g. https://tokopedia.com"
                    onInput={(e) => {
                      setFormik4(formik.values);
                      changeBackStatus("tokopedia", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="height40"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po">
                    Shopee Link <span className="tx-small-qu">(optional)</span>
                  </div>
                  <div className="desc tx-ex-small neutral400">
                    Your product link from Shopee.
                  </div>
                </div>
                <div className="col-md-7">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="input"
                    type="text"
                    name="shopee"
                    placeholder="e.g. https://shopee.co.id"
                    onInput={(e) => {
                      setFormik4(formik.values);
                      changeBackStatus("shopee", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="height40"></div>

              <div className="row">
                <div className="col-md-5 label-left">
                  <div className="tx-small-po">
                    Blibli Link <span className="tx-small-qu">(optional)</span>
                  </div>
                  <div className="desc tx-ex-small neutral400">
                    Your product link from Blibli.
                  </div>
                </div>
                <div className="col-md-7">
                  <FormikControl
                    addClass="isTaSmall isMoExSmall"
                    control="input"
                    type="text"
                    name="blibli"
                    placeholder="e.g. https://blibli.com"
                    onInput={(e) => {
                      setFormik4(formik.values);
                      changeBackStatus("blibli", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="height54"></div>

              <div className="text-end">
                <Buttons
                  control="button"
                  type="submit"
                  name="Update"
                  addClass="btnMoBlock btnCreateForm"
                  btnRegular
                  btnMoExSmall
                  btnPrimary
                  btnDisable={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}

      <Formik
        initialValues={initialValues5}
        onSubmit={onSubmit5}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form className="formSubjectInfo formFormik">
            <div className="divider"></div>

            <div className="tx-header5">Teacher Resources</div>
            <div className="height48"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">
                  Lesson Plan for School member{" "}
                  <span className="tx-small-qu">(optional)</span>
                </div>
                <ul className="desc tx-ex-small neutral400 ms-3">
                  <li>
                    Uploaded files can only be accessed by the School Admin and
                    Teacher roles.
                  </li>
                  <li>Format file .pdf</li>
                </ul>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="inputfile"
                  name="fakePdf1"
                  base64Name="lessonPlanSchool"
                  accept=".pdf"
                  validate={validateFilePdf1}
                  defFile={initialValues5}
                  onFileChange={(e) => {
                    setSizePdf1(e.size);
                    setFormik5(formik.values);
                    changeBackStatus(
                      "lessonPlanSchool",
                      e.name !== undefined ? e.name : ""
                    );
                  }}
                />
              </div>
            </div>
            <div className="height40"></div>

            <div className="row">
              <div className="col-md-5 label-left">
                <div className="tx-small-po mb-1">
                  Lesson Plan for Public member{" "}
                  <span className="tx-small-qu">(optional)</span>
                </div>
                <ul className="desc tx-ex-small neutral400 ms-3">
                  <li>
                    Uploaded files can only be accessed by the Public roles.
                  </li>
                  <li>Format file .pdf</li>
                </ul>
              </div>
              <div className="col-md-7">
                <FormikControl
                  addClass="isTaSmall isMoExSmall"
                  control="inputfile"
                  name="fakePdf2"
                  base64Name="lessonPlanPublic"
                  accept=".pdf"
                  validate={validateFilePdf2}
                  defFile={initialValues5}
                  onFileChange={(e) => {
                    setSizePdf2(e.size);
                    setFormik5(formik.values);
                    changeBackStatus(
                      "lessonPlanPublic",
                      e.name !== undefined ? e.name : ""
                    );
                  }}
                />
              </div>
            </div>

            <div className="height54"></div>

            <div className="text-end">
              <Buttons
                control="button"
                type="submit"
                name="Update"
                addClass="btnMoBlock btnCreateForm"
                btnRegular
                btnMoExSmall
                btnPrimary
                btnDisable={formik.isSubmitting}
                disabled={formik.isSubmitting}
                onClick={() =>
                  window.scrollTo({ top: 200, behavior: "smooth" })
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SubjectInfoEdit;
