import React from 'react'
import { Footer, Navbar, SchoolList } from '../../../components/organisms';

function Schools() {
  return (
    <>
        <Navbar/>
        <div className="height-82"></div>
        <SchoolList />
        <Footer/>
      </>
  )
}

export default Schools