import React from 'react'
import './Toast.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function Toast() {
    // const notifySuccess = () => toast.success(contentToast(props), {
    //     icon: <CheckCircleOutline style={{color: "var(--info500)", fontSize: '30px'}} />
    // });
    // const notifyError = () => toast.error(contentToast(props), {
    //     icon: <NotInterested style={{color: "var(--danger500)", fontSize: '30px'}}  />
    // });
    // const notifyInfo = () => toast.warn(contentToast(props), {
    //     icon: <ErrorOutline style={{color: "var(--success500)", fontSize: '30px'}} />
    // });

    return (
        <>
            <ToastContainer className="toast-admin"
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover />
        </>
    )
}

export default Toast