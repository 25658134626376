import React from 'react'

function BtnExLink(props) {
  const {
    children, name, path, isBlank, addClass, btnPrimary, btnSuccess, btnDanger, btnSecondary, btnWarning, btnInfo, btnGhost, btnOutline, btnWrapper, btnLink, btnRegular, btnSmall,  btnExSmall,btnTaSmall, btnMoSmall, btnTaExSmall, btnMoExSmall, btnIcon, isShadow, ...rest
  } = props

  const className = [""];
  className.push(addClass);
  if (btnPrimary) className.push("btnPrimary")
  if (btnSuccess) className.push("btnSuccess")
  if (btnDanger) className.push("btnDanger")
  if (btnSecondary) className.push("btnSecondary")
  if (btnWarning) className.push("btnWarning")
  if (btnInfo) className.push("btnInfo")
  if (btnGhost) className.push("btnGhost")
  if (btnOutline) className.push("btnOutline")
  if (btnWrapper) className.push("btnWrapper")
  if (btnLink) className.push("btnLink")
  if (btnRegular) className.push("btnRegular")
  if (btnSmall) className.push("btnSmall")
  if (btnExSmall) className.push("btnExSmall")
  if (btnTaSmall) className.push("btnTaSmall")
  if (btnMoSmall) className.push("btnMoSmall")
  if (btnTaExSmall) className.push("btnTaExSmall")
  if (btnMoExSmall) className.push("btnMoExSmall")
  if (btnIcon) className.push("btnIcon")
  if (isShadow) className.push("isShadow")

  return (
    <>
      <a href={path} className={className.join(" ")} target={isBlank ? "_blank" : undefined} rel={isBlank ? "noopener noreferrer" : undefined} {...rest}>{name}{children}</a>
    </>
  )
}

export default BtnExLink;