import './Modal.scss'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function ModalRedirect() {
    const statusMODAL = 'MODAL_REDIRECT'
    const navigate = useNavigate();
    const isStatusMODAL = useSelector((state) => state.openModal)

    if(statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
        navigate(isStatusMODAL.crud.path)
    }
    return null
}

export default connect()(ModalRedirect)