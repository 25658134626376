import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import RestAPI from "../../../../config/RestAPI";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { CheckCircleOutline, NotInterested } from "@mui/icons-material";

function useEditSubject() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const inpFile = useRef();
  const editorRef = useRef(null);
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = { headers: { token: "Bearer " + tokenAccess.accessToken } };

  const dataSubject = useSelector((state) => state.APIEditSubject);
  const [iconDiscount, setIconDiscount] = useState("Rp");
  const [maxDiscount, setMaxDiscount] = useState(99999999999);
  const [finalPrice, setFinalPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);

  const cefrOption = ["Pre A1", "A1", "A2", "B1", "B2", "C1", "C2"];

  const statusOptions = [
    { key: "Active", value: true },
    { key: "Inactive", value: false },
  ];
  const discountOptions = [
    { key: "Price", value: "price" },
    { key: "Percentage", value: "percent" },
  ];
  const [assignCourseOptions, setAssignCourseOptions] = useState([
    { key: "None", value: "" },
  ]);
  const [levelOptions, setLevelOptions] = useState([
    { key: "None", value: "" },
  ]);

  const [initialValues1, setInitialValues1] = useState({
    _id: "",
    courseId: "",
    name: "",
    overview: "",
    author: "",
    status: true,
  });
  const [initialValues2, setInitialValues2] = useState({
    imageCover: "",
    imageBook1: "",
    imageBook2: "",
    imageBook3: "",
    fakeImageCover: "",
    fakeImage1: "",
    fakeImage2: "",
    fakeImage3: "",
  });
  const [initialValues3, setInitialValues3] = useState({
    level: "",
    cefrLevel: "",
    subjectFocus: "",
  });
  const [initialValues4, setInitialValues4] = useState({
    originalPrice: 0,
    discountAmount: 0,
    discountType: "Price",
    tokopedia: "",
    shopee: "",
    blibli: "",
  });
  const [initialValues5, setInitialValues5] = useState({
    fakePdf1: "",
    fakePdf2: "",
    lessonPlanSchool: "",
    lessonPlanPublic: "",
  });

  const [formik1, setFormik1] = useState(initialValues1);
  const [formik2, setFormik2] = useState(initialValues2);
  const [formik3, setFormik3] = useState(initialValues3);
  const [formik4, setFormik4] = useState(initialValues4);
  const [formik5, setFormik5] = useState(initialValues5);

  const objValidationSchema1 = {
    name: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    author: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    overview: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  };
  let validationSchema1 = Yup.object(objValidationSchema1);
  if (tokenAccess.role === 5) {
    validationSchema1 = Yup.object({
      ...objValidationSchema1,
      originalPrice: Yup.number()
        .typeError("This field must specify a number")
        .min(0, "Min value 0."),
    });
  }

  const validationSchema3 = Yup.object({
    subjectFocus: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    cefrLevel: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  const validationSchema4 = Yup.object({
    originalPrice: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .required("This field is required. Don't leave it empty!"),
    discountAmount: Yup.number()
      .typeError("This field must specify a number")
      .min(0, "Min value 0.")
      .max(maxDiscount, `Max value ${maxDiscount}`),
  });

  const _validFileImage = [".jpg", ".jpeg"];
  const checkFormatImage = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFileImage.length; j++) {
        let sCurExtension = _validFileImage[j];
        if (
          value
            .substr(value.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true;
      }
    }
  };

  const [sizeImage1, setSizeImage1] = useState(0);
  const validateFileImage = (value, init) => {
    let error;
    if (init === "") {
      error = "This field is required. Don't leave it empty!";
    }
    if (sizeImage1 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };

  const [sizeImage2, setSizeImage2] = useState(0);
  const validateFileImage2 = (value) => {
    let error;
    if (sizeImage2 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };
  const [sizeImage3, setSizeImage3] = useState(0);
  const validateFileImage3 = (value) => {
    let error;
    if (sizeImage3 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };
  const [sizeImage4, setSizeImage4] = useState(0);
  const validateFileImage4 = (value) => {
    let error;
    if (sizeImage4 > 1000000) {
      error = "File size is too large";
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!";
    }
    return error;
  };

  const _validFilePdf = [".pdf"];
  const checkFormatPdf = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFilePdf.length; j++) {
        let sCurExtension = _validFilePdf[j];
        if (
          value
            .substr(value.length - sCurExtension.length, sCurExtension.length)
            .toLowerCase() === sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true;
      }
    }
  };

  const [sizePdf1, setSizePdf1] = useState(0);
  const validateFilePdf1 = (value) => {
    let error;
    if (sizePdf1 > 70000000) {
      error = "File size is too large";
    }
    if (value && checkFormatPdf(value)) {
      error = "The file format is not suitable!";
    }
    return error;
  };

  const [sizePdf2, setSizePdf2] = useState(0);
  const validateFilePdf2 = (value) => {
    let error;
    if (sizePdf2 > 70000000) {
      error = "File size is too large";
    }
    if (value && checkFormatPdf(value)) {
      error = "The file format is not suitable!";
    }
    return error;
  };

  const changeBackStatus = (initial, value) => {
    const allInitial = {
      ...initialValues1,
      ...initialValues2,
      ...initialValues3,
      ...initialValues4,
      ...initialValues5,
    };
    const fakeInitial = {
      ...formik1,
      ...formik2,
      ...formik3,
      ...formik4,
      ...formik5,
    };
    const newInitial = {
      ...fakeInitial,
      [initial]: value,
    };

    if (allInitial !== newInitial) {
      dispatch({ type: "BACK_STATUS", newValue: true });
    } else {
      dispatch({ type: "BACK_STATUS", newValue: false });
    }
  };

  const updateFinalPrice = (value) => {
    let total = 0;
    if (iconDiscount === "Rp") {
      total = value - discount;
    } else if (iconDiscount === "%") {
      total = value - (value * discount) / 100;
    }
    setOriginalPrice(value);
    setFinalPrice(total);
  };

  useEffect(() => {
    RestAPI.find("course", config).then((res) => {
      let data = [{ key: "None", value: "" }];
      res.map((crs) => {
        data.push({
          key: crs.name,
          value: crs._id,
        });
      });
      setAssignCourseOptions(data);
    });
    RestAPI.find("level", config).then((res2) => {
      let data = [{ key: "Select level", value: "" }];
      console.log(data);
      res2.map((lvl) => {
        data.push({
          key: lvl.level,
          value: lvl.level,
        });
      });
      setLevelOptions(data);
    });
    if (dataSubject) {
      const discountPrice = dataSubject.discountPrice
        ? dataSubject.discountPrice
        : null;
      setInitialValues1({
        _id: dataSubject._id,
        courseId: dataSubject.courseId,
        name: dataSubject.name,
        overview: dataSubject.overview,
        author: dataSubject.author,
        status: dataSubject.status,
      });

      if (discountPrice.discountType === "percent") {
        setIconDiscount("%");
      } else {
        setIconDiscount("Rp");
      }
      setOriginalPrice(dataSubject.originalPrice);
      setDiscount(
        discountPrice.discountAmount ? discountPrice.discountAmount : 0
      );
      setFinalPrice(discountPrice.total);
      setInitialValues2({
        fakeImageCover: "",
        fakeImage1: "",
        fakeImage2: "",
        fakeImage3: "",
        imageCover: dataSubject.imageCover ? dataSubject.imageCover : "",
        imageBook1: dataSubject.imageBook1 ? dataSubject.imageBook1 : "",
        imageBook2: dataSubject.imageBook2 ? dataSubject.imageBook2 : "",
        imageBook3: dataSubject.imageBook3 ? dataSubject.imageBook3 : "",
      });

      setInitialValues3({
        level: dataSubject.level,
        cefrLevel: dataSubject.cefrLevel,
        subjectFocus: dataSubject.subjectFocus,
      });

      setInitialValues4({
        _id: dataSubject._id,
        courseId: dataSubject.courseId,
        name: dataSubject.name,
        overview: dataSubject.overview,
        author: dataSubject.author,
        status: dataSubject.status,
        originalPrice: dataSubject.originalPrice
          ? dataSubject.originalPrice
          : 0,
        discountAmount: discountPrice.discountAmount
          ? discountPrice.discountAmount
          : 0,
        discountType: discountPrice.discountType
          ? discountPrice.discountType
          : "price",
        tokopedia: dataSubject.tokopedia ? dataSubject.tokopedia : "",
        shopee: dataSubject.shopee ? dataSubject.shopee : "",
        blibli: dataSubject.blibli ? dataSubject.blibli : "",
      });

      setInitialValues5({
        fakePdf1: "",
        fakePdf2: "",
        lessonPlanSchool: dataSubject.lessonPlanSchool
          ? dataSubject.lessonPlanSchool.split("/").pop()
          : "",
        lessonPlanPublic: dataSubject.lessonPlanPublic
          ? dataSubject.lessonPlanPublic.split("/").pop()
          : "",
      });
    }
  }, [dataSubject]);

  const onSubmit1 = (values, { setSubmitting }) => {
    onSubmitSubject("general_information", values, { setSubmitting });
  };
  const onSubmit2 = (values, { setSubmitting }) => {
    onSubmitSubject("image", values, { setSubmitting });
  };
  const onSubmit3 = (values, { setSubmitting }) => {
    onSubmitSubject("detail_information", values, { setSubmitting });
  };
  const onSubmit4 = (values, { setSubmitting }) => {
    onSubmitSubject("pricing_plan", values, { setSubmitting });
  };
  const onSubmit5 = (values, { setSubmitting }) => {
    onSubmitSubject("lesson_plan", values, { setSubmitting });
  };

  const onSubmitSubject = (target, values, { setSubmitting }) => {
    RestAPI.put(`subject/update/${target}/${id}`, values, config)
      .then((res) => {
        RestAPI.find(`subject/get/single/${id}`, config).then((res2) => {
          dispatch({ type: "API_EDIT_SUBJECT", newValue: res2 });
        });
        dispatch({ type: "BACK_STATUS", newValue: false });
        toast.success(
          contentToast("Subject Updated!", "Success to update the subject."),
          {
            icon: (
              <CheckCircleOutline
                style={{ color: "var(--info500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          contentToast(
            "Failed to update",
            "Your connection not stable. Try again."
          ),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };

  return {
    id,
    tokenAccess,
    inpFile,
    editorRef,
    dataSubject,
    iconDiscount,
    setIconDiscount,
    maxDiscount,
    setMaxDiscount,
    finalPrice,
    setFinalPrice,
    originalPrice,
    setOriginalPrice,
    discount,
    setDiscount,
    statusOptions,
    discountOptions,
    assignCourseOptions,
    levelOptions,
    cefrOption,
    initialValues1,
    initialValues2,
    initialValues3,
    initialValues4,
    initialValues5,
    validationSchema1,
    validationSchema3,
    validationSchema4,
    setFormik1,
    setFormik2,
    setFormik3,
    setFormik4,
    setFormik5,
    sizeImage1,
    setSizeImage1,
    sizeImage2,
    setSizeImage2,
    sizeImage3,
    setSizeImage3,
    sizeImage4,
    setSizeImage4,
    sizePdf1,
    setSizePdf1,
    sizePdf2,
    setSizePdf2,
    validateFileImage,
    validateFileImage2,
    validateFileImage3,
    validateFileImage4,
    validateFilePdf1,
    validateFilePdf2,
    changeBackStatus,
    updateFinalPrice,
    onSubmit1,
    onSubmit2,
    onSubmit3,
    onSubmit4,
    onSubmit5,
  };
}

export default useEditSubject;
