import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function useTrainingVideo() {
    const dispatch = useDispatch()
    const backStatus = useSelector((state) => state.BackStatus)

    useEffect(() => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
    }, [])

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "Teacher Resources", path: "/teacher-resources" },
        { name: "Teacher Training Video", path: "" },
    ]

    const styleTitle = {
        width: "88px",
        height: "2px",
        backgroundColor: "var(--primary500)",
    }

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel creating training video',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: '/teacher-resources',
        footerYes: 'Yes',
        footerNo: 'No',
    }

    return {
        backStatus,
        dataCrumbs,
        modalBack,
        styleTitle,
    }
}

export default useTrainingVideo