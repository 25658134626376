import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { format } from 'date-fns'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import {
    CheckCircleOutline,
    NotInterested,
    Cancel
} from '@mui/icons-material';
import * as Yup from 'yup'

function usePublicEdit() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [namePage, setNamePage] = useState('')
    const backStatus = useSelector((state) => state.BackStatus)

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "Member Information", path: "/public" },
        { name: namePage, path: "" }
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel updating member',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: '/public',
        footerYes: 'Yes',
        footerNo: 'No',
    }

    const [subjectOptions, setSubjectOptions] = useState([
        { key: 'Select subject', value: '' },
    ])

    const [initialValues, setInitialValues] = useState({
        _id: '',
        role: 0,
        subject: '',
        username: '',
        email: '',
        name: '',
        expiredSubject: '',
    })

    const validationSchema = Yup.object({
        subject: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeBackStatus = (initial, value) => {
        const allInitial = {
          ...initialValues,
        }
        const newInitial = {
          ...initialValues,
          [initial]: value
        }
    
        if (allInitial.subject !== newInitial.subject || allInitial.expiredSubject !== newInitial.expiredSubject) {
          dispatch({ type: 'BACK_STATUS', newValue: true })
        } else {
          dispatch({ type: 'BACK_STATUS', newValue: false })
        }
      }

    const onSubmitSubject = (values, { setSubmitting }) => {
        RestAPI.put(`school/member/${id}`, values, config).
            then(res => {
                RestAPI.find(`school/member/${id}`, config).then(res2 => {
                    setNamePage(res2.user.name)            
                    setInitialValues({
                        _id: res2._id,
                        role: res2.role.title,
                        subject: res2.subject._id,
                        username: res2.user.username,
                        email: res2.user.email,
                        name: res2.user.name,
                        expiredSubject: format(new Date(res2.expiredSubject), 'yyyy-MM-dd'),
                    })
                })
                dispatch({ type: 'BACK_STATUS', newValue: false })
                toast.success(contentToast(
                    "Member Updated!", "Success to update the member."), {
                    icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                })
                setSubmitting(false);
            })
            .catch((err) => {
                console.log(err.response)
                toast.error(contentToast(
                    "Failed to update", "Your connection not stable. Try again."), {
                    icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                })
                setSubmitting(false);
            });
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        RestAPI.find(`school/member/${id}`, config).then(res => {
            setNamePage(res.user.name)            
            setInitialValues({
                _id: res._id,
                role: res.role.title,
                subject: res.subject._id,
                username: res.user.username,
                email: res.user.email,
                name: res.user.name,
                expiredSubject: format(new Date(res.expiredSubject), 'yyyy-MM-dd'),
            })
        }).catch((err) => {
            navigate("/notfound")
        });
        dispatch({ type: 'BACK_STATUS', newValue: false })
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
        
        RestAPI.find('subject', config).then(res => {
            let data = [{ key: 'Select subject', value: '' }]
            res.map(sbj => {
                data.push({
                    key: sbj.name,
                    value: sbj._id
                })
            })
            setSubjectOptions(data)
        })
    }, [])

    return {
        id,
        backStatus,
        dataCrumbs,
        modalBack,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmitSubject,
    }
}

export default usePublicEdit