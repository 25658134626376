import Role from "../../../config/Role"
import { useEffect, useState } from 'react'

function useDashboard() {
    const Authentication = JSON.parse(localStorage.getItem("ATLAZ_Authentication"))
    const [name, setName] = useState('')

    useEffect(() => {
        setName(Authentication.userName)
    }, [])

    return {
        name,
    }
}

export default useDashboard