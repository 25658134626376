import React from "react";
import Skeleton from 'react-loading-skeleton'
import Buttons from "../../../atoms/Buttons";
import FormikControl from "../../../atoms/Formik/FormikControl";
import { Formik, Form } from "formik";
import { useQuickContent } from "../../../services";
import {
  LocalLibrary,
  LabelImportant,
  Wysiwyg,
  FiberManualRecord,
  Add,
  Delete,
  Edit,
  Cached,
  Rule
} from '@mui/icons-material';
import "./QuickContent.scss";
import 'react-loading-skeleton/dist/skeleton.css'

function QuickContent() {
  const {
    id,
    isLoading,
    dataSubject,
    dataLesson,
    changeOrder,
  } = useQuickContent()

  return (
    <>
      <div className="quick-content">
        <div className="top d-flex align-items-center justify-content-between">
          <div className="header-subject">
            <div className="tx-header5 mb-1">{dataSubject?.name}</div>
            <div className="status">
              {dataSubject?.status ? (
                <div className="status-active">
                  <FiberManualRecord style={{ fontSize: "6px" }} /> Active
                </div>
              ) : (
                <div className="status-inactive">
                  <FiberManualRecord style={{ fontSize: "6px" }} /> Inactive
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="height36"></div>
        {
          !isLoading ? 
          <>
          <div className="accordion" id="accordionLesson">
            {dataLesson.length > 0 &&
              dataLesson?.map((lesson, keyLesson) => {
                const initialValues = { orderLesson: lesson.order };
                const onSubmit = (values) => {
                  changeOrder(
                    lesson._id,
                    values.orderLesson,
                    "lesson",
                    "Lesson"
                  );
                };
                return (
                  <div key={keyLesson} className="accordion-item">
                    <h2
                      className="accordion-header d-flex"
                      id={`panelsStayOpen-lesson${lesson._id}`}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-CollapseLesson${lesson._id}`}
                      >
                        <div className="icon-header">
                          <LocalLibrary />
                        </div>
                      </button>
                      <div className="title-accordion d-flex flex-column align-items-start">
                        <div className="tx-body-po neutral500">
                          {lesson.name}
                        </div>
                        {lesson.status ? (
                          <Buttons
                            control="modal"
                            modal="INACTIVE"
                            target="MODAL_PUT_LESSON"
                            addClass="btnLink status-active ishover"
                            crud={lesson}
                          >
                            <span className="me-1">Active</span>
                            <Cached style={{ fontSize: "15px" }} />
                          </Buttons>
                        ) : (
                          <Buttons
                            control="modal"
                            modal="ACTIVE"
                            target="MODAL_PUT_LESSON"
                            addClass="btnLink status-inactive ishover"
                            crud={lesson}
                          >
                            <span className="me-1">Inactive</span>
                            <Cached style={{ fontSize: "15px" }} />
                          </Buttons>
                        )}
                      </div>
                      <div className="btn-more mt-0 mt-md-1">
                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          enableReinitialize={true}
                        >
                          {(formik) => (
                            <Form className="d-flex align-items-center">
                              <FormikControl
                                control="input"
                                type="number"
                                name="orderLesson"
                                addClass="isExSmall"
                                width="40px"
                                inputClass="hideArrow p-8 text-center isExSmall"
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "orderLesson",
                                    e.target.value
                                  );
                                }}
                              />
                            </Form>
                          )}
                        </Formik>
                        <Buttons control="modal" modal="REMOVE" target="MODAL_PUT_LESSON" addClass="neutral200 ms-1 ms-md-3" crud={lesson} btnExSmall btnGhost btnIcon><Delete className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                        <Buttons control="modal" modal="EDIT" target="MODAL_UPDATE_LESSON" addClass="neutral200 ms-1 ms-md-3" crud={lesson} btnExSmall btnGhost btnIcon><Edit className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                      </div>
                    </h2>
                    <div
                      id={`panelsStayOpen-CollapseLesson${lesson._id}`}
                      className="accordion-collapse collapse"
                    >
                      <div className="accordion-body">
                        <div className="accordion" id="accordionTopic">
                          {lesson.topics.map((topic, keyTopic) => {
                            const initialValues = { orderTopic: topic.order };
                            const onSubmit = (values) => {
                              changeOrder(
                                topic._id,
                                values.orderTopic,
                                "topic",
                                "Topic"
                              );
                            };
                            return (
                              <div key={keyTopic} className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`topic${topic._id}`}
                                >
                                  <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#panelsStayOpen-CollapseTopic${topic._id}`}
                                  >
                                    <div className="icon-header">
                                      {
                                        topic.isAssessment ? <Rule />
                                          : <LabelImportant />
                                      }
                                    </div>
                                  </button>
                                  <div className="title-accordion d-flex flex-column align-items-start">
                                    <div className="tx-body-po neutral500">
                                      {topic.name}
                                    </div>
                                    {topic.status ? (
                                      <Buttons
                                        control="modal"
                                        modal="INACTIVE"
                                        target="MODAL_PUT_TOPIC"
                                        addClass="btnLink status-active ishover"
                                        crud={topic}
                                      >
                                        <span className="me-1">Active</span>
                                        <Cached style={{ fontSize: "15px" }} />
                                      </Buttons>
                                    ) : (
                                      <Buttons
                                        control="modal"
                                        modal="ACTIVE"
                                        target="MODAL_PUT_TOPIC"
                                        addClass="btnLink status-inactive ishover"
                                        crud={topic}
                                      >
                                        <span className="me-1">Inactive</span>
                                        <Cached style={{ fontSize: "15px" }} />
                                      </Buttons>
                                    )}
                                  </div>
                                  <div className="btn-more">
                                    <Formik
                                      initialValues={initialValues}
                                      onSubmit={onSubmit}
                                      enableReinitialize={true}
                                    >
                                      {(formik) => (
                                        <Form className="d-flex align-items-center">
                                          <FormikControl
                                            control="input"
                                            type="number"
                                            name="orderTopic"
                                            addClass="isExSmall"
                                            width="40px"
                                            inputClass="hideArrow p-8 text-center"
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                "orderTopic",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form>
                                      )}
                                    </Formik>
                                    <Buttons control="modal" modal="REMOVE" target="MODAL_PUT_TOPIC" addClass="neutral200 ms-1 ms-md-3" crud={topic} btnExSmall btnGhost btnIcon><Delete className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                                    <Buttons control="modal" modal="EDIT" target="MODAL_UPDATE_TOPIC" addClass="neutral200 ms-1 ms-md-3" crud={topic} btnExSmall btnGhost btnIcon><Edit className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                                  </div>
                                </h2>
                                <div
                                  id={`panelsStayOpen-CollapseTopic${topic._id}`}
                                  className="accordion-collapse collapse"
                                  aria-labelledby={`topic${topic._id}`}
                                >
                                  <div className="accordion-body">
                                    <div
                                      className="accordion"
                                      id="accordionSubTopic"
                                    >
                                      {topic.subtopics.map(
                                        (subtopic, keySubTopic) => {
                                          const initialValues = { orderSubtopic: subtopic.order };
                                          const onSubmit = (values) => {
                                            changeOrder(
                                              subtopic._id,
                                              values.orderSubtopic,
                                              "subtopic",
                                              "Sub Topic"
                                            );
                                          };
                                          return (
                                            <div
                                              key={keySubTopic}
                                              className="accordion-item"
                                            >
                                              <h2
                                                className="accordion-header hide-arrow"
                                                id={`Subtopic${subtopic._id}`}
                                              >
                                                <button
                                                  className="accordion-button collapsed"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#CollapseSubTopic${subtopic._id}`}
                                                  onClick={() => {
                                                    localStorage.setItem(
                                                      "INPUT_CONTENT",
                                                      JSON.stringify({
                                                        goal: "EDIT",
                                                        subtopicId:
                                                          subtopic._id,
                                                        subtopicName:
                                                          subtopic.name,
                                                        toast: false,
                                                        message: {
                                                          title: "",
                                                          desc: "",
                                                        },
                                                      })
                                                    );
                                                    localStorage.setItem('BackLink', `/subject/editsubject/${id}`)
                                                    // navigate(`/subtopic/editsubtopic/${subtopic._id}`);
                                                    window.open(`/subtopic/editsubtopic/${subtopic._id}`, '_blank', 'noopener,noreferrer')
                                                  }}
                                                >
                                                  <div className="icon-header">
                                                    <Wysiwyg />
                                                  </div>
                                                </button>

                                                <div className="title-accordion d-flex flex-column align-items-start">
                                                  <div className="tx-body-po neutral500">
                                                    {subtopic.name}
                                                  </div>
                                                  {subtopic.status ? (
                                                    <Buttons
                                                      control="modal"
                                                      modal="INACTIVE"
                                                      target="MODAL_PUT_SUBTOPIC"
                                                      addClass="btnLink status-active ishover"
                                                      crud={subtopic}
                                                    >
                                                      <span className="me-1">Active</span>
                                                      <Cached style={{ fontSize: "15px" }} />
                                                    </Buttons>
                                                  ) : (
                                                    <Buttons
                                                      control="modal"
                                                      modal="ACTIVE"
                                                      target="MODAL_PUT_SUBTOPIC"
                                                      addClass="btnLink status-inactive ishover"
                                                      crud={subtopic}
                                                    >
                                                      <span className="me-1">Inactive</span>
                                                      <Cached style={{ fontSize: "15px" }} />
                                                    </Buttons>
                                                  )}
                                                </div>
                                                <div className="btn-more">
                                                  <Formik
                                                    initialValues={initialValues}
                                                    onSubmit={onSubmit}
                                                    enableReinitialize={true}
                                                  >
                                                    {(formik) => (
                                                      <Form className="d-flex align-items-center">
                                                        <FormikControl
                                                          control="input"
                                                          type="number"
                                                          name="orderSubtopic"
                                                          addClass="isExSmall"
                                                          width="40px"
                                                          inputClass="hideArrow p-8 text-center"
                                                          onChange={(e) => {
                                                            formik.setFieldValue(
                                                              "orderSubtopic",
                                                              e.target.value
                                                            );
                                                          }}
                                                        />
                                                      </Form>
                                                    )}
                                                  </Formik>
                                                  <Buttons control="modal" modal="REMOVE" target="MODAL_PUT_SUBTOPIC" addClass="neutral200 ms-1 ms-md-3" crud={subtopic} btnExSmall btnGhost btnIcon><Delete className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                                                  <Buttons control="modal" modal="EDIT" target="MODAL_UPDATE_SUBTOPIC" addClass="neutral200 ms-1 ms-md-3" crud={subtopic} btnExSmall btnGhost btnIcon><Edit className="icon-button" style={{ color: 'var(--neutral500)' }} /></Buttons>
                                                </div>
                                              </h2>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    <div className="mb-8"></div>
                                    <Buttons
                                      control="modal"
                                      modal="CREATE"
                                      target="MODAL_UPDATE_SUBTOPIC"
                                      addClass="btn-big col-12"
                                      crud={topic}
                                    >
                                      <div className="tx-body text-center neutral200">
                                        <Add className="icon" /> Create new sub topic
                                      </div>
                                    </Buttons>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="mb-8"></div>
                        <Buttons
                          control="modal"
                          modal="CREATE"
                          target="MODAL_UPDATE_TOPIC"
                          addClass="btn-big col-12"
                          crud={lesson}
                        >
                          <div className="tx-body text-center neutral200">
                            <Add className="icon" /> Create new topic
                          </div>
                        </Buttons>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="mb-8"></div>
          <Buttons
              control="modal"
              modal="CREATE"
              target="MODAL_UPDATE_LESSON"
              addClass="btn-big col-12"
            >
              <div className="tx-body text-center neutral200">
                <Add /> Create new lesson
              </div>
            </Buttons>
          </>
          : <Skeleton count={3} height={80} />
        }
      </div>
    </>
  );
}

export default QuickContent
