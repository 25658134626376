import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';
import {
    CheckCircleOutline,
    NotInterested,
} from '@mui/icons-material';
import * as Yup from 'yup'

function useUserMemberEdit() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [namePage, setNamePage] = useState('')
  const backStatus = useSelector((state) => state.BackStatus)

  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dataCrumbs = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Member Information", path: "/member" },
    { name: namePage, path: "" }
  ];

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel updating member',
    description: '',
    message: 'Once canceled, your inputed data will be lost.',
    path: '/member',
    footerYes: 'Yes',
    footerNo: 'No',
  }

  const statusOptions = [
    { key: 'Active', value: true },
    { key: 'Inactive', value: false },
  ]

  const [initialValues, setInitialValues] = useState({
    _id: '',
    role: 0,
    status: true,
    email: '',
    username: '',
    name: '',
  })

  const validationSchema = Yup.object({
    status: Yup.string().required("This field is required. Don't leave it empty!"),
  })

  const changeBackStatus = (initial, value) => {
    const allInitial = {
      ...initialValues,
      status: initialValues.status.toString()
    }
    const newInitial = {
      ...initialValues,
      [initial]: value
    }

    if (allInitial.status !== newInitial.status) {
      dispatch({ type: 'BACK_STATUS', newValue: true })
    } else {
      dispatch({ type: 'BACK_STATUS', newValue: false })
    }
  }

  const onSubmitSubject = (values, { setSubmitting }) => {
    values = {
      ...values,
      status: values.status === 'true' ? 1 : 0
    }
    RestAPI.put(`users/update/${id}`, values, config).
      then(res => {
        console.log(res)
        RestAPI.find(`users/find/${id}`, config).then(res2 => {
          setNamePage(res2.username)
          setInitialValues({
            _id: res2._id,
            role: res2.role.title,
            status: res2.status === 1 ? true : false,
            email: res2.email,
            username: res2.username,
            name: res2.name,
          })
        })
        dispatch({ type: 'BACK_STATUS', newValue: false })
        toast.success(contentToast(
          "Member Updated!", "Success to update the member."), {
          icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response)
        toast.error(contentToast(
          "Failed to update", "Your connection not stable. Try again."), {
          icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        setSubmitting(false);
      });
  }

  const initData = () => {
    RestAPI.find(`users/find/${id}`, config).then(res => {
      setNamePage(res.username)
      setInitialValues({
        _id: res._id,
        role: res.role.title,
        status: res.status === 1 ? true : false,
        email: res.email,
        username: res.username,
        name: res.name,
      })
    }).catch((err) => {
      console.log(err.response)
      navigate("/notfound")
    });
    dispatch({ type: 'BACK_STATUS', newValue: false })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    initData()
  }, [])

  return {
    id,
    backStatus,
    dataCrumbs,
    modalBack,
    statusOptions,
    initialValues,
    validationSchema,
    changeBackStatus,
    onSubmitSubject,
  }
}

export default useUserMemberEdit