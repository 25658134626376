import React, { useEffect, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";
import { Field, Form, Formik } from "formik";

const CompleteParahraphShow = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    let ans = [];
    data.answer.map((r) => {
      ans.push("");
    });
    setData({ ...data, answer: ans });
  }, []);

  const flatMap = (array, fn) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
      var mapping = fn(array[i], i);
      result = result.concat(mapping);
    }
    return result;
  };
  const qaReplace = (str) => {
    var result = str;
    result = flatMap(result.split("__"), function (part, i) {
      return [part, <AutoGrowInput name={`answer[${i}]`} key={i} />];
    });
    result.pop();
    return result;
  };
  const onSubmit = (values) => {};
  return (
    <>
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          {data.instruction ? (
            <h5 className="mb-16">{data.instruction}</h5>
          ) : (
            ""
          )}
          {data.image ? (
            <div className="text-center mb-2">
              <img src={data.image} alt="" width="100%" />
            </div>
          ) : (
            ""
          )}
          <div className="align-items-end fill-in-the-blank-question">
            <Formik
              initialValues={data}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              <Form>
                <div className="mb-4" style={{ whiteSpace: "pre-wrap" }}>
                  {qaReplace(data.questions)}
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};

const AutoGrowInput = ({ name }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        return (
          <>
            <label className={`input-sizer  `}>
              <input
                type="text"
                size="1"
                onInput={(e) => {
                  e.target.parentNode.dataset.value = e.target.value;
                }}
              />
            </label>
          </>
        );
      }}
    </Field>
  );
};
export default CompleteParahraphShow;
