import React from 'react';
import { Footer, LessonList, Navbar } from '../../../components/organisms';

function Lessons() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <LessonList />
        <Footer/>
      </>
  )
}

export default Lessons;
