import React, { useEffect, useState } from "react";
import "./SubTopicDialogAndNumbering.scss";
import * as Yup from "yup";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Toast from "../../../../atoms/Toast";
import TextError from "../../../../atoms/Formik/TextError";
import Tooltips from "../../../../atoms/Tooltips";
import { ModalLink, TitleSection } from "../../../../molecules";
import { ModalFillInTheBlank } from "../../../../molecules";
import { useParams } from 'react-router';
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import { 
  InfoOutlined,
  DeleteOutline,
  PostAdd,
  NotInterested
 } from '@mui/icons-material';

const SubTopicDialogAndNumbering = () => {
  const { id } = useParams()
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)
  const idSubTopic = localStorage.getItem('ID_SUBTOPIC');
  const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SUBTOPIC'))
  const [initialValues, setInitialValues] = useState({
    subTopicId: "",
    contentTypeId: "",
    instruction: "",
    image: "",
    fakeImage: "",
    questions: [
      {
        col1: {
          question: "",
          answer: "",
        },
        col2: {
          question: "",
          answer: "",
        },
      },
    ],
    correctionText: "",
  });

  const contentType = JSON.parse(localStorage.getItem("CONTENT_TYPE"));
  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(!idSubTopic) {
      navigate("/subtopic")
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config).then(res => {
        dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res })
      }).catch((err) => {
        console.log(err.response)
        navigate("/notfound")
      });
      if(id) {
          RestAPI.find(`content/dialog/${contentType.id}`, config).then(res => {
          const dataQuestions = [];
          res.questions.map((data1) => {
            const dataCol1 = {
              question: data1.col1.question,
              answer: data1.col1.answer,
            };
            const dataCol2 = {
              question: data1.col2.question,
              answer: data1.col2.answer,
            };
            dataQuestions.push({
              col1: dataCol1,
              col2: dataCol2,
            });
          });
          setInitialValues({
            subTopicId: "",
            contentTypeId: res._id,
            instruction: res.instruction,
            image: res.image,
            fakeImage: "",
            questions: dataQuestions,
            correctionText: res.correctionText,
          });
        })
      }
      dispatch({
        type: 'OPEN_MODAL', newValue: {
          status: false,
          target: '',
          crud: null,
          modal: '',
        }
      })
    }
  }, []);

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Dialog & Numbering", path: "" },
  ];

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        col1: Yup.object().shape({
          question: Yup.string().required(
            "This field is required. Don't leave it empty!!"
          ),
        }),
        col2: Yup.object().shape({
          question: Yup.string().required(
            "This field is required. Don't leave it empty!!"
          ),
        }),
      })
    ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!id) {
      values = {
        ...values,
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
      };
      RestAPI.post("content/dialog", values, config)
        .then((r) => {
          processSuccess("Created", "create");
        })
        .catch((err) => {
          processFailed("create");
          setSubmitting(false);
        });
    } else {
      values = {
        ...values,
        contentTypeId: contentType.id,
      };
      RestAPI.put(`content/dialog/${contentType.id}`, values, config)
        .then((r) => {
          processSuccess("Updated", "update");
        })
        .catch((err) => {
          processFailed("update");
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
      ...getWhereFrom,
      toast: true,
      message: { title: message1, desc: message2}
    }));
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  };

  const processFailed = (message) => {
    toast.error(
      contentToast(
        `Failed to ${message}!`,
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
  };

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  return (
    <>
      <Toast />
      <ModalLink />
      <section className=" dialog-and-numbering">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Fill In The Blank" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form className="formFormik">
                  <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <div className="mb-16 tx-header5">
                      Instruction{" "}
                      <span className="tx-header6">
                        <i>(Optional)</i>
                      </span>
                    </div>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="instruction"
                      placeholder="e.g. A. Answer the question!"
                    />
                  </div>
                  <ModalFillInTheBlank />
                  <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <div className="row">
                      <div className="col-md-12 ">
                        <label htmlFor="" className="tx-small ">
                          <span className="text-strong"> Upload image </span>
                          <i>(Optional)</i>
                        </label>
                        <FormikControl
                          addClass="isTaSmall isMoExSmall"
                          control="inputbase64"
                          name={`fakeImage`}
                          base64Name="image"
                        />
                      </div>
                      <QuestionSection formik={formik} />
                    </div>
                  </div>
                  <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <label htmlFor="" className="tx-small ">
                      <span className="text-strong ">Explanation Text </span>
                      <i>(Optional)</i>
                    </label>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name={`correctionText`}
                      placeholder="Explain the answer here"
                    />
                  </div>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={id ? "Update" : "Save"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

const QuestionSection = (props) => {
  return (
    <div className="mt-32">
      <label htmlFor="" className="tx-small ">
        <span className="text-strong mr-4">Question & Answer</span>
        <Tooltips 
          id="tooltips-dialog" 
          place="right" 
          icon={<InfoOutlined style={{ fontSize: "16px", color: "var(--neutral300)", }} />} 
          data={{
            title: 'Input Question',
            list: [
              { text: 'Use double underscore (__) to make answers. For example: 1+1 = __.' },
              { text: 'For example: 1+1 = __. For the answer, click the answer button and fill inside there.' },
            ]
          }}
        />
      </label>
      <FieldArray name={`questions`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          const { questions } = values;
          return (
            <>
              {questions.map((r, i) => {
                return (
                  <div className="mb-12 row gx-0 " key={i}>
                    <div className="col">
                      <div className="row neutral200 ">
                        <div className=" radius-4 mb-6 col-12 col-md-4 align-items-center ">
                          <div className="d-flex">
                            <Field name={`questions[${i}].col1.question`}>
                              {({ field, form: { touched, errors }, meta }) => {
                                return (
                                  <div className="col">
                                    <input
                                      type="text"
                                      placeholder="1st input"
                                      className={` form-control inp-group-radius ${
                                        meta.touched && meta.error
                                          ? "error"
                                          : ""
                                      }`}
                                      {...field}
                                    />
                                  </div>
                                );
                              }}
                            </Field>

                            <Buttons
                              type="button"
                              addClass="btnSuccess radius-tl-0 radius-bl-0 "
                              control="modal"
                              crud={{
                                name: `questions[${i}].col1.answer`,
                                data: props.formik.values.questions[i].col1
                                  .answer,
                                formik: props.formik,
                              }}
                              target="MODAL_FILL_IN_THE_BLANK"
                            >
                              Answer
                            </Buttons>
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name={`questions[${i}].col1.question`}
                          />
                          <p>
                            Answer :{" "}
                            {props.formik.values.questions[i].col1.answer}{" "}
                          </p>
                        </div>
                        <div className="pg-input  col">
                          <div className="d-flex">
                            <Field name={`questions[${i}].col2.question`}>
                              {({ field, form: { touched, errors }, meta }) => {
                                return (
                                  <div className="col">
                                    <input
                                      type="text"
                                      placeholder="2nd input"
                                      className={` form-control inp-group-radius ${
                                        meta.touched && meta.error
                                          ? "error"
                                          : ""
                                      }`}
                                      {...field}
                                    />
                                  </div>
                                );
                              }}
                            </Field>

                            <Buttons
                              type="button"
                              addClass="btnSuccess radius-tl-0 radius-bl-0 "
                              control="modal"
                              crud={{
                                name: `questions[${i}].col2.answer`,
                                data: props.formik.values.questions[i].col2
                                  .answer,
                                formik: props.formik,
                              }}
                              target="MODAL_FILL_IN_THE_BLANK"
                            >
                              Answer
                            </Buttons>
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name={`questions[${i}].col2.question`}
                          />
                          <p>
                            Answer :{" "}
                            {props.formik.values.questions[i].col2.answer}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className=" radius-4 cursor-pointer ml-8 d-flex justify-content-center align-items-center neutral200 remove-pg-button"
                      onClick={() => {
                        remove(i);
                      }}
                    >
                      <DeleteOutline className="btn-delete" />
                    </div>
                  </div>
                );
              })}
              <div className="mb-32 col-12 text-center  neutral300 more-option radius-4 p-8 ">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    push({
                      col1: {
                        question: "",
                        answer: "",
                      },
                      col2: {
                        question: "",
                        answer: "",
                      },
                    })
                  }
                >
                  More option <PostAdd />
                </span>
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default SubTopicDialogAndNumbering;
