import React, { useEffect, useState } from "react";
import "../Modal.scss";
import "./ModalSubTopic.scss";
import Modal from "react-modal";
import Buttons from "../../../atoms/Buttons";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

const ModalDeleteContent = (props) => {
  const statusMODAL = "MODAL_DELETE_CONTENT";
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const isStatusMODAL = useSelector((state) => state.openModal);
  const [initialValues, setInitialValues] = useState({
    target: "",
    id: "",
    contentType: "",
  });
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      setInitialValues({
        target: isStatusMODAL.crud.target,
        id: isStatusMODAL.crud.id,
        contentType: isStatusMODAL.crud.contentType,
      });
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  function isLast(index, data) {
    return index === data.length - 1;
  }

  return (
    <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
      <div className="modal-header bg-danger400">
        <h5 className="tx-header5 text-white">Delete Content </h5>
      </div>
      <div className="modal-body">
        <div className="message">
          <span className="tx-body-po">
            #{initialValues.target} {initialValues.contentType} &nbsp;
          </span>
          will be permanently lost
        </div>
      </div>
      <div className="modal-footer">
        <Buttons
          control="button"
          btnExSmall
          btnOutline
          name="Cancel"
          onClick={() => closeModal()}
        />
        <Buttons
          control="button"
          type="submit"
          name="Delete"
          addClass=""
          btnExSmall
          btnDanger
          onClick={() => {
            props.submitAction(initialValues.id, initialValues.contentType);
            closeModal();
          }}
        />
      </div>
    </Modal>
  );
};

export default connect()(ModalDeleteContent);
