import React from 'react';
import { Base64CompleteDialogs, Footer, Navbar } from '../../components/organisms';

function CompleteDialogs() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64CompleteDialogs />
        <Footer/>
      </>
  )
}

export default CompleteDialogs;
