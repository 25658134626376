import React, { useMemo } from 'react';
import Buttons from '../../../atoms/Buttons'
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect, useSortBy } from 'react-table'
import { Formik, Form } from 'formik'
import { COLUMNS } from './Columns'
import { GlobalFilter } from '../GlobalFilter'
import { ColumnFilterMember } from './ColumnFilterMember'
import { connect } from 'react-redux'
import { useMemberUpload } from '../../../services';
import {
    ArrowBack,
    ArrowForward,
    Sort,
    Upload,
} from '@mui/icons-material';
import '../DataTables.scss'

function TableMemberUpload() {
    const {
        data,
        initialValues,
        statusSubmit,
        loadingUpload,
        loading,
        handleFileUpload,
        onSubmit
      } = useMemberUpload()

    const columns = useMemo(() => COLUMNS, [])
    const defaultColumn = useMemo(() => {
        return { Filter: ColumnFilterMember }
    }, [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect)
    const { pageIndex, pageSize, globalFilter } = state

    if (loadingUpload) {
        return (
            <>
                <div className="text-center">
                    Uploading data, please wait ...
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="top-table d-flex align-items-center">
                    <div className="left">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                    </div>
                    <div className="right ms-auto d-flex align-items-center">
                        <Buttons control="upload" addClass="btnAdd text-center" accept=".csv,.xlsx,.xls" btnSmall btnWrapper isShadow onChange={handleFileUpload} ><Upload /> Browse Files</Buttons>
                    </div>
                </div>
                <div className="table-responsive">
                    <table {...getTableProps()} className="table-course">
                        <thead>
                            {
                                headerGroups.map((headerGroup, index) => {
                                    return (
                                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map((column, index2) => {
                                                    return (
                                                        <th key={index2} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {index2 != 0 && index2 < headerGroup.headers.length - 1 && <span className="float-end">
                                                                {column.isSorted ? (column.isSortedDesc ? <Sort style={{ fontSize: '14px' }} /> : <Sort style={{ transform: 'rotate(180deg)', fontSize: '14px' }} />) : <Sort style={{ opacity: '0.3', fontSize: '14px' }} />} </span>}
                                                        </th>
                                                    )


                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            {

                                headerGroups.map((headerGroup2, i) => {
                                    return (
                                        <tr key={i} {...headerGroup2.getHeaderGroupProps()} className="header-filtering">
                                            {
                                                headerGroup2.headers.map((column2, j) => {
                                                    return <th key={j} {...column2.getHeaderProps()}><div>{column2.canFilter ? column2.render('Filter') : null}</div></th>
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {
                                (loading) ?
                                    (page.length != 0) ?
                                        page.map((row, index3) => {
                                            prepareRow(row)
                                            return (
                                                <tr key={index3} {...row.getRowProps}>
                                                    {
                                                        row.cells.map((cell, index4) => {
                                                            {
                                                                if (cell.column.Header === 'Error Status' && cell.value && cell.value.length < 1) {
                                                                    return <td key={index4} {...cell.getCellProps}>
                                                                        <div className="status-active">Valid</div>
                                                                    </td>
                                                                } else if (cell.column.Header === 'Error Status' && cell.value && cell.value.length > 0) {
                                                                    return <td key={index4} {...cell.getCellProps}>
                                                                        {
                                                                            cell.value.map((item, index) => {
                                                                                return <div key={index}>
                                                                                    <div className="status-inactive">
                                                                                        {item}
                                                                                    </div>
                                                                                    <div className="mb-1" />
                                                                                </div>
                                                                            })
                                                                        }


                                                                    </td>
                                                                } else {
                                                                    return <td key={index4} {...cell.getCellProps} className='content'>
                                                                        {cell.render('Cell')}
                                                                    </td>
                                                                }
                                                            }
                                                        })

                                                    }
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td className="datanotfound text-center tx-small neutral200" colSpan="7">
                                                No data available
                                            </td>
                                        </tr>
                                    :
                                    <tr>
                                        <td className="datanotfound text-center tx-small neutral200" colSpan="7">
                                            Loading data...
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="navigation-table">
                    <div className="tx-small-hl neutral300 d-none d-md-block">
                        Page {' '} {pageIndex + 1} of {pageOptions.length}{' '}
                    </div>
                    <div className="pagination-table">
                        <div className="tx-small neutral300 your-page">
                            You're in page {' '}
                            <input type="number" className="numberpage" value={pageIndex + 1}
                                onChange={e => {
                                    const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(pageNumber)
                                }}
                                style={{ width: '50px' }}
                            />
                        </div>
                        <div className="divider-nav"></div>
                        <div style={{ marginRight: '24px' }}>
                            <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => previousPage()} btnDisable={!canPreviousPage} name={<ArrowBack style={{ fontSize: "20px" }} />} />
                        </div>
                        <div>
                            <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => nextPage()} btnDisable={!canNextPage} name={<ArrowForward style={{ fontSize: "20px" }} />} />
                        </div>
                    </div>
                </div>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {formik => (
                        <Form className="formSubmitCSV formFormik">

                            <div className="text-end">
                                <Buttons control="button" type="submit" name="Submit" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting || !statusSubmit} disabled={formik.isSubmitting || !statusSubmit} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                            </div>
                        </Form>
                    )}
                </Formik>
            </>

        )
    }


}

export default connect()(TableMemberUpload)
