import React, { useState } from "react";

import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import AssessmentShowFooter from "./AssessmentShowFooter";
const AssessmentMatchPairsShow = (props) => {
  const [data, setData] = useState(props.data);
  const [leftSide, setLeftSide] = useState(true);
  const [rightSide, setRightSide] = useState(false);
  const [nextColor, setNextColor] = useState("");
  const [nextAbjad, setNextAbjad] = useState("");
  const [dataClicked, setDataClicked] = useState(0);
  const [leftClickedData, setLeftClickedData] = useState([]);

  const maxData = data.options.length * 2;

  // console.log("mat", data);
  // return "";
  const handleLeftClicked = (e, r) => {
    let isDataClicked = checkIsbuttonClicked(r.abjad);

    if (leftSide && maxData > dataClicked && isDataClicked === undefined) {
      let dataCLicked = leftClickedData;
      dataCLicked.push(r.abjad);
      setLeftSide(false);
      setRightSide(true);
      setNextColor(r.color);
      setDataClicked(dataClicked + 1);
      setLeftClickedData(dataCLicked);
      setNextAbjad(r.abjad);
      e.currentTarget.innerHTML = r.abjad;
      e.currentTarget.style.backgroundColor = r.color;
    }
  };

  const handleRightClicked = (e) => {
    if (rightSide && maxData > dataClicked) {
      setLeftSide(true);
      setRightSide(false);
      setDataClicked(dataClicked + 1);
      e.currentTarget.innerHTML = nextAbjad;
      e.currentTarget.style.backgroundColor = nextColor;
    }
  };

  const checkIsbuttonClicked = (value) => {
    return leftClickedData.find((res) => res === value);
  };

  return (
    <div className="assessments-content-show">
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          <p className="">
            {props.nomor}.{data.question}
          </p>
          <div className="row">
            <div className="col-12">
              {data.options.map((r, i) => {
                return (
                  <div className="row  align-items-center" key={i}>
                    {/* left side */}
                    <div className={` col-6 pl-12 `}>
                      {r.question.includes("data:image/") ? (
                        <div className="d-flex align-items-center mb-16">
                          <div
                            style={{ width: "252px", height: "252px" }}
                            className=" d-flex align-items-center"
                          >
                            <img src={r.question} width="100%" />
                          </div>
                          <div
                            className={` radius-4 ml-16 d-flex justify-content-center align-items-center ${
                              leftSide &&
                              checkIsbuttonClicked(r.abjad) !== r.abjad &&
                              "cursor-pointer"
                            }`}
                            style={{
                              height: "36px",
                              width: "52px",
                              backgroundColor: "#D4D7DB",
                            }}
                            onClick={(e) => {
                              handleLeftClicked(e, r);
                            }}
                          >
                            <CircleOutlinedIcon
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center mb-16 "
                          style={{ maxWidth: "320px" }}
                        >
                          <div
                            className="d-flex  align-items-center radius-tl-4 radius-bl-4 pl-16 justify-content-between w-full"
                            style={{
                              minHeight: "36px",
                              border: "1px solid var(--neutral100)",
                            }}
                          >
                            <span>{r.question}</span>
                            <span className="mr-16 text-danger">
                              {/* <HighlightOffRoundedIcon /> */}
                            </span>
                          </div>
                          <div
                            className={` d-flex align-items-center radius-tr-4 radius-br-4 justify-content-center ${
                              leftSide &&
                              checkIsbuttonClicked(r.abjad) !== r.abjad &&
                              "cursor-pointer"
                            }`}
                            style={{
                              height: "36px",
                              backgroundColor: "#D4D7DB",
                              width: "60px",
                            }}
                            onClick={(e) => {
                              handleLeftClicked(e, r);
                            }}
                          >
                            <CircleOutlinedIcon
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {/* right side */}
                    <div className={`col-6 d-flex justify-content-end `}>
                      {data.options_dup[i].answer.includes("data:image/") ? (
                        <div className="d-flex align-items-center mb-16 ">
                          <div
                            className={`  radius-4  d-flex justify-content-center align-items-center ${
                              rightSide && "cursor-pointer"
                            }`}
                            style={{
                              height: "36px",
                              width: "52px",
                              backgroundColor: "#D4D7DB",
                            }}
                            onClick={(e) => {
                              handleRightClicked(e);
                            }}
                          >
                            <CircleOutlinedIcon
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              maxWidth: "252px",
                              maxHeight: "252px",
                            }}
                            className="ml-16 d-flex align-items-center"
                          >
                            <img
                              src={data.options_dup[i].answer}
                              width="100%"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-center mb-16 w-full "
                          style={{ maxWidth: "320px" }}
                        >
                          <div
                            className={` d-flex align-items-center justify-content-center radius-tl-4 radius-bl-4 ${
                              rightSide && "cursor-pointer"
                            }`}
                            style={{
                              height: "36px",
                              width: "60px",
                              backgroundColor: "#D4D7DB",
                            }}
                            onClick={(e) => {
                              handleRightClicked(e);
                            }}
                          >
                            <CircleOutlinedIcon
                              style={{
                                fontSize: "18px",
                              }}
                            />
                          </div>
                          <div
                            className="d-flex  align-items-center radius-tr-4 radius-br-4 pl-16 justify-content-between w-full"
                            style={{
                              minHeight: "36px",
                              border: "1px solid var(--neutral100)",
                            }}
                          >
                            <span>{data.options_dup[i].answer}</span>
                            <span className="mr-16 text-danger"></span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <AssessmentShowFooter data={data} submitAction={props.submitAction} />
      </div>
    </div>
  );
};

export default AssessmentMatchPairsShow;
