import React from "react";
import {
  Footer,
  Navbar,
  SubTopicCKEditor,
} from "../../../components/organisms";
const CKEditorContent = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SubTopicCKEditor />
      <Footer />
    </>
  );
};

export default CKEditorContent;
