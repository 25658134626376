import React, { useEffect, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";

const EmbedHtml5Show = (props) => {
  const [data, setData] = useState(props.data);
  const [frameSize, setFrameSize] = useState(60);
  const dataUser = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const userID = dataUser.userID


  useEffect(() => {
    setFrameSize(
      (data.contents.fileHeight / data.contents.fileWidth) * 100
    )
    console.log('data framme')
  }, [])

  const styleContainer = {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    paddingTop: `${frameSize}%`,
  }
  const styleIframe = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 'none',
  }
  // console.log(`aaa ${data.contents.documentPath}?userId=${userID}&contentId=${data._id}`)
  console.log('userID', userID)
  console.log('contentId', data._id)
  return (
    <>
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          <h5 className="mb-2">{data.contents.title}</h5>
          <div style={styleContainer}>
            <iframe style={styleIframe} src={`${data.contents.documentPath}?userId=${userID}&contentId=${data._id}`}></iframe>
          </div>
        </div>
        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};

export default EmbedHtml5Show
