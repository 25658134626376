import RestAPI from '../../../config/RestAPI'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';

function useTableTeacherResources() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable);
    const data = useSelector((state) => state.APITeacherResources);

    const initData = () => {
        dispatch({ type: "LOADING_TABLE", newValue: false });
        RestAPI.find("resources/teacher_resources", config).then((res) => {
            const newdata = [];
            res.map((item) => {
                newdata.push({
                    ...item,
                    attachment: {
                        type: item.type.code,
                        link: item.attachment
                    },
                    updatedAt: format(new Date(item.updatedAt), "yyyy-MM-dd, HH:mm"),
                });
            });
            dispatch({ type: "API_TEACHER_RESOURCES", newValue: [...newdata] });
            dispatch({ type: "LOADING_TABLE", newValue: true });
        });
    }

    useEffect(() => {
        initData()        
    }, [])


    return {
        loading,
        data,
        config,
        initData,
    }
}

export default useTableTeacherResources