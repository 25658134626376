import React from 'react'
import { Footer, Navbar, SchoolEdit } from '../../../components/organisms';

function EditSchool() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SchoolEdit />
      <Footer />
    </>
  )
}

export default EditSchool