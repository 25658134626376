import React, { useState, useEffect } from "react";
// import "./ShortAnswerAssessment.scss";
import * as Yup from "yup";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import Toast from "../../../../atoms/Toast";
import { useParams } from "react-router";
import { FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  ModalDeleteQuestion,
  ModalLink,
  TitleSection,
} from "../../../../molecules";
import { NotInterested, Add, Close } from "@mui/icons-material";
import { useAssessmentShortAnswer } from "../../../../services";

const ShortAnswerAssessment = () => {
  const {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
  } = useAssessmentShortAnswer();

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalDeleteQuestion />
      <section className="short-answer">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Short Answer" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <FieldArray name={"questions"}>
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { questions } = values;
                      // console.log("fieldArrayProps", fieldArrayProps);
                      // console.log("Form errors", values);
                      // <QuestionSection name={`questions[${i}]`} />;
                      return (
                        <>
                          {questions.map((q, i) => {
                            return (
                              <div
                                className="col-12 p-40 bg-secondary200 radius-14 mb-24"
                                key={i}
                              >
                                <div className="col mb-32">
                                  <label htmlFor="" className="tx-small">
                                    <span className="text-strong">
                                      Upload image
                                    </span>
                                    <i>(Optional)</i>
                                  </label>
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="inputbase64"
                                    type="text"
                                    base64Name={`questions.[${i}].image`}
                                    name={`questions.[${i}].fakeImage`}
                                  />
                                </div>
                                <div className="col mb-32">
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="textarea"
                                    type="text"
                                    name={`questions.[${i}].question`}
                                    label="Question"
                                    isLabel
                                    placeholder="Enter the question here"
                                  />
                                </div>
                                <div className="col mb-32">
                                  <label htmlFor="" className="tx-small  ">
                                    <span className="mr-4 text-strong">
                                      Answer
                                    </span>
                                  </label>

                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="textarea"
                                    type="text"
                                    name={`questions.[${i}].answer`}
                                    placeholder="Enter the answer here"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={action !== "update" ? "Save" : "Update"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShortAnswerAssessment;
