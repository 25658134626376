import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { connect } from 'react-redux'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useTopic } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalEditTopic() {
    const { config, initData } = useTopic()  
    const statusMODAL = 'MODAL_EDIT_TOPIC'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        _id: '',
        name: '',
        status: false,
        lessonId: '',
    })
    const [optionCourse, setOptionCourse] = useState([{ key: 'None', value: '' }])
    const [optionSubject, setOptionSubject] = useState([])
    const [optionLesson, setOptionLesson] = useState([])

    const [initialCourse, setInitialCourse] = useState()
    const [initialSubject, setInitialSubject] = useState()

    useEffect(() => {
        RestAPI.find('course', config).then(res => {
            let data = [{ key: 'None', value: '' }]
            res.map(course => {
                data.push({
                    key: course.name,
                    value: course._id
                })
            })
            setOptionCourse(data)
        })
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            setInitialValues({
                _id: isStatusMODAL.crud._id,
                name: isStatusMODAL.crud.name,
                status: isStatusMODAL.crud.status,
                lessonId: (isStatusMODAL.crud.lesson) ? isStatusMODAL.crud.lesson._id : '',
            })
            setInitialCourse((isStatusMODAL.crud.course) ? isStatusMODAL.crud.course._id : '')
            setInitialSubject((isStatusMODAL.crud.subject) ? isStatusMODAL.crud.subject._id : '')
            RestAPI.find(`subject/find?courseId=${(isStatusMODAL.crud.course) ? isStatusMODAL.crud.course._id : ''}`, config).then(res2 => {
                let data = [{ key: 'Select subject', value: '' }]
                res2.map(subject => {
                    data.push({
                        key: subject.name,
                        value: subject._id
                    })
                })
                setOptionSubject(data)
            })
            RestAPI.find(`lesson/find?subjectId=${(isStatusMODAL.crud.subject) ? isStatusMODAL.crud.subject._id : ''}`, config).then(res3 => {
                let data = [{ key: 'Select lesson', value: '' }]
                res3.map(lesson => {
                    data.push({
                        key: lesson.name,
                        value: lesson._id
                    })
                })
                setOptionLesson(data)
            })
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        lessonId: Yup.string().required("This field is required. Don't leave it empty!"),
    })
    
    const onSubmit = values => {
        RestAPI.put(`topic/${values._id}`, values, config).then(res => {
            initData()
            toast.success(contentToast(
                "Topic Updated!", "Success to update the topic."), {
                icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })
            closeModal()
        })
        .catch((err) => {
            toast.error(contentToast(
                "Failed to update", "Your connection not stable. Try again."), {
                icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
            })
            closeModal()
        });
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form className="formFormik">
                                <div className="modal-header">
                                    <h5 className="tx-header5">Edit Topic</h5>
                                    <p className="tx-small neutral300">Change and update the topic info.</p>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='Input topic name' label="Topic name" isLabel />
                                        </div>
                                        <div style={{ height: '24px' }}></div>
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' label="Topic status" isLabel options={[
                                                { key: 'Active', value: true },
                                                { key: 'Inactive', value: false },
                                            ]} onChange={(e) => console.log('tes')} />
                                        </div>
                                        <div style={{ height: '24px' }}></div>
                                        <div className="col-12">
                                            <label htmlFor="courseId" className="form-label tx-small-po">Assign course</label>
                                            <select name="courseId" className="form-select isTaSmall isMoExSmall" id="courseId" value={initialCourse}
                                                onChange={(e) => {
                                                    setInitialCourse(e.target.value)
                                                    formik.setFieldValue("subjectId", '')
                                                    formik.setFieldValue("lessonId", '')
                                                    setOptionLesson([{ key: 'Select lesson', value: '' }])
                                                    RestAPI.find(`subject/find?courseId=${e.target.value}`, 
                                                    config).then(res => {
                                                        let data = [{ key: 'Select subject', value: '' }]
                                                        res.map(subject => {
                                                            data.push({
                                                                key: subject.name,
                                                                value: subject._id
                                                            })
                                                            let data2 = [{ key: 'Select lesson', value: '' }]
                                                            RestAPI.find(`lesson/find?subjectId=${subject._id}`, 
                                                            config).then(res2 => {
                                                                res2.map(lesson => {
                                                                    data2.push({
                                                                        key: lesson.name,
                                                                        value: lesson._id
                                                                    })
                                                                })
                                                                setOptionLesson(data2)
                                                            })
                                                        })
                                                        setOptionSubject(data)
                                                    })
                                                }} >
                                                {
                                                    optionCourse.map((item, index) => {
                                                        return <option key={index} value={item.value}>{item.key}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={{ height: '24px' }}></div>
                                        <div className="col-12">
                                            <label htmlFor="subjectId" className="form-label tx-small-po">Assign subject</label>
                                            <select name="subjectId" className="form-select isTaSmall isMoExSmall" id="subjectId" value={initialSubject}
                                                onChange={(e) => {
                                                    setInitialSubject(e.target.value)
                                                    formik.setFieldValue("lessonId", '')
                                                    RestAPI.find(`lesson/find?subjectId=${e.target.value}`, 
                                                    config).then(res => {
                                                        let data = [{ key: 'Select lesson', value: '' }]
                                                        res.map(lesson => {
                                                            data.push({
                                                                key: lesson.name,
                                                                value: lesson._id
                                                            })
                                                        })
                                                        setOptionLesson(data)
                                                    })
                                                }} >
                                                {
                                                    optionSubject.map((item, index) => {
                                                        return <option key={index} value={item.value}>{item.key}</option>
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div style={{ height: '24px' }}></div>
                                        <div className="col-12">
                                            <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='lessonId' label="Assign lesson" isLabel options={optionLesson} />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name='Update' addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default connect()(ModalEditTopic)