import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: '#',
        accessor: 'no',
        Filter: ColumnFilter,
        disableFilters: true
    },
    {
        Header: 'Subject Name',
        accessor: 'subject',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Code Coupon',
        accessor: 'codeCoupon',
        Placehoder: 'e.g. ABCDEFGHI',
        Filter: ColumnFilter,
    },
]