import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import TitleSection from '../../../molecules/TitleSection';
import RestAPI from "../../../../config/RestAPI";
import '../Style.scss'

function CodeEditors() {
  const dataCrumbs = [
    { name: 'Test', path: '/test' },
    { name: 'Base64', path: '/base64' },
    { name: 'Code Editors', path: '' },
  ]

  const { id } = useParams();
  const [data, setData] = useState();
  const [globalCss, setGlobalCss] = useState("");
  const contentRef = useRef();

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const initData = () => {
    RestAPI.find(`base64/codeeditors/${id}`, config).then((res) => {
      setData(res.content)
    }).catch(err => {
      console.log(err)
    })
  };


  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    let imgTag = contentRef.current.querySelectorAll("img");
    imgTag.forEach((r) => {
      r.setAttribute("width", "100%");
      r.setAttribute("height", "100%");
      r.classList.add("radius-8");
    });

    const tokenAccess = JSON.parse(
      localStorage.getItem("ATLAZ_Authentication")
    );
    const config = {
      headers: { token: "Bearer " + tokenAccess.accessToken },
    };

    RestAPI.find("content/global_styles", config).then((r) => {
      setGlobalCss(r.content);
    });
  }, []);

  const createMarkup = (json) => {
    return { __html: json };
  };

  return (
    <>
      <section className="base64-list">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Base64 Code Editors" />
          </div>
          <div className="row">
          <div className="col-12 card-container mb-40">
        <div className="card-content">
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={createMarkup(data)}
          ></div>
          <Helmet>
            <style>{`${globalCss}`}</style>
          </Helmet>
        </div>
      </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default connect()(CodeEditors)
