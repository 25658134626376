import React from 'react'
import './InputText.scss'
import propTypes from "prop-types"

function InputText(props) {
    const className = ["inputText"];
    const inputClass = ["form-control"];
    className.push(props.addClass);
    inputClass.push(props.inputClass);
    if (props.isShadow) className.push("isShadow")
    if (props.isSmall) className.push("isSmall")
    if (props.isExSmall) className.push("isExSmall")
    if (props.isTaSmall) className.push("isTaSmall")
    if (props.isTaExSmall) className.push("isTaExSmall")
    if (props.isMoSmall) className.push("isMoSmall")
    if (props.isMoExSmall) className.push("isMoExSmall")

    return (
        <div className={className.join(" ")}>
            {
                props.isLabel && <label htmlFor={props.accessor} className="form-label tx-small-po">{props.label}</label>
            }
            <input type={props.type} className={inputClass.join(" ")} id={props.accessor} placeholder={props.placeholder} value={props.value} onKeyUp={props.onKeyUp} onChange={props.onChange} />
        </div>
    )
}

InputText.propTypes = {
    addClass: propTypes.string,
    className: propTypes.string,
    inputClass: propTypes.string,
    isLabel: propTypes.bool,
    isShadow: propTypes.bool,
    isSmall: propTypes.bool,
    isExSmall: propTypes.bool,
    isTaSmall: propTypes.bool,
    isTaExSmall: propTypes.bool,
    isMoSmall: propTypes.bool,
    isMoExSmall: propTypes.bool,
};

export default InputText