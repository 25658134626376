import React, { useState, useEffect } from 'react';
import './Modal.scss'
import propTypes from "prop-types"
import Buttons from '../../atoms/Buttons';
import Modal from 'react-modal'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function ModalLink() {
    const statusMODAL = 'MODAL_LINK'
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false)
    const isStatusMODAL = useSelector((state) => state.openModal)
    const [initialValues, setInitialValues] = useState({
        isForm: false,
        isNoForm: true,
        title: 'Loading',
        description: '',
        message: 'Loading...',
        path: '',
        footerYes: 'Yes',
        footerNo: 'No',
    })

    const modalClass = ["modal-content"]

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            setInitialValues({...isStatusMODAL.crud})
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])   

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }
    if (initialValues.isForm) modalClass.push("isForm")
    if (initialValues.isNoForm) modalClass.push("isNoForm")

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className={modalClass.join(" ")}>
                    <div className="modal-header">
                        <h5 className="tx-header5">{initialValues.title}</h5>
                        <p className="tx-small neutral300">{initialValues.description}</p>
                    </div>
                    <div className="modal-body">
                        <div className="message">{initialValues.message}</div>
                    </div>
                    <div className="modal-footer">
                        <Buttons control="button" btnExSmall btnOutline name={initialValues.footerNo} onClick={() => closeModal()} />
                        
                        <Buttons control="button" btnExSmall btnOutline name={initialValues.footerYes} addClass="btn-confirm" onClick={() => navigate(initialValues.path)} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

ModalLink.propTypes = {
    showClass: propTypes.string,
    modalClass: propTypes.string,
    isForm: propTypes.bool,
    isNoForm: propTypes.bool,
};

export default connect()(ModalLink)