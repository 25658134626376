import React, { useEffect, useState } from 'react';
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import {
    CheckCircleOutline,
    NotInterested,
} from '@mui/icons-material';

function useMemberEdit() {
    const { id, idmember } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [dataMember, setDataMember] = useState()
    // const dataMember = useSelector((state) => state.APIEditMember)
    const backStatus = useSelector((state) => state.BackStatus)

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "School Member", path: "/school" },
        { name: dataMember?.school.name, path: `/school/editschool/${id}` },
        { name: dataMember?.user && dataMember?.user.name, path: "" }
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel updating member',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: `/school/editschool/${id}`,
        footerYes: 'Yes',
        footerNo: 'No',
    }

    const [classOptions, setClassOptions] = useState([
        { key: 'Select classroom', value: '' },
    ])

    const [subjectOptions, setSubjectOptions] = useState([
        { key: 'Select subject', value: '' },
    ])

    const [initialValues, setInitialValues] = useState({
        _id: '',
        username: '',
        name: '',
        role: '',
        classlist: '',
        subject: '',
        expiredSubject: '',
    })

    const validationSchema = Yup.object({
        classlist: Yup.string().required("This field is required. Don't leave it empty!"),
        subject: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeBackStatus = () => {
        dispatch({ type: 'BACK_STATUS', newValue: true })
    }

    const onSubmit = (values, { setSubmitting }) => {
        RestAPI.put(`school/member/${idmember}`, values, config).
            then(res => {
                dispatch({ type: 'BACK_STATUS', newValue: false })
                toast.success(contentToast(
                    "Member Updated!", "Success to update the member."), {
                    icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                })
                setSubmitting(false);
            })
            .catch((err) => {
                console.log(err.response)
                toast.error(contentToast(
                    "Failed to update", "Your connection not stable. Try again."), {
                    icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                })
                setSubmitting(false);
            });
    }

    const initData = () => {
        RestAPI.find(`school/member/${idmember}`, config).then(res => { 
            setDataMember(res)
            setInitialValues({
                _id: res._id,
                username: res.user ? res.user.username : '',
                name: res.user ? res.user.name : '',
                role: res.role.title ? res.role.title : '',
                classlist: res.classlist ? res.classlist._id : '',
                subject: res.subject ? res.subject._id : '',
                expiredSubject: format(new Date(res.expiredSubject), 'yyyy-MM-dd'),
            })
        }).catch((err) => {
            console.log(err.response)
            navigate("/notfound")
        });
        dispatch({ type: 'BACK_STATUS', newValue: false })
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    useEffect(() => {
        initData()        
        window.scrollTo({ top: 0, behavior: 'smooth' })
        RestAPI.find(`school/classlist?school=${id}`, config).then(res => {
            let data = [{ key: 'Select classroom', value: '' }]
            res.map(cls => {
                data.push({
                    key: `${cls.name} - ${cls.academicYear}`,
                    value: cls._id
                })
            })
            setClassOptions(data)
        })
        RestAPI.find('subject/simple', config).then(res => {
            let data = [{ key: 'Select subject', value: '' }]
            res.map(sbj => {
                data.push({
                    key: sbj.name,
                    value: sbj._id
                })
            })
            setSubjectOptions(data)
        })
    }, [])

    return {
        id,
        idmember,
        dataMember,
        backStatus,
        dataCrumbs,
        modalBack,
        initData,
        classOptions,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
    }
}

export default useMemberEdit