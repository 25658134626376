import RestAPI from '../../../../config/RestAPI';
import { format } from 'date-fns'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

function useCourse() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APICourse)

    const initData = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: false })
        RestAPI.find('course', config).then(res => {
            const newdata = []
            res.map(item => {
                newdata.push({
                    ...item,
                    updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
                })
            })
            dispatch({ type: 'API_COURSE', newValue: [...newdata] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        initData()
    }, [])

    return {
        config,
        loading,
        data,
        initData,
    }
}

export default useCourse