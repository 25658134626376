import React from 'react'
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { useMemberInfo } from '../../../services'
import './MemberInfo.scss'

function MemberInfo() {
    const {
        roleOptions,
        classOptions,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
    } = useMemberInfo()

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formMemberInfo formFormik">
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">NISN / NIK</div>
                                <div className="desc tx-ex-small neutral400">
                                    This input will be used as <span className="tx-ex-small-po">username</span>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='username' placeholder='e.g. 123456'
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'username', e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Lulu'
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'name', e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Role</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='role' options={roleOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'role', e.target.value)
                                        formik.setFieldValue("role", e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Class / Academic Year</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='classlist' options={classOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'classlist', e.target.value)
                                        formik.setFieldValue("classlist", e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Subject</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='subject' options={subjectOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'subject', e.target.value)
                                        formik.setFieldValue("subject", e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Subject Expire Data <span className="tx-small-qu">(optional)</span></div>
                                <div className="desc tx-ex-small neutral400">
                                    If the subject has reached expiration, the user cannot access this subject anymore.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='date' type="date" name='expiredSubject' placeholder='e.g. 2022-03-01'
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'expiredSubject', e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name="Create" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                        </div>
                    </Form>
                )}
            </Formik>
            <Toast />
        </>
    )
}

export default connect()(MemberInfo)