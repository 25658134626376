import React, { useState, useEffect } from "react";
import "./SubTopicShortAnswer.scss";
import * as Yup from "yup";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import Toast from "../../../../atoms/Toast";
import { useParams } from "react-router";
import { FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  ModalDeleteQuestion,
  ModalLink,
  TitleSection,
} from "../../../../molecules";
import { NotInterested, Add, Close } from "@mui/icons-material";

const SubTopicShortAnswer = () => {
  const { id } = useParams();
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic);
  const idSubTopic = localStorage.getItem("ID_SUBTOPIC");
  const getWhereFrom = JSON.parse(localStorage.getItem("EDIT_SUBTOPIC"));
  const [initialValues, setInitialValues] = useState({
    subTopicId: "",
    contentTypeId: "",
    instruction: "",
    questions: [
      {
        fakeImage: "",
        image: "",
        question: "",
        answer: "",
      },
    ],
    correctionText: "",
  });

  const contentType = JSON.parse(localStorage.getItem("CONTENT_TYPE"));
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!idSubTopic) {
      navigate("/subtopic");
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config)
        .then((res) => {
          dispatch({ type: "API_EDIT_SUBTOPIC", newValue: res });
        })
        .catch((err) => {
          console.log(err.response);
          navigate("/notfound");
        });
      if (id) {
        RestAPI.find(`content/short_answer/${contentType.id}`, config).then(
          (res) => {
            const dataQuestions = [];
            res.questions.map((data1) => {
              dataQuestions.push({
                fakeImage: "",
                image: data1.image,
                question: data1.question,
                answer: data1.answer,
              });
            });
            setInitialValues({
              subTopicId: "",
              contentTypeId: res._id,
              instruction: res.instruction,
              questions: dataQuestions,
              correctionText: res.correctionText,
            });
          }
        );
      }
      dispatch({
        type: "OPEN_MODAL",
        newValue: {
          status: false,
          target: "",
          crud: null,
          modal: "",
        },
      });
    }
  }, []);

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Short Answer", path: "" },
  ];

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required(
          "This field is required. Don't leave it empty!"
        ),
        answer: Yup.string().required(
          "This field is required. Don't leave it empty!"
        ),
      })
    ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!id) {
      values = {
        ...values,
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
      };
      RestAPI.post("content/short_answer", values, config)
        .then((r) => {
          processSuccess("Created", "create");
        })
        .catch((err) => {
          processFailed("create");
          setSubmitting(false);
        });
    } else {
      values = {
        ...values,
        contentTypeId: contentType.id,
      };
      RestAPI.put(`content/short_answer/${contentType.id}`, values, config)
        .then((r) => {
          processSuccess("Updated", "update");
        })
        .catch((err) => {
          processFailed("update");
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem(
      "EDIT_SUBTOPIC",
      JSON.stringify({
        ...getWhereFrom,
        toast: true,
        message: { title: message1, desc: message2 },
      })
    );
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  };

  const processFailed = (message) => {
    toast.error(
      contentToast(
        `Failed to ${message}!`,
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
  };

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalDeleteQuestion />
      <section className="short-answer">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Short Answer" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <div className="col-md-12  mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <div className="mb-16 tx-header5">
                      Instruction{" "}
                      <span className="tx-header6">
                        <i>(Optional)</i>
                      </span>
                    </div>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="instruction"
                      placeholder="e.g. A. Answer the question!"
                    />
                  </div>

                  <FieldArray name={"questions"}>
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { questions } = values;
                      // console.log("fieldArrayProps", fieldArrayProps);
                      // console.log("Form errors", values);
                      // <QuestionSection name={`questions[${i}]`} />;
                      return (
                        <>
                          {questions.map((q, i) => {
                            return (
                              <div
                                className="col-12 p-40 bg-secondary200 radius-14 mb-24"
                                key={i}
                              >
                                <div className="d-flex justify-content-between mb-40">
                                  <div className="tx-header5">
                                    Number {i + 1}
                                  </div>
                                  <Buttons
                                    btnGhost
                                    btnIcon
                                    control="modal"
                                    target="MODAL_DELETE_QUESTION"
                                    crud={{
                                      onClick: () => {
                                        remove(i);
                                      },
                                    }}
                                  >
                                    <Close />
                                  </Buttons>
                                </div>
                                <div className="col mb-32">
                                  <label htmlFor="" className="tx-small">
                                    <span className="text-strong">
                                      Upload image
                                    </span>
                                    <i>(Optional)</i>
                                  </label>
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="inputbase64"
                                    type="text"
                                    base64Name={`questions.[${i}].image`}
                                    name={`questions.[${i}].fakeImage`}
                                  />
                                </div>
                                <div className="col mb-32">
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="textarea"
                                    type="text"
                                    name={`questions.[${i}].question`}
                                    label="Question"
                                    isLabel
                                    placeholder="Enter the question here"
                                  />
                                </div>
                                <div className="col mb-32">
                                  <label htmlFor="" className="tx-small  ">
                                    <span className="mr-4 text-strong">
                                      Answer
                                    </span>
                                  </label>

                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="textarea"
                                    type="text"
                                    name={`questions.[${i}].answer`}
                                    placeholder="Enter the answer here"
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <div className="col-12 mb-24">
                            <div
                              className=" text-center neutral300 more-option radius-4  cursor-pointer py-24 radius-8"
                              onClick={() =>
                                push({
                                  fakeImage: "",
                                  image: "",
                                  question: "",
                                  answer: "",
                                })
                              }
                            >
                              <span className="new-question">
                                <Add /> <br />
                                New question
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </FieldArray>
                  <div className="col-md-12  mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <label htmlFor="" className="tx-small">
                      <span className="text-strong">Explanation</span>{" "}
                      <i>(Optional)</i>
                    </label>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="correctionText"
                      placeholder="Explain the answer here"
                    />
                  </div>
                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={id ? "Update" : "Save"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubTopicShortAnswer;
