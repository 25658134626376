import React, { useEffect, useRef, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";
import Textarea from "react-expanding-textarea";

const EssayShow = (props) => {
  const textareaRef = useRef(null);
  const [data, setData] = useState(props.data);
  const handleChange = (e) => {
    console.log(e.target.value);
  };

  return (
    <div className="card-container mb-40">
      <div className="card-content">
        <div className="mb-16">
          {data.instruction ? (
            <h5 className="mb-16">{data.instruction}</h5>
          ) : (
            ""
          )}
          <p className="mb-16">{data.contents.annotation}</p>

          {data.contents.image ? (
            <div className="text-center mb-16">
              <img src={data.contents.image} alt="" width="100%" />
            </div>
          ) : (
            ""
          )}

          <div className="px-24 bg-info100">
            {/* <textarea onChange={handleChange} className="textarea" id="" cols="7" rows="7"></textarea> */}
            <Textarea
              className="textarea"
              onChange={handleChange}
              rows={7}
              ref={textareaRef}
            />
          </div>
        </div>
      </div>
      <SubTopicDetailShowFooter
        title={props.data.contentType.name}
        data={props.data}
        order={props.order}
        index={props.index}
        submitAction={props.submitAction}
      />
    </div>
  );
};

export default EssayShow;
