import React, { useEffect, useRef, useState } from "react";
import AssessmentShowFooter from "./AssessmentShowFooter";
const AssessmentTextEditorShow = (props) => {
  const data = props.data.contents;
  const contentRef = useRef();
  const createMarkup = () => {
    return { __html: data };
  };
  useEffect(() => {
    let imgTag = contentRef.current.querySelectorAll("img");
    imgTag.forEach((r) => {
      r.setAttribute("width", "100%");
      r.setAttribute("height", "100%");
      r.classList.add("radius-4");
    });
  }, []);
  return (
    <>
      <div className="assessments-content-show">
        <div className="card-container mb-40 ">
          <div
            className="card-content"
            ref={contentRef}
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
          <AssessmentShowFooter
            data={props.data}
            submitAction={props.submitAction}
          />
        </div>
      </div>
    </>
  );
};

export default AssessmentTextEditorShow;
