import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import RestAPI from '../../../../config/RestAPI'

function useUser() {
  const dispatch = useDispatch()
  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

  const loading = useSelector((state) => state.loadingTable)
  const data = useSelector((state) => state.APIUser)
  const [currentPage, setCurrentPage] = useState(1)

  const iniData = () => {
    dispatch({ type: 'LOADING_TABLE', newValue: false })
    RestAPI.find(`users/member_info?page=${currentPage}&perPage=3000`, config).then(res => {
      const newdata = []
      res.results?.map(item => {
        newdata.push({
          ...item,
          school: item.memberLists.length > 0 ? (item.memberLists[0].school ? item.memberLists[0].school.name : '-') : '-',
          status: item.status === 1 ? true : false,
          updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
        })
      })
      dispatch({ type: 'API_USER', newValue: [...newdata] })
      dispatch({ type: 'LOADING_TABLE', newValue: true })
    })
  }

  useEffect(() => {
    iniData()
  }, [])

  return {
    config,
    loading,
    data,
    iniData,
  }
}

export default useUser