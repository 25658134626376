import React, { useState, useEffect, useRef } from 'react'
import './SubtopicAssessment.scss'
import * as Yup from 'yup'
import FormikControl from '../../../atoms/Formik/FormikControl'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons'
import TextError from '../../../atoms/Formik/TextError'
import { useParams } from 'react-router';
import { Formik, Form, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { Link } from 'react-router-dom';
import { Editor } from "@tinymce/tinymce-react";
import {
    CheckCircleOutline,
    NotInterested,
    Info,
} from '@mui/icons-material';

function SubtopicAssessment(props) {
    const { dataSubTopic } = props
    const { id } = useParams()
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const editorRef = useRef(null);
    const inpFile = useRef();

    const statusOptions = [
        { key: 'Active', value: true },
        { key: 'Inactive', value: false },
    ]

    const [initialValues, setInitialValues] = useState({
        _id: '',
        name: '',
        status: false,
        assessmentType: '',
        courseName: '',
        subjectName: '',
        lessonName: '',
        topicName: '',
        preparationText: '',
    })

    useEffect(() => {
        if (dataSubTopic) {
            setInitialValues({
                _id: dataSubTopic._id,
                name: dataSubTopic.name,
                status: dataSubTopic.status,
                assessmentType: dataSubTopic.assessmentTitle,
                courseName: dataSubTopic.course.name,
                subjectName: dataSubTopic.subject.name,
                lessonName: dataSubTopic.lesson.name,
                topicName: dataSubTopic.topic.name,
                preparationText: dataSubTopic.preparationText,
            })
        }
    }, [dataSubTopic])

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeBackStatus = () => {
        dispatch({ type: 'BACK_STATUS', newValue: true })
    }

    const onSubmit = values => {
        RestAPI.put(`subtopic/${id}`, values, config).then(() => {
            RestAPI.find(`subtopic/${id}`, config).then(res2 => {
                dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res2 })
            })
            dispatch({ type: 'BACK_STATUS', newValue: false })
            toast.success(contentToast(
                "Sub topic Updated!", "Success to update the sub topic."), {
                icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
            })
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
            .catch((err) => {
                console.log(err.response)
                toast.error(contentToast(
                    "Failed to update", "Your connection not stable. Try again."), {
                    icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                })
                window.scrollTo({ top: 0, behavior: 'smooth' })
            });
    }

    return (
        <>
            <div className="caution bg-success200 p-24 radius-16 mb-54 d-flex align-items-center">
                <Info style={{ fontSize: '20px' }} />
                <div className="tx-body ml-10">
                    Default Weight per section is 10. For update the weight, please go to <Buttons control="link" path={`/subject/editsubject/${dataSubTopic.subject._id}`} name="subject detail" btnLink addClass="warning500" /> (edit subject) and edit the topic from this assessment.
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formAssessment formFormik">
                        <div className="tx-header5">General Information</div>
                        <div className="height48"></div>
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">ID</div>
                            </div>
                            <div className="col-md-7">
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues._id} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assessment Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. Reading 01' onInput={() => changeBackStatus()} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assessment Status</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' options={statusOptions} onInput={() => changeBackStatus()} />
                            </div>
                        </div>
                        <div className="height40"></div>


                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Assessment Type</div>
                                <div className="desc tx-ex-small neutral400 ml-16">
                                    <ul>
                                        <li>Reading Listening Assessment contain various type of question.</li>
                                        <li>Speaking Writing Assessment contain file upload and voice record.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' name='assessmentType' disabled  />
                            </div>
                        </div>

                        <hr className="divider" />

                        <div className="tx-header5">Preparation Info</div>
                        <div className="height48"></div>
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Rules & Conditions</div>
                                <div className="desc tx-ex-small neutral400">
                                    This section will be displayed on the first page of the Assessment.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <input type="file" ref={inpFile} hidden />
                                <Editor
                                    name="preparationText"
                                    initialValue={(initialValues) ? initialValues.preparationText : ""}
                                    apiKey='5bvlkdhnupz7sh6ea9qwoeyec1uh4uaa4yida68iaak00gfu'
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    onEditorChange={(value) => {
                                        formik.setFieldValue("preparationText", value)
                                        if (value !== initialValues.preparationText) {
                                            changeBackStatus()
                                        }
                                    }}
                                    onBlur={formik.handleBlur("preparationText")}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        placeholder: 'e.g. Assessment will contain 2 part',
                                        plugins: [
                                            "advlist autolink lists link image charmap print preview anchor",
                                            "searchreplace visualblocks code fullscreen",
                                            "insertdatetime media table paste code help wordcount",
                                        ],
                                        toolbar:
                                            "styleselect | bold italic link align | bullist numlist outdent indent | image media | insertfile undo redo",
                                        image_advtab: true,

                                        file_picker_callback: function (callback, value, meta) {
                                            inpFile.current.click();
                                            if (meta.filetype === "image") {
                                                inpFile.current.addEventListener("change", (ev) => {
                                                    var file = ev.target.files[0];
                                                    var reader = new FileReader();
                                                    reader.onload = function (e) {
                                                        callback(reader.result, {
                                                            alt: "",
                                                        });
                                                    };
                                                    reader.readAsDataURL(file);
                                                    console.log(ev.target.files[0]);
                                                });
                                            }
                                        },
                                    }}
                                />
                                <ErrorMessage component={TextError} name="overview" />
                            </div>
                        </div>

                        <hr className="divider" />

                        <div className="tx-header5">Assign Section</div>
                        <div className="height48"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Assign Course</div>
                            </div>
                            <div className="col-md-7">                                
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues.courseName !== '' ? initialValues.courseName : 'None'} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Subject</div>
                            </div>
                            <div className="col-md-7">                             
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues.subjectName} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Lesson</div>
                            </div>
                            <div className="col-md-7">                       
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues.lessonName} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Assign Topic</div>
                            </div>
                            <div className="col-md-7">                       
                                <input type="text" className='form-control isTaSmall isMoExSmall' value={initialValues.topicName} disabled />
                            </div>
                        </div>
                        <div className="height40"></div>
                        <div className="height48"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default connect()(SubtopicAssessment)