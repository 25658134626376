import React, { useEffect } from 'react'
import RestAPI from '../../../../config/RestAPI'
import { format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'

function useSubtopic() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APISubTopic)

    const initData = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: false })
        RestAPI.find('subtopic', config).then(res => {
            const newdata = []
            res.map(item => {
                newdata.push({
                    ...item,
                    updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
                })
            })
            dispatch({ type: 'API_SUBTOPIC', newValue: [...newdata] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        initData()
    }, [])

  return {
    config,
    loading,
    data,
    initData,
  }
}

export default useSubtopic