import RestAPI from '../../../config/RestAPI';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import { useEffect } from 'react'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

function useCouponList() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APIGenerateCoupons)
    const [arrCSV, setArrCSV] = useState([data])
    let arrNameCSV = ["data"]

    const dataCrumbs = [
      { name: "Dashboard", path: "/dashboard" },
      { name: "Coupon List", path: "" },
    ];

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [sortType, setSortType] = useState('ASC')
    const [sortBy, setSortBy] = useState('subject')

    const [pagination, setPagination] = useState({
        total: 0,
        total_page: 0,
        current_page: 0,
        per_page: 10,
        next_page: false,
        prev_page: false,
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = () => {
        let dataSheets = []
        let objSheets = {}
        for (let i = 0; i < arrCSV.length; i++) {
            dataSheets.push(arrNameCSV[i])
            objSheets = {
                ...objSheets,
                [arrNameCSV[i]] : XLSX.utils.json_to_sheet(arrCSV[i])
            }
        }
        const wb = {
            Sheets: objSheets, 
            SheetNames: dataSheets
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'CodeCouponsAll-Excel' + fileExtension);     
    }

    const formik = useFormik({
        initialValues: { topage: 0 },
        onSubmit: values => {
          if (parseInt(values.topage) > 0 && parseInt(values.topage) <= pagination.total_page) {
            setPage(parseInt(values.topage))
            initData(sortType, sortBy, parseInt(values.topage), perPage)
          }
        },
    });

    const handlePageClick = (toPage) => {
        setPage(toPage)
        formik.setFieldValue("topage", toPage)
        initData(sortType, sortBy, toPage, perPage)
    };

    const initData = (dataSortType, dataSortBy, dataPage, dataPerPage) => {
        dispatch({ type: 'LOADING_TABLE', newValue: false })
        RestAPI.find('coupon/all', config).then(res => {            
            setArrCSV([res])
        })
        RestAPI.find(`coupon/list?sort_type=${dataSortType}&sort_by=${dataSortBy}&page=${dataPage}&per_page=${dataPerPage}`, config).then(res => {
            formik.setFieldValue("topage", res.pagintion.current_page ? res.pagintion.current_page : 0)
            setPagination({
                total: res.pagintion.total ? res.pagintion.total : 0,
                total_page: res.pagintion.total_page ? res.pagintion.total_page : 0,
                current_page: res.pagintion.current_page ? res.pagintion.current_page : 0,
                per_page: res.pagintion.per_page ? res.pagintion.per_page : 10,
                next_page: res.pagintion.next_page ? res.pagintion.next_page : false,
                prev_page: res.pagintion.prev_page ? res.pagintion.prev_page : false,
            })
            dispatch({ type: 'API_GENERATE_COUPONS', newValue: [...res.results] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        initData(sortType, sortBy, page, perPage)
    }, [])


    return {
        initData,
        dataCrumbs,
        loading,
        data,
        pagination,
        formik,
        handlePageClick,
        arrCSV,
        arrNameCSV,
        exportToCSV,
    }
}

export default useCouponList