import React, { useEffect, useState } from "react";
import "./SingleChoiceAssessment.scss";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Buttons from "../../../../atoms/Buttons";
import { Field, FieldArray, Form, Formik } from "formik";
import Toast from "../../../../atoms/Toast";
import {
  ModalDeleteQuestion,
  ModalLink,
  TitleSection,
} from "../../../../molecules";
import { DeleteOutline, PostAdd, Add, Close } from "@mui/icons-material";
import { useAssessmentSingleChoice } from "../../../../services";
const SingleChoiceAssessment = () => {
  const {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
  } = useAssessmentSingleChoice();

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalDeleteQuestion />
      <section className="single-choice">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Single Choice" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <FieldArray name={"questions"}>
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { questions } = values;
                      return (
                        <>
                          {questions.map((q, i) => {
                            return (
                              <div
                                className="col-12 p-40 bg-secondary200 radius-14 mb-24"
                                key={i}
                              >
                                <div className="col mb-32">
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="input"
                                    type="text"
                                    name={`questions.[${i}].question`}
                                    label="Question"
                                    isLabel
                                    placeholder="Enter the question"
                                  />
                                </div>
                                <div className="col mb-32">
                                  <label>
                                    <span className="tx-body-po">
                                      Upload image
                                    </span>{" "}
                                    <i> (optional)</i>
                                  </label>
                                  <FormikControl
                                    addClass="isTaSmall isMoExSmall"
                                    control="inputbase64"
                                    name={`questions[${i}].fakeImage`}
                                    base64Name={`questions[${i}].image`}
                                  />
                                </div>
                                <div className="col;">
                                  <AnswerSection
                                    name={`questions[${i}]`}
                                    formik={formik}
                                    answer={q.answer}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          {/* <div className="col-12 mb-24">
                            <div className=" text-center neutral300 more-option radius-4  cursor-pointer py-24 radius-8">
                              <span
                                className="new-question"
                                onClick={() =>
                                  push({
                                    question: "",
                                    options: [{ text: "" }, { text: "" }],
                                    answer: null,
                                  })
                                }
                              >
                                <Add /> <br />
                                New question
                              </span>
                            </div>
                          </div> */}
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={action !== "update" ? "Save" : "Update"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

const AnswerSection = (props) => {
  return (
    <>
      <FieldArray name={`${props.name}.options`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          let spliter = props.name.split("[");
          const { options } = values[spliter[0]][spliter[1].substring(0, 1)];

          return (
            <div>
              <label htmlFor="" className="tx-small-po">
                Option
              </label>
              {options.map((an, index) => (
                <div key={index} className="mb-12 d-flex">
                  <div className="pg-input mr-16">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      name={`${props.name}.options[${index}].text`}
                      type="text"
                      control="input"
                      placeholder="Enter the option"
                    />
                  </div>
                  <div
                    className={`bg-white radius-4 pg-card d-flex justify-content-center align-items-center neutral200 ${
                      props.formik.getFieldMeta(`${props.name}.answer`).error ==
                        "" &&
                      props.formik.getFieldMeta(`${props.name}.answer`)
                        .touched == true
                        ? "border border-danger"
                        : ""
                    }`}
                  >
                    <Field
                      addClass="isTaSmall isMoExSmall"
                      type="radio"
                      name={`${props.name}.answer`}
                      value={`${index}`}
                      checked={index == props.answer ? true : false}
                    />
                  </div>
                  <div
                    className={`cursor-pointer pg-card ml-16 d-flex justify-content-center align-items-center neutral200 remove-pg-button ${
                      index < 2 ? "bg-neutral100" : ""
                    }`}
                    onClick={() => {
                      if (index > 1) {
                        return remove(index);
                      }
                    }}
                  >
                    <DeleteOutline className="btn-delete" />
                  </div>
                </div>
              ))}

              <div
                className={`text-center cursor-pointer neutral300 more-option  radius-4 p-8 ${
                  options.length >= 12 ? "bg-neutral100" : ""
                }`}
                onClick={() => {
                  if (options.length < 12) {
                    return push({
                      text: "",
                    });
                  }
                }}
              >
                <span className="cursor-pointer">
                  More option <PostAdd />
                </span>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </>
  );
};
export default SingleChoiceAssessment;
