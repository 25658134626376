import React from "react";
import {
  Footer,
  Navbar,
  CouponsList,
} from "../../../components/organisms";

const index = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <CouponsList />
      <Footer />
    </>
  );
};

export default index;
