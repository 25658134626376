import React from 'react'
import './Buttons.scss'
import BtnLink from './BtnLink'
import BtnExLink from './BtnExLink'
import BtnButton from './BtnButton'
import BtnModal from './BtnModal'
import BtnModalLink from './BtnModalLink'
import BtnUpload from './BtnUpload'
import BtnExportCSV from './BtnExportCSV'

function Buttons(props) {
    const { control, ...rest } = props
    switch (control) {
        case 'link':
            return <BtnLink {...rest} />
        case 'exlink':
            return <BtnExLink {...rest} />
        case 'button':
            return <BtnButton {...rest} />
        case 'modal':
            return <BtnModal {...rest} />
        case 'modalLink':
            return <BtnModalLink {...rest} />
        case 'upload':
            return <BtnUpload {...rest} />
        case 'exportCSV':
            return <BtnExportCSV {...rest} />
        default:
            return null
    }
}

export default Buttons