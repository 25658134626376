import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import RestAPI from "../../../../config/RestAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { NotInterested, ErrorOutline, Warning } from "@mui/icons-material";

function useMemberUpload() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [statusSubmit, setStatusSubmit] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const loading = useSelector((state) => state.loadingTable);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );

      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }
    const hasilValidasi = ValidationData(list);
    setStatusSubmit(hasilValidasi.status);
    setData(hasilValidasi.list);
    setInitialValues(hasilValidasi.list);
    dispatch({ type: "LOADING_TABLE", newValue: true });
  };

  const ValidationData = (list) => {
    let status = true;
    list.map((item, index) => {
      list[index] = {
        ...item,
        errors: [],
      };
    });
    list.map((item, index) => {
      if (
        !item.NISN ||
        !item.name ||
        !item.role ||
        !item.school ||
        !item.class ||
        !item.subject
      ) {
        let arrError = item.errors;
        if (!arrError.includes("Data Empty")) {
          arrError.push("Data Empty");
        }
        list[index] = {
          ...item,
          errors: arrError,
        };
        status = false;
      }
    });
    list.map((item, index) => {
      list.map((item2, index2) => {
        if (
          index !== index2 &&
          item.NISN === item2.NISN &&
          item.class === item2.class &&
          item.school === item2.school &&
          item.subject === item2.subject
        ) {
          item.NISN = item.NISN + `${index + 1}`;
          list[index2] = {
            ...item2,
            // errors: arrError,
          };
          status = true;
        }
      });
    });
    list.map((item, index) => {
      list.map((item2, index2) => {
        if (
          index !== index2 &&
          item.NISN === item2.NISN &&
          item.name !== item2.name
        ) {
          item.NISN = item.NISN + `${index + 1}`;
          list[index2] = {
            ...item2,
            // errors: arrError,
          };
          status = true;
        }
      });
    });
    list.map((item, index) => {
      list.map((item2, index2) => {
        if (
          index !== index2 &&
          item.NISN === item2.NISN &&
          item.role !== item2.role
        ) {
          let arrError = item2.errors;
          if (!arrError.includes("Invalid Role")) {
            arrError.push("Invalid Role");
          }
          list[index2] = {
            ...item2,
            errors: arrError,
          };
          status = false;
        }
      });
    });

    return { list, status };
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      dispatch({ type: "BACK_STATUS", newValue: true });
      dispatch({ type: "LOADING_TABLE", newValue: false });
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  };

  const onSubmit = (values, { setSubmitting }) => {
    setLoadingUpload(true);
    RestAPI.post("users/create/many", values, config)
      .then((res) => {
        setSubmitting(false);
        setLoadingUpload(false);
        if (res.status === "Error") {
          toast.error(
            contentToast("Failed to upload", "Some data doesn't match."),
            {
              icon: (
                <NotInterested
                  style={{ color: "var(--danger500)", fontSize: "30px" }}
                />
              ),
            }
          );
        } else {
          if (res.warning && res.warning?.data) {
            toast.warn(
              contentToast("Success to upload", "Some data failed to upload."),
              {
                icon: (
                  <ErrorOutline
                    style={{ color: "var(--success500)", fontSize: "30px" }}
                  />
                ),
              }
            );
          } else {
            setData([]);
            toast.success(
              contentToast("Success to upload", "Success to upload member."),
              {
                icon: (
                  <ErrorOutline
                    style={{ color: "var(--info500)", fontSize: "30px" }}
                  />
                ),
              }
            );
          }
          dispatch({ type: "BACK_STATUS", newValue: false });
        }
      })
      .catch((err) => {
        console.log("error2", err.response);
        setLoadingUpload(false);
        setSubmitting(false);
        toast.error(
          contentToast("Failed to upload", err.response?.data?.message),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
      });
  };

  useEffect(() => {
    dispatch({ type: "LOADING_TABLE", newValue: true });
  }, []);

  return {
    id,
    data,
    initialValues,
    statusSubmit,
    loadingUpload,
    loading,
    processData,
    handleFileUpload,
    onSubmit,
  };
}

export default useMemberUpload;
