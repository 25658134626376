import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { BaseURL } from "../config/BaseURL";

const GlobalStyle = () => {
  const textRef = useRef();
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [defaultData, setDefaultData] = useState("");

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  useEffect(() => {
    axios.get(BaseURL + "/content/global_styles", config).then((r) => {
      setDefaultData(r.data.data.content);
      setIsLoading(false);
    });
  });

  const submit = () => {
    axios
      .post(
        BaseURL + "/content/global_styles",
        { content: textRef.current.value },
        config
      )
      .then((r) => {
        setStatus("Sukses nih mas");
      });
  };

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex  justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <h3>Loading.........</h3>
        </div>
      ) : (
        <div style={{ padding: "20px" }} className="text-center">
          <textarea
            ref={textRef}
            className="form-control mb-4"
            id=""
            style={{ width: "100%" }}
            rows="30"
          >
            {defaultData}
          </textarea>
          <div className="row gx-0">
            <button className="btn btn-warning col" onClick={submit}>
              Submit
            </button>
          </div>

          <div className="text-center">
            <h4>{status}</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalStyle;
