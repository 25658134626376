import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import {connect} from 'react-redux'
import { useSelector, useDispatch  } from 'react-redux'

function ModalDeleteQuestion() {
    const statusMODAL = 'MODAL_DELETE_QUESTION'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        onClick: null
    })
    let saveValue = []

    useEffect(() => {
        if(statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            setInitialValues({
                onClick: isStatusMODAL.crud.onClick,
            })
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])
    
    const closeModal = () => {
        dispatch({ type: 'OPEN_MODAL', newValue: {
            status: false,
            target: '',
            crud: null,
            modal: '',
        } })
    }
    
    const onSubmit = values => {
        values.onClick()
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isNoForm">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                <div className="modal-header">
                                    <h5 className="tx-header5">Delete Section</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="message">
                                        Confirm to delete number? Once deleted, it will be permanently lost.
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Buttons control="button" btnExSmall btnOutline name="No" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name="Yes" addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default connect()(ModalDeleteQuestion)