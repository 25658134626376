import Buttons from '../../../atoms/Buttons';
import { ColumnFilter } from '../ColumnFilter'
import { 
    Delete, Edit, LibraryAdd, LibraryAddCheck
} from '@mui/icons-material';

export const COLUMNS = [
    {
        Header: 'Subject Name',
        accessor: 'name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Course',
        accessor: 'course.name',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Original Price',
        accessor: 'originalPrice',
        Placehoder: 'e.g. 200000',
        Cell: ({value}) => { return `Rp ${value.toLocaleString(undefined, {maximumFractionDigits:2})}`},
        Filter: ColumnFilter
    },
    {
        Header: 'Lesson Plan',
        accessor: 'lessonPlan',
        Placehoder: 'e.g. Available',
        Filter: ColumnFilter
    },
    {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                
                {/* <Buttons control="modal" target="MODAL_DELETE_SUBJECT" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}
                {
                    props.row.original.certificateFile ?
                    (<Buttons control="modal" target="MODAL_MANAGE_CERTIFICATE" modal="UPDATE" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><LibraryAddCheck className="icon-button" /></Buttons>)
                    : <Buttons control="modal" target="MODAL_MANAGE_CERTIFICATE" modal="CREATE" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><LibraryAdd className="icon-button" /></Buttons>
                }

                <Buttons control="link" path={`/subject/editsubject/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]