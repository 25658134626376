import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Username',
        accessor: 'username',
        Placehoder: 'e.g. 123456',
        Filter: ColumnFilter
    },
    {
        Header: 'Name',
        accessor: 'name',
        Placehoder: 'e.g. Lulu',
        Filter: ColumnFilter
    },
    {
        Header: 'Role',
        accessor: 'role.title',
        Placehoder: 'e.g. Student',
        Filter: ColumnFilter
    },
    {
        Header: 'School',
        accessor: 'school',
        Placehoder: 'e.g. Atlaz',
        Filter: ColumnFilter
    },
    {
        Header: 'Class & Subject',
        accessor: 'memberLists',
        Placehoder: 'e.g. English',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">                
                <Buttons control="link" path={`/member/editmember/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]