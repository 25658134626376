import React from "react";
import { Footer, Navbar, SubTopicEssay } from "../../../components/organisms";
const Essay = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SubTopicEssay />
      <Footer />
    </>
  );
};

export default Essay;
