import React from 'react';
import TitleSection from '../../../molecules/TitleSection';
import { connect } from 'react-redux';
import { ModalActiveCourse, ModalDeleteCourse, ModalUpdateCourse, TableCourse } from '../../../molecules';
import './CourseList.scss'

function CourseList() {
  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Course', path: '' }
  ]

  return (
      <>
        <section className="course-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Course List" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableCourse />
                <ModalUpdateCourse />
                <ModalDeleteCourse />
                <ModalActiveCourse />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(CourseList)
