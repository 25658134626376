import { Add, Visibility } from "@mui/icons-material";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import Buttons from "../../../../atoms/Buttons";
import Loading from "../../../../atoms/Loading";
import Toast from "../../../../atoms/Toast";
import {
  ModalDeleteAssessment,
  ModalCreateAssessment,
  TitleSection,
} from "../../../../molecules";
import { useAssessmentShow } from "../../../../services";
import "./AssessmentShow.scss";

const AssessmentShow = () => {
  const {
    responseSubtopic,
    assessmentData,
    modalData,
    isLoading,
    idsubtopic,
    callBlock,
    handleCreateInline,
    handleDeleteAssessment,
    changeOrder,
  } = useAssessmentShow();
  let nomor = 0;
  return (
    <>
      {/* <Toast /> */}
      <ModalCreateAssessment
        onCreateInlineData={(data) => {
          handleCreateInline(data);
        }}
      />
      <ModalDeleteAssessment
        onDeleteAssessment={(id) => {
          handleDeleteAssessment(id);
        }}
      />
      <section className="assessment-show ">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>{responseSubtopic.name}</h5>
                  <p>
                    <span className=" radius-4  label-success tx-ex-small">
                      {
                        responseSubtopic.assessmentTitle === 'Automatic Grading' ?
                        'Reading Listening Assessment' :
                        (responseSubtopic.assessmentTitle === 'Manual Grading' ?
                        'Speaking Writing Assessment' : responseSubtopic.assessmentTitle)
                      }
                    </span>
                  </p>
                </div>
                <div>
                  <Buttons
                    control="link"
                    path={`/subtopic/assessment-preview/${idsubtopic}`}
                    addClass="btnAdd text-center"
                    btnSmall
                    btnMoExSmall
                    btnOutline
                    isShadow
                  >
                    <Visibility className="icon" /> Preview
                  </Buttons>
                </div>
              </div>
            </div>

            {
              isLoading ?
                <Skeleton height={120} />
                :
                <>
                  <div className="col-md-8 mt-24">
                    {
                      assessmentData.map((r, i) => {
                        if (
                          r.assessmentType.name.toLowerCase() !== "text editor" &&
                          r.assessmentType.name.toLowerCase() !== "divider"
                        ) {
                          nomor += 1;
                        }
                        return callBlock(r, nomor);
                      })
                    }
                  </div>

                  <div className="col-12 my-28">
                    <Buttons
                      type="button"
                      addClass="col-12 button-create-assessment"
                      control="modal"
                      target="MODAL_CREATE_ASSESSMENT"
                      crud={{
                        contentEditor: modalData,
                        inlineEditor: modalData,
                        dataSubTopic: responseSubtopic,
                      }}
                    >
                      <div className="tx-body text-center neutral200">
                        <span className="new-question">
                          <Add /> <br />
                          Create new block
                        </span>
                      </div>
                    </Buttons>
                  </div>
                </>
            }

          </div>
        </div>
      </section>
    </>
  );
};

export default connect()(AssessmentShow);
