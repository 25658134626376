import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom"
import { NotInterested } from '@mui/icons-material';
import * as Yup from 'yup'
import RestAPI from '../../../../config/RestAPI';
import { contentToast } from '../../../atoms/Toast/ContentToast';

function useSchoolDetails() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const backStatus = useSelector((state) => state.BackStatus)

    const editorRef = useRef(null);
    const inpFile = useRef();

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }

    const [institutionOptions, setInstitutionOptions] = useState([
        { key: 'Select fondations', value: '' },
    ])

    const [provinceOptions, setProvinceOptions] = useState([
        { key: 'Select province', value: '' },
    ])
    const [cityOptions, setCityOptions] = useState([
        { key: 'Select city', value: '' },
    ])

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "School List", path: "/school" },
        { name: "School Details", path: "" },
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel creating school',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: '/school',
        footerYes: 'Yes',
        footerNo: 'No',
    }

    const initialValues = {
        name: '',
        npsn: '',
        institution: '',
        province: '',
        prov: '',
        city: '',
        regency: '',
        address: '',
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
        npsn: Yup.string().required("This field is required. Don't leave it empty!"),
        institution: Yup.string().required("This field is required. Don't leave it empty!"),
        province: Yup.string().required("This field is required. Don't leave it empty!"),
        regency: Yup.string().required("This field is required. Don't leave it empty!"),
        address: Yup.string().required("This field is required. Don't leave it empty!"),
    })

    const changeProv = (provinceId) => {
        RestAPI.find(`area/province/${provinceId}`, config).then(res => {
            let data = [{ key: 'Select city', value: '' }]
            res?.city?.map(cty => {
                data.push({
                    key: cty.name,
                    value: cty._id
                })
            })
            setCityOptions(data)
        })
    }

    const changeBackStatus = (formik, initial, value) => {
        const newInitial = {
          ...formik.values,
          [initial]: value
        }
        const { name, npsn, institution, province, city, address } = newInitial
        
        if (name || npsn || institution || province || city || address) {
          dispatch({ type: 'BACK_STATUS', newValue: true })
        } else {
          dispatch({ type: 'BACK_STATUS', newValue: false })
        }
    }

    const onSubmit = (values, { setSubmitting }) => {
        RestAPI.post('school', values, config).then(res => {
            dispatch({ type: 'BACK_STATUS', newValue: false })
            localStorage.setItem("EDIT_SCHOOL", JSON.stringify({
                goal: 'CREATE',
                toast: true,
            }));
            setSubmitting(false);
            navigate(`/school/editschool/${res.data._id}`)
        })
            .catch((err) => {
                console.log('error', err.response)
                setSubmitting(false);
                toast.error(contentToast(
                    "Failed to create", "Your connection not stable. Try again."), {
                    icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                })
            });
    }

    useEffect(() => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
        RestAPI.find('area/province', config).then(res => {
            let data = [{ key: 'Select province', value: '' }]
            res.map(prv => {
                data.push({
                    key: prv.name,
                    value: prv._id
                })
            })
            setProvinceOptions(data)
        })
        RestAPI.find('institution', config).then(res => {
            let data = [{ key: 'Select fondations', value: '' }]
            res.map(ins => {
                data.push({
                    key: ins.name,
                    value: ins._id
                })
            })
            setInstitutionOptions(data)
        })
    }, [])

    return {
        backStatus,
        dataCrumbs,
        modalBack,
        editorRef,
        inpFile,
        institutionOptions,
        provinceOptions,
        cityOptions,
        changeProv,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
    }
}

export default useSchoolDetails