import React from "react";
import "./NavbarPreview.scss";
import Buttons from "../../../components/atoms/Buttons";
import imgBrand1 from "../../../assets/images/logo/regular/default/Double.png";
import imgBrand2 from "../../../assets/images/logo/logogram/default/Regular.png";
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import { Close } from '@mui/icons-material';

function NavbarPreview(props) {
  const { id } = useParams()
  return (
    <nav className="navbar navbar-expand-lg navbar-preview bg-neutral50">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/dashboard">
          <img src={imgBrand2} className="img-brand d-md-none" alt="" />
          <img src={imgBrand1} className="img-brand d-md-block d-none" alt="" />
        </Link>
        <div className="tx-header5">{props.subject}</div>
        <div className="flex ms-auto">
          <Buttons
            btnGhost
            btnIcon
            control="link"
            path={`/subtopic/editsubtopic/${id}`}
          >
            <Close />
          </Buttons>
        </div>
      </div>
    </nav>
  );
}

export default NavbarPreview;