import React from 'react';
import { Base64CodeEditors, Footer, Navbar } from '../../components/organisms';

function ContentCodeEditors() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64CodeEditors />
        <Footer/>
      </>
  )
}

export default ContentCodeEditors;
