import React, { useEffect, useState } from "react";
import RestAPI from "../../../config/RestAPI";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from 'react-toastify'
import { contentToast } from "../../atoms/Toast/ContentToast";
import { NotInterested, CheckCircleOutline } from '@mui/icons-material';
import * as Yup from "yup";

const useResourcesTrainingVideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { idType, id } = useParams();
  const [actionType, setActionType] = useState("");

  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

  const [initialValues, setInitialValues] = useState({
    name: "",
    type: idType,
    attachment: "",
    author: "",
  })

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required. Don't leave it empty!"),
    attachment: Yup.string().required("This field is required. Don't leave it empty!"),
    author: Yup.string().required("This field is required. Don't leave it empty!"),
  });

  const initUpdateData = () => {
    RestAPI.find(`resources/teacher_resources/${id}`, config).then(res => {
      setInitialValues({
        ...initialValues,
        name: res.name ? res.name : '',
        attachment: res.attachment ? res.attachment : '',
        author: res.author ? res.author : '',
      })
    })
      .catch((err) => {
        console.log('error', err.response)
      });
  }

  const changeBackStatus = (formik, initial, value) => {
    const newInitial = {
      ...formik.values,
      [initial]: value
    }
    const { name, attachment, author } = newInitial
    
    if (actionType === 'Create' && (name || attachment || author)) {
      dispatch({ type: 'BACK_STATUS', newValue: true })
    } else if(actionType === 'Update' && (name !== initialValues.name || attachment !== initialValues.attachment || author !== initialValues.author)) {
      dispatch({ type: 'BACK_STATUS', newValue: true })
    } else {
      dispatch({ type: 'BACK_STATUS', newValue: false })
    }
  }

  const onSubmit = (values, { setSubmitting }) => {
    if (actionType === "Create") {
      RestAPI.post('resources/teacher_resources/create', values, config).then(res => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
        localStorage.setItem("CRUD_RESOURCES", JSON.stringify({
          goal: 'CREATE',
          toast: true,
        }));
        setSubmitting(false);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setInterval(1000)
        navigate(`/teacher-resources`)
      })
        .catch((err) => {
          console.log('error', err.response)
          setSubmitting(false);
          toast.error(contentToast(
            "Failed to create", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
          })
        });
    } else if(actionType === "Update") {
      RestAPI.put(`resources/teacher_resources/update/${id}`, values, config).then(res => {
        dispatch({ type: 'BACK_STATUS', newValue: false })
        setSubmitting(false);
        window.scrollTo({ top: 0, behavior: 'smooth' })
        toast.success(contentToast(
          "Teacher Resources Updated!", "Success to update the teacher resources."), {
          icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
      })
        .catch((err) => {
          console.log('error', err.response)
          setSubmitting(false);
          window.scrollTo({ top: 0, behavior: 'smooth' })
          toast.error(contentToast(
            "Failed to update", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
          })
        });
    }
  }

  useEffect(() => {
    if (!idType && !id) {
      navigate("/not-found");
    } if(idType && !id) {
      setActionType("Create");
    } else if(idType && id) {
      setActionType("Update");
      initUpdateData()
    }
  }, []);

  return {
    id,
    idType,
    tokenAccess,
    initialValues,
    validationSchema,
    actionType,
    changeBackStatus,
    onSubmit,
  };
};

export default useResourcesTrainingVideo;
