import React, { useEffect } from 'react';
import TitleSection from '../../../molecules/TitleSection';
import { ModalActiveSubject, ModalCertificate, ModalDeleteSubject, TableSubject } from '../../../molecules';
import { connect, useDispatch } from 'react-redux'
import './SubjectList.scss'

function SubjectList() {
  const quickAddStatus = JSON.parse(localStorage.getItem('QUICK_ADD_CONTENT'))

  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Subject', path: '' }
  ]

  const dispatch = useDispatch()
  useEffect(() => {
    if(quickAddStatus) { localStorage.removeItem("QUICK_ADD_CONTENT"); }
    dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: [] })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }, [])

  return (
      <>
        <section className="subject-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Subject List" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableSubject />
                <ModalDeleteSubject />
                <ModalActiveSubject />
                <ModalCertificate />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(SubjectList)
