import { ColumnFilter } from "../ColumnFilter";
import { Delete, Edit } from "@mui/icons-material";
import Buttons from "../../../atoms/Buttons";

const convertToSlug = (Text) => {
  return Text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
      ;
}

export const COLUMNS = [
  {
    Header: "Filename",
    accessor: "name",
    Placehoder: "e.g. 123456",
    Filter: ColumnFilter,
  },
  {
    Header: "Status",
    accessor: "status",
    Placehoder: "e.g. Active",
    Filter: ColumnFilter,
  },
  {
    Header: "Type",
    accessor: "type.title",
    Placehoder: "e.g. Student",
    Filter: ColumnFilter,
  },
  {
    Header: "Author",
    accessor: "author",
    Placehoder: "e.g. Atlaz",
    Filter: ColumnFilter,
  },
  {
    Header: "Attachment",
    accessor: "attachment",
    Placehoder: "e.g. Jl. Utama",
    Filter: false
  },

  {
    Header: "Last update",
    accessor: "updatedAt",
    Placehoder: "e.g. 2022-01-01",
    Filter: ColumnFilter,
  },
  {
    Header: "Action",
    Cell: (props) => (
      <div className="d-flex">
        <Buttons
          control="modal"
          target="MODAL_DELETE_RESOURCES"
          addClass="neutral200 mr-10"
          crud={props.row.original}
          btnExSmall
          btnGhost
          btnIcon
        >
          <Delete className="icon-button" />
        </Buttons>

        <Buttons
          control="link"
          path={`/teacher-resources/${convertToSlug(props.row.original.type.title)}/${props.row.original.type._id}/${props.row.original._id}`}
          addClass="neutral200"
          btnExSmall
          btnGhost
          btnIcon
        >
          <Edit className="icon-button" />
        </Buttons>
      </div>
    ),
  },
];
