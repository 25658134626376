import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Fondations',
        accessor: 'institution.name',
        Placehoder: 'e.g. Yayasan',
        Filter: ColumnFilter
    },
    {
        Header: 'School Name',
        accessor: 'name',
        Placehoder: 'e.g. Atlaz School',
        Filter: ColumnFilter
    },
    {
        Header: 'NPSN',
        accessor: 'npsn',
        Placehoder: 'e.g. 123455678',
        Filter: ColumnFilter
    },
    {
        Header: 'Province',
        accessor: 'province.name',
        Placehoder: 'e.g. Banten',
        Filter: ColumnFilter
    },
    {
        Header: 'City',
        accessor: 'city',
        Placehoder: 'e.g. Tangerang',
        Filter: ColumnFilter
    },
    // {
    //     Header: 'Address',
    //     accessor: 'address',
    //     Placehoder: 'e.g. Jl. Utama',
    //     Filter: ColumnFilter
    // },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter
    },
    {
        Header: 'Last update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex">
                {/* <Buttons control="modal" target="MODAL_DELETE_SCHOOL" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons> */}

                <Buttons control="link" path={`/school/editschool/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]