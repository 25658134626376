import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import FormikControl from "../../../atoms/Formik/FormikControl";
import AssessmentShowFooter from "./AssessmentShowFooter";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
const AssessmentSingleChoiceShow = (props) => {
  const { data, nomor } = props;

  const onSubmit = (values) => {
    return;
  };
  return (
    <>
      <div className="assessments-content-show">
        <div className="card-container mb-40 ">
          <div className="card-content">
            <Formik initialValues={data} onSubmit={onSubmit}>
              <Form>
                {data.questions.map((r, i) => {
                  return (
                    <React.Fragment key={i}>
                      {r.image ? (
                        <div className="text-center mb-24 ">
                          <img src={r.image} alt="" width="100%" />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row ">
                        <div className="col-12 mb-16">
                          {" "}
                          {nomor}. {r.question}
                        </div>
                        {r.options.map((res, ind) => {
                          return (
                            <React.Fragment key={ind}>
                              <div className="px-16 col-12">
                                <label className=" rounded mr-8 w-full">
                                  <Field
                                    type="radio"
                                    name={`questions[${i}].answer`}
                                    value={`${ind}`}
                                    className={`radio-custom `}
                                  />
                                  <div className="custom single-option d-flex justify-content-between align-items-center px-24 py-16 radius-14 mb-16 w-full">
                                    <span>{res.text}</span>{" "}
                                    <span className="isChecked">
                                      <CheckCircleRoundedIcon
                                        style={{ color: "var(--success600)" }}
                                      />
                                    </span>
                                    <span className="noChecked">
                                      <RadioButtonUncheckedRoundedIcon
                                        style={{ color: "var(--neutral200)" }}
                                      />
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </Form>
            </Formik>
          </div>
          <AssessmentShowFooter data={data} submitAction={props.submitAction} />
        </div>
      </div>
    </>
  );
};

export default AssessmentSingleChoiceShow;
