import React, { useEffect, useRef, useState } from "react";
import "./Assessments-preview.scss";
const AssessmentTextEditorPreview = (props) => {
  const data = props.data.contents;
  const contentRef = useRef();
  const createMarkup = () => {
    return { __html: data };
  };
  useEffect(() => {
    let imgTag = contentRef.current.querySelectorAll("img");
    imgTag.forEach((r) => {
      r.setAttribute("width", "100%");
      r.setAttribute("height", "100%");
      r.classList.add("radius-4");
    });
  });
  return (
    <>
      <div className="assessments-content-preview">
        <div
          className="card-content"
          ref={contentRef}
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
      </div>
    </>
  );
};

export default AssessmentTextEditorPreview;
