import React from 'react'
import Input from './Input'
import InputGroup from './InputGroup'
import Textarea from './Textarea'
import Select from './Select'
import SelectMulti from "./SelectMulti"
import RadioButtons from './RadioButtons'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import InputFile from './InputFile'
import InputImageBox from './InputImageBox'
import InputBase64 from "./InputBase64"

function FormikControl (props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'inputgroup':
      return <InputGroup {...rest} />
    case 'inputfile':
      return <InputFile {...rest} />
    case 'inputImageBox':
      return <InputImageBox {...rest} />
    case 'textarea':
      return <Textarea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'selectMulti':
      return <SelectMulti {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <Checkbox {...rest} />
    case 'date':
      return <DatePicker {...rest} />
    case "inputbase64":
      return <InputBase64 {...rest} />;
    default:
      return null
  }
}

export default FormikControl