import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { connect, useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useParams } from 'react-router';
import { useTableMember } from '../../../services';
import {
    CheckCircleOutline,
    NotInterested,
} from '@mui/icons-material';

function ModalDeleteMember() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const statusMODAL = 'MODAL_DELETE_MEMBER'
    const { initDataMember } = useTableMember()
    const isStatusMODAL = useSelector((state) => state.openModal)
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        _id: 0,
        name: ''
    })
    let saveValue = []

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if (isStatusMODAL.crud.length) {
                isStatusMODAL.crud.map(item => {
                    saveValue.push({
                        _id: item.original._id,
                    })
                })
                setInitialValues([...saveValue])
            } else {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.user.name,
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])
    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
            }
        })
    }

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }
    const onSubmit = values => {
        if (values.length) {
            RestAPI.deleteMany("school/members/delete", values, config).then(res => {
                reloadTable("Deleted", "delete");
            })
                .catch((err) => {
                    failedProcess()
                });
        } else {
            RestAPI.delete(`school/member/${values._id}`, config).then(res => {
                reloadTable("Deleted", "delete");
            })
                .catch((err) => {
                    failedProcess()
                });
        }
    }

    const reloadTable = (mess1, mess2) => {
        initDataMember()
        toast.success(contentToast(
            `Member ${mess1}!`, `Success to ${mess2} the member.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    };

    const failedProcess = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: true })
        toast.error(contentToast(
            "Failed to delete member", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    };

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isNoForm">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                <div className="modal-header">
                                    <h5 className="tx-header5">Delete Member</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="message">
                                        <span className='tx-body-po'>
                                            {
                                                (initialValues.length) ? "All Selected Member" :
                                                    `${initialValues.name}`
                                            }
                                        </span> will be permanently lost, but the assigned content will remain and be unassigned.
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Buttons control="button" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name="Delete" addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default connect()(ModalDeleteMember)