import React from "react";
import { Footer, Navbar, EssayAssessment } from "../../../components/organisms";
const EssayAssessmentPage = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <EssayAssessment />
      <Footer />
    </>
  );
};

export default EssayAssessmentPage;
