import React, { useEffect } from "react";
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import { ModalLink, SubjectInfo } from '../../../../molecules';
import { connect, useSelector, useDispatch } from 'react-redux'
import { ArrowBack } from '@mui/icons-material';
import { useSubjectDetails } from "../../../../services";
import "./SubjectDetails.scss";

function SubjectDetails() {
  const {
    backStatus,
    dataCrumbs,
    modalBack,
  } = useSubjectDetails()

  return (
    <>
      <ModalLink />
      <section className="subject-info">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} titleheader="Subject Details" />
            <div className="col-md-12 nav-back">
              <Buttons
                control={backStatus ? 'modalLink' : 'link'}
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : '/subject'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav className='navtabs-admin'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active"  >
                    Subject Info
                  </button>
                </div>
              </nav>

              <div className="tab-content tab-content-admin" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-subjectinfo" >
                  <SubjectInfo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default connect()(SubjectDetails)