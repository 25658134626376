import React from 'react'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import { Formik, Form } from 'formik'
import { useUserMemberEdit } from '../../../services'
import './UserEdit.scss'

function UserEdit() {
    const {
        statusOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmitSubject,
    } = useUserMemberEdit()

    return (
        <>
            <Toast />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitSubject}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formUserEdit formFormik">
                        <div className="tx-header5">General Information</div>
                        <div className="height48"></div>
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">ID</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='_id' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Role</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='role' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Email</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='email' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Username</div>
                                <div className="desc tx-ex-small neutral400">
                                This input can be in the form of <span className="tx-ex-small-po">NIK/NISN</span> if it is part of a school member.
                                </div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='username' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">Status</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' options={statusOptions} 
                                onInput={(e) => {
                                    changeBackStatus('status', e.target.value)
                                    formik.setFieldValue('status', e.target.value)
                                }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UserEdit