import React, { useEffect } from "react";
import Buttons from "../../../atoms/Buttons";
import FormikControl from "../../../atoms/Formik/FormikControl";
import { useDispatch, connect } from "react-redux";
import { Formik, Form } from "formik";
import { Delete, Edit } from "@mui/icons-material";

const AssessmentShowFooter = (props) => {
  const dispatch = useDispatch();
  const { data, submitAction } = props;

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  const initialValues = { orderContent: data.order };
  const onSubmit = (values) => {
    submitAction(values.orderContent, data._id);
    closeModal();
  };

  return (
    <>
      <div className="card-footer bg-secondary200 d-flex justify-content-between align-items-center tx-small">
        <p>{data.assessmentType.name}</p>
        <div className="d-flex align-items-center">
          <span className="tx-small neutral300 mr-16"> Order number</span>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                <FormikControl
                  control="input"
                  type="number"
                  name="orderContent"
                  addClass="isExSmall"
                  width="40px"
                  inputClass="hideArrow p-8 text-center"
                  onChange={(e) => {
                    formik.setFieldValue("orderContent", e.target.value);
                  }}
                />
              </Form>
            )}
          </Formik>
          <span className="mr-32 ml-32 secondary500">|</span>
          {props.status !== "inline" ? (
            <Buttons
              addClass="mr-24"
              btnExSmall
              btnWrapper
              btnIcon
              control="link"
              path={`/subtopic/assessment/${data.assessmentType.path}/${data.idsubtopic}/${data.assessmentType._id}?action=update&contentId=${data._id}`}
            >
              <Edit style={{ fontSize: "20px" }} />
            </Buttons>
          ) : (
            ""
          )}
          <Buttons
            control="modal"
            btnExSmall
            btnWrapper
            btnIcon
            target="MODAL_DELETE_ASSESSMENT"
            crud={{
              id: data._id,
              contentType: data.assessmentType.name,
            }}
          >
            <Delete style={{ fontSize: "20px" }} />
          </Buttons>
        </div>
      </div>
    </>
  );
};

export default connect()(AssessmentShowFooter);
