import React from 'react';
import { Footer, Navbar, TopicList } from '../../../components/organisms';

function Topics() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <TopicList />
        <Footer/>
      </>
  )
}

export default Topics;
