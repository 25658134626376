import React, { useEffect, useState } from "react";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import { useDispatch, connect } from "react-redux";
import { Formik, Form } from "formik";
import { Delete, Edit } from "@mui/icons-material";

const SubTopicDetailShowFooter = (props) => {
  const dispatch = useDispatch();
  const { data, order, submitAction } = props;
  const [dataOrder, setDataOrder] = useState([
    { value: "0", name: "-", disabled: true, selected: true },
  ]);

  useEffect(() => {
    const ordering = [{ value: "", name: "-", disabled: true, selected: true }];
    order.map((item, index) => {
      ordering.push({
        value: index + 1,
        name: index + 1,
        disabled: false,
        selected: false,
      });
    });
    setDataOrder([...ordering]);
  }, []);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  const initialValues = { orderContent: data.order };
  const onSubmit = (values) => {
    submitAction(values.orderContent, data.contentBlockId);
    closeModal();
  };

  return (
    <>
      <div className="card-footer bg-secondary200 d-flex justify-content-between align-items-center tx-small">
        <p>{props.title}</p>
        <div className="d-flex align-items-center">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                <FormikControl
                  control="input"
                  type="number"
                  name="orderContent"
                  addClass="isExSmall"
                  width="40px"
                  inputClass="hideArrow p-8 text-center"
                  onChange={(e) => {
                    formik.setFieldValue("orderContent", e.target.value);
                  }}
                />
              </Form>
            )}
          </Formik>
          <span className="mr-32 ml-32">|</span>
          {data.contentType.name.toLowerCase() !== "empty space" ? (
            <Buttons
              addClass="mr-24"
              btnExSmall
              btnWrapper
              btnIcon
              control="link"
              // control="exlink"
              // isBlank
              path={`/subtopic/editsubtopic/${data.contentType.path}/${data._id}`}
              name={""}
              onClick={() => {
                localStorage.setItem(
                  "CONTENT_TYPE",
                  JSON.stringify({
                    id: data._id,
                    name: data.contentType.name,
                    goal: "EDIT",
                  })
                );
              }}
            >
              <Edit style={{ fontSize: "20px" }} />
            </Buttons>
          ) : (
            ""
          )}
          <Buttons
            control="modal"
            btnExSmall
            btnWrapper
            btnIcon
            target="MODAL_DELETE_CONTENT"
            crud={{
              target: props.index + 1,
              id: data._id,
              contentType: data.contentType.name,
            }}
          >
            <Delete style={{ fontSize: "20px" }} />
          </Buttons>
        </div>
      </div>
    </>
  );
};

export default connect()(SubTopicDetailShowFooter);
