import React from 'react'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Buttons from '../../../atoms/Buttons'
import { Formik, Form } from 'formik'
import { useSchoolInfoEdit } from '../../../services'
import './SchoolInfoEdit.scss'

function SchoolInfoEdit() {
    const {
        statusOptions,
        institutionOptions,
        provinceOptions,
        cityOptions,
        changeProv,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmitSubject,
    } = useSchoolInfoEdit()

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitSubject}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formSchoolInfo formFormik">
                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">ID</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='_id' disabled />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">School Status</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' options={statusOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, "status", e.target.value)
                                        formik.setFieldValue("status", e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">School Name</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='e.g. SD Atlaz Belajar Bahasa'
                                    onInput={(e) => {
                                        changeBackStatus(formik, "name", e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">NPSN / School Code</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='npsn' placeholder='e.g. 12345678'
                                    onInput={(e) => {
                                        changeBackStatus(formik, "npsn", e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Fondations</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='institution' options={institutionOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, "institution", e.target.value)
                                        formik.setFieldValue("institution", e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po mb-1">Province</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='province' options={provinceOptions}
                                    onInput={(e) => {
                                        changeProv(e.target.value)
                                        changeBackStatus(formik, "province", e.target.value)
                                        formik.setFieldValue("province", e.target.value)
                                        formik.setFieldValue('city', '')
                                        formik.setFieldValue('regency', '')
                                    }} />
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='hidden' name='prov' placeholder='e.g. Banten' />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">City</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='regency'
                                    options={cityOptions}
                                    onInput={(e) => {
                                        changeBackStatus(formik, 'regency', e.target.value)
                                        formik.setFieldValue('regency', e.target.value)
                                        formik.setFieldValue('city', e.target[e.target.selectedIndex].innerText)
                                    }}
                                />
                                <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='hidden' name='city' placeholder='e.g. Tangerang' />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="row">
                            <div className="col-md-5 label-left">
                                <div className="tx-small-po">School Address</div>
                            </div>
                            <div className="col-md-7">
                                <FormikControl addClass="isTaSmall isMoExSmall" control='textarea' type='text' name='address' placeholder='e.g. Jl. Jalur Sutera Barat 38K'
                                    onInput={(e) => {
                                        changeBackStatus(formik, "address", e.target.value)
                                    }} />
                            </div>
                        </div>
                        <div className="height40"></div>

                        <div className="text-end">
                            <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default SchoolInfoEdit