import { NotInterested } from "@mui/icons-material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import RestAPI from "../../../../../config/RestAPI";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Toast from "../../../../atoms/Toast";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { ModalLink, TitleSection } from "../../../../molecules";
import { Helmet } from "react-helmet";
import "./SubTopicCodeEditor.scss";
const SubTopicCodeEditor = () => {
  const { id } = useParams();
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic);
  const idSubTopic = localStorage.getItem("ID_SUBTOPIC");
  const getWhereFrom = JSON.parse(localStorage.getItem("EDIT_SUBTOPIC"));
  const [initialValues, setInitialValues] = useState({
    subTopicId: "",
    contentTypeId: "",
    content: "",
    style: "",
    script: "",
  });

  const contentType = JSON.parse(localStorage.getItem("CONTENT_TYPE"));
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!idSubTopic) {
      navigate("/subtopic");
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config)
        .then((res) => {
          dispatch({ type: "API_EDIT_SUBTOPIC", newValue: res });
        })
        .catch((err) => {
          navigate("/notfound");
        });
      if (id) {
        RestAPI.find(`content/code/${contentType.id}`, config).then((res) => {
          console.log(res);
          setInitialValues({
            subTopicId: "",
            contentTypeId: res._id,
            content: res.content,
            style: res.style,
            script: res.script,
          });
        });
      }
      dispatch({
        type: "OPEN_MODAL",
        newValue: {
          status: false,
          target: "",
          crud: null,
          modal: "",
        },
      });
    }
  }, []);

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Code Editor", path: "" },
  ];

  const validationSchema = Yup.object({
    content: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!id) {
      values = {
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
        content: values.content,
        style: values.style,
        script: values.script,
      };
      RestAPI.post("content/code", values, config)
        .then((r) => {
          processSuccess("Created", "create");
        })
        .catch((err) => {
          processFailed("create");
          setSubmitting(false);
        });
    } else {
      values = {
        contentTypeId: id,
        content: values.content,
        style: values.style,
        script: values.script,
      };
      RestAPI.put(`content/code/${id}`, values, config)
        .then((r) => {
          processSuccess("Updated", "update");
        })
        .catch((err) => {
          console.log("error", err.response);
          processFailed("update");
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem(
      "EDIT_SUBTOPIC",
      JSON.stringify({
        ...getWhereFrom,
        toast: true,
        message: { title: message1, desc: message2 },
      })
    );
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  };

  const processFailed = (message) => {
    toast.error(
      contentToast(
        `Failed to ${message}!`,
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
  };

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="text-editor">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Code Editor" />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form>
                {
                  <div className="row">
                    <div className="col-12 mb-24 ">
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="textarea"
                        type="text"
                        name="style"
                        label="Style"
                        isLabel
                        rows={10}
                        placeholder="e.g. console.log('code')"
                      />
                    </div>
                    <div className="col-12 mb-24 ">
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="textarea"
                        type="text"
                        name="content"
                        label="HTML"
                        isLabel
                        rows={18}
                        placeholder="e.g. <div> Enter the code</div>"
                      />
                    </div>
                    <div className="col-12 mb-24 ">
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="textarea"
                        type="text"
                        name="script"
                        label="Script"
                        isLabel
                        rows={18}
                        placeholder="e.g. console.log('code')"
                      />
                    </div>
                  </div>
                }

                <div className="text-end btn-form mb-32">
                  <Buttons
                    control="modalLink"
                    addClass="py-8 mr-16 px-48"
                    btnOutline
                    btnRegular
                    btnMoExSmall
                    data={modalBack}
                    path="MODAL_LINK"
                  >
                    Cancel
                  </Buttons>
                  <Buttons
                    control="button"
                    type="submit"
                    name={id ? "Update" : "Save"}
                    addClass="py-8 px-60"
                    btnPrimary
                    btnRegular
                    btnMoExSmall
                    btnDisable={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default connect()(SubTopicCodeEditor);
