import React from 'react'
import { Footer, Navbar, UserMemberEdit } from '../../../components/organisms';

function EditUserMember() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <UserMemberEdit />
      <Footer />
    </>
  )
}

export default EditUserMember