import React from 'react';
import TitleSection from '../../../molecules/TitleSection';
import { connect } from 'react-redux'
import { ModalActiveLesson, ModalDeleteLesson, ModalEditLesson, TableLesson } from '../../../molecules';
import './LessonList.scss'

function LessonList() {
  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Lesson', path: '' }
  ]

  return (
      <>
        <section className="lesson-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Lesson List" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableLesson />
                <ModalEditLesson />
                <ModalDeleteLesson />
                <ModalActiveLesson />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(LessonList)
