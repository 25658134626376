import React, { useEffect } from 'react';
import TitleSection from '../../../molecules/TitleSection';
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { 
  ModalActiveSubtopic, ModalDeleteSubtopic, TableSubtopic 
} from '../../../molecules';
import './SubtopicList.scss'

function SubtopicList() {
  const inputContent = JSON.parse(localStorage.getItem('INPUT_CONTENT'))

  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Sub Topic', path: '' }
  ]

  if(inputContent) { localStorage.removeItem("INPUT_CONTENT"); }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({ type: 'BACK_STATUS', newValue: false })
    if(inputContent) { localStorage.removeItem("INPUT_CONTENT"); }
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }, [])

  return (
      <>
        <section className="subtopic-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Sub Topic List" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableSubtopic />
                <ModalDeleteSubtopic />
                <ModalActiveSubtopic />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(SubtopicList)
