import React from "react";
import { Field, Form, Formik } from "formik";
import FormikControl from "../../../atoms/Formik/FormikControl";
import "./Assessments-preview.scss";
const AssessmentFileUploadPreview = (props) => {
  const { data, index, formik, setFieldValue } = props;
  return (
    <>
      <div
        className={`assessments-content-preview [${index}].userAnswer.base64File`}
      >
        <div className="card-content ">
          <input
            className=" form-control"
            type="file"
            name={`[${index}].userAnswer.fakeFile`}
            onInput={(e) => {
              props.onSendProgress(`[${index}].userAnswer.base64File`);
              let file = e.target.files[0];
              let reader = new FileReader();
              reader.onloadend = () => {
                var result = reader.result;

                setFieldValue(`[${index}].userAnswer.base64File`, result);
              };
              reader.readAsDataURL(file);
            }}
          />
          <div className="tx-small neutral200">
            Format file : pdf, doc, docx
            <br />
            File size : &lt; 5mb
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentFileUploadPreview;
