import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function BtnExportCSV(props) {
    const {
        children, name, type, addClass, btnPrimary, btnSuccess, btnDanger, btnSecondary, btnWarning, btnInfo, btnGhost, btnOutline, btnWrapper, btnRegular, btnSmall, btnExSmall, btnTaSmall, btnMoSmall, btnTaExSmall, btnMoExSmall, btnIcon, btnDisable, isShadow, btnBlock, csvData, csvDataName, fileName, btnMoBlock, ...rest
    } = props

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, csvDataName, fileName) => {
        let dataSheets = []
        let objSheets = {}
        for (let i = 0; i < csvData.length; i++) {
            dataSheets.push(csvDataName[i])
            objSheets = {
                ...objSheets,
                [csvDataName[i]] : XLSX.utils.json_to_sheet(csvData[i])
            }
        }
        const wb = {
            Sheets: objSheets, 
            SheetNames: dataSheets
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const className = [""];
    className.push(addClass);
    if (btnPrimary) className.push("btnPrimary")
    if (btnSuccess) className.push("btnSuccess")
    if (btnDanger) className.push("btnDanger")
    if (btnSecondary) className.push("btnSecondary")
    if (btnWarning) className.push("btnWarning")
    if (btnInfo) className.push("btnInfo")
    if (btnGhost) className.push("btnGhost")
    if (btnOutline) className.push("btnOutline")
    if (btnWrapper) className.push("btnWrapper")
    if (btnRegular) className.push("btnRegular")
    if (btnSmall) className.push("btnSmall")
    if (btnExSmall) className.push("btnExSmall")
    if (btnTaSmall) className.push("btnTaSmall")
    if (btnMoSmall) className.push("btnMoSmall")
    if (btnTaExSmall) className.push("btnTaExSmall")
    if (btnMoExSmall) className.push("btnMoExSmall")
    if (btnIcon) className.push("btnIcon")
    if (btnDisable) className.push("disabled")
    if (isShadow) className.push("isShadow")
    if (btnBlock) className.push("btnBlock")
    if (btnMoBlock) className.push("btnMoBlock")

    return (
        <>
            <button className={className.join(" ")} type={type} onClick={() => exportToCSV(csvData, csvDataName, fileName)} {...rest}>
                {name}{children}
            </button>
        </>
    )
}

export default BtnExportCSV