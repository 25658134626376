import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Fondations Name',
        accessor: 'name',
        Placehoder: 'e.g. Atlaz',
        Filter: ColumnFilter
    },
    {
        Header: 'Total School',
        accessor: 'schoolList',
        Placehoder: 'e.g. 10 school',
        Filter: ColumnFilter,
    },
    {
        Header: 'Last Update',
        accessor: 'updatedAt',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Action',
        Cell: (props) => (
            <div className="d-flex" key={props.row.original._id}>              
                <Buttons control="modal" modal="DELETE" target="MODAL_DELETE_INSTITUTION" addClass="neutral200 mr-10" crud={props.row.original} btnExSmall btnGhost btnIcon><Delete className="icon-button" /></Buttons>
                
                <Buttons control="link" path={`/fondations/editfondations/${props.row.original._id}`} addClass="neutral200" btnExSmall btnGhost btnIcon ><Edit className="icon-button" /></Buttons>
            </div>
        )
    }
]