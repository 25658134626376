import RestAPI from '../../../config/RestAPI';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

function useCouponNew() {
    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APICoupon)
    const [arrCSV, setArrCSV] = useState([data])

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let arrNameCSV = ["data"];
    const exportToCSV = () => {
        let dataSheets = []
        let objSheets = {}
        for (let i = 0; i < arrCSV.length; i++) {
            dataSheets.push(arrNameCSV[i])
            objSheets = {
                ...objSheets,
                [arrNameCSV[i]] : XLSX.utils.json_to_sheet(arrCSV[i])
            }
        }
        const wb = {
            Sheets: objSheets, 
            SheetNames: dataSheets
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'CodeCouponsAll-Excel' + fileExtension);     
    }

    const initData = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: false })
        RestAPI.find('coupon/all', config).then(res => {
            setArrCSV([res])
            dispatch({ type: 'API_COUPON', newValue: [...res] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        initData()
    }, [])

    return {
        config,
        loading,
        data,
        initData,
        exportToCSV,
    }
}

export default useCouponNew