import React, { useEffect } from "react";
import "./MemberDetails.scss";
import RestAPI from '../../../../../config/RestAPI'
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import { MemberInfo, ModalLink } from '../../../../molecules';
import { connect, useSelector, useDispatch } from 'react-redux'
import { ArrowBack } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useMemberInfo } from "../../../../services";

function MemberDetails() {
    const {
        id,
        dataCrumbs,
        modalBack,
        backStatus,
    } = useMemberInfo()

    return (
        <>
            <ModalLink />
            <section className="subject-info">
                <div className="container-fluid">
                    <div className="row">
                        <Breadcrumbs data={dataCrumbs} titleheader="Subject Details" />
                        <div className="col-md-12 nav-back">
                            <Buttons
                                control={backStatus ? 'modalLink' : 'link'}
                                addClass="ps-0"
                                btnSmall
                                btnLink
                                data={modalBack} path={backStatus ? 'MODAL_LINK' : `/school/editschool/${id}` }
                            ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <nav className='navtabs-admin'>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active"  >
                                        Member Info
                                    </button>
                                </div>
                            </nav>

                            <div className="tab-content tab-content-admin" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-subjectinfo" >
                                    <MemberInfo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default connect()(MemberDetails)