import React from 'react'
import { EditSubtopic, Footer, Navbar } from '../../../components/organisms'

function SubtopicEdit() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <EditSubtopic />
      <Footer />
    </>
  )
}

export default SubtopicEdit