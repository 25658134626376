import React, { useEffect, useRef, useState } from "react";
import SubTopicDetailShowFooter from "./SubTopicDetailShowFooter";

const TextEditorShow = (props) => {
  const [data, setData] = useState(JSON.parse(props.data.contents));
  const contentRef = useRef();

  useEffect(() => {
    let imgTag = contentRef.current.querySelectorAll("img");
    imgTag.forEach((r) => {
      r.setAttribute("width", "100%");
      r.setAttribute("height", "100%");
      r.classList.add("radius-8");
    });
  });

  const createMarkup = () => {
    return { __html: data };
  };

  return (
    <>
      <div className="col-12 card-container mb-40">
        <div
          className="card-content"
          ref={contentRef}
          dangerouslySetInnerHTML={createMarkup()}
        ></div>

        <SubTopicDetailShowFooter
          title={props.data.contentType.name}
          data={props.data}
          order={props.order}
          index={props.index}
          submitAction={props.submitAction}
        />
      </div>
    </>
  );
};

export default TextEditorShow;
