import React from 'react'
import FormikControl from '../../../atoms/Formik/FormikControl'
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { useMemberEdit } from "../../../services";
import './MemberInfoEdit.scss'

function MemberInfoEdit() {
    const {
        classOptions,
        subjectOptions,
        initialValues,
        validationSchema,
        changeBackStatus,
        onSubmit,
    } = useMemberEdit()

    return (
        <>
            <Toast />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {formik => (
                    <Form className="formMemberInfoEdit formFormik">
                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">ID</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='_id' disabled />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">NISN / NIK</div>
                            <div className="desc tx-ex-small neutral400">
                                This input will be used as <span className="tx-ex-small-po">username</span>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='username' disabled />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Name</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' disabled />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Role</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='role' disabled />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po mb-1">Class / Academic Year</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='classlist' options={classOptions}
                                onInput={changeBackStatus}
                            />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Subject</div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='subject' options={subjectOptions}
                                onInput={(e) => {
                                    changeBackStatus()
                                    formik.setFieldValue('subject', e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="row">
                        <div className="col-md-5 label-left">
                            <div className="tx-small-po">Subject Expire Data <span className="tx-small-qu">(optional)</span></div>
                            <div className="desc tx-ex-small neutral400">
                                If the subject has reached expiration, the user cannot access this subject anymore.
                            </div>
                        </div>
                        <div className="col-md-7">
                            <FormikControl addClass="isTaSmall isMoExSmall" control='date' type="date" name='expiredSubject' value={initialValues.expiredSubject} placeholder='e.g. 2022-03-01' onInput={changeBackStatus}
                            />
                        </div>
                    </div>
                    <div className="height40"></div>

                    <div className="text-end">
                        <Buttons control="button" type="submit" name="Update" addClass='btnMoBlock btnCreateForm' btnRegular btnMoExSmall btnPrimary btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
                    </div>
                </Form>
                )}
            </Formik>
        </>
    )
}

export default connect()(MemberInfoEdit)