import React, { useMemo } from 'react';
import Buttons from '../../../atoms/Buttons';
import DropdownSelect from '../../../atoms/DropdownSelect';
import Toast from '../../../atoms/Toast';
import { useCouponList } from '../../../services';
import {
    ArrowBack,
    ArrowForward,
    FiberManualRecord,
    PlaylistAdd,
    Sort,
    VerticalAlignBottom
} from '@mui/icons-material';
import '../DataTables.scss';

function TableCoupons() {
    const {
        loading,
        data,        
        pagination,
        formik,
        arrCSV,
        arrNameCSV,
        handlePageClick,
        exportToCSV,
    } = useCouponList()

    return (
        <>
            <Toast />
            <div className="top-table d-flex align-items-center">
                <div className="left"></div>
                <div className="right ms-auto d-flex align-items-center">
                    <Buttons control="button" addClass="btnAdd text-center mr-16" onClick={exportToCSV} btnSmall btnWrapper isShadow ><VerticalAlignBottom /> <span className="d-none d-md-inline-block">Download Excel</span></Buttons>
                    <Buttons control="modal" modal="CREATE" target="MODAL_GENERATE_COUPONS" addClass="btnAdd text-center" btnSmall btnWrapper isShadow><PlaylistAdd /> Generate Coupons</Buttons>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table-course">
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Code Coupon</th>
                            <th>User</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (loading) ?
                                (data.length) ?
                                    data?.map((row, key) => {
                                        return (
                                            <tr key={`Row_${key}`}>
                                                <td width="40%">{row.subject?.name ? row.subject?.name : ''}</td>
                                                <td width="25%">{row.codeCoupon ? row.codeCoupon : ''}</td>
                                                <td width="25%">
                                                    {
                                                        (row.user && row.user.length > 0 ? row.user[0].name : '-')
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        row.status ? 
                                                        (<div className="status-active">Unregistered</div>) : 
                                                        (<div className="status-inactive">Registered</div>)
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="datanotfound text-center tx-small neutral200" colSpan="6">
                                            No data available
                                        </td>
                                    </tr>
                                :
                                <tr>
                                    <td className="datanotfound text-center tx-small neutral200" colSpan="6">
                                        Loading data...
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="navigation-table">
                <div className="tx-small-hl neutral300 d-none d-md-block">
                    Page {pagination.current_page} of {pagination.total_page}
                </div>
                <div className="pagination-table">
                    <div className="tx-small neutral300 your-page d-flex align-items-center">
                        You're in page {' '}
                        {/* <input type="number" className="numberpage" value={pagination.current_page}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        /> */}
                        <form onSubmit={formik.handleSubmit}>
                            <input type="number" id="topage" name="topage"
                                  className="numberpage"
                                  style={{ width: '50px' }}
                                  onChange={formik.handleChange}
                                  value={formik.values.topage}
                                />
                        </form>
                    </div>
                    <div className="divider-nav"></div>
                    <div style={{ marginRight: '24px' }}>
                        {
                          pagination.prev_page ?
                            (<Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => handlePageClick(pagination.current_page - 1)} name={<ArrowBack style={{ fontSize: "20px" }} />} />)
                            : (<Buttons control="button" btnSecondary isShadow btnExSmall btnIcon name={<ArrowBack style={{ fontSize: "20px" }} />} />)
                        }
                        
                    </div>
                    <div>
                        {
                          pagination.next_page ?
                            (<Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() =>handlePageClick(pagination.current_page + 1)} name={<ArrowForward style={{ fontSize: "20px" }} />} />)
                            : (<Buttons control="button" btnSecondary isShadow btnExSmall btnIcon name={<ArrowForward style={{ fontSize: "20px" }} />} />)
                        }
                        
                    </div>
                </div>
            </div>
        </>

    )
}

export default TableCoupons