export const dropdownAction = [
    { 
        name: "Active", 
        control: 'modal',
        path: "MODAL_ACTIVE_CLASS", 
        modal: 'ACTIVE',
        addClass: "" ,
    },
    { 
        name: "Inactive", 
        control: 'modal',
        path: "MODAL_ACTIVE_CLASS", 
        modal: 'INACTIVE',
        addClass: "" ,
    },
    // { 
    //     name: "Delete", 
    //     control: "modal",
    //     path: "MODAL_DELETE_CLASS", 
    //     modal: 'DELETE',
    //     addClass: "danger400 btnGhost",
    // },
]