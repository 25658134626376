import React, { useEffect, useState } from "react";
import "./SubTopicDetailPreview.scss";
import TextEditorPreview from "./TextEditorPreview";
import EmbedHtml5Preview from "./EmbedHtml5Preview";
import MultipleChoicePreview from "./MultipleChoicePreview";
import DialogAndNumberingPreview from "./DialogAndNumberingPreview";
import CompleteParahraphPreview from "./CompleteParahraphPreview";
import SingleChoicePreview from "./SingleChoicePreview";
import ShortAnswerPreview from "./ShortAnswerPreview";
import EssayPreview from "./EssayPreview";
import RestAPI from "../../../../../config/RestAPI";
import NavbarPreview from "../../../NavbarPreview";
import imgHeader1 from "../../../../../assets/images/detial-preview-bg-1.png";
import imgHeader2 from "../../../../../assets/images/detial-preview-bg-2.png";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DoubleArrowRounded } from "@mui/icons-material";
import EmptySpacePreview from "./EmptySpacePreview";
import CodeEditorPreview from "./CodeEditorPreview";
import MatchPairsPreview from "./MatchPairsPreview";

const SubTopicPreview = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataSubtopic = useSelector((state) => state.APIEditSubTopic);

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [dataContent, setDataContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };
  const showContent = (data, index) => {
    switch (data.contentType.name.toLowerCase()) {
      case "complete a paragraph":
        return <CompleteParahraphPreview data={data} />
      case "fill in the blank":
        return <DialogAndNumberingPreview data={data} />
      case "embed html5":
        return <EmbedHtml5Preview data={data} />;
      case "essay":
        return <EssayPreview data={data} />
      case "multiple choice":
        return <MultipleChoicePreview data={data} />
      case "short answer":
        return <ShortAnswerPreview data={data} />
      case "single choice":
        return <SingleChoicePreview data={data} />
      case "text editor":
        return <TextEditorPreview data={data} />;
      case "code editor":
        return <CodeEditorPreview data={data} />;
      case "match pairs":
        return <MatchPairsPreview data={data} />;
      case "empty space":
        return <EmptySpacePreview />;
      default:
        return;
    }
  };
  const initData = () => {
    setIsLoading(true);
    RestAPI.find(`content/preview?subTopicId=${id}`, config).then((res) => {
      setDataContent(res);
      setIsLoading(false);
    });
    RestAPI.find(`subtopic/${id}`, config)
      .then((res) => {
        dispatch({ type: "API_EDIT_SUBTOPIC", newValue: res });
      })
      .catch((err) => {
        navigate("/notfound");
      });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      <NavbarPreview subject={dataSubtopic?.subject.name} />
      <section className="sub-topic-detail-preview">
        <img src={imgHeader1} className="image-header1 w-100" alt="" />
        <img src={imgHeader2} className="image-header2 w-100" alt="" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-12">
              <div className="header-preview radius-14 bg-white py-16 px-24 mb-24">
                <div className="neutral300 mb-8">
                  {dataSubtopic?.topic.name}
                </div>
                <div className="tx-header3 ">{dataSubtopic?.name}</div>
              </div>

              <div className="preview-content  radius-8 ">
                <div className="content ">
                  {isLoading ? (
                    ""
                  ) : (
                    <div className="content">
                      {dataContent.map((data, index) => {
                        return (
                          <div key={index} className="">
                            {showContent(data, index)}
                          </div>
                        );
                      })}
                      {dataContent.length < 1 ? (
                        <p className="text-center">No data available</p>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="preview-footer  d-flex justify-content-center">
                <div className="pt-98 text-center">
                  <p
                    className=" tx-header5 text-strong cursor-pointer"
                    onClick={backToTop}
                  >
                    <DoubleArrowRounded className="rotate" />
                    <span className="d-block">Back To Top</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubTopicPreview;
