import React, { useMemo } from 'react';
import DropdownSelect from '../../../atoms/DropdownSelect';
import Buttons from '../../../atoms/Buttons'
import Toast from '../../../atoms/Toast'
import { useTable, useGlobalFilter, useFilters, usePagination, useRowSelect, useSortBy } from 'react-table'
import { COLUMNS } from './Columns'
import { GlobalFilter } from '../GlobalFilter'
import { ColumnFilter } from '../ColumnFilter'
import { Checkbox } from '../Checkbox'
import { dropdownAction } from './Dropdown'
import { format } from 'date-fns'
import { useUser } from "../../../services"
import {
    ArrowBack,
    ArrowForward,
    FiberManualRecord,
    Sort,
} from '@mui/icons-material';
import '../DataTables.scss'

function TableUser() {
    const {
        loading,
        data,
    } = useUser()

    const columns = useMemo(() => COLUMNS, [])
    const defaultColumn = useMemo(() => {
        return { Filter: ColumnFilter }
    }, [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
                    },
                    ...columns
                ]
            })
        })
    const { pageIndex, pageSize, globalFilter } = state

    return (
        <>
            <Toast />
            <div className="top-table d-flex align-items-center mt-32">
                <div className="left">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
                <div className="right ms-auto d-flex align-items-center">
                    {
                        (selectedFlatRows.length > 0) && <DropdownSelect isDivider isSmall divider={2} dropdownMenu="dropdown-menu-end bRadius8" dropId="actionTableUser" title="Action" list={dropdownAction} addClass="ms-md-3 ms-2" bgToogle="bg-primary400 brd-primary400 bRadius8" dataSelected={selectedFlatRows} width="110px" />
                    }
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table-course table-user">
                    <thead>
                        {
                            headerGroups.map((headerGroup, index) => {
                                return (
                                    <tr key={`Header_${index}`} {...headerGroup.getHeaderGroupProps()}>
                                        {
                                            headerGroup.headers.map((column, index2) => {
                                                return (
                                                    <th key={`Header_column_${index2}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        {index2 != 0 && index2 < headerGroup.headers.length - 1 && <span className="float-end">
                                                            {column.isSorted ? (column.isSortedDesc ? <Sort style={{ fontSize: '14px' }} /> : <Sort style={{ transform: 'rotate(180deg)', fontSize: '14px' }} />) : <Sort style={{ opacity: '0.3', fontSize: '14px' }} />} </span>}
                                                    </th>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        {

                            headerGroups.map((headerGroup2, i) => {
                                return (
                                    <tr key={`Filter_${i}`} {...headerGroup2.getHeaderGroupProps()} className="header-filtering">
                                        {
                                            headerGroup2.headers.map((column2, j) => {
                                                return <th key={`Filter_column_${j}`} {...column2.getHeaderProps()}><div>{column2.canFilter ? column2.render('Filter') : null}</div></th>
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            (loading) ?
                                (page.length != 0) ?
                                    page.map((row, index3) => {
                                        prepareRow(row)
                                        return (
                                            <tr key={`Row_${index3}`} {...row.getRowProps}>
                                                {
                                                    row.cells.map((cell, index4) => {
                                                        {
                                                            if (cell.column.Header === 'Status' && cell.value === true) {
                                                                return <td key={`Row_column_${index4}`} {...cell.getCellProps}>
                                                                    <div className="status-active"><FiberManualRecord style={{ fontSize: "6px" }} /> Active</div>
                                                                </td>
                                                            } else if (cell.column.Header === 'Status' && cell.value === false) {
                                                                return <td key={`Row_column_${index4}`} {...cell.getCellProps}>
                                                                    <div className="status-inactive">
                                                                        <FiberManualRecord style={{ fontSize: "6px" }} /> Inactive
                                                                    </div>
                                                                </td>
                                                            } else if (cell.column.Header === 'Class & Subject' && cell.value.length < 1) {
                                                                return <td key={`Row_column_${index4}`} {...cell.getCellProps} className='content'>
                                                                    -
                                                                </td>
                                                            } else if (cell.column.Header === 'Class & Subject' && cell.value && cell.value.length > 0) {
                                                                return <td key={`Row_column_${index4}`} {...cell.getCellProps} className='content py-16'>
                                                                    {
                                                                        cell.value.map((item, index5) => {
                                                                            return <div key={`Row_columnMember_${index5}`}>
                                                                                {item.classlist && `${item.classlist.name} - `}{`${item.subject !== null ? item.subject.name : ''} (Exp.${item.expiredSubject && format(new Date(item.expiredSubject), 'ddMMyy')})`}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </td>
                                                            } else {
                                                                return <td key={`Row_column_${index4}`} {...cell.getCellProps} className='content'>
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            }
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="datanotfound text-center tx-small neutral200" colSpan="9">
                                            No data available
                                        </td>
                                    </tr>
                                :
                                <tr>
                                    <td className="datanotfound text-center tx-small neutral200" colSpan="9">
                                        Loading data...
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="navigation-table">
                <div className="tx-small-hl neutral300 d-none d-md-block">
                    Page {' '} {pageIndex + 1} of {pageOptions.length}{' '}
                </div>
                <div className="pagination-table">
                    <div className="tx-small neutral300 your-page">
                        You're in page {' '}
                        <input type="number" className="numberpage" value={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </div>
                    <div className="divider-nav"></div>
                    <div style={{ marginRight: '24px' }}>
                        <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => previousPage()} btnDisable={!canPreviousPage} name={<ArrowBack style={{ fontSize: "20px" }} />} />
                    </div>
                    <div>
                        <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => nextPage()} btnDisable={!canNextPage} name={<ArrowForward style={{ fontSize: "20px" }} />} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default TableUser
