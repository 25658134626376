import React from "react";
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import Toast from "../../../../atoms/Toast";
import { connect } from 'react-redux'
import { useSubjectEdit } from '../../../../services'
import {
  ModalLink, ModalPutLesson, ModalPutSubtopic, ModalPutTopic, ModalRedirect, ModalUpdateLesson, ModalUpdateSubtopic, ModalUpdateTopic, QuickContent, SubjectInfoEdit
} from '../../../../molecules';
import {
  ArrowBack,
} from '@mui/icons-material';
import "./SubjectEdit.scss";

function SubjectEdit() {
  const {
    getWhereFrom,
    dataSubject,
    backStatus,
    tabs1,
    tabs2,
    tabsBody1,
    tabsBody2,
    dataCrumbs,
    modalBack,
  } = useSubjectEdit()

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalRedirect />
      <ModalUpdateLesson />
      <ModalPutLesson />
      <ModalUpdateTopic />
      <ModalPutTopic />
      <ModalUpdateSubtopic />
      <ModalPutSubtopic />
      <section className="subject-info">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} />
            <div className="col-md-12 nav-back">
              <Buttons
                control='modalLink'
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : 'MODAL_REDIRECT'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav className='navtabs-admin'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link ${tabs1}`}
                    id="nav-subjectinfo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-subjectinfo"
                    type="button"
                    role="tab"
                    aria-controls="nav-subjectinfo"
                    aria-selected="true"
                    onClick={() => {
                      localStorage.setItem("EDIT_SUBJECT", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'EDIT',
                      }));
                    }}
                  >
                    Subject Info
                  </button>
                  <button
                    className={`nav-link ${tabs2}`}
                    id="nav-addcontent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-addcontent"
                    type="button"
                    role="tab"
                    aria-controls="nav-addcontent"
                    aria-selected="false"
                    onClick={() => {
                      localStorage.setItem("EDIT_SUBJECT", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'CREATE',
                      }));
                    }}
                  >
                    Quick Add Content
                  </button>
                </div>
              </nav>

              <div className="tab-content tab-content-admin" id="nav-tabContent">
                <div
                  className={`tab-pane ${tabsBody1}`}
                  id="nav-subjectinfo"
                  role="tabpanel"
                  aria-labelledby="nav-subjectinfo-tab" >
                  <SubjectInfoEdit />
                </div>
                <div
                  className={`tab-pane ${tabsBody2}`}
                  id="nav-addcontent"
                  role="tabpanel"
                  aria-labelledby="nav-addcontent-tab" >
                  <QuickContent dataSubject={dataSubject} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default connect()(SubjectEdit)
