import React from 'react'
import Buttons from '../../../../atoms/Buttons';
import Breadcrumbs from '../../../../atoms/Breadcrumbs';
import { ArrowBack } from '@mui/icons-material';
import { ModalLink, ResourcesWorksheet } from '../../../../molecules';
import { useWorksheets } from '../../../../services';

function Worksheet() {
    const {
        backStatus,
        dataCrumbs,
        modalBack,
        styleTitle,
    } = useWorksheets()

    return (
        <>
            <ModalLink />
            <section className="school-list">
                <div className="container-fluid">
                    <div className="row mb-48">
                        <Breadcrumbs data={dataCrumbs} titleheader="Worksheet" />
                        <div className="col-md-12 mb-32 nav-back">
                            <Buttons
                                control={backStatus ? 'modalLink' : 'link'}
                                addClass="ps-0"
                                btnSmall
                                btnLink
                                data={modalBack} path={backStatus ? 'MODAL_LINK' : '/teacher-resources'}
                            ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
                        </div>
                        <div className="col-md-12">
                            <h5>Worksheet</h5>
                            <div style={styleTitle}></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ResourcesWorksheet />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Worksheet