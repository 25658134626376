import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';

function useQuickContent() {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
    const config = { headers: { token: "Bearer " + tokenAccess.accessToken } };

    const [isLoading, setIsLoading] = useState(false);
    const dataSubject = useSelector((state) => state.APIEditSubject)
    const dataLesson = useSelector((state) => state.APIQuickAddContent);

    const initData = () => {
        setIsLoading(true)
        RestAPI.find(`content/frame?subjectId=${id}`, config
        ).then((res) => {
            setIsLoading(false)
            dispatch({
                type: "API_QUICK_ADD_CONTENT",
                newValue: res[0].subject.lessons,
            });
        });
        setIsLoading(true)
    };

    useEffect(() => {
        setIsLoading(false)
        if (dataSubject) {
            initData();
        }

    }, [dataSubject]);

    const changeOrder = (id, value, target, title) => {
        const values = {
            _id: id,
            order: value,
        };
        RestAPI.put(`${target}/${id}`, values, config)
            .then((res) => {
                initData();
                toast.success(
                    contentToast(`${title} Updated!`, `Success to update the ${target}.`),
                    {
                        icon: (
                            <CheckCircleOutline
                                style={{ color: "var(--info500)", fontSize: "30px" }}
                            />
                        ),
                    }
                );
            })
            .catch(() => {
                toast.error(
                    contentToast(
                        "Failed to process",
                        "Your connection not stable. Try again."
                    ),
                    {
                        icon: (
                            <NotInterested
                                style={{ color: "var(--danger500)", fontSize: "30px" }}
                            />
                        ),
                    }
                );
            });
    };

    return {
        id,
        isLoading,
        dataSubject,
        dataLesson,
        initData,
        changeOrder,
    }
}

export default useQuickContent