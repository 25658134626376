import React, { useState, useEffect } from 'react';
import '../Modal.scss'
import RestAPI from '../../../../config/RestAPI'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import { useParams } from 'react-router';
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { connect, useSelector, useDispatch } from 'react-redux'
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalPutSubtopic() {
    const { id } = useParams()
    const statusMODAL = 'MODAL_PUT_SUBTOPIC'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        _id: 0,
        name: '',
        status: null,
    })

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if (isStatusMODAL.modal === 'REMOVE') {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    status: isStatusMODAL.crud.status,
                    topicId: '',
                })
            } else {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    status: (isStatusMODAL.modal === 'ACTIVE') ? true : false,
                })
                onSubmit({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    status: (isStatusMODAL.modal === 'ACTIVE') ? true : false,
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [isStatusMODAL])
    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = {
        headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
    }
    const onSubmit = values => {
        if (isStatusMODAL.modal === 'REMOVE') {
            RestAPI.put(`subtopic/${values._id}`, values, config).then(() => {
                RestAPI.find(`content/frame?subjectId=${id}`, config).then(res => {
                  dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: res[0].subject.lessons })
                })
                toast.success(contentToast(
                    "Sub Topic Removed!", "Success to remove the sub topic."), {
                    icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                })
                closeModal()
            })
                .catch(() => {
                    toast.error(contentToast(
                        "Failed to remove", "Your connection not stable. Try again."), {
                        icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                    })
                    closeModal()
                })
        } else {
            RestAPI.put(`subtopic/${values._id}`, values, config).then(() => {
                RestAPI.find(`content/frame?subjectId=${id}`, config).then(res => {
                  dispatch({ type: 'API_QUICK_ADD_CONTENT', newValue: res[0].subject.lessons })
                })
                toast.success(contentToast(
                    (isStatusMODAL.modal === 'ACTIVE') ? "Sub Topic Activated!" : "Sub Topic Inactivated!",
                    (isStatusMODAL.modal === 'ACTIVE') ? "Success change status to activated." : "Success change status to inactivated."), {
                    icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                })
                closeModal()
            })
                .catch((err) => {
                    toast.error(contentToast(
                        "Failed to change status", "Your connection not stable. Try again."), {
                        icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
                    })
                    closeModal()
                });
        }
    }

    if (isStatusMODAL.modal === 'REMOVE') {
        return (
            <>
                <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                    <div className="modal-content isNoForm">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit} >
                            {formik => (
                                <Form>
                                    <div className="modal-header">
                                        <h5 className="tx-header5">Remove Sub Topic</h5>
                                    </div>
                                    <div className="modal-body">
                                        <div className="message">
                                            <span className='tx-body-po'>
                                                {initialValues.name}
                                            </span> will be removed from this subject. You can still find it in the master sub topic.
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <Buttons control="button" btnExSmall btnOutline name="No" onClick={() => closeModal()} />
                                        <Buttons control="button" type="submit" name="Yes" addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal>
            </>
        )
    }
    return null
}

export default connect()(ModalPutSubtopic)