import React from "react";
import {
  Footer,
  GenerateList,
  Navbar,
} from "../../../components/organisms";

const GenerateCoupons = () => {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <GenerateList />
      <Footer />
    </>
  );
};

export default GenerateCoupons;
