import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from 'react-redux';
import TitleSection from '../../../molecules/TitleSection';
import RestAPI from "../../../../config/RestAPI";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import '../Style.scss'

function MatchPairs() {
    const dataCrumbs = [
        { name: 'Test', path: '/test' },
        { name: 'Base64', path: '/base64' },
        { name: 'Match Pairs', path: '' },
    ]

    const { id } = useParams();
    const [data, setData] = useState();

    const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
    const config = {
        headers: { token: "Bearer " + tokenAccess.accessToken },
    };

    const initData = () => {
        RestAPI.find(`base64/matchpairs/${id}`, config).then((res) => {
            setData(res)
        }).catch(err => {
            console.log(err)
        })
    };


    useEffect(() => {
        initData();
    }, []);

    return (
        <>
            <section className="base64-list">
                <div className="container-fluid">
                    <div className="row">
                        <TitleSection data={dataCrumbs} titleheader="Base64 Match Pairs" />
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                                {data?.options?.map((r, i) => {
                                    return (
                                        <div className="row  align-items-center" key={i}>
                                            <div className={` col-6 pl-12 `}>
                                                {r.question.includes("data:image/") ? (
                                                    <div className="d-flex align-items-center mb-16">
                                                        <div
                                                            style={{ width: "252px", height: "252px" }}
                                                            className=" d-flex align-items-center"
                                                        >
                                                            <img src={r.question} width="100%" />
                                                        </div>
                                                        <div
                                                            className={` radius-4 ml-16 d-flex justify-content-center align-items-center`}
                                                            style={{
                                                                height: "36px",
                                                                width: "52px",
                                                                backgroundColor: "#D4D7DB",
                                                            }}
                                                        >
                                                            <CircleOutlinedIcon
                                                                style={{
                                                                    fontSize: "18px",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="d-flex align-items-center mb-16 "
                                                        style={{ maxWidth: "320px" }}
                                                    >
                                                        <div
                                                            className="d-flex  align-items-center radius-tl-4 radius-bl-4 pl-16 justify-content-between w-full"
                                                            style={{
                                                                minHeight: "36px",
                                                                border: "1px solid var(--neutral100)",
                                                            }}
                                                        >
                                                            <span>{r.question}</span>
                                                            <span className="mr-16 text-danger">
                                                                {/* <HighlightOffRoundedIcon /> */}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={` d-flex align-items-center radius-tr-4 radius-br-4 justify-content-center `}
                                                            style={{
                                                                height: "36px",
                                                                backgroundColor: "#D4D7DB",
                                                                width: "60px",
                                                            }}
                                                        >
                                                            <CircleOutlinedIcon
                                                                style={{
                                                                    fontSize: "18px",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={`col-6 d-flex justify-content-end `}>
                                                {data.options[i].answer.includes("data:image/") ? (
                                                    <div className="d-flex align-items-center mb-16 ">
                                                        <div
                                                            className={`  radius-4  d-flex justify-content-center align-items-center `}
                                                            style={{
                                                                height: "36px",
                                                                width: "52px",
                                                                backgroundColor: "#D4D7DB",
                                                            }}
                                                        >
                                                            <CircleOutlinedIcon
                                                                style={{
                                                                    fontSize: "18px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                maxWidth: "252px",
                                                                maxHeight: "252px",
                                                            }}
                                                            className="ml-16 d-flex align-items-center"
                                                        >
                                                            <img src={data.options[i].answer} width="100%" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="d-flex align-items-center mb-16 w-full "
                                                        style={{ maxWidth: "320px" }}
                                                    >
                                                        <div
                                                            className={` d-flex align-items-center justify-content-center radius-tl-4 radius-bl-4 `}
                                                            style={{
                                                                height: "36px",
                                                                width: "60px",
                                                                backgroundColor: "#D4D7DB",
                                                            }}
                                                        >
                                                            <CircleOutlinedIcon
                                                                style={{
                                                                    fontSize: "18px",
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="d-flex  align-items-center radius-tr-4 radius-br-4 pl-16 justify-content-between w-full"
                                                            style={{
                                                                minHeight: "36px",
                                                                border: "1px solid var(--neutral100)",
                                                            }}
                                                        >
                                                            <span>{data.options[i].answer}</span>
                                                            <span className="mr-16 text-danger"></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect()(MatchPairs)