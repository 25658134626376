import React from 'react'
import { Footer, MemberEdit, Navbar } from '../../../components/organisms';

function EditMember() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <MemberEdit />
      <Footer />
    </>
  )
}

export default EditMember