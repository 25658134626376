import React from 'react'
import { Footer, Navbar, EditInstitutionList } from '../../../components/organisms';

function EditInstitution() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <EditInstitutionList />
      <Footer />
    </>
  )
}

export default EditInstitution