import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import RestAPI from "../../../../config/RestAPI";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { CheckCircleOutline, NotInterested } from "@mui/icons-material";

function useSchoolInfoEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const dataSchool = useSelector((state) => state.APIEditSchool);

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const statusOptions = [
    { key: "Active", value: true },
    { key: "Inactive", value: false },
  ];

  const [institutionOptions, setInstitutionOptions] = useState([
    { key: "Select fondations", value: "" },
  ]);

  const [provinceOptions, setProvinceOptions] = useState([
    { key: "Select province", value: "" },
  ]);
  const [cityOptions, setCityOptions] = useState([
    { key: "Select city", value: "" },
  ]);

  const [initialValues, setInitialValues] = useState({
    _id: "",
    status: true,
    name: "",
    npsn: "",
    institution: "",
    province: "",
    prov: "",
    city: "",
    regency: "",
    address: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    npsn: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    institution: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    province: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    regency: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
    address: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  const changeProv = (provinceId) => {
    RestAPI.find(`area/province/${provinceId}`, config).then((res) => {
      let data = [{ key: "Select city", value: "" }];
      res?.city?.map((cty) => {
        data.push({
          key: cty.name,
          value: cty._id,
        });
      });
      setCityOptions(data);
    });
  };

  const changeBackStatus = (formik, initial, value) => {
    const allInitial = {
      ...initialValues,
    };
    const newInitial = {
      ...formik.values,
      [initial]: value,
    };

    if (
      allInitial.status.toString() !== newInitial.status.toString() ||
      allInitial.name !== newInitial.name ||
      allInitial.npsn !== newInitial.npsn ||
      allInitial.province !== newInitial.province ||
      allInitial.city !== newInitial.city ||
      allInitial.address !== newInitial.address
    ) {
      dispatch({ type: "BACK_STATUS", newValue: true });
    } else {
      dispatch({ type: "BACK_STATUS", newValue: false });
    }
  };

  const onSubmitSubject = (values, { setSubmitting }) => {
    RestAPI.put(`school/${id}`, values, config)
      .then((res) => {
        RestAPI.find(`school/${id}`, config).then((res2) => {
          dispatch({ type: "API_EDIT_SCHOOL", newValue: res2 });
        });
        dispatch({ type: "BACK_STATUS", newValue: false });
        toast.success(
          contentToast("School Updated!", "Success to update the school."),
          {
            icon: (
              <CheckCircleOutline
                style={{ color: "var(--info500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          contentToast(
            "Failed to update",
            "Your connection not stable. Try again."
          ),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };

  useEffect(() => {
    RestAPI.find("area/province", config).then((res) => {
      let data = [{ key: "Select province", value: "" }];
      res.map((prv) => {
        data.push({
          key: prv.name,
          value: prv._id,
        });
      });
      setProvinceOptions(data);
    });
    RestAPI.find("institution", config).then((res) => {
      let data = [{ key: "Select fondations", value: "" }];
      res.map((ins) => {
        data.push({
          key: ins.name,
          value: ins._id,
        });
      });
      setInstitutionOptions(data);
    });
    if (dataSchool) {
      setInitialValues({
        _id: dataSchool._id,
        status: dataSchool.status,
        name: dataSchool.name,
        npsn: dataSchool.npsn,
        institution: dataSchool.institution?._id,
        province: dataSchool.province._id,
        city: dataSchool.city,
        regency: dataSchool.regency,
        address: dataSchool.address,
      });
      RestAPI.find(`area/province/${dataSchool.province._id}`, config).then(
        (res) => {
          let data = [{ key: "Select city", value: "" }];
          res?.city?.map((cty) => {
            data.push({
              key: cty.name,
              value: cty._id,
            });
          });
          setCityOptions(data);
        }
      );
    }
  }, [dataSchool]);

  return {
    id,
    dataSchool,
    statusOptions,
    institutionOptions,
    provinceOptions,
    cityOptions,
    changeProv,
    initialValues,
    validationSchema,
    changeBackStatus,
    onSubmitSubject,
  };
}

export default useSchoolInfoEdit;
