import { useEffect } from "react";

function ScrollToTop(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return props.children;
}

export default ScrollToTop;
