import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

function DatePicker(props) {
  const { label, name, placeholder, disabled, type, onClick, onChange, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, maxlength, onInput, width, hideArrow, ...rest } = props

  const className = ["formFormik"];
  const inputClassName = ["form-control"];
  className.push(addClass);
  inputClassName.push(inputClass);

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }
      <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
        {
          ({
            form, field, meta: { touched, error }
          }) => 
          {
            const { setFieldValue } = form
            const { value } = field
            return (
              <input 
                type={type}
                style={{width: width}} className={ touched && error ? inputClassName.join(" error") : inputClassName.join(" ") } 
                placeholder={placeholder} 
                onClick={onClick} 
                value={value}
                onChange={val => setFieldValue(name, val)} { ...field } 
              />
            )
          }
          
        }
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default DatePicker