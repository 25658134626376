import React, { useEffect, useRef, useState } from "react";
import "./SubTopicCKEditor.scss";
import * as Yup from 'yup'
import RestAPI from "../../../../../config/RestAPI";
import Buttons from "../../../../atoms/Buttons";
import Toast from "../../../../atoms/Toast";
import TextError from '../../../../atoms/Formik/TextError'
import { useParams } from 'react-router';
import { Editor } from "@tinymce/tinymce-react";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import { Formik, Form, ErrorMessage } from 'formik'
import { ModalLink, TitleSection } from "../../../../molecules";
import { NotInterested } from '@mui/icons-material';

const SubTopicCKEditor = () => {
  const { id } = useParams()
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)
  const idSubTopic = localStorage.getItem('ID_SUBTOPIC');
  const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SUBTOPIC'))
  const [initialValues, setInitialValues] = useState({
    subTopicId: '',
    contentTypeId: '',
    contents: '',
  })

  const contentType = JSON.parse(localStorage.getItem('CONTENT_TYPE'))
  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(!idSubTopic) {
      navigate("/subtopic")
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config).then(res => {
        dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res })
      }).catch((err) => {
        console.log(err.response)
        navigate("/notfound")
      });
      if(id) {
          RestAPI.find(`content/editor/${contentType.id}`, config).then(res => {
          setInitialValues({
            subTopicId: '',
            contentTypeId: res._id,
            contents: JSON.parse(res.contents),
          })
        })
      }
      dispatch({
        type: 'OPEN_MODAL', newValue: {
          status: false,
          target: '',
          crud: null,
          modal: '',
        }
      })
    }
  }, [])

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Text Editor", path: "" },
  ];

  const validationSchema = Yup.object({
    contents: Yup.string().required("This field is required. Don't leave it empty!"),
  })

  const editorRef = useRef(null);
  const inpFile = useRef();

  const onSubmit = (values, { setSubmitting }) => {
    if(!id) {
      values = {
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
        contents: JSON.stringify(values.contents),
      }
      RestAPI.post("content/editor", values, config)
        .then((r) => {
          processSuccess('Created', 'create')
        })
        .catch((err) => {
          processFailed('create')
          setSubmitting(false);
        });
    } else {
      values = {
        contentTypeId: id,
        contents: JSON.stringify(values.contents),
      }
      RestAPI.put(`content/editor/${id}`, values, config)
        .then((r) => {
          processSuccess('Updated', 'update')
        })
        .catch((err) => {
          console.log('error', err.response)
          processFailed('update')
          setSubmitting(false);
        });

    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
      ...getWhereFrom,
      toast: true,
      message: { title: message1, desc: message2}
    }));
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  }

  const processFailed = (message) => {
    toast.error(contentToast(`Failed to ${message}!`, "Your connection not stable. Try again."), {
      icon: (<NotInterested style={{ color: "var(--danger500)", fontSize: "30px" }}
      />
      ),
    });
  }

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel input',
    description: '',
    message: 'Continue to cancel input? Once canceled, any changes will be lost.',
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: 'Yes',
    footerNo: 'No',
  }

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="text-editor">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Text Editor" />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit} >
            {formik => (
              <Form>
                {
                  <div className="row">
                    <div className="col-12 mb-24 ">
                      <input type="file" ref={inpFile} hidden />
                      <Editor
                        name="contents"
                        initialValue={id ? initialValues.contents : ''}
                        apiKey='5bvlkdhnupz7sh6ea9qwoeyec1uh4uaa4yida68iaak00gfu'
                        onInit={(evt, editor) => {
                          return (editorRef.current = editor);
                        }}
                        onEditorChange={(value) => {
                          formik.setFieldValue("contents", value)
                        }}
                        onBlur={formik.handleBlur("contents")}
                        init={{
                          height: 500,
                          menubar: false,
                          placeholder: 'Create your content here',
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "styleselect | bold italic link align | bullist numlist outdent indent | image media code table | insertfile undo redo",
                          image_advtab: true,

                          file_picker_callback: function (callback, value, meta) {
                            inpFile.current.click();
                            if (meta.filetype === "image") {
                              inpFile.current.addEventListener("change", (ev) => {
                                var file = ev.target.files[0];
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                  callback(reader.result, {
                                    alt: "",
                                  });
                                };
                                reader.readAsDataURL(file);
                              });
                            }
                          },
                        }}
                      />
                      <ErrorMessage component={TextError} name="contents" />
                    </div>
                  </div>
                }

                <div className="text-end btn-form mb-32">
                  <Buttons
                    control="modalLink"
                    addClass="py-8 mr-16 px-48"
                    btnOutline btnRegular btnMoExSmall
                    data={modalBack} path="MODAL_LINK">Cancel</Buttons>
                  <Buttons
                    control='button'
                    type="submit"
                    name={id ? 'Update' : 'Save'}
                    addClass='py-8 px-60'
                    btnPrimary btnRegular btnMoExSmall
                    btnDisable={formik.isSubmitting} disabled={formik.isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
};

export default connect()(SubTopicCKEditor);