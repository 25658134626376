import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import '../Modal.scss'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import { useInstitutionList } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalCreateInstitution() {
    const { config, initData } = useInstitutionList()    
    const statusMODAL = 'MODAL_CREATE_INSTITUTION'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        name: '',
    })

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            setInitialValues({
                name: '',
            })
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
    })
    
    const onSubmit = values => {
        RestAPI.post('institution/create', values, config).then(res => {
            reloadTable('Created', 'create')
        })
        .catch((err) => {
            failedProcess()
        });
    }
    const reloadTable = (mess1, mess2) => {
        initData()
        toast.success(contentToast(
            `Fondations ${mess1}!`, `Success to ${mess2} the fondations.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = () => {
        toast.error(contentToast(
            "Failed to update", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                            <div className="modal-header">
                                                <h5 className="tx-header5">Create Fondations</h5>
                                                <p className="tx-small neutral300">Fill all input to create new fondations</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='Input fondations name' label="Fondations name" isLabel />
                                                    </div>
                                                </div>
                                            </div>

                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name='Create' addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default ModalCreateInstitution