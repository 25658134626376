import React from "react";
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import Toast from "../../../../atoms/Toast";
import { connect } from 'react-redux'
import { useSchoolEdit } from "../../../../services";
import { ModalLink, ModalRedirect, SchoolInfoEdit, TableClass, TableMember } from '../../../../molecules';
import {
  ArrowBack,
} from '@mui/icons-material';
import "./SchoolEdit.scss";

function SchoolEdit() {
  const {
    getWhereFrom,
    backStatus,
    tabs1,
    tabs2,
    tabs3,
    tabsBody1,
    tabsBody2,
    tabsBody3,
    dataCrumbs,
    modalBack,
  } = useSchoolEdit()

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalRedirect />
      <section className="school-info">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} />
            <div className="col-md-12 nav-back">
              <Buttons
                control='modalLink'
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : 'MODAL_REDIRECT'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav className='navtabs-admin'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link ${tabs1}`}
                    id="nav-schoolinfo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-schoolinfo"
                    type="button"
                    role="tab"
                    aria-controls="nav-schoolinfo"
                    aria-selected="true" 
                    onClick={() => {
                      localStorage.setItem("EDIT_SCHOOL", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'EDIT',
                      }));
                    }}
                  >
                    School Info
                  </button>
                  <button
                    className={`nav-link ${tabs2}`}
                    id="nav-classinfo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-classinfo"
                    type="button"
                    role="tab"
                    aria-controls="nav-classinfo"
                    aria-selected="false"
                    onClick={() => {
                      localStorage.setItem("EDIT_SCHOOL", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'CREATE_CLASS',
                      }));
                    }}
                  >
                    Class Info
                  </button>
                  <button
                    className={`nav-link ${tabs3}`}
                    id="nav-schoolmember-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-schoolmember"
                    type="button"
                    role="tab"
                    aria-controls="nav-schoolmember"
                    aria-selected="false"
                    onClick={() => {
                      localStorage.setItem("EDIT_SCHOOL", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'CREATE_MEMBER',
                      }));
                    }}
                  >
                    School Member
                  </button>
                </div>
              </nav>

              <div className="tab-content tab-content-admin" id="nav-tabContent">
                <div
                  className={`tab-pane ${tabsBody1}`}
                  id="nav-schoolinfo"
                  role="tabpanel"
                  aria-labelledby="nav-schoolinfo-tab" >
                  <SchoolInfoEdit />
                </div>
                <div
                  className={`tab-pane ${tabsBody2}`}
                  id="nav-classinfo"
                  role="tabpanel"
                  aria-labelledby="nav-classinfo-tab" >
                  <TableClass />
                </div>
                <div
                  className={`tab-pane ${tabsBody3}`}
                  id="nav-schoolmember"
                  role="tabpanel"
                  aria-labelledby="nav-schoolmember-tab" >
                  <TableMember />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default connect()(SchoolEdit)
