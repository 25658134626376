import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'
import '../Formik.scss'

function Input(props) {
  const { label, name, type, placeholder, icongroup, onClick, onInput, addClass, inputClass, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, isLabel, ...rest } = props

  const className = ["formFormik"];
  const inputClassName = ["form-control"];
  className.push(addClass);
  inputClassName.push(inputClass);

  if (isShadow) className.push("isShadow")
  if (isSmall) className.push("isSmall")
  if (isExSmall) className.push("isExSmall")
  if (isTaSmall) className.push("isTaSmall")
  if (isTaExSmall) className.push("isTaExSmall")
  if (isMoSmall) className.push("isMoSmall")
  if (isMoExSmall) className.push("isMoExSmall")

  return (
    <div className={className.join(" ")}>
      {
        isLabel && <label htmlFor={name} className="form-label tx-small-po">{label}</label>
      }

      <div className="input-group">
        <span className="input-group-text" id={name}>{icongroup}</span>
        <Field className={inputClassName.join(" ")} id={name} name={name} {...rest} >
          {
            ({
              field, meta: { touched, error }
            }) => <input className={touched && error ? inputClassName.join(" error") : inputClassName.join(" ")} type={type} placeholder={placeholder} onClick={onClick} onInput={onInput} {...field} />
          }
        </Field>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Input