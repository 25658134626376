import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import SearchBar from '../../atoms/SearchBar'

export const GlobalFilter = ({ filter, setFilter}) => {
    const [value, setValue] = useState(filter)

    const onChange = useAsyncDebounce( value => {
        setFilter(value || undefined)
    }, 1000)

    return (
        <SearchBar isSearchIcon addClass="global-search" isExSmall isShadow label="Search anything here" value={value || ''} 
        onChange={ (e) => {
            setValue(e.target.value)
            onChange(e.target.value)
        }}/>
    )
}
