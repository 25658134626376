import React from 'react'
import { Link } from 'react-router-dom';

function BtnLink(props) {
  const { 
    children, name, path, addClass, btnPrimary, btnSuccess, btnDanger, btnSecondary, btnWarning, btnInfo, btnGhost, btnOutline, btnWrapper, btnLink, btnRegular, btnSmall, btnExSmall, btnTaSmall, btnTaExSmall, btnMoSmall, btnMoExSmall, btnIcon, isShadow, btnBlock, btnMoBlock, ...rest 
  } = props

    const className = [""];
    className.push(addClass);
    if(btnPrimary) className.push("btnPrimary")
    if(btnSuccess) className.push("btnSuccess")
    if(btnDanger) className.push("btnDanger")
    if(btnSecondary) className.push("btnSecondary")
    if(btnWarning) className.push("btnWarning")
    if(btnInfo) className.push("btnInfo")
    if(btnGhost) className.push("btnGhost")
    if(btnOutline) className.push("btnOutline")
    if(btnWrapper) className.push("btnWrapper")
    if(btnLink) className.push("btnLink")
    if(btnRegular) className.push("btnRegular")
    if(btnSmall) className.push("btnSmall")
    if(btnExSmall) className.push("btnExSmall")
    if(btnTaSmall) className.push("btnTaSmall")
    if(btnTaExSmall) className.push("btnTaExSmall")
    if(btnMoSmall) className.push("btnMoSmall")
    if(btnMoExSmall) className.push("btnMoExSmall")
    if(btnIcon) className.push("btnIcon")
    if(isShadow) className.push("isShadow")
    if(btnBlock) className.push("btnBlock")
    if(btnMoBlock) className.push("btnMoBlock")

    return (
      <>
        <Link className={className.join(" ")} to={path} {...rest} >{name}{children}</Link>
      </>
    )
}

export default BtnLink;