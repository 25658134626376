import React from 'react'

export const Checkbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <>
            <div className="form-check d-flex align-items-center">
                <input type="checkbox" className="checkbox-table" ref={resolvedRef} {...rest} />
            </div>
        </>
    )
})