import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import Buttons from "../../../../atoms/Buttons";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Toast from "../../../../atoms/Toast";
import { ModalLink, TitleSection } from "../../../../molecules";
import { useAssessmentEssay } from "../../../../services";

const EssayAssessment = () => {
  const {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
  } = useAssessmentEssay();

  return (
    <>
      <Toast />
      <ModalLink />
      <section className="essay">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Essay" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <div className="col-12 p-40 bg-secondary200 radius-14 mb-24">
                    <div className="col mb-32">
                      <label htmlFor="" className="tx-small">
                        <span className="text-strong ">Question</span>
                      </label>
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="textarea"
                        type="text"
                        name={`question`}
                        placeholder="Enter the question here"
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="" className="tx-small">
                        <span className="text-strong">Upload image</span>
                        <i>(Optional)</i>
                      </label>
                      <FormikControl
                        addClass="isTaSmall isMoExSmall"
                        control="inputbase64"
                        type="text"
                        name={`fakeImage`}
                        base64Name={`image`}
                      />
                    </div>
                  </div>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={action !== "update" ? "Save" : "Update"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect()(EssayAssessment);
