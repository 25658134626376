import React from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'

function BtnModalLink(props) {
  const { 
    children, name, path, data, addClass, btnPrimary, btnSuccess, btnDanger, btnSecondary, btnWarning, btnInfo, btnGhost, btnOutline, btnWrapper, btnLink, btnRegular, btnSmall, btnExSmall, btnTaSmall, btnTaExSmall, btnMoSmall, btnMoExSmall, btnIcon, btnDisable, isShadow, ...rest 
  } = props
  
  const className = [""];
  className.push(props.addClass);
  if(props.btnPrimary) className.push("btnPrimary")
  if(props.btnSuccess) className.push("btnSuccess")
  if(props.btnDanger) className.push("btnDanger")
  if(props.btnSecondary) className.push("btnSecondary")
  if(props.btnWarning) className.push("btnWarning")
  if(props.btnInfo) className.push("btnInfo")
  if(props.btnGhost) className.push("btnGhost")
  if(props.btnOutline) className.push("btnOutline")
  if(props.btnWrapper) className.push("btnWrapper")
  if(props.btnLink) className.push("btnLink")
  if(props.btnRegular) className.push("btnRegular")
  if(props.btnSmall) className.push("btnSmall")
  if(props.btnExSmall) className.push("btnExSmall")
  if(props.btnTaSmall) className.push("btnTaSmall")
  if(props.btnTaExSmall) className.push("btnTaExSmall")
  if(props.btnMoSmall) className.push("btnMoSmall")
  if(props.btnMoExSmall) className.push("btnMoExSmall")
  if(props.btnIcon) className.push("btnIcon")
  if(props.btnDisable) className.push("disabled")
  if(props.isShadow) className.push("isShadow")

  const dispatch = useDispatch()
  const openModal = () => {
    dispatch({ type: "OPEN_MODAL", newValue: {
        status: true,
        target: path,
        crud:  data,
        modal: '',
    } })
  }

  return (
    <>
      <button type="button" className={className.join(" ")} onClick={() => openModal()} {...rest} >
        {children}
      </button>
    </>
  )
}

export default BtnModalLink