import React, { useEffect, useState } from 'react'
import RestAPI from '../../../../config/RestAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast';
import { CheckCircleOutline } from '@mui/icons-material';

function useSubjectEdit() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SUBJECT'))
    const dataSubject = useSelector((state) => state.APIEditSubject)
    const backStatus = useSelector((state) => state.BackStatus)
    const [tabs1, setTabs1] = useState('active')
    const [tabs2, setTabs2] = useState('')
    const [tabsBody1, setTabsBody1] = useState('show active')
    const [tabsBody2, setTabsBody2] = useState('')

    const dataCrumbs = [
        { name: "Dashboard", path: "/dashboard" },
        { name: "Subject", path: "/subject" },
        { name: dataSubject?.name, path: "" },
    ];

    const modalBack = {
        isForm: false,
        isNoForm: true,
        title: 'Cancel updating subject',
        description: '',
        message: 'Once canceled, your inputed data will be lost.',
        path: '/subject',
        footerYes: 'Yes',
        footerNo: 'No',
    }

    useEffect(() => {
        RestAPI.find(`subject/get/single/${id}`, config).then(res => {
            dispatch({ type: 'API_EDIT_SUBJECT', newValue: res })
        }).catch((err) => {
            navigate("/notfound")
        });
        if (getWhereFrom) {
            if (getWhereFrom.goal === 'CREATE') {
                setTabs1('')
                setTabsBody1('')
                setTabs2('active')
                setTabsBody2('show active')
                if (getWhereFrom.toast) {
                    toast.success(contentToast(
                        "Subject Created!", "Success to create the subject."), {
                        icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
                    })
                }
                localStorage.setItem("EDIT_SUBJECT", JSON.stringify({
                    ...getWhereFrom,
                    toast: false,
                }));
            } else {
                setTabs1('active')
                setTabsBody1('show active')
                setTabs2('')
                setTabsBody2('')
            }
        } else {
            setTabs1('active')
            setTabsBody1('show active')
            setTabs2('')
            setTabsBody2('')
        }
        dispatch({ type: 'BACK_STATUS', newValue: false })
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }, [])

    return {
        config,
        getWhereFrom,
        dataSubject,
        backStatus,
        tabs1,
        tabs2,
        tabsBody1,
        tabsBody2,
        dataCrumbs,
        modalBack,
    }
}

export default useSubjectEdit