import { ColumnFilterMember } from "./ColumnFilterMember";
import { Delete, Edit } from "@mui/icons-material";
import Buttons from "../../../atoms/Buttons";

export const COLUMNS = [
  {
    Header: "NISN / NIK",
    accessor: "NISN",
    Placehoder: "e.g. 123456",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Name",
    accessor: "name",
    Placehoder: "e.g. User 01",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Role",
    accessor: "roleName",
    Placehoder: "e.g. Public",
    Filter: ColumnFilterMember,
  },
  {
    Header: "School",
    accessor: "schoolName",
    Placehoder: "e.g. SD Atlaz",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Class",
    accessor: "className",
    Placehoder: "e.g. Class 01",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Subject",
    accessor: "subjectName",
    Placehoder: "e.g. English",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Expired Subject",
    accessor: "expiredSubject",
    Placehoder: "e.g. 2022-12-12",
    Filter: ColumnFilterMember,
  },
  {
    Header: "Error Status",
    accessor: "errors",
    Placehoder: "e.g. Valid",
    Filter: ColumnFilterMember,
    disableFilters: true,
  },
];
