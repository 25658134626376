import React, { useState } from "react";
import AssessmentShowFooter from "./AssessmentShowFooter";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
const AssessmentMultipleChoiceShow = (props) => {
  const abjads = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const [data, setData] = useState(props.data);

  return (
    <div className="assessments-content-show">
      <div className="col-12 card-container mb-40">
        <div className="card-content">
          {data.instruction ? (
            <p className="tx-header5 mb-16">{data.instruction}</p>
          ) : (
            ""
          )}

          <div className="question ">
            <div>
              {data.questions.map((d, i) => {
                return (
                  <React.Fragment key={i}>
                    {d.image ? (
                      <div className="text-center ">
                        <img src={d.image} alt="" width="100%" />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={`${i > 0 ? "mt-16" : "mt-8"}`}>
                      <span>
                        {props.nomor}. {d.question}
                      </span>

                      {d.options.map((r, i) => {
                        return (
                          <div className="my-8 ml-12" key={i}>
                            <label className=" rounded mr-8">
                              <input
                                type="checkbox"
                                name="value"
                                hidden
                                className="radio-custom-multiple"
                              />
                              <div className="custom align-items-center text-center rounded ps-2">
                                <div className="mr-3 text-center">
                                  {abjads[i]}.
                                </div>
                              </div>
                            </label>
                            {r.text}
                          </div>
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <AssessmentShowFooter data={data} submitAction={props.submitAction} />
      </div>
    </div>
  );
};
export default AssessmentMultipleChoiceShow;
