import { ColumnFilter } from '../ColumnFilter'
import { Delete, Edit } from '@mui/icons-material';
import Buttons from '../../../atoms/Buttons';

export const COLUMNS = [
    {
        Header: 'Book',
        accessor: 'subject',
        Placehoder: 'e.g. English Play 01',
        Filter: ColumnFilter
    },
    {
        Header: 'Code Coupon',
        accessor: 'codeCoupon',
        Placehoder: 'e.g. ATLAZ123',
        Filter: ColumnFilter
    },
    {
        Header: 'Status',
        accessor: 'status',
        Placehoder: 'e.g. Active',
        Filter: ColumnFilter,
    },
    {
        Header: 'User',
        accessor: 'user',
        Placehoder: 'e.g. Atlaz',
        Filter: ColumnFilter
    },
    {
        Header: 'Date Created',
        accessor: 'date_created',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
    {
        Header: 'Date Used',
        accessor: 'date_used',
        Placehoder: 'e.g. 2022-01-01',
        Filter: ColumnFilter
    },
]