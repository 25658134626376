import React from 'react'
import { ModalGenerateCoupons, TableCouponsNew, TitleSection } from '../../../molecules'
import { useCouponList } from '../../../services'

function CouponsList() {
  const {
    dataCrumbs,
  } = useCouponList()

  return (
    <section className="school-list">
      <div className="container-fluid">
        <div className="row">
          <TitleSection data={dataCrumbs} titleheader="Coupon List" />
        </div>
        <div className="row">
          <div className="col-md-12">
            <TableCouponsNew />
            <ModalGenerateCoupons />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CouponsList