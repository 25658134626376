import React from 'react'
import { Footer, Navbar, SubjectEdit } from '../../../components/organisms';

function EditSubject() {  
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <SubjectEdit />
      <Footer />
    </>
  )
}

export default EditSubject