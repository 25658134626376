import React from 'react';
import './SearchBar.scss'
import SearchIcon from '@mui/icons-material/Search';

function SearchBar(props) {
    const { label, onChange, value, addClass, isSearchIcon, isShadow, isSmall, isExSmall, isTaSmall, isTaExSmall, isMoSmall, isMoExSmall, ...rest } = props

    const className = ["inputSearch"];
    className.push(addClass);
    if(isSearchIcon) className.push("has-search")
    if(isShadow) className.push("isShadow")
    if(isSmall) className.push("isSmall")
    if(isExSmall) className.push("isExSmall")
    if(isTaSmall) className.push("isTaSmall")
    if(isTaExSmall) className.push("isTaExSmall")
    if(isMoSmall) className.push("isMoSmall")
    if(isMoExSmall) className.push("isMoExSmall")

    return (
        <>
            <div className={className.join(" ")}>
                {
                    isSearchIcon && <span className="form-control-feedback"><SearchIcon className="icon-search" /></span>
                }
                <input type="text" className="form-control" placeholder={label} onChange={onChange} value={value} />
            </div>
        </>
    )
}

export default SearchBar;