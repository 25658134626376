import React from 'react';
import './Tooltips.scss'
import ReactTooltip from 'react-tooltip';

function Tooltips(props) {
    const { id, place, icon, data } = props
    return (
        <>
            <span data-tip className="tooltips-hover" data-for={id}>{icon}</span>

            <ReactTooltip id={id} place={place} className="react-tooltips" >
                <div className="tx-small-po neutral50">{data?.title}</div>
                <div className="tx-ex-small neutral50">{data?.desc}</div>
                <ul className="tx-ex-small neutral50 ml-16">
                    {
                        data && data?.list.map((item, index) => {
                            return <li key={index}>{item.text}</li>
                        })
                    }
                </ul>
            </ReactTooltip>
        </>
    )
}

export default Tooltips