import React from 'react';
import { Base64ShoirtAnswers, Footer, Navbar } from '../../components/organisms';

function ContentShortAnswers() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64ShoirtAnswers />
        <Footer/>
      </>
  )
}

export default ContentShortAnswers;
