import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import FormikControl from "../../../atoms/Formik/FormikControl";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import "./Assessments-preview.scss";
const AssessmentSingleChoicePreview = (props) => {
  const { data, formik, nomor } = props;

  const addProgress = () => {};

  return (
    <>
      <div className="assessments-content-preview ">
        <div className="card-container  ">
          <div className="card-content">
            {data.questions.map((r, i) => {
              return (
                <React.Fragment key={i}>
                  {r.image ? (
                    <div className="text-center mb-24">
                      <img src={r.image} alt="" width="100%" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row gx-0">
                    <div className="col-12  mb-16">
                      {nomor}. {r.question}
                    </div>
                    {r.options.map((res, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <div className=" col-12">
                            <div
                              className={`${
                                r.options.length !== ind + 1 ? "mb-8 " : ""
                              } ${props.index}.questions[${i}].userAnswer`}
                            >
                              <label className=" rounded mr-8 w-full">
                                <Field
                                  type="radio"
                                  name={`${props.index}.questions[${i}].userAnswer`}
                                  value={`${ind}`}
                                  className={`radio-custom `}
                                  onInput={(e) => {
                                    let setIsfilled = false;
                                    let formikValue =
                                      props.formik.getFieldProps(
                                        `${props.index}.questions[${i}].userAnswer`
                                      );
                                    if (formikValue.value.length > 0) {
                                      setIsfilled = true;
                                    }
                                    props.onSendProgress(
                                      `${props.index}.questions[${i}].userAnswer`,
                                      setIsfilled
                                    );
                                  }}
                                />
                                <div className="custom single-option d-flex justify-content-between align-items-center px-24 py-16 radius-14 w-full">
                                  <span>{res.text}</span>{" "}
                                  <span className="isChecked">
                                    <CheckCircleRoundedIcon
                                      style={{ color: "var(--success600)" }}
                                    />
                                  </span>
                                  <span className="noChecked">
                                    <RadioButtonUncheckedRoundedIcon
                                      style={{ color: "var(--neutral200)" }}
                                    />
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentSingleChoicePreview;
