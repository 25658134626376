import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import RestAPI from "../../../../config/RestAPI";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { NotInterested } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
const useAssessmentTextEditor = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let action = searchParams.get("action");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [responseSubtopic, setResponseSubtopic] = useState({});
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)

  const dataCrumbs = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Sub Topic", path: "/subtopic" },
    {
      name: dataSubTopic?.name,
      path: `/subtopic/editsubtopic/${params.idsubtopic}`,
    },
    { name: "Text Editor", path: "" },
  ];

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${params.idsubtopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const [initialValues, setInitialValues] = useState({
    subTopicId: params.idsubtopic,
    assessmentCategory: responseSubtopic.assessmentType,
    assessmentTypeId: params.idassessmenttype,
    contents: "",
  });

  const validationSchema = Yup.object({
    contents: Yup.string().required(
      "This field is required. Don't leave it empty!"
    ),
  });

  useEffect(() => {
    if (action !== "create" && action !== "update") {
      navigate("/page-not-found");
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    RestAPI.find("subtopic/" + params.idsubtopic, config)
      .then((r) => {
        setResponseSubtopic(r);
        setInitialValues({
          ...initialValues,
          assessmentCategory: r.assessmentType,
        });
        if (action === "update") {
          let contentId = searchParams.get("contentId");
          RestAPI.find("assessment/" + contentId, config)
            .then((r) => {
              setInitialValues({ ...initialValues, contents: r.contents });
            })
            .catch((err) => {
              navigate("/page-not-found");
            });
        }
      })
      .catch((err) => {
        navigate("/page-not-found");
      });
  };

  const createSubmit = (req, setSubmitting) => {
    RestAPI.post("assessment", req, config)
      .then((r) => {
        localStorage.setItem(
          "atlazAssessmentToast",
          JSON.stringify({
            show: true,
            status: "success",
            title: "Content created!",
            message: "Success to create content",
          })
        );
        navigate(`/subtopic/editsubtopic/${params.idsubtopic}`);
      })
      .catch((err) => {
        toast.error(
          contentToast("Failed to create content!", err.response.data.message),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };

  const updateSubmit = (req, setSubmitting) => {
    let contentId = searchParams.get("contentId");
    RestAPI.put("assessment/" + contentId, req, config)
      .then((r) => {
        localStorage.setItem(
          "atlazAssessmentToast",
          JSON.stringify({
            show: true,
            status: "success",
            title: "Content updated!",
            message: "Success to update content",
          })
        );
        navigate(`/subtopic/editsubtopic/${params.idsubtopic}`);
      })
      .catch((err) => {
        toast.error(
          contentToast("Failed to create content!", err.response.data.message),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    if (action === "create") {
      createSubmit(values, setSubmitting);
    } else if (action === "update") {
      updateSubmit(values, setSubmitting);
    }
  };

  const handleCancel = (formik) => {
    if (formik.getFieldMeta("contents").value === initialValues.contents) {
      return "MODAL_REDIRECT";
    } else {
      return "MODAL_LINK";
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
    dataCrumbs,
    modalBack,
    action,
    handleCancel,
  };
};

export default useAssessmentTextEditor;
