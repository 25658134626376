import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RestAPI from '../../../../config/RestAPI'

function useTopic() {
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }
    
    const dispatch = useDispatch()
    const loading = useSelector((state) => state.loadingTable)
    const data = useSelector((state) => state.APITopic)

    const initData = () => {
        dispatch({ type: 'LOADING_TABLE', newValue: false })
        RestAPI.find('topic', config).then(res => {
            const newdata = []
            res.map(item => {
                newdata.push({
                    ...item,
                    updatedAt: format(new Date(item.updatedAt), 'yyyy-MM-dd, HH:mm')
                })
            })
            dispatch({ type: 'API_TOPIC', newValue: [...newdata] })
            dispatch({ type: 'LOADING_TABLE', newValue: true })
        })
    }

    useEffect(() => {
        initData()
    }, [])

  return {
    config,
    loading,
    data,
    initData,
  }
}

export default useTopic