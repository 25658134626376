import React from 'react'
import { Footer, Navbar, PublicEdit } from '../../../components/organisms';

function EditPublic() {
  return (
    <>
      <Navbar />
      <div className="height-82"></div>
      <PublicEdit />
      <Footer />
    </>
  )
}

export default EditPublic