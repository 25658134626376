import React, { useState, useEffect } from 'react';
import Modal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { ChevronRight } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import * as Icons from '@mui/icons-material'
import '../Modal.scss'
import RestAPI from '../../../../config/RestAPI';

function ModalTypeResources() {
    const statusMODAL = 'MODAL_TYPE_RESOURCES'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const [modalOpen, setModalOpen] = useState(false)
    const [dataType, setDataType] = useState([])

    const dispatch = useDispatch()
    const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
    const config = { headers: { 'token': 'Bearer ' + tokenAccess.accessToken } }

    const convertToSlug = (Text) => {
        return Text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
            ;
    }

    useEffect(() => {
        RestAPI.find('resources/type_resources', config).then(res => {
            setDataType(res)
        })
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <div className="modal-header">
                        <h5 className="tx-header5">Add new resources</h5>
                        <p className="tx-small neutral300">Resources used for learning guide</p>
                    </div>
                    <div className="modal-body modalCreateTeacherResources">
                        {
                            dataType && dataType?.map((item, index) => {
                                return <div key={index}>
                                    <Link to={`/teacher-resources/${convertToSlug(item.title)}/${item._id}`} className="no-decoration" onClick={closeModal} >
                                        <div className="link-card d-flex justify-content-between">
                                            <div className="d-flex">
                                                {React.createElement(Icons[item.icon])}
                                                <p className="tx-body-hl card-text">{item.title}</p>
                                            </div>
                                            <ChevronRight />
                                        </div>
                                    </Link>
                                </div>
                            })
                        }
                    </div>

                    <div className="modal-footer"></div>
                </div>
            </Modal>
        </>
    )
}

export default ModalTypeResources