import React, { useState, useEffect } from 'react';
import RestAPI from '../../../../config/RestAPI'
import '../Modal.scss'
import Buttons from '../../../atoms/Buttons';
import Modal from 'react-modal'
import FormikControl from '../../../atoms/Formik/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify';
import { contentToast } from '../../../atoms/Toast/ContentToast'
import { useSelector, useDispatch } from 'react-redux'
import { useCourse } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalUpdateCourse() {
    const { config, initData } = useCourse()    
    const statusMODAL = 'MODAL_UPDATE_COURSE'
    const isStatusMODAL = useSelector((state) => state.openModal)
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({
        name: '',
    })

    useEffect(() => {
        if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
            setModalOpen(true)
            if (isStatusMODAL.modal === 'EDIT') {
                setInitialValues({
                    _id: isStatusMODAL.crud._id,
                    name: isStatusMODAL.crud.name,
                    status: isStatusMODAL.crud.status,
                })
            } else {
                setInitialValues({
                    name: '',
                })
            }
        } else {
            setModalOpen(false)
        }
    }, [modalOpen, isStatusMODAL])

    const closeModal = () => {
        dispatch({
            type: 'OPEN_MODAL', newValue: {
                status: false,
                target: '',
                crud: null,
                modal: '',
            }
        })
    }

    const validationSchema = Yup.object({
        name: Yup.string().required("This field is required. Don't leave it empty!"),
    })
    
    const onSubmit = values => {
        if (isStatusMODAL.modal === 'CREATE') {
            RestAPI.post('course', values, config).then(res => {
                reloadTableCourse('Created', 'create')
            })
            .catch((err) => {
                failedProcess()
            });
        } else {
            RestAPI.put(`course/${values._id}`, values, config).then(res => {
                reloadTableCourse('Updated', 'update')
            })
            .catch((err) => {
                failedProcess()
            });
        }
    }
    const reloadTableCourse = (mess1, mess2) => {
        initData()
        toast.success(contentToast(
            `Course ${mess1}!`, `Success to ${mess2} the course.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
    }
    const failedProcess = () => {
        toast.error(contentToast(
            "Failed to update", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
        })
        closeModal()
    }

    return (
        <>
            <Modal isOpen={modalOpen} onRequestClose={() => closeModal()} >
                <div className="modal-content isForm">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit} >
                        {formik => (
                            <Form>
                                {
                                    (isStatusMODAL.modal === 'CREATE') ?
                                        <>
                                            <div className="modal-header">
                                                <h5 className="tx-header5">Create New Course</h5>
                                                <p className="tx-small neutral300">Fill all input to create new course</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='Input course name' label="Course name" isLabel />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="modal-header">
                                                <h5 className="tx-header5">Edit Course</h5>
                                                <p className="tx-small neutral300">Change and update the course info.</p>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='input' type='text' name='name' placeholder='Input course name' label="Course name" isLabel />
                                                    </div>
                                                    <div style={{ height: '24px' }}></div>
                                                    <div className="col-12">
                                                        <FormikControl addClass="isTaSmall isMoExSmall" control='select' name='status' label="Course status" isLabel options={[
                                                            { key: 'Active', value: true },
                                                            { key: 'Inactive', value: false },
                                                        ]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }

                                <div className="modal-footer">
                                    <Buttons control="button" type="reset" btnExSmall btnOutline name="Cancel" onClick={() => closeModal()} />
                                    <Buttons control="button" type="submit" name={(isStatusMODAL.modal === 'CREATE') ? 'Create' : 'Update'} addClass="btn-confirm" btnExSmall btnOutline btnDisable={formik.isSubmitting} disabled={formik.isSubmitting} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        </>
    )
}

export default ModalUpdateCourse