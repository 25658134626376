import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { useSearchParams } from "react-router-dom";
import RestAPI from "../../../../config/RestAPI";
import * as Yup from "yup";
import { NotInterested } from "@mui/icons-material";
import { useSelector } from "react-redux";
const useAssessmentMatchPairs = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)
  let action = searchParams.get("action");
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };
  const abj = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

  const navigate = useNavigate();
  const [responseSubtopic, setResponseSubtopic] = useState({});
  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${params.idsubtopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };
  //static data
  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    {
      name: dataSubTopic?.name,
      path: `/subtopic/editsubtopic/${params.idsubtopic}`,
    },
    { name: "Match Pairs", path: "" },
  ];
  const [initialValues, setInitialValues] = useState({
    subTopicId: params.idsubtopic,
    assessmentTypeId: params.idassessmenttype,
    assessmentCategory: responseSubtopic.assessmentType,
    question: "",
    options: [
      { abjad: "A", question: "", answer: "", color: "" },
      { abjad: "B", question: "", answer: "", color: "" },
    ],
  });
  const validationSchema = Yup.object().shape({
    options: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required(
          "This field is required. Don't leave it empty!"
        ),
        answer: Yup.string().required(
          "This field is required. Don't leave it empty!"
        ),
      })
    ),
  });
  const colors = [
    "#F29E4C",
    "#F7F19C",
    "#DBF24C",
    "#89F24C",
    "#4CF2EC",
    "#4CA5F2",
    "#4F70F2",
    "#9870F5",
    "#DB75F5",
    "#F24CC0",
  ];
  useEffect(() => {
    if (action !== "create" && action !== "update") {
      navigate("/page-not-found");
    }
  }, []);
  const init = async () => {
    try {
      const subTopicResult = await RestAPI.find(
        "subtopic/" + params.idsubtopic,
        config
      );
      console.log("ini", subTopicResult);
      setResponseSubtopic(subTopicResult);
      setInitialValues({
        ...initialValues,
        assessmentCategory: subTopicResult.assessmentType,
      });
    } catch (error) {
      navigate("/page-not-found");
    }

    if (action === "update") {
      let contentId = searchParams.get("contentId");
      try {
        const contentResult = await RestAPI.find(
          "assessment/" + contentId,
          config
        );

        setInitialValues({
          ...initialValues,
          question: contentResult.question,
          options: contentResult.options,
        });
      } catch (error) {
        navigate("/page-not-found");
      }
    }
  };
  useEffect(() => {
    init();
  }, []);

  const createSubmit = (req, setSubmitting) => {
    RestAPI.post("assessment", req, config)
      .then((r) => {
        localStorage.setItem(
          "atlazAssessmentToast",
          JSON.stringify({
            show: true,
            status: "success",
            title: "Content created!",
            message: "Success to create content",
          })
        );
        console.log(r);
        navigate(`/subtopic/editsubtopic/${params.idsubtopic}`);
      })
      .catch((err) => {
        toast.error(
          contentToast("Failed to create content!", err.response.data.message),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };
  const updateSubmit = (req, setSubmitting) => {
    let contentId = searchParams.get("contentId");
    RestAPI.put("assessment/" + contentId, req, config)
      .then((r) => {
        localStorage.setItem(
          "atlazAssessmentToast",
          JSON.stringify({
            show: true,
            status: "success",
            title: "Content updated!",
            message: "Success to update content",
          })
        );
        navigate(`/subtopic/editsubtopic/${params.idsubtopic}`);
      })
      .catch((err) => {
        toast.error(
          contentToast("Failed to create content!", err.response.data.message),
          {
            icon: (
              <NotInterested
                style={{ color: "var(--danger500)", fontSize: "30px" }}
              />
            ),
          }
        );
        setSubmitting(false);
      });
  };
  const onSubmit = (values, { setSubmitting }) => {
    let remakeOptions = values.options.map((r, i) => {
      r.abjad = abj[i];
      r.color = colors[i];
      return r;
    });
    values = { ...values, options: remakeOptions };
    if (action === "create") {
      createSubmit(values, setSubmitting);
    } else if (action === "update") {
      updateSubmit(values, setSubmitting);
      // console.log(values);
    }
  };
  return {
    dataCrumbs,
    modalBack,
    initialValues,
    validationSchema,
    onSubmit,
    action,
    colors,
    abj,
  };
};

export default useAssessmentMatchPairs;
