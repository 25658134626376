import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { scroller } from "react-scroll";
import { CSSTransition } from "react-transition-group";
import Buttons from "../../../../atoms/Buttons";
import { ModalLink } from "../../../../molecules";
const AssessmentNav = (props) => {
  const [showButton, setShowButton] = useState(false);
  const { progressSetup } = props;
  const [progress, setProgress] = useState(0);
  const params = useParams();
  useEffect(() => {
    let totalQuestion = progressSetup.length;
    let pointPerQuestion = 100 / totalQuestion;
    let tempProggress = 0;
    progressSetup.map((r) => {
      if (r.isFilled === true) {
        tempProggress += pointPerQuestion;
        setProgress(tempProggress);
      }
    });
    if (tempProggress < 1) {
      setProgress(0);
    }
  }, [progressSetup]);

  const scrollToSection = (name) => {
    scroller.scrollTo(name, {
      duration: 800,
      delay: 0,
      offset: -280,
    });
  };
  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel process",
    description: "",
    message:
      "Continue to cancel process? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${params.idsubtopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };
  return (
    <>
      <ModalLink />
      <div
        className="assessment-preview-nav position-fixed "
        style={{ width: "100%", zIndex: "99" }}
      >
        <div className="d-flex align-items-center  ">
          <div>
            <h6 className=" mr-32" style={{ whiteSpace: "nowrap" }}>
              Reading Assessment
            </h6>
          </div>
          <div className="progress w-full bg-info100" style={{ height: "8px" }}>
            <div
              className="progress-bar bg-success400"
              role="progressbar"
              style={{ width: `${progress}%`, height: "8px" }}
            ></div>
          </div>
          <div className="d-flex ml-32 neutral300">
            <Buttons
              btnGhost
              btnIcon
              control="modalLink"
              data={modalBack}
              path="MODAL_LINK"
            >
              <CloseIcon />
            </Buttons>
          </div>
        </div>
        <div
          className="w-full bg-secondary500 my-24"
          style={{ height: "2px" }}
        ></div>
        {props.withExpand && (
          <div className="text-center  ">
            <CSSTransition
              in={showButton}
              timeout={200}
              classNames="numbering"
              unmountOnExit
            >
              <div className={`row  show-button `}>
                {progressSetup.map((r, i) => {
                  return (
                    <button
                      className={`btn-number ${r.isFilled ? "active" : ""}`}
                      key={i}
                      type="button"
                      onClick={() => {
                        scrollToSection(r.name);
                      }}
                      style={{ margin: "12px 16px" }}
                    >
                      {i + 1}
                    </button>
                  );
                })}
              </div>
            </CSSTransition>

            <span
              className="cursor-pointer "
              onClick={() => {
                showButton ? setShowButton(false) : setShowButton(true);
              }}
            >
              <KeyboardArrowDownIcon />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AssessmentNav;
