import React from "react";

import { Footer, AssessmentPreview } from "../../../components/organisms";
const AssessmentPreviewPage = () => {
  return (
    <>
      <AssessmentPreview />
      <Footer />
    </>
  );
};

export default AssessmentPreviewPage;
