import React, { useEffect, useState } from 'react';
import './Navbar.scss'
import imgBrand1 from '../../../assets/images/logo/regular/default/Double.png'
import imgBrand2 from '../../../assets/images/logo/logogram/default/Regular.png'
import imgAvatar from '../../../assets/images/Avatar.png'
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
    NotificationsNone,
    Menu
} from '@mui/icons-material';

function Navbar() {
    const { pathname } = useLocation()
    const targetPathname = `/${pathname.split('/')[1]}`
    let myParent1 = '/'
    switch (targetPathname) {
        case '/course': myParent1 = targetPathname
        case '/subject': myParent1 = targetPathname
        case '/lesson': myParent1 = targetPathname
        case '/topic': myParent1 = targetPathname
        case '/subtopic': myParent1 = targetPathname
    }
    let myParent2 = '/'
    switch (targetPathname) {
        case '/school': myParent2 = targetPathname
        case '/user': myParent2 = targetPathname
    }

    const [dataMenu, setDataMenu] = useState();

    const dataMenuAdmin = [
        { name: 'Dashboard', path: '/dashboard', numOrder: 1 },
        {
            name: 'My Books',
            path: myParent1,
            numOrder: 2,
            submenu: [
                { name: 'Course List', path: '/course', numOrder: 1 },
                { name: 'Subject List', path: '/subject', numOrder: 2 },
                { name: 'Lesson List', path: '/lesson', numOrder: 3 },
                { name: 'Topic List', path: '/topic', numOrder: 4 },
                { name: 'Sub Topic List', path: '/subtopic', numOrder: 5 },
            ]
        },
        {
            name: 'Master Menu',
            path: myParent2,
            numOrder: 3,
            submenu: [
                { name: 'Member Information', path: '/member', numOrder: 1 },
                { name: 'Fondations Information', path: '/fondations', numOrder: 2 },
                { name: 'School Member', path: '/school', numOrder: 3 },
                { name: 'Public Member', path: '/public', numOrder: 4 },
                { name: "Teacher Resources", path: "/teacher-resources", numOrder: 5 },
                { name: 'Coupon List', path: '/coupons', numOrder: 6 },
            ]
        }
    ]

    const dataMenuTeacher = [
        { name: 'Dashboard', path: '/dashboard', numOrder: 1 },
        {
            name: 'My Books',
            path: myParent1,
            numOrder: 2,
            submenu: [
                { name: 'Course List', path: '/course', numOrder: 1 },
                { name: 'Subject List', path: '/subject', numOrder: 2 },
                { name: 'Lesson List', path: '/lesson', numOrder: 3 },
                { name: 'Topic List', path: '/topic', numOrder: 4 },
                { name: 'Sub Topic List', path: '/subtopic', numOrder: 5 },
            ]
        },
    ]

    useEffect(() => {
        const dataLogin = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
        if (dataLogin.role <= 2) {
            setDataMenu(dataMenuAdmin)
        } else if(dataLogin.role >= 3 && dataLogin.role <= 5) {
            setDataMenu(dataMenuTeacher)
        }
    }, [])

    return (
        <>
            <nav className="navbar navbar-admin navbar-expand-lg bg-neutral50 fixed-top">
                <div className="container-fluid">
                    <Link className="navbar-brand d-none d-lg-block" to='/dashboard'>
                        <img src={imgBrand1} className="img-brand" alt="" />
                    </Link>
                    <Link className="navbar-brand d-block d-lg-none" to='/dashboard'>
                        <img src={imgBrand2} className="img-brand2" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                        <Menu />
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-header">
                            <Link className="navbar-brand d-block d-lg-none" to='/'>
                                <img src={imgBrand2} className="img-brand2" alt="" />
                            </Link>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="navbar-collapse offcanvas-body">
                            <ul className="navbar-nav mx-auto">
                                {
                                    dataMenu && dataMenu?.map((item, index) => {
                                        return (
                                            <li key={index} className={`nav-item ${item.submenu && 'dropdown'}`}>
                                                <NavLink to={item.path} activeclassname={`nav-link tx-small active ${item.submenu && 'dropdown-toggle'}`} className={`nav-link tx-small ${item.submenu && 'dropdown-toggle'}`} aria-current="page" href="#" data-bs-toggle={`${item.submenu ? 'dropdown' : undefined}`}>{item.name}</NavLink>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    {
                                                        item.submenu?.map((sm, key) => (
                                                            <li key={key}><Link to={sm.path} className="dropdown-item tx-small" href="#">{sm.name}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                            </li>
                                        )

                                    })
                                }
                            </ul>
                            <div className="navbar-right d-flex align-items-center">
                                {/* <Link to="/notification"><NotificationsNone className="neutral200" /></Link> */}

                                <div className="dropdown dropdown-avatar">
                                    <button className="btn btn-avatar dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={imgAvatar} alt="" className="avatar-account" />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <Link to="/logout" className="dropdown-item">Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
