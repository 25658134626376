import React from 'react';
import { Base64MultipleChoices, Footer, Navbar } from '../../components/organisms';

function ContentMultipleChoices() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <Base64MultipleChoices />
        <Footer/>
      </>
  )
}

export default ContentMultipleChoices;
