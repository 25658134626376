import React, { useMemo } from 'react';
import Buttons from '../../../atoms/Buttons';
import DropdownSelect from '../../../atoms/DropdownSelect';
import Title from '../../../atoms/Title'
import ModalUpdateClass from '../../Modals/ClassList/ModalUpdateClass';
import ModalActiveClass from '../../Modals/ClassList/ModalActiveClass';
import ModalDeleteClass from '../../Modals/ClassList/ModalDeleteClass';
import { Checkbox } from '../Checkbox';
import { ColumnFilter } from '../ColumnFilter';
import { GlobalFilter } from '../GlobalFilter';
import { COLUMNS } from './Columns';
import { dropdownAction } from './Dropdown';
import { useTableClass } from '../../../services';
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import {
    ArrowBack,
    ArrowForward,
    FiberManualRecord,
    PlaylistAdd,
    Sort
} from '@mui/icons-material';
import '../DataTables.scss';

function TableClass() {
    const {
        loading,
        data,
        dataSchool,
      } = useTableClass()

    const columns = useMemo(() => COLUMNS, [])
    const defaultColumn = useMemo(() => {
        return { Filter: ColumnFilter }
    }, [])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        state,
        prepareRow,
        setGlobalFilter,
        selectedFlatRows
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: {
            pageIndex: 0,
        }
    }, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
                    },
                    ...columns
                ]
            })
        })
    const { pageIndex, pageSize, globalFilter } = state

    return (
        <>
            <ModalUpdateClass />
            <ModalActiveClass />
            <ModalDeleteClass />
            <Title title={dataSchool?.name} />
            <div className="top-table d-flex align-items-center mt-32">
                <div className="left">
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
                <div className="right ms-auto d-flex align-items-center">
                    <Buttons control="modal" modal="CREATE" target="MODAL_UPDATE_CLASS" addClass="btnAdd text-center" btnSmall btnWrapper isShadow><PlaylistAdd /> Create class</Buttons>
                    {
                        (selectedFlatRows.length > 0) && <DropdownSelect isDivider isSmall divider={2} dropdownMenu="dropdown-menu-end bRadius8" dropId="actionTableClass" title="Action" list={dropdownAction} addClass="ms-md-3 ms-2" bgToogle="bg-primary400 brd-primary400 bRadius8" dataSelected={selectedFlatRows} width="110px" />
                    }
                </div>
            </div>
            <div className="table-responsive">
                <table {...getTableProps()} className="table-course">
                    <thead>
                        {
                            headerGroups.map((headerGroup) => {
                                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                        <tr key={key} {...restHeaderGroupProps}>
                                            {
                                                headerGroup.headers.map((column, key) => {
                                                    return (
                                                        <th key={key} className="w-m-400" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                            {column.render('Header')}
                                                            {key != 0 && key < headerGroup.headers.length - 1 && <span className="float-end">
                                                                {column.isSorted ? (column.isSortedDesc ? <Sort style={{ fontSize: '14px' }} /> : <Sort style={{ transform: 'rotate(180deg)', fontSize: '14px' }} />) : <Sort style={{ opacity: '0.3', fontSize: '14px' }} />} </span>}
                                                        </th>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        {

                            headerGroups.map((headerGroup2) => {
                                const { key, ...restHeaderGroupProps } = headerGroup2.getHeaderGroupProps();
                                return (
                                    <tr key={`Filter_${key}`} {...restHeaderGroupProps} className="header-filtering">
                                        {
                                            headerGroup2.headers.map((column) => {
                                                const { key } = column.getHeaderProps();
                                                return <th key={`Filter_${key}`} {...column.getHeaderProps}><div>{column.canFilter ? column.render('Filter') : null}</div></th>
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            (loading) ?
                                (page.length) ?
                                    page.map(row => {
                                        prepareRow(row)
                                        const { key, ...restRowProps } = row.getRowProps();
                                        return (
                                            <tr key={key} {...restRowProps}>
                                                {
                                                    row.cells.map((cell) => {
                                                        const { key, ...restCellProps } = cell.getCellProps();
                                                        {
                                                            if (cell.column.Header === 'Status' && cell.value === true) {
                                                                return <td key={key} {...restCellProps}>
                                                                    <div className="status-active"><FiberManualRecord style={{ fontSize: "6px" }} /> Active</div>
                                                                </td>
                                                            } else if (cell.column.Header === 'Status' && cell.value === false) {
                                                                return <td key={key} {...restCellProps}>
                                                                    <div className="status-inactive">
                                                                        <FiberManualRecord style={{ fontSize: "6px" }} /> Inactive
                                                                    </div>
                                                                </td>
                                                            } else {
                                                                return <td key={key} {...restCellProps} >
                                                                    {cell.render('Cell')}
                                                                </td>
                                                            }
                                                        }
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="datanotfound text-center tx-small neutral200" colSpan="6">
                                            No data available
                                        </td>
                                    </tr>
                                :
                                <tr>
                                    <td className="datanotfound text-center tx-small neutral200" colSpan="6">
                                        Loading data...
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="navigation-table">
                <div className="tx-small-hl neutral300 d-none d-md-block">
                    Page {' '} {pageIndex + 1} of {pageOptions.length}{' '}
                </div>
                <div className="pagination-table">
                    <div className="tx-small neutral300 your-page">
                        You're in page {' '}
                        <input type="number" className="numberpage" value={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            style={{ width: '50px' }}
                        />
                    </div>
                    <div className="divider-nav"></div>
                    <div style={{ marginRight: '24px' }}>
                        <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => previousPage()} btnDisable={!canPreviousPage} name={<ArrowBack style={{ fontSize: "20px" }} />} />
                    </div>
                    <div>
                        <Buttons control="button" btnWrapper isShadow btnExSmall btnIcon onClick={() => nextPage()} btnDisable={!canNextPage} name={<ArrowForward style={{ fontSize: "20px" }} />} />
                    </div>
                </div>
            </div>
        </>

    )
}

export default TableClass