import React, { useState, useEffect } from 'react'
import './EditSubtopic.scss'
import RestAPI from '../../../../../config/RestAPI'
import Breadcrumbs from "../../../../atoms/Breadcrumbs";
import Buttons from "../../../../atoms/Buttons";
import Toast from "../../../../atoms/Toast";
import SubTopicDetailShow from '../SubTopicDetailShow';
import AssessmentShow from '../../Assessments/AssessmentShow';
import { useParams } from 'react-router';
import { ModalLink, ModalRedirect, SubtopicAssessment, SubtopicInfo } from '../../../../molecules';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { contentToast } from '../../../../atoms/Toast/ContentToast'
import { 
  CheckCircleOutline, ArrowBack 
} from '@mui/icons-material';

function EditSubtopic() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const getWhereFrom = JSON.parse(localStorage.getItem('EDIT_SUBTOPIC'))
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic)
  const backStatus = useSelector((state) => state.BackStatus)
  // const inputContent = JSON.parse(localStorage.getItem('INPUT_CONTENT'))
  const contentType = JSON.parse(localStorage.getItem('CONTENT_TYPE'))
  const [tabs1, setTabs1] = useState('active')
  const [tabs2, setTabs2] = useState('')
  const [tabsBody1, setTabsBody1] = useState('show active')
  const [tabsBody2, setTabsBody2] = useState('')
  const tokenAccess = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))
  const config = {
    headers: { 'token': 'Bearer ' + tokenAccess.accessToken }
  }
  
  useEffect(() => {
    RestAPI.find(`subtopic/${id}`, config).then(res => {
      dispatch({ type: 'API_EDIT_SUBTOPIC', newValue: res })
    }).catch((err) => {
      console.log(err.response)
      navigate("/notfound")
    });
    if(getWhereFrom) {
      if(getWhereFrom.goal === 'CREATE') {
        setTabs1('')
        setTabsBody1('')
        setTabs2('active')
        setTabsBody2('show active')
        if (getWhereFrom.toast) {    
          toast.success(contentToast(
            `Content ${getWhereFrom.message.title}!`, `Success to ${getWhereFrom.message.desc} content.`), {
            icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
          })
        }
        localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
          ...getWhereFrom,
          toast: false,
        }));
      } else {
        setTabs1('active')
        setTabsBody1('show active')
        setTabs2('')
        setTabsBody2('')
      }
    } else {
      setTabs1('active')
      setTabsBody1('show active')
      setTabs2('')
      setTabsBody2('')
    }
    dispatch({ type: 'BACK_STATUS', newValue: false })
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
    if (contentType) { localStorage.removeItem("CONTENT_TYPE"); }
  }, [])

  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Sub Topic', path: '/subtopic' },
    { name: dataSubTopic?.name, path: '' },
  ]

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: 'Cancel updating sub topic',
    description: '',
    message: 'Once canceled, your inputed data will be lost.',
    path: localStorage.getItem('BackLink') ? localStorage.getItem('BackLink') : '/subtopic',
    footerYes: 'Yes',
    footerNo: 'No',
  }

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalRedirect />
      <section className="subtopic-edit">
        <div className="container-fluid">
          <div className="row">
            <Breadcrumbs data={dataCrumbs} />
            <div className="col-md-12 nav-back">
              <Buttons
                control="modalLink"
                addClass="ps-0"
                btnSmall
                btnLink
                data={modalBack} path={backStatus ? 'MODAL_LINK' : 'MODAL_REDIRECT'}
              ><ArrowBack className="iconback" fontSize="small" /> Back</Buttons>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <nav className='navtabs-admin'>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link ${tabs1}`}
                    id="nav-subjectinfo-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-subjectinfo"
                    type="button"
                    role="tab"
                    aria-controls="nav-subjectinfo"
                    aria-selected="true"
                    onClick={() => {
                      localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'EDIT',
                      }));
                    }}
                  >
                    Sub Topic Info
                  </button>
                  <button
                    className={`nav-link ${tabs2}`}
                    id="nav-addcontent-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-addcontent"
                    type="button"
                    role="tab"
                    aria-controls="nav-addcontent"
                    aria-selected="false"
                    onClick={() => {
                      localStorage.setItem("EDIT_SUBTOPIC", JSON.stringify({
                        ...getWhereFrom,
                        goal: 'CREATE',
                      }));
                    }}
                  >
                    Input Content
                  </button>
                </div>
              </nav>

              <div className="tab-content tab-content-admin" id="nav-tabContent">
                <div
                  className={`tab-pane ${tabsBody1}`}
                  id="nav-subjectinfo"
                  role="tabpanel"
                  aria-labelledby="nav-subjectinfo-tab" >
                    {
                      dataSubTopic && dataSubTopic.isAssessment ?
                      <SubtopicAssessment dataSubTopic={dataSubTopic} />
                      : <SubtopicInfo dataSubTopic={dataSubTopic} />
                    }
                </div>
                <div
                  className={`tab-pane ${tabsBody2}`}
                  id="nav-addcontent"
                  role="tabpanel"
                  aria-labelledby="nav-addcontent-tab" >
                    {
                      dataSubTopic && dataSubTopic.isAssessment ?
                      <AssessmentShow />
                      : <SubTopicDetailShow dataSubTopic={dataSubTopic} />
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditSubtopic