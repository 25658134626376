import React from 'react'
import Buttons from '../components/atoms/Buttons'
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

function Notfound() {
  const navigate = useNavigate();

  return (
    <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '100vh'}}>
        <div className="tx-header2 mb-3">Page Not Found</div>
        <Buttons control="button" btnSmall btnLink
          onClick={() => {
            navigate(-1)
          }} >
          <ArrowBack className="iconback" fontSize="small" /> Back
        </Buttons>
    </div>
  )
}

export default Notfound