import React, { useEffect, useRef, useState } from "react";
import Textarea from "react-expanding-textarea";
import AssessmentShowFooter from "./AssessmentShowFooter";

const AssessmentEssayShow = (props) => {
  const textareaRef = useRef(null);
  const [data, setData] = useState(props.data);
  const handleChange = (e) => {
    console.log(e.target.value);
  };
  return (
    <div className="assessments-content-show">
      <div className="card-container mb-40">
        <div className="card-content">
          <div className="mb-16">
            <p className="mb-16">{data.question}</p>
            {data.image ? (
              <div className="text-center mb-16">
                <img src={data.image} alt="" width="100%" />
              </div>
            ) : (
              ""
            )}

            <div className="px-24 bg-info100">
              {/* <textarea onChange={handleChange} className="textarea" id="" cols="7" rows="7"></textarea> */}
              <Textarea
                className="textarea"
                // onChange={handleChange}
                rows={7}
                ref={textareaRef}
              />
            </div>
          </div>
        </div>
        <AssessmentShowFooter data={data} submitAction={props.submitAction} />
      </div>
    </div>
  );
};

export default AssessmentEssayShow;
