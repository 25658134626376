import React, { useState, useEffect } from "react";
import "../Modal.scss";
import "./ModalSubTopic.scss";
import RestAPI from "../../../../config/RestAPI";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ModalCreateAssessment = (props) => {
  const statusMODAL = "MODAL_CREATE_ASSESSMENT";
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const isStatusMODAL = useSelector((state) => state.openModal);
  const crud = useSelector((state) => state.openModal.crud);
  const [contentEditor, setContentEditor] = useState([]);
  const [inlineEditor, setInlineEditor] = useState([]);
  const [subTopic, setSubTopic] = useState({});

  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      setContentEditor(crud.contentEditor);
      setInlineEditor(crud.inlineEditor);
      setSubTopic(crud.dataSubTopic);
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };
  return (
    <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
      <div className="modal-sub-topic modal-content">
        <div
          className=" isNoForm p-8  scrollbar-modal"
          style={{ height: "400px" }}
        >
          {contentEditor.map((r, i) => {
            if (r.name !== "Open-ended question") {
              return (
                <React.Fragment key={"title-" + i}>
                  {i > 0 && <hr />}
                  <h6 className="neutral300 mb-8">{r.name}</h6>
                  {r.assessmentType.map((res, ind) => {
                    return (
                      <ContentCardGoTo
                        key={"card-" + ind}
                        className="mb-16"
                        id={res._id}
                        title={res.name}
                        isInline={res.isInline}
                        text={res.description}
                        icon={res.icon}
                        subTopicId={subTopic._id}
                        assessmentCategory={subTopic.assessmentType}
                        assessmentTypeId={res._id}
                        closeModal={closeModal}
                        createInlineData={(data) => {
                          props.onCreateInlineData(data);
                        }}
                        to={`/subtopic/assessment/${res.path}/${subTopic._id}/${res._id}?action=create`}
                      />
                    );
                  })}
                </React.Fragment>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

const ContentCardGoTo = (props) => {
  const cardComponent = (
    <div
      className={`d-flex align-items-center content-card-go-to p-1  ${props.className}`}
    >
      <div className="mr-8">
        <img
          src={props.icon}
          alt=""
          width="54"
          style={{
            padding: "10px",
            border: "1px solid #D4D7DB",
            borderRadius: "4px",
          }}
        />
      </div>
      <div>
        <h6 className="neutral500">{props.title}</h6>
        <p className="">
          <span className="tx-small">{props.text}</span>
        </p>
      </div>
    </div>
  );

  if (props.isInline) {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.closeModal();
          props.createInlineData([
            props.subTopicId,
            props.assessmentCategory,
            props.assessmentTypeId,
          ]);
        }}
      >
        {cardComponent}
      </div>
    );
  }

  return (
    <Link to={props.to} className="text-decoration-none">
      {cardComponent}
    </Link>
  );
};
export default connect()(ModalCreateAssessment);
