import React, { useEffect, useState } from "react";
import "./SubTopicMultipleChoice.scss";
import * as Yup from "yup";
import FormikControl from "../../../../atoms/Formik/FormikControl";
import Buttons from "../../../../atoms/Buttons";
import RestAPI from "../../../../../config/RestAPI";
import Toast from "../../../../atoms/Toast";
import { toast } from "react-toastify";
import { contentToast } from "../../../../atoms/Toast/ContentToast";
import { Field, FieldArray, Form, Formik } from "formik";
import { useParams } from "react-router";
import { useSelector, useDispatch, connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  ModalDeleteQuestion,
  ModalLink,
  TitleSection,
} from "../../../../molecules";
import {
  PostAdd,
  Add,
  DeleteOutline,
  NotInterested,
  Close,
} from "@mui/icons-material";

const SubTopicMultipleChoice = () => {
  const { id } = useParams();
  const dataSubTopic = useSelector((state) => state.APIEditSubTopic);
  const idSubTopic = localStorage.getItem("ID_SUBTOPIC");
  const getWhereFrom = JSON.parse(localStorage.getItem("EDIT_SUBTOPIC"));
  const [initQuestion, setInitQuestion] = useState({
    question: "",
    fakeImage: "",
    image: "",
    options: [
      {
        text: "",
        isAnswer: false,
      },
      {
        text: "",
        isAnswer: false,
      },
      {
        text: "",
        isAnswer: false,
      },
      {
        text: "",
        isAnswer: false,
      },
    ],
  });
  const [initialValues, setInitialValues] = useState({
    instruction: "",
    subTopicId: "",
    contentTypeId: "",
    questions: [initQuestion],
    correctionText: "",
  });

  const contentType = JSON.parse(localStorage.getItem("CONTENT_TYPE"));
  const tokenAccess = JSON.parse(localStorage.getItem("ATLAZ_Authentication"));
  const config = {
    headers: { token: "Bearer " + tokenAccess.accessToken },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!idSubTopic) {
      navigate("/subtopic");
    } else {
      RestAPI.find(`subtopic/${idSubTopic}`, config)
        .then((res) => {
          dispatch({ type: "API_EDIT_SUBTOPIC", newValue: res });
        })
        .catch((err) => {
          console.log(err.response);
          navigate("/notfound");
        });
      if (id) {
        RestAPI.find(`content/multiple/${contentType.id}`, config).then(
          (res) => {
            const dataQuestions = [];
            res.questions.map((data1) => {
              const dataOptions = [];
              data1.options.map((data2) => {
                dataOptions.push({
                  text: data2.text,
                  isAnswer: data2.isAnswer,
                });
              });
              dataQuestions.push({
                question: data1.question,
                fakeImage: "",
                image: data1.image,
                options: dataOptions,
              });
            });
            setInitialValues({
              instruction: res.instruction,
              subTopicId: "",
              contentTypeId: res._id,
              questions: dataQuestions,
              correctionText: res.correctionText,
            });
          }
        );
      }
      dispatch({
        type: "OPEN_MODAL",
        newValue: {
          status: false,
          target: "",
          crud: null,
          modal: "",
        },
      });
    }
  }, []);

  const dataCrumbs = [
    { name: "Home", path: "/" },
    { name: "Sub Topic", path: "/subtopic" },
    { name: dataSubTopic?.name, path: `/subtopic/editsubtopic/${idSubTopic}` },
    { name: "Multiple Choice", path: "" },
  ];

  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required(
          "This field is required. Don't leave it empty!"
        ),
        options: Yup.array().of(
          Yup.object().shape({
            text: Yup.string().required(
              "This field is required. Don't leave it empty!"
            ),
          })
        ),
      })
    ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (!id) {
      values = {
        ...values,
        subTopicId: idSubTopic,
        contentTypeId: contentType.id,
      };
      RestAPI.post("content/multiple", values, config)
        .then((r) => {
          processSuccess("Created", "create");
        })
        .catch((err) => {
          processFailed("create");
          setSubmitting(false);
        });
    } else {
      values = {
        ...values,
        contentTypeId: contentType.id,
      };
      RestAPI.put(`content/multiple/${contentType.id}`, values, config)
        .then((r) => {
          processSuccess("Updated", "update");
        })
        .catch((err) => {
          processFailed("update");
          setSubmitting(false);
        });
    }
  };

  const processSuccess = (message1, message2) => {
    localStorage.setItem(
      "EDIT_SUBTOPIC",
      JSON.stringify({
        ...getWhereFrom,
        toast: true,
        message: { title: message1, desc: message2 },
      })
    );
    navigate(`/subtopic/editsubtopic/${idSubTopic}`);
  };

  const processFailed = (message) => {
    toast.error(
      contentToast(
        `Failed to ${message}!`,
        "Your connection not stable. Try again."
      ),
      {
        icon: (
          <NotInterested
            style={{ color: "var(--danger500)", fontSize: "30px" }}
          />
        ),
      }
    );
  };

  const modalBack = {
    isForm: false,
    isNoForm: true,
    title: "Cancel input",
    description: "",
    message:
      "Continue to cancel input? Once canceled, any changes will be lost.",
    path: `/subtopic/editsubtopic/${idSubTopic}`,
    footerYes: "Yes",
    footerNo: "No",
  };

  return (
    <>
      <Toast />
      <ModalLink />
      <ModalDeleteQuestion />
      <section className="multiple-choice">
        <div className="container-fluid">
          <div className="row">
            <TitleSection data={dataCrumbs} titleheader="Multiple Choice" />
          </div>
          <div className="row">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form>
                  <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <div className="mb-16 tx-header5">
                      Instruction{" "}
                      <span className="tx-header6">
                        <i>(Optional)</i>
                      </span>
                    </div>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="instruction"
                      placeholder="e.g. A. Answer the question!"
                    />
                  </div>
                  <FieldArray name={"questions"}>
                    {(fieldArrayProps) => {
                      const { push, remove, form } = fieldArrayProps;
                      const { values } = form;
                      const { questions } = values;
                      return (
                        <>
                          {questions.map((q, i) => {
                            return (
                              <React.Fragment key={i}>
                                <QuestionSection
                                  name={`questions[${i}]`}
                                  title={`Number ${i + 1}`}
                                  onClose={() => {
                                    remove(i);
                                  }}
                                  formik={formik}
                                />
                              </React.Fragment>
                            );
                          })}
                          <div className="col-12 mb-24">
                            <div
                              className=" text-center neutral300 more-option radius-4  cursor-pointer py-24 radius-8"
                              onClick={() => push(initQuestion)}
                            >
                              <span className="new-question">
                                <Add /> <br />
                                New question
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </FieldArray>

                  <div className="col-md-12 radius-14 mb-24 bg-secondary200 px-40 py-24 radius-14">
                    <label htmlFor="" className="tx-small">
                      <span className="text-strong">Explanation Text </span>
                      <i>(Optional)</i>
                    </label>
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      control="textarea"
                      type="text"
                      name="correctionText"
                      placeholder="Explain the answer here"
                    />
                  </div>

                  <div className="text-end btn-form mb-32">
                    <Buttons
                      control="modalLink"
                      addClass="py-8 mr-16 px-48"
                      btnOutline
                      btnRegular
                      btnMoExSmall
                      data={modalBack}
                      path="MODAL_LINK"
                    >
                      Cancel
                    </Buttons>
                    <Buttons
                      control="button"
                      type="submit"
                      name={id ? "Update" : "Save"}
                      addClass="py-8 px-60"
                      btnPrimary
                      btnRegular
                      btnMoExSmall
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </>
  );
};
const QuestionSection = (props) => {
  return (
    <>
      <div className="col-12 p-40 bg-secondary200 radius-14 mb-24">
        <div className="d-flex justify-content-between mb-40">
          <div className="tx-header5">{props.title}</div>
          <Buttons
            btnGhost
            btnIcon
            control="modal"
            target="MODAL_DELETE_QUESTION"
            crud={{
              onClick: () => {
                props.onClose();
              },
            }}
          >
            <Close />
          </Buttons>
        </div>
        <div className="mb-16">
          <label htmlFor="">
            <h6 className="text-strong">Question </h6>
          </label>
          <FormikControl
            addClass="isTaSmall isMoExSmall"
            control="input"
            type="text"
            name={`${props.name}.question`}
            placeholder="Enter the question"
          />
        </div>
        <div className="mb-32">
          <label htmlFor="">
            <p className="tx-small">
              <span className="text-strong">Upload image </span>
              <i>(optional)</i>
            </p>
          </label>

          <FormikControl
            addClass="isTaSmall isMoExSmall"
            control="inputbase64"
            name={`${props.name}.fakeImage`}
            base64Name={`${props.name}.image`}
          />
        </div>
        <div>
          <p className="text-strong mb-16">Option</p>
          <PG name={props.name} />
        </div>
      </div>
    </>
  );
};
const PG = (props) => {
  return (
    <>
      <FieldArray name={`${props.name}.options`}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          let spliter = props.name.split("[");
          const { options } = values[spliter[0]][spliter[1].substring(0, 1)];
          // console.log("fieldArrayProps", fieldArrayProps);
          // console.log("Form ", options);
          return (
            <div className="mb-24">
              {options.map((options, index) => (
                <div key={index} className="mb-12 d-flex">
                  <div className="pg-input mr-16">
                    <FormikControl
                      addClass="isTaSmall isMoExSmall"
                      name={`${props.name}.options[${index}].text`}
                      type="text"
                      control="input"
                      placeholder="Enter the option"
                    />
                  </div>
                  <div className="bg-white radius-4 pg-card d-flex justify-content-center align-items-center neutral200 ">
                    <Field
                      type="checkbox"
                      name={`${props.name}.options[${index}].isAnswer`}
                    />
                  </div>
                  <div
                    className={` radius-4 cursor-pointer pg-card ml-16 d-flex justify-content-center align-items-center neutral200 remove-pg-button ${
                      index < 2 ? "bg-neutral100" : ""
                    }`}
                    onClick={() => {
                      if (index > 1) {
                        return remove(index);
                      }
                    }}
                  >
                    <DeleteOutline className="btn-delete" />
                  </div>
                </div>
              ))}
              <div
                className={`mb-32 text-center cursor-pointer neutral300 more-option  radius-4 p-8 ${
                  options.length >= 12 ? "bg-neutral100" : ""
                }`}
                onClick={() => {
                  if (options.length < 12) {
                    return push({
                      text: "",
                      isAnswer: false,
                    });
                  }
                }}
              >
                <span className="cursor-pointer">
                  More option <PostAdd />
                </span>
              </div>
            </div>
          );
        }}
      </FieldArray>
    </>
  );
};

export default connect()(SubTopicMultipleChoice);
