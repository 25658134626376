import React, { useEffect } from 'react';
import './MemberList.scss'
import TitleSection from '../../../molecules/TitleSection';
import { ModalActiveUser, TableUser } from '../../../molecules';
import { connect, useDispatch } from 'react-redux'

function MemberList() {
  const dataCrumbs = [
    { name: 'Dashboard', path: '/dashboard' },
    { name: 'Member Information', path: '' }
  ]

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'OPEN_MODAL', newValue: {
        status: false,
        target: '',
        crud: null,
        modal: '',
      }
    })
  }, [])

  return (
      <>
        <section className="user-list">
          <div className="container-fluid">
            <div className="row">
              <TitleSection data={dataCrumbs} titleheader="Member Information" />
            </div>
            <div className="row">
              <div className="col-md-12">
                <TableUser />
                <ModalActiveUser />
              </div>
            </div>
          </div>
        </section>
      </>
  )
}

export default connect()(MemberList)
