import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Buttons from "../../../../atoms/Buttons";
import Loading from "../../../../atoms/Loading";
import { useAssessmentPreview } from "../../../../services";
import AssessmentNav from "./AssessmentNav";
import "./AssessmentPreview.scss";
const AssessmentPreview = () => {
  const {
    callBlock,
    params,
    isLoading,
    initialValues,
    onSubmit,
    questionsName,
    subTopicData,
  } = useAssessmentPreview();
  let nomor = 0;
  return (
    <>
      <AssessmentNav
        progressSetup={questionsName}
        withExpand={
          subTopicData.assessmentType === "Manual Grading" ? false : true
        }
      />

      <div className="d-flex justify-content-center">
        <section
          className="assessment-preview col-12 position-abosulte"
          style={{ marginTop: "182px" }}
        >
          <div className="container-fluid ">
            <div className="row mt-28 " style={{ width: "100%" }}>
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form>
                    <div className="col-12  preview-block ">
                      {isLoading ? (
                        <Loading />
                      ) : (
                        initialValues.map((r, i) => {
                          if (i === 0) {
                            nomor = 0;
                          }
                          if (
                            r.assessmentType.name.toLowerCase() !==
                              "text editor" &&
                            r.assessmentType.name.toLowerCase() !== "divider"
                          ) {
                            nomor += 1;
                          }
                          return callBlock(r, i, formik, nomor);
                        })
                      )}
                    </div>

                    <div
                      style={{ marginBottom: "217px", marginTop: "68px" }}
                      className="text-end"
                    >
                      <Buttons
                        control="button"
                        type="submit"
                        name={"Submit"}
                        addClass="py-8 px-60"
                        btnPrimary
                        btnRegular
                        btnMoExSmall
                        btnDisable={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AssessmentPreview;
