import React from "react";
import AssessmentShowFooter from "./AssessmentShowFooter";
import "./Assessments.scss";
const AssessmentDividerShow = (props) => {
  return (
    <>
      <div className="assessments-content-show">
        <div className="card-container mb-40 ">
          <div className="card-content  d-flex justify-content-center">
            <div
              className=" "
              style={{
                width: "240px",
                backgroundColor: "var(--secondary500)",
                height: "4px",
              }}
            ></div>
          </div>
          <AssessmentShowFooter
            data={props.data}
            status="inline"
            submitAction={props.submitAction}
          />
        </div>
      </div>
    </>
  );
};

export default AssessmentDividerShow;
