import React from 'react';
import Breadcrumbs from '../../atoms/Breadcrumbs';
import './TitleSection.scss'

function TitleSection(props) {
    return (
        <>
            {
                props.data && (
                    <div className="col-md-12">
                        <Breadcrumbs data={props.data} titleheader={props.titleheader} />
                    </div>
                )
            }
            <div className="col-md-12 header-breadcrumbs">
                <div className="tx-header4">{props.titleheader}</div>
            </div>
        </>
    );
}

export default TitleSection;
