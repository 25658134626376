import React from 'react';
import { Footer, Navbar, SubjectList } from '../../../components/organisms';

function Subjects() {
  return (
      <>
        <Navbar/>
        <div className="height-82"></div>
        <SubjectList />
        <Footer/>
      </>
  )
}

export default Subjects;
