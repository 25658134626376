import React, { useState, useEffect } from "react";
import "../Modal.scss";
import * as Yup from "yup";
import RestAPI from "../../../../config/RestAPI";
import Buttons from "../../../atoms/Buttons";
import Modal from "react-modal";
import FormikControl from "../../../atoms/Formik/FormikControl";
import { useParams } from 'react-router';
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { contentToast } from "../../../atoms/Toast/ContentToast";
import { useSelector, useDispatch } from "react-redux";
import { useSubject } from '../../../services';
import {
  CheckCircleOutline,
  NotInterested,
} from '@mui/icons-material';

function ModalCertificate() {
  const { config, initData } = useSubject()
  const statusMODAL = "MODAL_MANAGE_CERTIFICATE";
  const isStatusMODAL = useSelector((state) => state.openModal);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    certificateFile: "",
    fakeCertificate: "",
  });
  const dataLogin = JSON.parse(localStorage.getItem('ATLAZ_Authentication'))

  const _validFileImage = [".jpg", ".jpeg"];
  const checkFormatImage = (value) => {
    if (value.length > 0) {
      let blnValid = false;
      for (let j = 0; j < _validFileImage.length; j++) {
        let sCurExtension = _validFileImage[j];
        if (value.substr(value.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }
      if (!blnValid) {
        return true
      }
    }
  }

  const [sizeImage1, setSizeImage1] = useState(0)
  const validateFileImage = (value) => {
    let error
    if (!value && sizeImage1 === 0) {

      error = "This field is required. Don't leave it empty!"
    }
    if (sizeImage1 > 1000000) {
      error = "File size is too large"
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!"
    }
    return error
  }
  const validateFileImageEdit = (value, init) => {
    let error
    if (init === '') {
      error = "This field is required. Don't leave it empty!"
    }
    if (sizeImage1 > 1000000) {
      error = "File size is too large"
    }
    if (value && checkFormatImage(value)) {
      error = "The image format is not suitable!"
    }
    return error
  }

  useEffect(() => {
    if (statusMODAL === isStatusMODAL.target && isStatusMODAL.status) {
      setModalOpen(true);
      if (isStatusMODAL.modal === "UPDATE") {
        setInitialValues({
          fakeCertificate: "",
          certificateFile: isStatusMODAL.crud.certificateFile ? isStatusMODAL.crud.certificateFile : "",
        });
      }
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isStatusMODAL]);

  const closeModal = () => {
    setInitialValues({
      certificateFile: "",
      fakeCertificate: "",
    });
    dispatch({
      type: "OPEN_MODAL",
      newValue: {
        status: false,
        target: "",
        crud: null,
        modal: "",
      },
    });
  };

  const onSubmit = (values) => {
    if(dataLogin.role <=2) {
      RestAPI.put(`subject/certificate/${isStatusMODAL.crud._id}`, values, config).then(() => {
        initData()
        dispatch({ type: 'LOADING_TABLE', newValue: true })
        toast.success(contentToast(
          (isStatusMODAL.modal === 'CREATE') ? "Certificate Submited!" : "Certificate Updated!",
          (isStatusMODAL.modal === 'UPDATE') ? "Success submit certificate." : "Success change certificate."), {
          icon: <CheckCircleOutline style={{ color: "var(--info500)", fontSize: '30px' }} />
        })
        closeModal()
      })
        .catch((err) => {
          toast.error(contentToast(
            "Failed to change status", "Your connection not stable. Try again."), {
            icon: <NotInterested style={{ color: "var(--danger500)", fontSize: '30px' }} />
          })
          closeModal()
        });
    }
  };

  return (
    <>
      <Modal isOpen={modalOpen} onRequestClose={() => closeModal()}>
        <div className="modal-content isForm">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form className="formFormik">
                <div className="modal-header">
                  <h5 className="tx-header5">
                    {isStatusMODAL.modal === "CREATE" ? "Upload" : "Update"}{" "}
                    Certificate
                  </h5>
                  <p className="tx-small neutral300">
                    {isStatusMODAL.modal === "CREATE"
                      ? "Choose image to upload certificate."
                      : "Choose image to update certificate."}
                  </p>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <label
                        htmlFor="imageCover"
                        className="form-label tx-small-po"
                      >
                        Certificate Image
                        <div className="tx-small neutral400">
                          For best quality, please upload with size 1028x785 pixel.
                        </div>
                      </label>
                      {
                        isStatusMODAL.modal === "CREATE" ?
                          (<FormikControl
                            addClass="isTaSmall isMoExSmall"
                            control='inputImageBox'
                            type='file'
                            name='fakeCertificate'
                            base64Name='certificateFile'
                            accept=".jpg, .jpeg"
                            onFileChange={(e) => setSizeImage1(e.size)}
                            validate={validateFileImage}
                            defImg={initialValues}
                          />) : (<FormikControl
                            addClass="isTaSmall isMoExSmall"
                            control='inputImageBox'
                            type='file'
                            name='fakeCertificate'
                            base64Name='certificateFile'
                            accept=".jpg, .jpeg"
                            onFileChange={(e) => setSizeImage1(e.size)}
                            validate={(e) => validateFileImageEdit(e, formik.values.certificateFile)}
                            defImg={initialValues}
                          />)
                      }
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Buttons
                    control="button"
                    type="reset"
                    btnExSmall
                    btnOutline
                    name="Cancel"
                    onClick={() => closeModal()}
                  />
                  {
                    dataLogin.role <=2 && (<Buttons
                      control="button"
                      type="submit"
                      name={
                        isStatusMODAL.modal === "CREATE" ? "Submit" : "Update"
                      }
                      addClass="btn-confirm"
                      btnExSmall
                      btnOutline
                      btnDisable={formik.isSubmitting}
                      disabled={formik.isSubmitting}
                    />)
                  }
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
}

export default ModalCertificate
